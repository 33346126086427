import { createSelector } from 'reselect';

import { ISRMembershipState } from './index';

import { RootState } from '../../index';
import { MembershipsType } from '../../../../models/types/MembershipState';

export const srMembershipSelector = (state: RootState) => state.srMembership;

export const srMembershipsSelector = createSelector(
  srMembershipSelector,
  (membershipState: ISRMembershipState): MembershipsType =>
    membershipState.srMemberships,
);
