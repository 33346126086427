import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';

import { useIsAuthenticated } from '@azure/msal-react';

import {
  SectionHeader,
  SectionWrapper,
} from '../../../../../../../components/Form/Form';
import { FormColumn } from '../../../../../../../components/UI/Form/FormColumn/FormColumn';
import FormDateOfBirth from '../../../../../../../components/UI/Form/FormMemberDateOfBirth/FormMemberDateOfBirth';
import { FormInput } from '../../../../../../../components/UI/Form/FormInput/FormInput';
import { NumberFormInput } from '../../../../../../../components/UI/Form/FormInput/NumberFormInput';
import { FormRow } from '../../../../../../../components/UI/Form/FormRow/FormRow';
import { CustomForm } from '../../../../../../../models/types/CustomForm';
import {
  formatPhoneNumber,
  parsePhoneNumber,
  PHONE_NUMBER_MAX_LENGTH,
} from '../../../../../../../utils/phoneNumberHelpers';
import {
  MemberDetailsFields,
  MemberTitleOptions,
} from './memberDetailsForm.models';
import { StyledFormSelect } from './memberDetailsForm.styles';
import { selectedMembershipPlanSelector } from '../../../../../../../store/redux-slices/membership/airAmbulance/selectors';
import { MemberType } from '../../../../../../../models/enums/MemberType';
import { getFieldPropertiesForGroup } from '../../../../../../../utils/formGroupFieldHelpers';
import InfoComponent from '../../../../../../../components/UI/InfoComponent/InfoComponent';
import { SectionTitle } from '../../../../../../../components/Typography/Typography';

const MemberDetailsForm = ({ className, disabled, name }: CustomForm) => {
  const { t } = useTranslation();
  const membershipPlan = useSelector(selectedMembershipPlanSelector);
  const isAuth = useIsAuthenticated();

  return (
    <SectionWrapper className={className}>
      <SectionHeader>
        <SectionTitle>{t('headers.member-details')}</SectionTitle>
        {isAuth && (
          <InfoComponent
            tooltip={t('phrase.if-want-change-data-contact-with-support')}
          />
        )}
      </SectionHeader>
      <FormColumn>
        <FormRow>
          <FormRow isSubRow>
            <Field
              component={StyledFormSelect}
              disabled={disabled}
              isClearable
              label={t('fields.title.label')}
              options={MemberTitleOptions}
              {...getFieldPropertiesForGroup({
                inputId: 'add-membership-member-title-input',
                name: MemberDetailsFields.PersonPrefix,
                groupName: name,
              })}
            />
            <Field
              component={FormInput}
              disabled={disabled}
              {...getFieldPropertiesForGroup({
                inputId: 'add-membership-member-first-name-input',
                name: MemberDetailsFields.FirstName,
                groupName: name,
              })}
            />
          </FormRow>
          <FormRow>
            <Field
              component={FormInput}
              disabled={disabled}
              {...getFieldPropertiesForGroup({
                inputId: 'add-membership-member-last-name-input',
                name: MemberDetailsFields.LastName,
                groupName: name,
              })}
            />
          </FormRow>
        </FormRow>
        <FormRow>
          <Field
            component={NumberFormInput}
            disabled={disabled}
            label={t('fields.phoneNumberOptional.label')}
            {...getFieldPropertiesForGroup({
              inputId: 'add-membership-member-phone-input',
              name: MemberDetailsFields.PhoneNumber,
              groupName: name,
            })}
            formatValue={formatPhoneNumber}
            parseValue={parsePhoneNumber}
            maxLength={PHONE_NUMBER_MAX_LENGTH}
          />
          <Field
            component={FormDateOfBirth}
            disabled={disabled}
            {...getFieldPropertiesForGroup({
              inputId: 'add-membership-member-date-of-birth-input',
              name: MemberDetailsFields.DateOfBirth,
              groupName: name,
            })}
            memberType={MemberType.Primary}
            memberPlanId={membershipPlan?.planId}
          />
        </FormRow>
      </FormColumn>
    </SectionWrapper>
  );
};

export default MemberDetailsForm;
