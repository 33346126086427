export enum RoutePath {
  Home = '/',
  SomethingWentWrong = '/something-went-wrong',
  AddMembership = '/add-membership',
  Wizard = 'wizard',
  AddMembershipPlan = 'plan',
  AddMembershipMemberDetails = 'member-details',
  AddMembershipPayment = 'payment',
  AddMembershipConfirmation = '/confirmation',
  Login = '/login',
  ForgotPassword = '/forgot-password',
  ForgotPasswordSuccess = '/forgot-password-success',
  SetPassword = '/set-password',
  ResetPasswordSuccess = '/reset-password-success',
  ResetPasswordFailed = '/reset-password-failed',
  Profile = '/profile',
  AddMembershipPlanFullPath = '/add-membership/wizard/plan',
  AddMembershipMemberDetailsFullPath = '/add-membership/wizard/member-details',
  AddMembershipPaymentFullPath = '/add-membership/wizard/payment',
  NotFoundPage = '/404',
  LoggedOut = '/logged-out',
  Callback = '/callback',
  EnrollCallback = '/enrollcallback',
}
