import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

import {
  StandardText,
  StandardTextBold,
} from '../../../../../../../components/Typography/Typography';
import { mq } from '../../../../../../../utils/mediaQueries';

export const Wrapper = styled.div`
  width: 100%;
  margin-top: 0.125em;
  display: flex;
  flex-direction: column;
  gap: 1.125rem;

  ${mq.xSmall(css`
    margin-top: 1.375rem;
    flex-direction: row;
    gap: 1.5rem;
  `)}
`;

export const ExpiredCardText = styled(StandardText)`
  color: ${({ theme }) => theme.color.error};
  display: flex;
  align-items: center;
  gap: 0.25rem;
`;

type CardNumberProps = {
  disabled?: boolean;
};

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const CreditCardTypeText = styled.span`
  font: 700 1rem / 3rem ${({ theme }) => theme.font.gotham};
  color: ${({ theme }) => theme.color.black1};
`;

export const CardTypeImage = styled.img`
  height: 2rem;
`;

export const CardNumber = styled(StandardTextBold)<CardNumberProps>`
  white-space: nowrap;
  margin-left: 0.5rem;

  ${({ disabled, theme }) =>
    disabled &&
    css`
      color: ${theme.color.dark2};
    `}
`;
