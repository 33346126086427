import styled from '@emotion/styled/macro';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const TitleContainer = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export const DescriptionContainer = styled.div`
  font: normal normal 1rem/1.5rem ${({ theme }) => theme.font.gotham};
  color: ${({ theme }) => theme.color.dark1};
`;
