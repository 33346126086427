import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IMembershipPlansTreeModel } from '../../../../models/interfaces/MembershipPlansTreeModel';
import { clearState } from '../../../globalActions';

export interface IAAPlansState {
  membershipPlans: IMembershipPlansTreeModel;
}

const initialState: IAAPlansState = {
  membershipPlans: null,
};

const aaPlansSlice = createSlice({
  name: 'aaPlans',
  initialState,
  reducers: {
    setAAMembershipPlans: (
      state,
      { payload }: PayloadAction<IMembershipPlansTreeModel>,
    ) => {
      state.membershipPlans = payload;
    },
    clearAAMembershipPlans: (state) => {
      state.membershipPlans = initialState.membershipPlans;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearState, () => initialState);
  },
});

export const { setAAMembershipPlans, clearAAMembershipPlans } =
  aaPlansSlice.actions;
export default aaPlansSlice.reducer;
export const plansReducerName = aaPlansSlice.name;
