import axios, { AxiosResponse } from 'axios';

import config from '../../../config/config';
import { IPerson } from '../../../models/interfaces/Person';
import { IUpdatePersonModel } from '../../../models/interfaces/UpdatePersonModel';

const MainUrl = '/api/personaldata';

export const getPerson = (
  cancelToken?: AbortSignal,
): Promise<AxiosResponse<IPerson>> =>
  axios.get(`${MainUrl}`, {
    signal: cancelToken,
    baseURL: config.peopleManagementApi.baseUrl,
  });

export const updatePerson = (
  data: IUpdatePersonModel,
  cancelToken?: AbortSignal,
): Promise<AxiosResponse<void>> =>
  axios.put(`${MainUrl}`, data, {
    signal: cancelToken,
    baseURL: config.peopleManagementApi.baseUrl,
  });
