import { createSliceSaga, SagaType } from 'redux-toolkit-saga';
import { PayloadAction } from '@reduxjs/toolkit';
import { put, call } from 'typed-redux-saga/macro';

import { Log } from '../../../utils/logger';
import { ILoadMembershipPromotionBannerPayload } from './models';
import { updateProcessState } from '../../redux-slices/processes';
import { MEMBERSHIP_PROMOTION_LOAD_PROCESSING } from '../../redux-slices/processes/constants';
import { getMembershipPromotionBanner } from './api';
import { setBannerInfo } from '../../redux-slices/membershipPromotion';

const membershipPromotionSlice = createSliceSaga({
  caseSagas: {
    *loadMembershipPromotionBanner({
      payload: { error, success, cancellationToken },
    }: PayloadAction<ILoadMembershipPromotionBannerPayload>) {
      try {
        yield put(updateProcessState(MEMBERSHIP_PROMOTION_LOAD_PROCESSING));

        const response = yield* call(
          getMembershipPromotionBanner,
          cancellationToken,
        );

        yield put(setBannerInfo(response.data));
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(MEMBERSHIP_PROMOTION_LOAD_PROCESSING));
      }
    },
  },
  name: 'membership-saga',
  sagaType: SagaType.TakeLatest,
});

export default membershipPromotionSlice.saga;
export const { loadMembershipPromotionBanner } =
  membershipPromotionSlice.actions;
export const { actions } = membershipPromotionSlice;
