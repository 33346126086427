import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

import { mq } from '../../../../../../../../utils/mediaQueries';

export const Wrapper = styled.div`
  padding: 2rem 0;
  background-color: ${({ theme }) => theme.color.hover};
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  padding: 2rem 1rem;
  display: flex;
  justify-content: space-between;

  ${mq.xSmall(css`
    padding: 2rem 1.5rem;
  `)}
`;

export const TotalText = styled.h3`
  font: normal bold 1rem/1.125rem ${({ theme }) => theme.font.gotham};
  color: ${({ theme }) => theme.color.primary};
`;
