import { useTranslation } from 'react-i18next';

import { IRetailMembershipOrderSummaryModel } from '../../../../../../../../models/interfaces/RetailMembershipOrderSummaryModel';
import { Wrapper } from './pricingOverview.styles';
import PricingOverviewItem from './PricingOverviewItem/PricingOverviewItem';

type PricingOverviewType = {
  data: IRetailMembershipOrderSummaryModel;
};

const PricingOverview = ({ data }: PricingOverviewType) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <PricingOverviewItem
        label={t('properties.membership')}
        value={data?.regularPrice}
      />
      {data?.promoCode && (
        <PricingOverviewItem
          label={t('properties.promotionCode')}
          value={+`-${data?.promoCodeDiscount}`}
          description={
            data?.isSubscriptionPromoCode
              ? t('phrase.promo-code-organization', {
                  promoCode: data?.promoCode,
                  organizationName: data?.promoCodeOwner,
                })
              : data?.promoCode
          }
        />
      )}
      {data?.autoRenewalDiscount && (
        <PricingOverviewItem
          label={t('properties.autoRenewal')}
          value={+`-${data?.autoRenewalDiscount}`}
        />
      )}
    </Wrapper>
  );
};

export default PricingOverview;
