import { createSliceSaga, SagaType } from 'redux-toolkit-saga';
import { put, call } from 'typed-redux-saga/macro';
import { PayloadAction } from '@reduxjs/toolkit';

import { AxiosResponse } from 'axios';

import { Log } from '../../../../utils/logger';
import {
  IAddSRFamilyMemberPayload,
  ILoadSRMembershipsPayload,
  IRemoveSRFamilyMemberPayload,
} from './models';
import { updateProcessState } from '../../../redux-slices/processes';
import {
  FAMILY_MEMBER_ADDING_PROCESSING,
  FAMILY_MEMBER_REMOVING_PROCESSING,
  MEMBERSHIPS_SR_LOAD_PROCESSING,
} from '../../../redux-slices/processes/constants';
import {
  createSRSecondaryMember,
  deleteSRSecondaryMember,
  getSRMemberships,
} from './api';
import { IMemberships } from '../../../../models/interfaces/IMemberships';
import { setSRMemberships } from '../../../redux-slices/membership/securityResponse';

const srMembershipSlice = createSliceSaga({
  caseSagas: {
    *addSRFamilyMember({
      payload: { error, success, data, membershipId, cancellationToken },
    }: PayloadAction<IAddSRFamilyMemberPayload>) {
      try {
        yield put(updateProcessState(FAMILY_MEMBER_ADDING_PROCESSING));
        yield call(
          createSRSecondaryMember,
          membershipId,
          data,
          cancellationToken,
        );
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(FAMILY_MEMBER_ADDING_PROCESSING));
      }
    },
    *removeSRFamilyMember({
      payload: { error, success, membershipId, memberId, cancellationToken },
    }: PayloadAction<IRemoveSRFamilyMemberPayload>) {
      try {
        yield put(updateProcessState(FAMILY_MEMBER_REMOVING_PROCESSING));
        yield call(
          deleteSRSecondaryMember,
          membershipId,
          memberId,
          cancellationToken,
        );
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(FAMILY_MEMBER_REMOVING_PROCESSING));
      }
    },
    *loadSRMemberships({
      payload: { error, success, cancellationToken },
    }: PayloadAction<ILoadSRMembershipsPayload>) {
      try {
        yield put(updateProcessState(MEMBERSHIPS_SR_LOAD_PROCESSING));

        const response: AxiosResponse<IMemberships> = yield* call(
          getSRMemberships,
          cancellationToken,
        );

        yield put(setSRMemberships({ memberships: response.data.memberships }));
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(MEMBERSHIPS_SR_LOAD_PROCESSING));
      }
    },
  },
  name: 'sr-membership-saga',
  sagaType: SagaType.TakeLatest,
});

export default srMembershipSlice.saga;
export const { addSRFamilyMember, removeSRFamilyMember, loadSRMemberships } =
  srMembershipSlice.actions;
export const { actions } = srMembershipSlice;
