import { useTranslation } from 'react-i18next';

import { ReactComponent as WarningIcon } from '../../../../../../../../../assets/icons/error-icon.svg';

import { Wrapper } from './familyMemberMessage.styles';

const FamilyMemberMessage = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <WarningIcon />
      {t('phrase.family-member-has-age-out')}
    </Wrapper>
  );
};

export default FamilyMemberMessage;
