import FormErrorProvider from '../../../../components/FormErrorProvider/FormErrorProvider';
import { ModalForm } from '../../../../components/ModalForm/ModalForm';
import { ADD_CREDIT_CARD_MODAL } from './addCreditCard.consts';
import AddCreditCardModalForm from './AddCreditCardModalForm/AddCreditCardModalForm';

type AddCreditCardModalProps = {
  onCreditCardAdded?: () => void;
  title?: string;
  description?: string;
  modalName?: string;
};

const AddCreditCardModal = ({
  modalName = ADD_CREDIT_CARD_MODAL,
  ...props
}: AddCreditCardModalProps) => (
  <FormErrorProvider>
    <ModalForm name={modalName} isLoading={false}>
      <AddCreditCardModalForm {...props} modalName={modalName} />
    </ModalForm>
  </FormErrorProvider>
);

export default AddCreditCardModal;
