import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Dictionary } from '../models/types/Dictionary';

export const useRouteParams = () => {
  const [searchParams] = useSearchParams();

  const preparedParams = useMemo((): Dictionary<string> => {
    const result = {};
    for (const entry of searchParams.entries()) {
      const [param, value] = entry;
      result[param] = value;
    }
    return result;
  }, [searchParams]);
  return preparedParams;
};
