import { useMemo } from 'react';

import { MembershipPlanPeriodsType } from 'models/enums/MembershipPlanPeriodsType';

import { useActions } from '../../../../../hooks/useActions';
import {
  Membership,
  FamilyMember,
} from '../../../../../models/types/Membership';
import { updateModalState } from '../../../../../store/redux-slices/modals';
import { REMOVE_AA_FAMILY_MEMBER_MODAL } from './Modals/RemoveAAFamilyMemberModal/removeAAFamilyMemberModal.const';
import { ADD_AA_FAMILY_MEMBER_MODAL } from './Modals/AddAAFamilyMemberModal/addAAFamilyMemberModal.const';
import { AddAAFamilyMemberModalDetails } from './Modals/AddAAFamilyMemberModal/addAAFamilyMemberModal.models';
import { RemoveAAFamilyMemberModalDetails } from './Modals/RemoveAAFamilyMemberModal/removeAAFamilyMemberModal.models';

export const useAAMembership = (membership: Membership) => {
  const UpdateModalState = useActions(updateModalState);

  const {
    isExpired,
    renewable,
    familyMembers,
    membershipId,
    metadata: { canRenew, canAddMemberTypes },
  } = membership;

  const addFamilyMemberHandler = () => {
    const details: AddAAFamilyMemberModalDetails = {
      membership,
    };
    UpdateModalState([ADD_AA_FAMILY_MEMBER_MODAL + membershipId, details]);
  };

  const removeFamilyMemberHandler = (member: FamilyMember) => {
    const details: RemoveAAFamilyMemberModalDetails = {
      membership,
      member,
    };
    UpdateModalState([REMOVE_AA_FAMILY_MEMBER_MODAL + membershipId, details]);
  };

  const renewAvailable = !isExpired && renewable;

  const manualRenewButtonVisible = renewable && canRenew;

  const showFamilyMembers =
    !!familyMembers?.length || !!canAddMemberTypes?.length;

  const isRenewableByPlanType = useMemo(() => {
    switch (membership.membershipPlanTermType) {
      case MembershipPlanPeriodsType.Days_30:
      case MembershipPlanPeriodsType.Days_14:
        return false;
      case MembershipPlanPeriodsType.Annual:
        return true;
      default:
        return true;
    }
  }, [membership.membershipPlanTermType]);
  return {
    addFamilyMemberHandler,
    removeFamilyMemberHandler,
    renewAvailable,
    manualRenewButtonVisible,
    showFamilyMembers,
    isRenewableByPlanType,
  };
};
