import { useCallback } from 'react';

import { useActions } from '../../../../hooks/useActions';
import { useProcessing } from '../../../../hooks/useProcessing';
import { useCancellationToken } from '../../../../hooks/useTokenCancellation';
import { PERSON_DETAILS_LOAD_PROCESSING } from '../../../../store/redux-slices/processes/constants';
import { loadPersonDetails } from '../../../../store/saga-slices/personalData';
import { ILoadPersonDetailsPayload } from '../../../../store/saga-slices/personalData/models';

export const useEditMemberDetailsModal = () => {
  const LoadPersonDetails = useActions(loadPersonDetails);
  const cancellationToken = useCancellationToken();
  const dataLoading = useProcessing(PERSON_DETAILS_LOAD_PROCESSING);

  const onModalShowHandler = useCallback(() => {
    const payload: ILoadPersonDetailsPayload = { cancellationToken };
    LoadPersonDetails(payload);
  }, [LoadPersonDetails, cancellationToken]);

  return {
    onModalShowHandler,
    dataLoading,
  };
};
