import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

import Button from '../../../../../../../components/UI/Button/Button';
import { mq } from '../../../../../../../utils/mediaQueries';

export const StyledButton = styled(Button)`
  width: 100%;

  ${mq.xSmall(css`
    width: auto;
  `)}
`;
