import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

type WrapperType = {
  disabled?: boolean;
};

export const Wrapper = styled.div<WrapperType>`
  min-width: 15rem;
  height: 2.5rem;
  border-radius: 0.25rem;
  background-color: ${({ theme }) => theme.color.white};
  display: flex;
  flex-direction: row;
  padding: 0.25rem;
  justify-content: space-between;
  border: 0.0625rem solid ${({ theme }) => theme.color.secondary2};
  gap: 0.5rem;

  ${({ disabled, theme }) =>
    disabled &&
    css`
      border-color: ${theme.color.dark2};
    `}
`;
