import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

import { StyledInput } from '../../Input/input.styles';

import { InputWithButton } from '../../InputWithButton/InputWithButton';

type StyledFormInputWithButtonType = {
  error?: boolean;
};

export const StyledFormInputWithButton = styled(
  InputWithButton,
)<StyledFormInputWithButtonType>`
  width: 100%;
  height: 3rem;

  ${({ error, theme }) =>
    error &&
    css`
      ${StyledInput} {
        border-color: ${theme.color.error};
      }
    `}
`;
