import { SeverityLevel } from '@microsoft/applicationinsights-common';

import { appInsights } from './appInsights';

const appInsightsLog = {
  trackException(severityLevel: SeverityLevel, error: Error) {
    appInsights.trackException({
      exception: error,
      severityLevel,
    });
  },

  trace(severityLevel: SeverityLevel, message: string) {
    appInsights.trackTrace({
      message,
      severityLevel,
    });
  },
};

export const Log = {
  isApi2xxError(error: any): boolean {
    return (
      error.isAxiosError &&
      error.response &&
      error.response.status >= 400 &&
      error.response.status <= 500
    );
  },
  errorException(error: Error) {
    if (this.isApi2xxError(error)) {
      appInsightsLog.trackException(SeverityLevel.Warning, error);
    } else {
      appInsightsLog.trackException(SeverityLevel.Error, error);
    }
  },
  criticalException(error: Error) {
    appInsightsLog.trackException(SeverityLevel.Critical, error);
  },

  information(message: string) {
    appInsightsLog.trace(SeverityLevel.Information, message);
  },
  warning(message: string) {
    appInsightsLog.trace(SeverityLevel.Warning, message);
  },
  error(message: string) {
    appInsightsLog.trace(SeverityLevel.Error, message);
  },
  critical(message: string) {
    appInsightsLog.trace(SeverityLevel.Critical, message);
  },
};
