import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';

import { useActions } from '../../../../hooks/useActions';
import { useModalDetails } from '../../../../hooks/useModalDetails';
import { useProcessing } from '../../../../hooks/useProcessing';
import { useCancellationToken } from '../../../../hooks/useTokenCancellation';
import { updateModalState } from '../../../../store/redux-slices/modals';
import { CREDIT_CARD_DELETE_PROCESSING } from '../../../../store/redux-slices/processes/constants';
import { deleteCreditCard } from '../../../../store/saga-slices/payment';
import { IDeleteCreditCardPayload } from '../../../../store/saga-slices/payment/models';
import { DELETE_CREDIT_CARD_MODAL } from './deleteCreditCardModal.const';

type UseDeleteCreditCardModalProps = {
  onCreditCardDeleted?: () => void;
};

export const useDeleteCreditCardModal = ({
  onCreditCardDeleted,
}: UseDeleteCreditCardModalProps) => {
  const UpdateModalState = useActions(updateModalState);
  const cancellationToken = useCancellationToken();
  const alert = useAlert();
  const { t } = useTranslation();

  const DeleteCreditCard = useActions(deleteCreditCard);

  const { creditCardId }: { creditCardId: string } = useModalDetails(
    DELETE_CREDIT_CARD_MODAL,
  );

  const isDeleting = useProcessing(CREDIT_CARD_DELETE_PROCESSING);

  const onCancelHandler = () => {
    UpdateModalState(DELETE_CREDIT_CARD_MODAL);
  };

  const deleteCreditCardHandler = () => {
    const payload: IDeleteCreditCardPayload = {
      creditCardId,
      cancellationToken,
      error: () =>
        alert.error(t('phrase.credit-card-not-removed-successfully')),
      success: () => {
        UpdateModalState(DELETE_CREDIT_CARD_MODAL);
        onCreditCardDeleted?.();
        alert.success(t('phrase.credit-card-was-removed-successfully'));
      },
    };
    DeleteCreditCard(payload);
  };

  return {
    onCancelHandler,
    deleteCreditCardHandler,
    isDeleting,
  };
};
