import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

import { SectionWrapper } from '../../../../../components/Form/Form';

import AddressInformationsForm from '../../../../../components/Forms/AddressInformationsForm/AddressInformationsForm';
import { mq } from '../../../../../utils/mediaQueries';

export const StyledAddressInformationsForm = styled(AddressInformationsForm)`
  ${SectionWrapper} {
    margin-top: 2rem;

    ${mq.xSmall(css`
      margin-top: 2.5rem;
    `)}
  }
`;
