import { useTranslation } from 'react-i18next';
import { Field, useFormState } from 'react-final-form';

import FormAddress from '../AddressForm/AddressForm';
import { SectionTitle, SectionWrapper } from '../../Form/Form';
import { AddressInformationsFields } from './addressInformations.models';
import { CustomField } from '../../../models/types/CustomField';
import { StyledFormCheckbox, Wrapper } from './addressInformations.styles';

const AddressInformationsForm = ({ className, disabled }: CustomField) => {
  const { t } = useTranslation();
  const formState = useFormState();
  const value =
    formState.values[
      AddressInformationsFields.BillingAddressSameAsResidenceAddress
    ];

  return (
    <Wrapper className={className}>
      <SectionWrapper>
        <SectionTitle>{t('headers.residential-address')}</SectionTitle>
        <FormAddress
          disabled={disabled}
          name={AddressInformationsFields.ResidenceAddress}
        />
      </SectionWrapper>
      <Field
        component={StyledFormCheckbox}
        disabled={disabled}
        inputId="address-informations-checkbox"
        name={AddressInformationsFields.BillingAddressSameAsResidenceAddress}
        type="checkbox"
      />

      {!value && (
        <SectionWrapper>
          <SectionTitle>{t('headers.billing-address')}</SectionTitle>
          <FormAddress
            disabled={disabled}
            name={AddressInformationsFields.BillingAddress}
          />
        </SectionWrapper>
      )}
    </Wrapper>
  );
};

export default AddressInformationsForm;
