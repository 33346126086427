/* eslint-disable no-undefined */
import styled from '@emotion/styled/macro';

import { css } from '@emotion/react';

import { StyledButtonProps } from './button.models';
import Spinner from '../../Spinner/Spinner';

export const ButtonWrapper = styled.button<StyledButtonProps>`
  font-family: ${({ theme }) => theme.font.gotham};
  font-size: 1rem;
  font-weight: 700;
  line-height: 2rem;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  border: none;
  border-radius: 0.25rem;
  letter-spacing: 0.11em;
  height: 3rem;
  padding: 0.375rem 0.75rem;

  ${({ styleType, theme }) => {
    switch (styleType) {
      case 'standard':
        return css`
          background-color: ${theme.color.primary};
          color: ${theme.color.white};
        `;
      case 'primary':
        return css`
          background-color: ${theme.color.primary40};
          color: ${theme.color.white};
        `;
      case 'link':
        return css`
          background: none;
          color: ${theme.color.secondary3};
          height: auto;
          padding: 0;
          border-radius: 0;
          letter-spacing: 0.0125rem;

          &.disabled {
            & > {
              stroke: ${theme.color.dark2};
            }
          }
        `;
      case 'lighter-blue':
        return css`
          background-color: transparent;
          color: ${theme.color.secondary3};
          border: 0.063rem solid ${theme.color.secondary3};
        `;
      case 'light1':
        return css`
          background-color: ${theme.color.light1};
          color: ${theme.color.secondary1};
        `;
      case 'delete':
        return css`
          background: none;
          color: ${theme.color.error};

          &.disabled {
            & > {
              stroke: ${theme.color.dark2};
            }
          }
        `;
      case 'background-delete':
        return css`
          background: ${theme.color.error};
          color: ${theme.color.white};
        `;
      case 'standard-rounded':
        return css`
          width: 100%;
          background-color: ${theme.color.primary};
          color: ${theme.color.white};
        `;
      case 'icon':
        return css`
          background: none;
          color: none;
          font-weight: bold;
          padding: 0;
          height: auto;
          margin: 0;
          &.disabled {
            & > {
              stroke: ${theme.color.dark2};
            }
          }
        `;
      case 'secondary':
        return css`
          background-color: ${theme.color.secondary1};
          color: ${theme.color.white};
        `;
    }
  }}

  ${({ isLoading }) =>
    isLoading &&
    css`
      cursor: not-allowed;
    `}

    ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `}
`;

export const ButtonContentContainer = styled.div`
  display: flex;
  height: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  align-items: center;
  gap: 0.5rem;
  text-align: center;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const StyledSpinner = styled(Spinner)`
  width: auto;
  position: absolute;
`;

type ContentType = {
  isVisible: boolean;
};

export const Content = styled.div<ContentType>`
  ${({ isVisible }) =>
    !isVisible &&
    css`
      visibility: hidden;
    `}
  width: 100%;
`;
