import axios, { AxiosResponse } from 'axios';

import { IPartnershipInfoModel } from '../../../../models/interfaces/PartnershipModel';
import {
  QueryParamItem,
  prepareQueryParams,
} from '../../../../utils/queryParamsHelpers';

const MainUrl = '/api/partnership';

export const getPartnershipInfoRequest = (
  promoCode: string,
  cancelationToken?: AbortSignal,
): Promise<AxiosResponse<IPartnershipInfoModel>> => {
  const queryParams: QueryParamItem[] = [
    {
      name: 'promoCode',
      value: promoCode,
    },
  ];
  const params = prepareQueryParams({ queryParams });
  return axios.get(`${MainUrl}/partnerinfo${params}`, {
    signal: cancelationToken,
  });
};
