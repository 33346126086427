import { useTranslation } from 'react-i18next';

import {
  SimpleModal,
  ModalTitle,
  ModalButton,
} from '../../../../components/ModalCommon/ModalCommon';

import { StandardText } from '../../../../components/Typography/Typography';
import config from '../../../../config/config';
import { usePurchaseFailure } from './purchaseFailureActiveMembershipModal.hooks';
import { Email } from './purchaseFailureActiveMembershipModal.styles';

export const PURCHASE_FAILURE_ACTIVE_MEMBERSHIP_MODAL_NAME =
  'PURCHASE_FAILURE_ACTIVE_MEMBERSHIP_MODAL_NAME';

const PurchaseFailureActiveMembershipModal = () => {
  const { t } = useTranslation();
  const { seeYourPlanClick } = usePurchaseFailure();

  return (
    <SimpleModal name={PURCHASE_FAILURE_ACTIVE_MEMBERSHIP_MODAL_NAME}>
      <ModalTitle>{t('headers.purchase-failure')}</ModalTitle>
      <StandardText>{t('phrase.you-already-have-active-plan')}</StandardText>
      <Email>{`${t('properties.email')}: ${
        config.contact.airAmbulanceEmail
      }`}</Email>
      <StandardText>{`${t('properties.phone')}: ${
        config.contact.operationsCenterPhone
      }`}</StandardText>
      <ModalButton onClick={seeYourPlanClick}>
        {t(`buttons.see-your-plan`)}
      </ModalButton>
    </SimpleModal>
  );
};

export default PurchaseFailureActiveMembershipModal;
