import { useTranslation } from 'react-i18next';

import { CustomComponent } from '../../../models/types/CustomComponent';
import Button from '../../UI/Button/Button';
import { useWizardPage } from './wizardPage.hooks';
import { ButtonsContainer, Wrapper } from './wizardPage.styles';

type WizardPageType = CustomComponent & {
  backButton?: boolean;
  nextButtonLabel?: string;
  onNextStepClick?: () => void;
  onPrevStepClick?: () => void;
  isDirty?: boolean;
  canSubmit?: boolean;
  isLoading?: boolean;
};

const WizardPage = ({
  children,
  backButton,
  nextButtonLabel,
  onNextStepClick,
  onPrevStepClick,
  canSubmit,
  isLoading,
  isDirty,
}: WizardPageType) => {
  const { onPrevStepClickHandler } = useWizardPage({
    onNextStepClick,
    onPrevStepClick,
    isDirty,
  });

  const { t } = useTranslation();

  return (
    <Wrapper>
      {children}
      <ButtonsContainer>
        <Button
          isLoading={isLoading}
          styleType={canSubmit ? 'standard' : 'primary'}
          type="submit"
        >
          {isLoading
            ? t('buttons.processing')
            : nextButtonLabel || t('buttons.continue')}
        </Button>
        {backButton && (
          <Button
            styleType="lighter-blue"
            disabled={isLoading}
            onClick={onPrevStepClickHandler}
          >
            {t('buttons.back')}
          </Button>
        )}
      </ButtonsContainer>
    </Wrapper>
  );
};

export default WizardPage;
