import { useTranslation } from 'react-i18next';

import { Form } from 'react-final-form';

import WizardPage from '../../../../../components/Wizard/WizardPage/WizardPage';
import ContactInformationForm from './Forms/ContactInformationForm/ContactInformationForm';
import PromotionCodeForm from './Forms/PromotionCodeForm/PromotionCodeForm';
import MembershipPlanForm from './Forms/MembershipPlanForm/MembershipPlanForm';
import { FormPage } from '../../../../../components/Form/Form';
import { StyledStandardText } from './addMembershipPlanForm.styles';
import { useAddMembershipPlanForm } from './addMembershipPlanForm.hooks';
import PurchaseFailureActiveMembersModal from '../../../Modals/PurchaseFailureActiveMembershipModal/PurchaseFailureActiveMembershipModal';
import PurchaseFailureIncorrectPlanModal from '../../../Modals/PurchaseFailureIncorrectPlanModal/PurchaseFailureIncorrectPlanModal';
import LoginConfirmationModal from '../../../Modals/LoginConfirmationModal/LoginConfirmationModal';

const AddMembershipPlanForm = () => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    validateForm,
    membershipIsValidation,
    isAuth,
    initState,
    membershipId,
    canChangePromoCode,
  } = useAddMembershipPlanForm();

  return (
    <>
      <PurchaseFailureActiveMembersModal />
      <PurchaseFailureIncorrectPlanModal />
      <LoginConfirmationModal />
      <Form
        keepDirtyOnReinitialize
        onSubmit={handleSubmit}
        initialValues={initState}
        validate={validateForm}
      >
        {({ dirty, values, valid, ...renderProps }) => (
          <FormPage onSubmit={renderProps.handleSubmit}>
            <WizardPage
              nextButtonLabel={t('buttons.start-enrollment')}
              isDirty={dirty}
              canSubmit={valid}
              isLoading={membershipIsValidation}
            >
              <StyledStandardText>
                {t('phrase.add-membership-plan-page-description')}
              </StyledStandardText>
              {!isAuth && (
                <ContactInformationForm disabled={membershipIsValidation} />
              )}
              <PromotionCodeForm
                disabled={membershipIsValidation || !canChangePromoCode}
              />
              <MembershipPlanForm
                disabled={membershipIsValidation}
                isRenewal={!!membershipId}
              />
            </WizardPage>
          </FormPage>
        )}
      </Form>
    </>
  );
};

export default AddMembershipPlanForm;
