import { useTranslation } from 'react-i18next';

import { Page } from '../../components/Page/Page';
import Spinner from '../../components/Spinner/Spinner';

import { PageTitle } from '../../components/Typography/Typography';
import AddMembershipRoutes from './addMembership.routes';

import { useMembershipPage } from './addMembershipPage.hooks';
import { StyledNeedHelpInfo } from './addMembershipPage.styles';

const AddMembershipPage = () => {
  const { t } = useTranslation();
  const { canShowMembershipWizard } = useMembershipPage();

  let content = <AddMembershipRoutes />;

  if (!canShowMembershipWizard) {
    content = <Spinner size="6rem" />;
  }

  return (
    <Page>
      <PageTitle>{t('headers.air-ambulance-membership')}</PageTitle>
      {content}
      <StyledNeedHelpInfo />
    </Page>
  );
};

export default AddMembershipPage;
