import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import TextField from '@mui/material/TextField';

import { transientOptions } from '../../../../utils/transientOptions';
import FieldLabel from '../../Field/FieldLabel';

type StyledTextFieldProps = {
  $isError?: boolean;
  disabled?: boolean;
};

export const StyledTextField = styled(
  TextField,
  transientOptions,
)<StyledTextFieldProps>`
  height: 100%;
  z-index: 1;

  .MuiInputBase-root {
    height: 100%;
    font: normal normal 1rem/1.5rem ${({ theme }) => theme.font.gotham};
    ${({ theme, disabled }) =>
      disabled &&
      css`
        color: ${theme.color.dark2};
      `}

    .MuiOutlinedInput-notchedOutline {
      border: 0.063rem solid
        ${({ theme, $isError }) =>
          $isError ? theme.color.error : theme.color.input_border}!important;
    }
  }
`;

export const StyledFieldLabel = styled(FieldLabel)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: transparent;
  width: 67%;
  left: 0.75rem;

  @media (pointer: fine) {
    left: 3rem;
  }
`;
