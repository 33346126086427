import { Outlet } from 'react-router-dom';

import { RouteProps } from 'react-router';

import { useSelector } from 'react-redux';

import Redirect from '../Shared/Redirect';
import { PartnershipLogo, Wrapper } from './wizardWrapper.styles';
import { useWizardWrapper } from './wizardWrapper.hooks';
import WizardStepper from './WizardStepper/WizardStepper';
import { partnershipLogoSelector } from '../../store/redux-slices/partnership/airAmbulance/selectors';

interface IWizardWrapperProps extends RouteProps {
  isStepperVisible?: boolean;
}

const WizardWrapper = ({ isStepperVisible }: IWizardWrapperProps) => {
  const { routesMatch, state, wizardPath } = useWizardWrapper();
  const partnershipLogo = useSelector(partnershipLogoSelector);

  return (
    <Wrapper>
      {isStepperVisible && <WizardStepper />}
      {!!partnershipLogo && (
        <PartnershipLogo
          src={`data:image/png;base64,${partnershipLogo}`}
          alt="partnership_logo"
        />
      )}
      {routesMatch && <Outlet />}
      {!routesMatch && <Redirect state={state} to={wizardPath} />}
    </Wrapper>
  );
};

export default WizardWrapper;
