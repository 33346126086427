import { AnySchema } from 'ajv';

import { IAddress } from '../../../models/interfaces/Address';

import { FieldErrorMessage } from '../../../models/interfaces/FieldErrorMessage';

import { validateFormData } from '../../../utils/validations/validateFormData';
import { addressFormSchema } from '../AddressForm/addressForm.validation';
import { AddressInformationsFields } from './addressInformations.models';

export const addressInformationsFormSchema: AnySchema | any = {
  type: 'object',
  properties: {
    [AddressInformationsFields.ResidenceAddress]: addressFormSchema,
  },
  dependencies: {
    [AddressInformationsFields.BillingAddressSameAsResidenceAddress]: {
      if: {
        properties: {
          [AddressInformationsFields.BillingAddressSameAsResidenceAddress]: {
            enum: [false],
          },
        },
      },
      then: {
        properties: {
          [AddressInformationsFields.BillingAddress]: addressFormSchema,
        },
      },
    },
  },
  required: [],
};

export const validateAddressInformationsForm = (
  data: IAddress,
  additionalErrors?: FieldErrorMessage[],
) => validateFormData(data, additionalErrors, addressInformationsFormSchema);
