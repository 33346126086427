import { useTranslation } from 'react-i18next';

import { RoutePath } from 'models/enums/RoutePath';

import Button from '../../UI/Button/Button';
import { ReactComponent as LogoutIcon } from '../../../assets/icons/logout-icon.svg';

import { NavigationProps } from '../Navigation';
import { LogoutNavItem, StyledNavLink, Wrapper } from '../navigation.styles';
import { useAuthNavigation } from './authNavigation.hooks';
import { Mobile, NotMobile } from '../../../utils/mediaQueries';

const AuthNavigation = ({ className, onClick }: NavigationProps) => {
  const { t } = useTranslation();
  const { logoutHandler } = useAuthNavigation({ onClick });
  return (
    <Wrapper className={className}>
      <NotMobile>
        <Button onClick={logoutHandler}>
          <LogoutIcon />
        </Button>
      </NotMobile>
      <Mobile>
        <StyledNavLink onClick={onClick} to={RoutePath.Profile}>
          {t('navigation.profile')}
        </StyledNavLink>
        <LogoutNavItem onClick={logoutHandler}>
          <LogoutIcon />
          {t('navigation.log-out')}
        </LogoutNavItem>
      </Mobile>
    </Wrapper>
  );
};

export default AuthNavigation;
