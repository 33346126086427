import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { MembershipPlanPeriodsType } from 'models/enums/MembershipPlanPeriodsType';

import { IMemberships } from '../../../../models/interfaces/IMemberships';
import { Membership } from '../../../../models/types/Membership';

import { ICreateMembershipMemberDetails } from '../../../../models/interfaces/ICreateMembershipMemberDetails';

import { ICreateMembershipPlan } from '../../../../models/interfaces/ICreateMembershipPlan';
import { IIndividualMembershipPaymentDetails } from '../../../../models/interfaces/IndividualMembershipPaymentDetails';
import { IRetailMembershipOrderSummaryModel } from '../../../../models/interfaces/RetailMembershipOrderSummaryModel';
import { clearState } from '../../../globalActions';
import { IMembershipPlansTreeModel } from '../../../../models/interfaces/MembershipPlansTreeModel';
import { ICreateMembershipPayment } from '../../../../models/interfaces/ICreateMembershipPayment';
import {
  prepareCreateMembershipMemberDetails,
  prepareCreateMembershipPayment,
  prepareCreateMembershipPlan,
} from './utils';
import { ProductType } from '../../../../models/enums/ProductType';
import { ICreateMembershipSuggestion } from '../../../../models/interfaces/CreateMembershipSuggestion';
import { ICreateMembershipAdditionalDataSuggestion } from '../../../../models/interfaces/CreateMembershipAdditionalDataSuggestion';
import { parseDate } from '../../../../utils/dateHelpers';

export interface IAAMembershipState {
  renewalSuggestionTabOption?: MembershipPlanPeriodsType;
  createMembershipPlan?: ICreateMembershipPlan;
  createMembershipMemberDetails?: ICreateMembershipMemberDetails;
  createMembershipPayment?: ICreateMembershipPayment;
  orderPaymentDetails?: IIndividualMembershipPaymentDetails;
  summary?: IRetailMembershipOrderSummaryModel;
  aaMemberships?: {
    active: Membership[];
    expired: Membership[];
  };
  createdAAMembershipId?: string;
  additionalSuggestionData?: ICreateMembershipAdditionalDataSuggestion;
}

const initialState: IAAMembershipState = {
  renewalSuggestionTabOption: null,
  createMembershipPlan: null,
  createMembershipMemberDetails: null,
  createMembershipPayment: null,
  orderPaymentDetails: null,
  summary: null,
  aaMemberships: {
    active: [],
    expired: [],
  },
  createdAAMembershipId: null,
  additionalSuggestionData: null,
};

const aaMembershipSlice = createSlice({
  initialState,
  name: 'aaMembership',
  reducers: {
    setCreateMembershipPlan: (
      state,
      { payload }: PayloadAction<ICreateMembershipPlan>,
    ) => {
      state.createMembershipPlan = payload;
    },
    setCreateMembershipMemberDetails: (
      state,
      { payload }: PayloadAction<ICreateMembershipMemberDetails>,
    ) => {
      state.createMembershipMemberDetails = payload;
    },
    setCreateMembershipPayment: (
      state,
      { payload }: PayloadAction<ICreateMembershipPayment>,
    ) => {
      state.createMembershipPayment = payload;
    },
    setMembershipSummary: (
      state,
      { payload }: PayloadAction<IRetailMembershipOrderSummaryModel>,
    ) => {
      state.summary = payload;
    },
    setOrderPaymentDetails: (
      state,
      { payload }: PayloadAction<IIndividualMembershipPaymentDetails>,
    ) => {
      state.orderPaymentDetails = payload;
    },
    setMembershipRenewalSuggestion: (
      state,
      {
        payload: { plans, suggestion },
      }: PayloadAction<{
        suggestion: ICreateMembershipSuggestion;
        plans: IMembershipPlansTreeModel;
      }>,
    ) => {
      state.createMembershipPlan = prepareCreateMembershipPlan(
        plans,
        suggestion,
      );
      state.renewalSuggestionTabOption = suggestion.membershipPlanTermType;
      state.createMembershipMemberDetails =
        prepareCreateMembershipMemberDetails(suggestion);
      state.createMembershipPayment =
        prepareCreateMembershipPayment(suggestion);
      state.additionalSuggestionData = {
        effectiveDate: parseDate(suggestion.effectiveDate),
      };
    },
    setAAMemberships: (state, { payload }: PayloadAction<IMemberships>) => {
      const memberships: Membership[] = payload.memberships.map((x) => ({
        ...x,
        productType: ProductType.AirAmbulance,
      }));
      state.aaMemberships.active = memberships.filter(
        (membership) => !membership.isExpired,
      );
      state.aaMemberships.expired = memberships.filter(
        (membership) => membership.isExpired,
      );
    },
    setCreatedMembershipId: (state, { payload }: PayloadAction<string>) => {
      state.createdAAMembershipId = payload;
    },
    clearCreatedMembershipId: (state) => {
      state.createdAAMembershipId = initialState.createdAAMembershipId;
    },
    clearCreateMembershipProcessData: (state) => {
      state.createMembershipMemberDetails =
        initialState.createMembershipMemberDetails;
      state.createMembershipPayment = initialState.createMembershipPayment;
      state.createMembershipPlan = initialState.createMembershipPlan;
      state.orderPaymentDetails = initialState.orderPaymentDetails;
      state.summary = initialState.summary;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearState, () => initialState);
  },
});

export const {
  setCreateMembershipPlan,
  clearCreateMembershipProcessData,
  setMembershipSummary,
  setCreateMembershipMemberDetails,
  setOrderPaymentDetails,
  setAAMemberships,
  setMembershipRenewalSuggestion,
  clearCreatedMembershipId,
  setCreateMembershipPayment,
  setCreatedMembershipId,
} = aaMembershipSlice.actions;
export default aaMembershipSlice.reducer;
export const membershipReducerName = aaMembershipSlice.name;
