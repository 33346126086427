import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { RoutePath } from '../../../models/enums/RoutePath';
import { NavigationProps } from '../Navigation';
import { StyledNavItem, StyledNavLink, Wrapper } from '../navigation.styles';
import { useNoAuthNavigation } from './noAuthNavigation.hooks';

const NoAuthNavigation = ({ className, onClick }: NavigationProps) => {
  const { t } = useTranslation();
  const { loginHandler } = useNoAuthNavigation({ onClick });
  const location = useLocation();
  const currentRelativePath = location.pathname;
  const wizardPages = [
    RoutePath.AddMembershipPlanFullPath.toString(),
    RoutePath.AddMembershipMemberDetailsFullPath.toString(),
    RoutePath.AddMembershipPaymentFullPath.toString(),
  ];
  const showEnrollLink = wizardPages.indexOf(currentRelativePath) < 0;

  return (
    <Wrapper className={className}>
      {showEnrollLink && (
        <StyledNavLink
          onClick={onClick}
          to={RoutePath.AddMembershipPlanFullPath}
        >
          {t('navigation.enroll')}
        </StyledNavLink>
      )}
      <StyledNavItem onClick={loginHandler}>
        {t('navigation.log-in')}
      </StyledNavItem>
    </Wrapper>
  );
};

export default NoAuthNavigation;
