import { useEffect } from 'react';

export const useClickBackButtonBrowser = (listener: (evt: any) => void) => {
  useEffect(() => {
    const handler = (evt: any) => {
      evt.preventDefault();
      evt.stopPropagation();
      listener && listener(evt);
    };
    window.addEventListener('popstate', handler);
    return () => window.removeEventListener('popstate', handler);
  }, [listener]);
};
