import { FallbackProps } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';

import ErrorPage from '../../components/ErrorPage/ErrorPage';
import ErrorImage from '../../assets/images/500.png';

const SomethingWentWrongPage = (props: FallbackProps) => {
  const { t } = useTranslation();
  return (
    <ErrorPage
      iconPath={ErrorImage}
      title={t('headers.something-went-wrong')}
      description={t('phrase.working-on-fix-problem')}
    />
  );
};

export default SomethingWentWrongPage;
