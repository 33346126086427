import { IMembershipPlanGroupModel } from 'models/interfaces/MembershipPlanGroupModel';

import { CustomField } from '../../../../models/types/CustomField';
import Tooltip from '../../Tooltip/Tooltip';
import { Icon, Label, Wrapper } from './tabOption.styles';

export interface ITabOptionProps<T> extends CustomField {
  icon?: JSX.Element;
  label: string;
  value: IMembershipPlanGroupModel;
  isActive?: boolean;
  tooltip?: string;
  onClick?: (value: IMembershipPlanGroupModel | T) => void;
  isDivider: boolean;
}

const TabOption = <T,>({
  icon,
  label,
  value,
  onClick,
  tooltip,
  ...props
}: ITabOptionProps<T>) => (
  <Wrapper
    {...props}
    onClick={() => (!props.disabled && !props.readOnly ? onClick(value) : {})}
  >
    <Tooltip title={tooltip} isShowing={!!tooltip} arrow>
      <>
        {icon && <Icon {...props}>{icon}</Icon>}
        <Label {...props}>{label}</Label>
      </>
    </Tooltip>
  </Wrapper>
);

export default TabOption;
