import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

import { mq } from '../../utils/mediaQueries';

export const Wrapper = styled.div`
  margin: 2.5rem 0;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  text-align: center;

  ${mq.xSmall(css`
    margin: 3.5rem 0;
  `)}
`;
