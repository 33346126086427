import { useCookies } from 'react-cookie';

import {
  CookieConsentsName,
  CookieConsentsValue,
} from './cookieConsent.models';

export const useCookieConsents = () => {
  const [cookie, setCookie] = useCookies([CookieConsentsName]);

  const acceptHandle = () => {
    setCookie(CookieConsentsName, CookieConsentsValue.Accept, { path: '/' });
  };

  const declineHandle = () => {
    setCookie(CookieConsentsName, CookieConsentsValue.Decline, { path: '/' });
  };

  const value = cookie.cookie_consents;
  return { acceptHandle, declineHandle, value };
};
