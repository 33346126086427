import { css, Theme } from '@emotion/react';

import { GlobalFonts } from './components/Typography/Fonts';

export const globalStyles = (theme: Theme) => css`
  ${GlobalFonts}

  body {
    margin: 0;
    font-family: ${theme.font.gotham};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  h1,
  h2,
  h3,
  h4,
  p,
  button {
    margin: 0;
    padding: 0;
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;
