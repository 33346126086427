import { createSelector } from 'reselect';

import { IErrorsState } from '.';
import { RootState } from '../index';

export const errorsStateSelector = (state: RootState) => state.errors;

export const errorsSelector = createSelector(
  errorsStateSelector,
  (errorsState: IErrorsState): string =>
    errorsState.error || errorsState.accessError,
);

export const accessErrorSelector = createSelector(
  errorsStateSelector,
  (errorsState: IErrorsState): string => errorsState.accessError,
);

export const notFoundErrorSelector = createSelector(
  errorsStateSelector,
  (errorsState: IErrorsState): string => errorsState.notFoundError,
);

export const authErrorSelector = createSelector(
  errorsStateSelector,
  (errorsState: IErrorsState): string => errorsState.authError,
);
