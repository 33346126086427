import { t } from 'i18next';

export const getOptionsFromArray = (
  values: string[],
  enumName?: string,
): { label: string; value: string }[] =>
  values.map((type) => ({
    label: enumName ? t(`enums.${enumName}.${type}`) : type,
    value: type,
  }));
