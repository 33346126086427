import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useIsAuthenticated } from '@azure/msal-react';

import { useActions } from '../../hooks/useActions';
import { loadMembershipPromotionBanner } from '../../store/saga-slices/membershipPromotion';
import { ILoadMembershipPromotionBannerPayload } from '../../store/saga-slices/membershipPromotion/models';
import { useCancellationToken } from '../../hooks/useTokenCancellation';
import { bannerInfoSelector } from '../../store/redux-slices/membershipPromotion/selectors';

export const useBanners = () => {
  const LoadMembershipPromotionBanner = useActions(
    loadMembershipPromotionBanner,
  );
  const cancellationToken = useCancellationToken();

  const bannerInfo = useSelector(bannerInfoSelector);
  const isAuth = useIsAuthenticated();

  useEffect(() => {
    if (isAuth) {
      return;
    }
    const payload: ILoadMembershipPromotionBannerPayload = {
      cancellationToken,
    };

    LoadMembershipPromotionBanner(payload);
  }, [LoadMembershipPromotionBanner, cancellationToken, isAuth]);

  return { bannerInfo, isAuth };
};
