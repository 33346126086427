import { useIsAuthenticated } from '@azure/msal-react';
import { useCallback, useMemo } from 'react';

import { RoutePath } from '../models/enums/RoutePath';
import { useNavigateSearch } from './useNavigationSearch';

export const useNavigationHomePage = () => {
  const isAuth = useIsAuthenticated();
  const navigate = useNavigateSearch();
  const homePagePath = useMemo(
    () => (isAuth ? RoutePath.Profile : RoutePath.AddMembershipPlanFullPath),
    [isAuth],
  );

  const navigateHomePage = useCallback(() => {
    navigate(homePagePath);
  }, [homePagePath, navigate]);

  return { navigateHomePage, homePagePath };
};
