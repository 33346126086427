import { ReactNode } from 'react';

import { RootPage } from '../Page/Page';
import PageFooter from '../PageFooter/PageFooter';
import PageHeader from '../PageHeader/PageHeader';

type LayoutType = {
  children: ReactNode;
};

const Layout = ({ children }: LayoutType) => (
  <RootPage>
    <PageHeader />
    <main>{children}</main>
    <PageFooter />
  </RootPage>
);

export default Layout;
