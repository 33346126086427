import { useCallback, useEffect, useMemo, useState } from 'react';
import { FieldInputProps } from 'react-final-form';

import { MembershipPlanPeriods } from 'models/enums/MembershipPlanPeriods';

import { getOptionsFromEnum } from 'utils/getOptionsFromEnum';

import { translatePlanPeriodPlanToType } from 'utils/translatePlanPeriodPlan';

import { CheckCardListItem } from '../../../../../../../../components/UI/CheckCardList/checkCardList.models';
import { ICreateMembershipPlanInput } from '../../../../../../../../models/interfaces/ICreateMembershipPlan';
import { IMembershipPlanGroupItemModel } from '../../../../../../../../models/interfaces/MembershipPlanGroupItemModel';
import { IMembershipPlanGroupModel } from '../../../../../../../../models/interfaces/MembershipPlanGroupModel';
import { IMembershipPlansTreeModel } from '../../../../../../../../models/interfaces/MembershipPlansTreeModel';
import { isPropertyEmpty } from '../../../../../../../../utils/propertyHelpers';
import {
  prepareGroupOption,
  preparePlanOption,
  prepareMembershipOption,
} from './membershipPlan.utils';

type UseMembershipPlanType = {
  membershipPlans: IMembershipPlansTreeModel;
  input: FieldInputProps<ICreateMembershipPlanInput, HTMLElement>;
  isRenewal?: boolean;
  selectedMembershipPlanPeriod?: MembershipPlanPeriods;
};

export const useMembershipPlan = ({
  membershipPlans,
  input: { onChange, value },
  isRenewal,
  selectedMembershipPlanPeriod,
}: UseMembershipPlanType) => {
  const [membershipPlanPeriodPlan, setMembershipPlanPeriodPlan] =
    useState<MembershipPlanPeriods>(
      selectedMembershipPlanPeriod || MembershipPlanPeriods.Annual,
    );

  const filteredMembershipPlans = useMemo(() => {
    if (!membershipPlans) {
      return null;
    }
    return {
      ...membershipPlans,
      groups: membershipPlans?.groups?.map((group) => ({
        ...group,
        items: group?.items?.filter(
          (item) =>
            item?.termType ===
            translatePlanPeriodPlanToType(membershipPlanPeriodPlan),
        ),
      })),
    };
  }, [membershipPlanPeriodPlan, membershipPlans]);
  const selectedPlanExists = useMemo(
    () =>
      !!filteredMembershipPlans &&
      isPropertyEmpty(
        filteredMembershipPlans?.groups
          ?.find((group) => group.id === value.membershipPlanGroup)
          ?.items?.find((plan) => plan.planId === value.membershipPlan.planId),
      ),
    [filteredMembershipPlans, value],
  );
  useEffect(() => {
    if (
      !filteredMembershipPlans ||
      filteredMembershipPlans?.groups?.length === 0 ||
      !selectedPlanExists
    ) {
      return;
    }

    onChange({
      membershipPlanGroup: filteredMembershipPlans?.groups?.[0].id,
      membershipPlan: filteredMembershipPlans?.groups?.[0].items[0],
    });
  }, [selectedPlanExists, filteredMembershipPlans, onChange, value]);

  const onPlanGroupChangeHandler = useCallback(
    (group: IMembershipPlanGroupModel) => {
      onChange({
        membershipPlan: group.items[0],
        membershipPlanGroup: group.id,
      });
    },
    [onChange],
  );

  const membershipPlanPeriodsTypeHandler = useCallback(
    (plan: MembershipPlanPeriods) => {
      setMembershipPlanPeriodPlan(plan);
    },
    [],
  );

  const membershipPlansGroupsOptions = useMemo(() => {
    if (!filteredMembershipPlans) {
      return [];
    }
    return filteredMembershipPlans.groups?.map((group) =>
      prepareGroupOption(group, value?.membershipPlanGroup, isRenewal),
    );
  }, [filteredMembershipPlans, isRenewal, value?.membershipPlanGroup]);

  const currentGroupPlan = useMemo(
    () =>
      filteredMembershipPlans?.groups?.find(
        (x) => x.id === value.membershipPlanGroup,
      ),
    [filteredMembershipPlans?.groups, value.membershipPlanGroup],
  );

  const membershipGroupPlanOptions =
    useMemo((): CheckCardListItem<IMembershipPlanGroupItemModel>[] => {
      if (!value?.membershipPlanGroup || !filteredMembershipPlans) {
        return [];
      }

      return currentGroupPlan?.items.map((plan) =>
        preparePlanOption(plan, value?.membershipPlan?.planId, isRenewal),
      );
    }, [
      currentGroupPlan,
      filteredMembershipPlans,
      isRenewal,
      value?.membershipPlan?.planId,
      value?.membershipPlanGroup,
    ]);

  const onMembershipGroupPlanChangeHandler = useCallback(
    (id: string) => {
      const currentPlan = currentGroupPlan?.items?.find(
        (plan) => plan?.planName === id,
      );

      onChange({
        membershipPlan: currentPlan,
        membershipPlanGroup: value.membershipPlanGroup,
      });
    },
    [currentGroupPlan, onChange, value.membershipPlanGroup],
  );
  const groupDescription = useMemo(() => {
    switch (membershipPlanPeriodPlan) {
      case MembershipPlanPeriods.Days_14:
      case MembershipPlanPeriods.Days_30:
        return currentGroupPlan?.shortTermMembershipDescription;
      case MembershipPlanPeriods.Annual:
        return currentGroupPlan?.planGroupDescription;
      default:
        return '';
    }
  }, [
    currentGroupPlan?.shortTermMembershipDescription,
    currentGroupPlan?.planGroupDescription,
    membershipPlanPeriodPlan,
  ]);

  const membershipPeriodOptions = useMemo(() => {
    const options = getOptionsFromEnum(MembershipPlanPeriods);

    if (selectedMembershipPlanPeriod) {
      return options.map((item) =>
        prepareMembershipOption(item, selectedMembershipPlanPeriod),
      );
    }
    return options;
  }, [selectedMembershipPlanPeriod]);

  return {
    membershipPlansGroupsOptions,
    onPlanGroupChangeHandler,
    membershipGroupPlanOptions,
    planGroup: currentGroupPlan,
    onMembershipGroupPlanChangeHandler,
    currentValue: value?.membershipPlan?.planName,
    groupDescription,
    membershipPlanPeriodsTypeHandler,
    membershipPlanPeriodPlan,
    membershipPeriodOptions,
  };
};
