import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { THEME } from '../../../config/theme';
import { mq } from '../../../utils/mediaQueries';

import Button from '../../../components/UI/Button/Button';

import editIcon from '../../../assets/icons/edit-icon.svg';

export const Root = styled.div`
  align-items: start;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  padding: 0;
  width: 100%;

  ${mq.medium(css`
    justify-content: start;
    /* padding: 2.5rem 1.5rem; */
  `)}
`;

export const CreditCardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.5rem;
  gap: 0.5rem;
`;

export const FullName = styled.p`
  margin-bottom: 0.5rem;
`;

export const DetailsWrapper = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-auto-columns: 1fr;
  gap: 2.5rem;
  width: 100%;

  ${mq.medium(css`
    grid-auto-flow: column;
    grid-auto-columns: 0.8fr 0.8fr 1.2fr;
  `)}
  ${mq.large(css`
    grid-auto-flow: row;
    grid-auto-columns: 1fr;
  `)}
`;

export const DetailsTitle = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.color.dark1};
  display: flex;
  justify-content: space-between;
  font: normal 500 1rem/1.2rem ${({ theme }) => theme.font.gotham};
  margin: 0;
`;

export const DetailsContainer = styled.div`
  margin-top: 2rem;
  display: grid;
  gap: 0.25rem;
  grid-auto-columns: 1fr;

  ${mq.large(css`
    gap: 1.5rem;
    grid-auto-columns: 0.7fr 1.3fr;
  `)}
`;

export const Label = styled.div`
  align-items: center;
  color: #afb5b7;
  font: normal 400 0.825rem/1.5rem ${({ theme }) => theme.font.gotham};
  grid-column: 1;
  white-space: nowrap;
`;

export const Value = styled.div`
  align-items: center;
  color: #212721;
  font: normal 400 1rem/1.5rem ${({ theme }) => theme.font.gotham};
  grid-column: 1;
  ${mq.large(css`
    grid-column: 2;
  `)}
`;

export const EllipsisValue = styled.div`
  align-items: center;
  color: #212721;
  font: normal 400 1rem/1.5rem ${({ theme }) => theme.font.gotham};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 17rem;

  ${mq.medium(css`
    width: 12.5rem;
  `)}
  ${mq.large(css`
    grid-column: 2;
  `)}
`;

export const PaymentContainer = styled.div`
  padding: 2.25rem 0 0 0;
  border-left: 0;
  border-top: 1px solid ${THEME.color.light1};

  ${mq.medium(css`
    padding: 0 0 0 1.125rem;
    border-top: 0;
    border-left: 1px solid ${THEME.color.light1};
  `)}
  ${mq.large(css`
    border-left: 0;
    border-top: 1px solid ${THEME.color.light1};
    padding: 1.5rem 0 0 0;
  `)}
`;

export const EditButton = styled(Button)`
  align-items: center;
  background: url(${editIcon});
  background-repeat: no-repeat;
  background-position: center left;
  display: flex;
  font-weight: normal;
  gap: 1rem;
  height: 1.25rem;
  padding-left: 2rem;
  width: 1.25rem;
`;
EditButton.defaultProps = {
  styleType: 'link',
};

export const AddressContainer = styled.div`
  grid-column: 1;
  width: auto;
  ${mq.large(css`
    grid-column: 2;
    width: 12.5rem;
  `)}
`;

export const TooltipAddress = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
