import axios, { AxiosResponse } from 'axios';

import config from '../../../config/config';

import { HubSpotRequestObject } from './models';

export const submitDataForm = (
  data: HubSpotRequestObject,
  cancelToken?: AbortSignal,
): Promise<AxiosResponse<void>> =>
  axios.post(
    `${config.hubSpot.apiFormSubmitUrl}/${config.hubSpot.portalId}/${config.hubSpot.formId}`,
    data,
    {
      signal: cancelToken,
    },
  );
