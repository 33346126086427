import { Field } from 'react-final-form';
import { Trans } from 'react-i18next';

import FormCheckbox from '../../../../../../../components/UI/Form/FormCheckbox/FormCheckbox';
import { FormColumn } from '../../../../../../../components/UI/Form/FormColumn/FormColumn';
import { FormRow } from '../../../../../../../components/UI/Form/FormRow/FormRow';
import { TextLinkButton } from '../../../../../../../components/UI/TextLinkButton/TextLinkButton';
import { CustomForm } from '../../../../../../../models/types/CustomForm';
import { useAgreementForm } from './agreementForm.hooks';
import { AgreementFields } from './agreementForm.models';
import { SectionWrapper } from './agreementForm.styles';

const AgreementForm = ({ disabled, className }: CustomForm) => {
  const { showTermsAndConditions } = useAgreementForm();

  return (
    <SectionWrapper className={className}>
      <FormColumn>
        <FormRow>
          <Field
            component={FormCheckbox}
            inputId="add-membership-agreement-input"
            disabled={disabled}
            label={
              <Trans
                i18nKey="phrase.read-and-agree-terms"
                components={{
                  myLink: <TextLinkButton onClick={showTermsAndConditions} />,
                }}
              />
            }
            name={AgreementFields.MemberAgreedTermsAndConditions}
            type="checkbox"
          />
        </FormRow>
      </FormColumn>
    </SectionWrapper>
  );
};

export default AgreementForm;
