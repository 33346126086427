import { RoutePath } from '../../models/enums/RoutePath';
import { useNavigateSearch } from '../../hooks/useNavigationSearch';
import { useAccountInfo } from '../../hooks/useAccountInfo';

export const useHeaderLoggedUser = () => {
  const navigate = useNavigateSearch();
  const { firstName, shortName } = useAccountInfo();

  const handleNavigateToProfile = () => {
    navigate(RoutePath.Profile);
  };

  return {
    handleNavigateToProfile,
    firstName,
    shortName,
  };
};
