import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

import { mq } from '../../utils/mediaQueries';

export const MainWrapper = styled.div`
  width: 100%;
  position: relative;
`;

export const Wrapper = styled.header`
  background-color: ${({ theme }) => theme.color.primary};
  display: flex;
  align-items: center;
  padding: 0 1rem;
  justify-content: space-between;
  position: fixed;
  z-index: 100;
  top: 0;
  box-sizing: border-box;
  width: 100%;
  height: 3rem;

  ${mq.xSmall(css`
    padding: 0 5rem;
    height: 7rem;
  `)}

  ${mq.medium(css`
    padding: 0 5rem;
  `)}
`;

export const RightContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;
