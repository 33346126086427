import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

import { StandardText } from '../../../../../../../../components/Typography/Typography';
import { mq } from '../../../../../../../../utils/mediaQueries';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const DescriptionText = styled(StandardText)`
  color: ${({ theme }) => theme.color.dark1};
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${mq.xSmall(css`
    flex-direction: row;
    gap: 0.5rem;
  `)}
`;
