import { InteractionStatus } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { useEffect } from 'react';

import Spinner from '../../components/Spinner/Spinner';
import { b2cPolicies } from '../../config/authConfig';
import { useNavigateSearch } from '../../hooks/useNavigationSearch';
import { useRouteParams } from '../../hooks/useRouteParams';
import { RoutePath } from '../../models/enums/RoutePath';

const SetPassword = () => {
  const { instance, inProgress } = useMsal();

  const { email } = useRouteParams();

  const navigate = useNavigateSearch();

  useEffect(() => {
    if (!email) {
      navigate(RoutePath.AddMembershipPlanFullPath);
      return;
    }

    if (inProgress === InteractionStatus.None) {
      instance.loginRedirect(
        b2cPolicies.authorities.signInWithParams({
          resetPassword: 'true',
          email: encodeURIComponent(email),
        }),
      );
    }
  }, [email, inProgress, instance, navigate]);

  return <Spinner size="10rem" />;
};

export default SetPassword;
