import { FieldRenderProps } from 'react-final-form';

import { FormFieldWrapper } from '../../../Form/Form';
import { ICheckboxProps } from '../../Checkbox/Checkbox';
import { ErrorLabel } from '../../Field/Field';
import { useFormCheckbox } from './formCheckbox.hooks';
import { StyledCheckbox } from './formCheckbox.styles';

export interface IFormCheckboxProps
  extends FieldRenderProps<boolean>,
    ICheckboxProps {
  label: string;
}

const FormCheckbox = ({
  input,
  meta: { error, touched },
  inputId,
  onChange,
  label,
  ...props
}: IFormCheckboxProps) => {
  const { onChangeHandler, currentLabel } = useFormCheckbox({
    onChange,
    input,
    label,
  });
  return (
    <FormFieldWrapper>
      <StyledCheckbox
        {...props}
        id={inputId}
        isError={touched && error}
        checked={input.checked}
        onChange={onChangeHandler}
        label={currentLabel}
      />
      {touched && error && <ErrorLabel>{error}</ErrorLabel>}
    </FormFieldWrapper>
  );
};

export default FormCheckbox;
