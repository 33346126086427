import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import ModalFormPage from '../../../../../components/ModalFormPage/modalFormPage';
import { useEditMemberDetailsModalForm } from './editMemberDetailsModalForm.hooks';
import { StyledAddressInformationsForm } from './editMemberDetailsModalForm.styled';
import MemberPersonalDetailsForm from './Forms/MemberPersonalDetailsForm/MemberPersonalDetailsForm';

type EditMemberDetailsModalFormProps = {
  onEditedMember?: () => void;
};

const EditMemberDetailsModalForm = ({
  onEditedMember,
}: EditMemberDetailsModalFormProps) => {
  const { t } = useTranslation();

  const {
    submitForm,
    validateForm,
    personUpdating,
    onCancelHandler,
    initForm,
    personName,
  } = useEditMemberDetailsModalForm({
    onEditedMember,
  });

  return (
    <Form
      initialValues={initForm}
      keepDirtyOnReinitialize
      onSubmit={submitForm}
      validate={validateForm}
    >
      {({ ...renderProps }) => (
        <ModalFormPage
          handleSubmit={renderProps.handleSubmit}
          onCancel={onCancelHandler}
          isProcessing={personUpdating}
          title={t('headers.edit-personal-details')}
        >
          <MemberPersonalDetailsForm
            disabled={personUpdating}
            personName={personName}
          />
          <StyledAddressInformationsForm disabled={personUpdating} />
        </ModalFormPage>
      )}
    </Form>
  );
};

export default EditMemberDetailsModalForm;
