import styled from '@emotion/styled/macro';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2.5rem;
  align-items: center;
  width: 100%;
`;

export const PartnershipLogo = styled.img`
  max-height: 5rem;
  margin-top: 2rem;
`;
