import { createSelector } from 'reselect';

import { IKEMembershipState } from './index';

import { RootState } from '../../index';
import { MembershipsType } from '../../../../models/types/MembershipState';

export const keMembershipSelector = (state: RootState) => state.keMembership;

export const keMembershipsSelector = createSelector(
  keMembershipSelector,
  (membershipState: IKEMembershipState): MembershipsType =>
    membershipState.keMemberships,
);
