import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { transparentize } from 'polished';
import { NavLink } from 'react-router-dom';

import { mq } from '../../utils/mediaQueries';

export const Wrapper = styled.div`
  display: flex;
  gap: 3rem;
`;

export const StyledNavLink = styled(NavLink)`
  width: 100%;
  font: 700 1rem/1.5rem ${({ theme }) => theme.font.gotham};
  color: ${({ theme }) => theme.color.white};
  letter-spacing: 0.031rem;
  text-decoration: none;
  text-align: center;
  padding-bottom: 1rem;

  ${({ theme }) =>
    css`
      border-bottom: 0.0625rem solid ${transparentize(0.7, theme.color.white)};
    `};

  ${mq.xSmall(css`
    padding-bottom: 0;
    border-bottom: none;
  `)}
`;

export const StyledNavItem = styled.div`
  font: normal 700 1rem/1.5rem ${({ theme }) => theme.font.gotham};
  color: ${({ theme }) => theme.color.white};
  letter-spacing: 0.031rem;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  white-space: nowrap;
`;

export const LogoutNavItem = styled(StyledNavItem)`
  font-weight: 400;
`;
