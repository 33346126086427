/* eslint-disable no-undefined */
export const getFirstLetters = (text: string) =>
  text &&
  text
    .split(' ')
    .filter((x) => x)
    .map((x) => x.charAt(0).toUpperCase())
    .join('');

export const firstLetterToLowercase = (text: string) =>
  text.charAt(0).toLocaleLowerCase() + text.slice(1);

export const firstLetterToUppercase = (text: string) =>
  text.charAt(0).toLocaleUpperCase() + text.slice(1).toLocaleLowerCase();

export const afterDotLetterToLowercase = (text: string) => {
  const textParts = text.split('.');
  return textParts.map((element) => firstLetterToLowercase(element)).join('.');
};

export const textContainsWords = (text: string, words: string[]) =>
  words.some((word: string) => text.slice(1).includes(word));

export const isEmpty = (text: string | any) =>
  text === null || text === undefined || text === '';
