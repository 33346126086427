import { useTranslation } from 'react-i18next';

import Button from '../../../../components/UI/Button/Button';
import CheckboxGroup from '../../../../components/UI/CheckboxGroup/CheckboxGroup';
import { ICheckboxGroupItem } from '../../../../components/UI/CheckboxGroup/checkboxGroup.models';
import { IFamilyMemberInfo } from '../../../../models/interfaces/FamilyMemberInfo';
import { useFamilyEnrollment } from './familyEnrollmentModal.hooks';
import {
  StyledModal,
  StyledButtonsContainer,
  Description,
  SkipText,
  StyledSectionTitle,
} from './familyEnrollmentModal.styles';
import FamilyMemberLabel from './FamilyMemberLabel/FamilyMemberLabel';

export const FAMILY_ENROLLMENT_MODAL_NAME = 'FAMILY_ENROLLMENT_MODAL_NAME';

type FamilyEnrollmentModalProps = {
  members: IFamilyMemberInfo[];
  onSelectFamilyMembers?: (members: IFamilyMemberInfo[]) => void;
  onSkipSelectFamilyMembers?: () => void;
};

const FamilyEnrollmentModal = ({
  members,
  onSelectFamilyMembers,
  onSkipSelectFamilyMembers,
}: FamilyEnrollmentModalProps) => {
  const { t } = useTranslation();
  const {
    selectedMembers,
    onChangeMembersHandler,
    preparedMembersItems,
    onSelectFamilyMembersHandler,
    onSkipFamilyMembers,
    isMobile,
    isSelectingFamilyMembersAvailable,
  } = useFamilyEnrollment({
    members,
    onSelectFamilyMembers,
    onSkipSelectFamilyMembers,
  });

  return (
    <StyledModal name={FAMILY_ENROLLMENT_MODAL_NAME}>
      <StyledSectionTitle>{t('headers.family-enrollment')}</StyledSectionTitle>
      <Description>{t('phrase.family-enrollment-description')}</Description>
      <CheckboxGroup
        value={selectedMembers}
        onChange={onChangeMembersHandler}
        items={preparedMembersItems}
        labelRenderer={(data: ICheckboxGroupItem<IFamilyMemberInfo>) => (
          <FamilyMemberLabel member={data.value} />
        )}
      />
      <SkipText>{t('phrase.skip-add-members')}</SkipText>
      <StyledButtonsContainer>
        <Button styleType="lighter-blue" onClick={onSkipFamilyMembers}>
          {t(`buttons.skip`)}
        </Button>
        <Button
          disabled={!isSelectingFamilyMembersAvailable}
          onClick={onSelectFamilyMembersHandler}
        >
          {t(
            `buttons.${isMobile ? 'select-members' : 'select-family-members'}`,
          )}
        </Button>
      </StyledButtonsContainer>
    </StyledModal>
  );
};

export default FamilyEnrollmentModal;
