import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import CreditCardDetailsForm from '../../../../../components/Forms/CreditCardDetailsForm/CreditCardDetailsForm';
import ModalFormPage from '../../../../../components/ModalFormPage/modalFormPage';
import { withStripeElements } from '../../../../../components/Stripe/withStripeElements';
import { useReplaceCreditCardModalForm } from './replaceCreditCardModalForm.hooks';

type ReplaceCreditCardModalFormProps = {
  onCreditCardReplaced?: () => void;
  title?: string;
  description?: string;
  modalName: string;
};

const ReplaceCreditCardModalForm = ({
  onCreditCardReplaced,
  title,
  description,
  modalName,
}: ReplaceCreditCardModalFormProps) => {
  const { t } = useTranslation();
  const { onCancelHandler, submitHandler, validateForm, creditCardReplacing } =
    useReplaceCreditCardModalForm({
      onCreditCardReplaced,
      modalName,
    });

  return (
    <Form
      keepDirtyOnReinitialize
      onSubmit={submitHandler}
      validate={validateForm}
    >
      {({ ...renderProps }) => (
        <ModalFormPage
          handleSubmit={renderProps.handleSubmit}
          onCancel={onCancelHandler}
          title={title || t('headers.replace-credit-card')}
          description={description}
          isProcessing={creditCardReplacing}
        >
          <CreditCardDetailsForm disabled={creditCardReplacing} />
        </ModalFormPage>
      )}
    </Form>
  );
};

export default withStripeElements(ReplaceCreditCardModalForm);
