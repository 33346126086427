import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { FormPage } from '../../../../../components/Form/Form';
import FormErrorActivator from '../../../../../components/FormErrorProvider/FormErrorActivator/FormErrorActivator';
import { withStripeElements } from '../../../../../components/Stripe/withStripeElements';
import WizardPage from '../../../../../components/Wizard/WizardPage/WizardPage';
import { focusOnErrorDecorator } from '../../../../../utils/focusOnErrorDecorator';
import PaymentFailureModal from '../../../Modals/PaymentFailureModal/PaymentFailureModal';
import { useAddMembershipPaymentForm } from './addMembershipPaymentForm.hooks';
import AgreementForm from './Forms/AgreementForm/AgreementForm';
import AutoRenewForm from './Forms/AutoRenewForm/AutoRenewForm';
import PaymentSection from './Forms/PaymentSection/PaymentSection';
import PaymentSummary from './PaymentSummary/PaymentSummary';

const AddMembershipPaymentForm = () => {
  const { t } = useTranslation();
  const {
    onSubmitHandler,
    validateForm,
    initForm,
    membershipCreating,
    orderPaymentDetails,
    isMobile,
    isRenewalPlan,
  } = useAddMembershipPaymentForm();

  return (
    <>
      <PaymentFailureModal />
      <Form
        decorators={[focusOnErrorDecorator]}
        keepDirtyOnReinitialize
        onSubmit={onSubmitHandler}
        validate={validateForm}
        initialValues={initForm}
      >
        {({ values, dirty, valid, ...renderProps }) => (
          <FormErrorActivator>
            <FormPage onSubmit={renderProps.handleSubmit}>
              <WizardPage
                backButton
                nextButtonLabel={t(
                  `buttons.${
                    !isMobile && orderPaymentDetails?.isPaymentRequired
                      ? 'confirm-and-pay'
                      : 'confirm'
                  }`,
                )}
                isDirty={dirty}
                canSubmit={valid}
                isLoading={membershipCreating}
              >
                {orderPaymentDetails?.isPaymentRequired && (
                  <>
                    <PaymentSection disabled={membershipCreating} />
                    {isRenewalPlan && (
                      <AutoRenewForm
                        discountPrice={orderPaymentDetails?.autoRenewalDiscount}
                        disabled={membershipCreating}
                      />
                    )}
                  </>
                )}
                <PaymentSummary />
                <AgreementForm disabled={membershipCreating} />
              </WizardPage>
            </FormPage>
          </FormErrorActivator>
        )}
      </Form>
    </>
  );
};

export default withStripeElements(AddMembershipPaymentForm);
