import aaMembershipSaga from './airAmbulance';
import membershipSaga from './common';
import kidnapAndExtortionSaga from './kidnapAndExtortion';
import securityResponseSaga from './securityResponse';
import dutyOfCareSaga from './dutyOfCare';

const membershipSagasRoot = [
  aaMembershipSaga(),
  membershipSaga(),
  kidnapAndExtortionSaga(),
  securityResponseSaga(),
  dutyOfCareSaga(),
];
export default membershipSagasRoot;
