import { StyledButton } from './burgerButton.styles';

type BurgerButtonType = {
  onClick?: () => void;
  isOpen?: boolean;
};

const BurgerButton = ({ onClick, isOpen }: BurgerButtonType) => (
  <StyledButton isOpen={isOpen} onClick={onClick}>
    <div />
    <div />
    <div />
  </StyledButton>
);

export default BurgerButton;
