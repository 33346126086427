import { useCallback } from 'react';

import { IMembershipPlanGroupModel } from 'models/interfaces/MembershipPlanGroupModel';

type UseTabOptionsType<T> = {
  valueIdSelector?: (
    value: IMembershipPlanGroupModel | T,
  ) => IMembershipPlanGroupModel | T;
};

export const useTabOptions = <T,>({
  valueIdSelector,
}: UseTabOptionsType<T>) => {
  const isActive = useCallback(
    (
      optionValue: IMembershipPlanGroupModel | T,
      value: IMembershipPlanGroupModel | T,
    ) =>
      valueIdSelector
        ? valueIdSelector(optionValue) === valueIdSelector(value)
        : optionValue === value,
    [valueIdSelector],
  );

  const getOptionKey = useCallback(
    (optionValue: IMembershipPlanGroupModel | T): string =>
      valueIdSelector
        ? valueIdSelector(optionValue)?.toString()
        : optionValue?.toString(),
    [valueIdSelector],
  );

  const isDividerNeeded = useCallback((index: number): boolean => {
    if (index === 0) {
      return false;
    }

    return true;
  }, []);

  return {
    isActive,
    getOptionKey,
    isDividerNeeded,
  };
};
