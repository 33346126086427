import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';

export const AddButton = styled('button', {
  shouldForwardProp: isPropValid,
})`
  background: transparent;
  border: none;
  color: ${({ theme }) => theme.color.secondary1};
  font: 700 normal 1rem/2rem ${({ theme }) => theme.font.gotham};
  outline: none;
  padding: 0 1rem;
  text-align: left;
  width: 100%;
`;
