import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { transparentize } from 'polished';

import { mq } from '../../utils/mediaQueries';
import Button from '../UI/Button/Button';
import { ButtonProps } from '../UI/Button/button.models';

export const Wrapper = styled.footer`
  background-color: ${({ theme }) => theme.color.primary};
  padding: 1rem 1.5rem;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  ${mq.xSmall(css`
    justify-content: flex-end;
    align-items: center;
  `)}

  ${mq.xLarge(css`
    padding: 1.5rem 5rem;
    justify-content: space-between;
  `)}
`;

export const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const ContactContentContainer = styled.div`
  display: flex;
  gap: 6.25rem;
`;

export const PropertiesContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const ContactLine = styled.p`
  font: normal 400 0.875rem/1.5rem ${({ theme }) => theme.font.gotham};
  color: ${({ theme }) => theme.color.white};
`;

export const ContactLink = styled.a`
  font: normal 400 0.875rem/1.5rem ${({ theme }) => theme.font.gotham};
  color: ${({ theme }) => theme.color.white};
  text-decoration: none;
`;

export const Copyright = styled.p`
  font: normal 400 0.875rem/1.5rem ${({ theme }) => theme.font.gotham};
  color: ${({ theme }) => transparentize(0.4, theme.color.white)};
`;

export const TermsContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  justify-self: flex-end;
  flex-direction: column;
  align-items: end;

  ${mq.small(css`
    gap: 2rem;
    flex-direction: row;
  `)}
`;

export const Link = styled((props: ButtonProps) => (
  <Button {...props} styleType="link" />
))`
  font: normal bold 0.875rem/1.5rem ${({ theme }) => theme.font.gotham};
  color: ${({ theme }) => theme.color.white};
`;
