import { useEffect, useRef } from 'react';

export const useCancellationToken = () => {
  const controller = useRef(new AbortController());
  const isMounted = useRef(false);
  useEffect(
    () => () => {
      if (isMounted.current || process.env.NODE_ENV !== 'development') {
        controller.current.abort();
      }
      isMounted.current = true;
    },
    [],
  );

  return controller.current?.signal;
};
