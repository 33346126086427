import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import config from '../../config/config';
import { useHubSpotTracking } from '../../hooks/useHubSpotTracking';

const HubSpotTracking = () => {
  const { setPathPage } = useHubSpotTracking();
  const location = useLocation();
  useEffect(() => {
    if (config.hubSpot.tracking) {
      const script = document.createElement('script');
      script.setAttribute('type', 'text/javascript');
      script.setAttribute(
        'src',
        `//js.hs-scripts.com/${config.hubSpot.portalId}.js`,
      );

      script.setAttribute('id', 'hs-script-loader');
      script.async = true;
      script.defer = true;
      document.head.appendChild(script);
    }
  }, []);
  useEffect(() => {
    if (config.hubSpot.tracking) {
      setPathPage(location.pathname + location.search);
    }
  }, [location, setPathPage]);
  return <></>;
};
export default HubSpotTracking;
