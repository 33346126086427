import { createSliceSaga, SagaType } from 'redux-toolkit-saga';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'typed-redux-saga/macro';
import { AxiosResponse } from 'axios';

import { updateProcessState } from '../../redux-slices/processes';
import { Log } from '../../../utils/logger';
import {
  PERSON_DETAILS_LOAD_PROCESSING,
  PERSON_UPDATE_PROCESSING,
} from '../../redux-slices/processes/constants';
import {
  ILoadPersonDetailsPayload,
  IUpdatePersonDetailsPayload,
} from './models';
import { getPerson, updatePerson } from './api';
import { IPerson } from '../../../models/interfaces/Person';
import { setPerson } from '../../redux-slices/personalData';
import { prepareUpdatePersonData } from './utils';

const personalDataSlice = createSliceSaga({
  caseSagas: {
    *loadPersonDetails({
      payload: { success, error, cancellationToken },
    }: PayloadAction<ILoadPersonDetailsPayload>) {
      try {
        yield put(updateProcessState(PERSON_DETAILS_LOAD_PROCESSING));
        const response: AxiosResponse<IPerson> = yield* call(
          getPerson,
          cancellationToken,
        );

        yield put(setPerson(response.data));
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(PERSON_DETAILS_LOAD_PROCESSING));
      }
    },
    *updatePersonDetails({
      payload: { data, success, error, cancellationToken },
    }: PayloadAction<IUpdatePersonDetailsPayload>) {
      try {
        yield put(updateProcessState(PERSON_UPDATE_PROCESSING));
        const preparedData = yield* call(prepareUpdatePersonData, data);
        yield call(updatePerson, preparedData, cancellationToken);
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(PERSON_UPDATE_PROCESSING));
      }
    },
  },
  name: 'personal-details-saga',
  sagaType: SagaType.TakeLatest,
});

export default personalDataSlice.saga;
export const { loadPersonDetails, updatePersonDetails } =
  personalDataSlice.actions;
export const { actions } = personalDataSlice;
