import { createSliceSaga, SagaType } from 'redux-toolkit-saga';
import { PayloadAction } from '@reduxjs/toolkit';
import { call } from 'typed-redux-saga/macro';

import { Log } from '../../../utils/logger';

import { submitDataForm } from './api';
import { ISubmitHubSpotFormPayload } from './models';
import { prepareRequestObject } from './utils';
import config from '../../../config/config';

const hubSpotSlice = createSliceSaga({
  caseSagas: {
    *submitHubSpotForm({
      payload: { success, cancellationToken, data } = {},
    }: PayloadAction<ISubmitHubSpotFormPayload>) {
      try {
        if (config.hubSpot.manualHubspotFormSubmit !== 'true') {
          return;
        }

        const preparedData = prepareRequestObject(data);

        yield call(submitDataForm, preparedData, cancellationToken);
        success?.();
      } catch (err: any) {
        Log.errorException(err);
      }
    },
  },
  name: 'hub-spot-saga',
  sagaType: SagaType.TakeLatest,
});

export default hubSpotSlice.saga;
export const { submitHubSpotForm } = hubSpotSlice.actions;
export const { actions } = hubSpotSlice;
