import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

import { StandardText } from '../../../../../components/Typography/Typography';
import { mq } from '../../../../../utils/mediaQueries';

export const Wrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: column;

  ${mq.small(css`
    flex-direction: row;
  `)}
`;

type MemberNameProps = {
  isAvailable?: boolean;
};

export const MemberName = styled(StandardText)<MemberNameProps>`
  color: ${({ theme, isAvailable }) =>
    isAvailable ? theme.color.black : theme.color.input_border};
  white-space: nowrap;
`;

export const TypeContainer = styled.div`
  display: flex;
  gap: 0.5rem;
`;

type MemberTypeProps = {
  isAvailable?: boolean;
};

export const MemberType = styled(StandardText)<MemberTypeProps>`
  color: ${({ theme, isAvailable }) =>
    isAvailable ? theme.color.dark1 : theme.color.error};
`;
