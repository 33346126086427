import { CustomField } from '../../../models/types/CustomField';
import { SectionTitle } from '../../Typography/Typography';
import RemoveButton from '../../UI/RemoveButton/RemoveButton';
import { Wrapper } from './formFieldArrayTitle.styles';

type FormFieldArrayTitleProps = CustomField & {
  title: string;
  onRemove?: () => void;
};

const FormFieldArrayTitle = ({
  title,
  onRemove,
  className,
  disabled,
}: FormFieldArrayTitleProps) => (
  <Wrapper className={className}>
    <SectionTitle>{title}</SectionTitle>
    {onRemove && <RemoveButton disabled={disabled} onRemove={onRemove} />}
  </Wrapper>
);

export default FormFieldArrayTitle;
