import { useEffect } from 'react';
import { useLocation } from 'react-router';

import { useMsal } from '@azure/msal-react';

import { Log } from '../../utils/logger';

export const useLoggedOutPage = () => {
  const { pathname, state } = useLocation();
  const { instance } = useMsal();

  useEffect(() => {
    Log.warning(`Automatically logged out. ${state}`);
  }, [pathname, state]);

  const buttonClickHandler = () => {
    instance.loginRedirect();
  };

  return { buttonClickHandler };
};
