import { useTranslation } from 'react-i18next';

import MainTooltip from '../../../../../components/UI/Tooltip/Tooltip';
import {
  ActionsContainer,
  AddButton,
  RemoveButton,
} from './paymentTools.styles';
import { ReactComponent as ReplaceIcon } from '../../../../../assets/icons/replace-icon.svg';
import Button from '../../../../../components/UI/Button/Button';
import { ICreditCardInfo } from '../../../../../models/interfaces/CreditCardInfo';
type PaymentToolsProps = {
  creditCard?: ICreditCardInfo;
  onReplaceCreditCard?: (creditCardId: string) => void;
  onDeleteCreditCard?: (creditCardId: string) => void;
  onAddCreditCard?: () => void;
  canRemoveCard?: boolean;
};

const PaymentTools = ({
  creditCard,
  onAddCreditCard,
  onDeleteCreditCard,
  onReplaceCreditCard,
  canRemoveCard,
}: PaymentToolsProps) => {
  const { t } = useTranslation();
  return (
    <ActionsContainer>
      {!!creditCard ? (
        <>
          <MainTooltip arrow title={t('phrase.replace-credit-card')}>
            <Button
              onClick={() => onReplaceCreditCard?.(creditCard.creditCardId)}
              styleType="icon"
            >
              <ReplaceIcon />
            </Button>
          </MainTooltip>
          {canRemoveCard && (
            <MainTooltip arrow title={t('phrase.delete-credit-card')}>
              <RemoveButton
                onClick={() => onDeleteCreditCard?.(creditCard.creditCardId)}
              />
            </MainTooltip>
          )}
        </>
      ) : (
        <AddButton onClick={onAddCreditCard} styleType="link">
          {t('buttons.add-card')}
        </AddButton>
      )}
    </ActionsContainer>
  );
};

export default PaymentTools;
