import { t } from 'i18next';

import { MembershipPlanPeriods } from 'models/enums/MembershipPlanPeriods';

import { SelectOption } from 'components/UI/MainSelect/mainSelect.types';

import { IMembershipPlanGroupItemModel } from '../../../../../../../../models/interfaces/MembershipPlanGroupItemModel';
import { IMembershipPlanGroupModel } from '../../../../../../../../models/interfaces/MembershipPlanGroupModel';

export const isGroupDisabled = (
  group: IMembershipPlanGroupModel,
  currentGroupId: string,
  isRenewal: boolean,
) => !group.isAvailable || (isRenewal && currentGroupId !== group.id);

export const isGroupReadOnly = (
  group: IMembershipPlanGroupModel,
  currentGroupId: string,
  isRenewal: boolean,
) => isRenewal && currentGroupId === group.id;

export const prepareGroupTooltip = (
  group: IMembershipPlanGroupModel,
  currentGroupId: string,
  isRenewal: boolean,
) =>
  isRenewal && currentGroupId !== group.id
    ? t('phrase.to-select-another-membership-choose-new-project')
    : !group.isAvailable && t('phrase.you-cannot-select-this-membership-type');

export const preparePeriodOptionTooltip = (
  option: SelectOption,
  selected: MembershipPlanPeriods,
) =>
  option.value !== selected &&
  t('phrase.you-cannot-select-this-membership-type');

export const preparePeriodOptionDisabled = (
  option: SelectOption,
  selected: MembershipPlanPeriods,
) => option.value !== selected;

export const preparePeriodOptionReadOnly = (
  option: SelectOption,
  selected: MembershipPlanPeriods,
) => option.value !== selected;

export const prepareMembershipOption = (
  option: SelectOption,
  selected: MembershipPlanPeriods,
) => ({
  ...option,
  readOnly: preparePeriodOptionReadOnly(option, selected),
  disabled: preparePeriodOptionDisabled(option, selected),
  tooltip: preparePeriodOptionTooltip(option, selected),
});

export const prepareGroupOption = (
  group: IMembershipPlanGroupModel,
  currentGroupId: string,
  isRenewal: boolean,
) => ({
  label: group.planGroupName,
  value: group,
  readOnly: isGroupReadOnly(group, currentGroupId, isRenewal),
  disabled: isGroupDisabled(group, currentGroupId, isRenewal),
  tooltip: prepareGroupTooltip(group, currentGroupId, isRenewal),
});

export const isPlanDisabled = (
  plan: IMembershipPlanGroupItemModel,
  currentPlanId: string,
  isRenewal: boolean,
) => !plan.isAvailable || (isRenewal && currentPlanId !== plan.planId);

export const isPlanReadOnly = (
  plan: IMembershipPlanGroupItemModel,
  currentPlanId: string,
  isRenewal: boolean,
) => isRenewal && currentPlanId === plan.planId;

export const preparePlanOption = (
  plan: IMembershipPlanGroupItemModel,
  currentPlanId: string,
  isRenewal: boolean,
) => ({
  ...plan,
  readOnly: isPlanReadOnly(plan, currentPlanId, isRenewal),
  disabled: isPlanDisabled(plan, currentPlanId, isRenewal),
  id: plan?.planName,
});
