import { isEmpty } from '../../../utils/textHelpers';
import { IInputProps, Input } from '../Input/Input';
import { StyledButton, Wrapper } from './inputWithButton.styles';

export type IInputWithButtonProps = IInputProps & {
  buttonLabel: string;
  inputDisabled?: boolean;
  onClick?: () => void;
};

export const InputWithButton = ({
  buttonLabel,
  onClick,
  inputDisabled,
  disabled,
  value,
  ...props
}: IInputWithButtonProps) => (
  <Wrapper>
    <Input {...props} value={value} disabled={disabled || inputDisabled} />
    <StyledButton
      styleType="secondary"
      disabled={disabled || isEmpty(value)}
      onClick={onClick}
    >
      {buttonLabel}
    </StyledButton>
  </Wrapper>
);
