export const PHONE_NUMBER_MAX_LENGTH = 16;

export const formatPhoneNumber = (phoneNumber: string) => {
  if (!phoneNumber) return phoneNumber;
  const digits = phoneNumber.replace(/[^\d]/g, '');
  if (digits.length === 0) {
    return null;
  }

  if (digits.length <= 3) {
    return `${digits.slice(0, 3)}`;
  }

  if (digits.length <= 6) {
    return `${digits.slice(0, 3)} ${digits.slice(3, 6)}`;
  }

  if (digits.length <= 10) {
    return `${digits.slice(0, 3)} ${digits.slice(3, 6)} ${digits.slice(6, 10)}`;
  }

  if (digits.length === 11) {
    return `+${digits.slice(0, 1)} ${digits.slice(1, 4)} ${digits.slice(
      4,
      7,
    )} ${digits.slice(7, 11)}`;
  }

  if (digits.length > 11 && digits.length <= PHONE_NUMBER_MAX_LENGTH) {
    return digits;
  }
};

export const parsePhoneNumber = (phoneNumber: string) => {
  if (!phoneNumber) return phoneNumber;
  return phoneNumber.replace(/[^\d]/g, '').slice(0, PHONE_NUMBER_MAX_LENGTH);
};
