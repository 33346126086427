import { useTranslation } from 'react-i18next';

import { formatDate } from '../../../../../utils/dateHelpers';
import { DOCMembership as DOCMembershipType } from '../../../../../models/types/DOCMembership';

import {
  MembershipContainer,
  MembershipHeader,
  ItemInfo,
  MembershipDetails,
  MembershipDetailItem,
  ExpireIcon,
  ExpirationDateWrapper,
  MembershipSection,
  MembershipSectionItem,
  SectionItemDescription,
} from '../Common/membership.styles';
import MembershipTitle from '../Common/MembershipTitle/MembershipTitle';

export type DOCMembershipProps = {
  membership: DOCMembershipType;
};

export const DOCMembership = ({ membership }: DOCMembershipProps) => {
  const { t } = useTranslation();

  const {
    productType,
    effectiveDate,
    isExpiring,
    expirationDate,
    organizationName,
  } = membership;

  return (
    <>
      <MembershipContainer>
        <MembershipHeader>
          <MembershipTitle
            organizationName={organizationName}
            productType={productType}
          />
        </MembershipHeader>
        <MembershipSection>
          <MembershipSectionItem>
            <SectionItemDescription>
              {t('phrase.doc-membership-description')}
            </SectionItemDescription>
          </MembershipSectionItem>
        </MembershipSection>
        <MembershipDetails>
          <MembershipDetailItem>
            <ItemInfo>{t('properties.effectiveDate')}</ItemInfo>
            {formatDate(effectiveDate)}
          </MembershipDetailItem>
          <MembershipDetailItem>
            <ItemInfo>{t('properties.expirationDate')}</ItemInfo>
            <ExpirationDateWrapper>
              {formatDate(expirationDate)}
              {isExpiring && <ExpireIcon />}
            </ExpirationDateWrapper>
          </MembershipDetailItem>
        </MembershipDetails>
      </MembershipContainer>
    </>
  );
};
