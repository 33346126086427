/* eslint-disable import/no-named-as-default-member */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import moment from 'moment';

import translationsEn from './locale/en/translations.json';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: translationsEn,
    },
  },
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    format(value, format) {
      if (format === 'uppercase') return value.toUpperCase();
      if (value instanceof Date) return moment(value).format(format);
      return value;
    },
    escapeValue: false,
  },
  react: {
    useSuspense: false,
  },
});

export default i18n;
