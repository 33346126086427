import { ReactNode, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { getPropertyName } from '../../../../utils/getPropertyName';

import { SelectOption } from '../../MainSelect/mainSelect.types';

type UseFormSelect = {
  onChange: (event: any) => void;
  value?: any;
  promiseOptions?: () => Promise<any>;
  options: any;
  setDefaultValue?: boolean;
  labelAnimation?: boolean;
  placeholder?: ReactNode;
  name?: string;
};

export const useFormSelect = ({
  onChange,
  value,
  promiseOptions,
  options,
  setDefaultValue,
  labelAnimation,
  placeholder,
  name,
}: UseFormSelect) => {
  const { t } = useTranslation();

  const onChangeHandler = useCallback(
    (option: SelectOption) => {
      onChange(option?.value);
    },
    [onChange],
  );

  useEffect(() => {
    if (!promiseOptions && !options) {
      onChange(null);
    }
  }, [options, onChange, promiseOptions]);

  useEffect(() => {
    if (
      !setDefaultValue ||
      !options ||
      options.length !== 1 ||
      value === options[0].value
    ) {
      return;
    }

    setTimeout(() => onChangeHandler(options[0]), 0);
  }, [value, onChange, options, setDefaultValue, onChangeHandler]);

  const preparedPlaceholder = useMemo(
    () =>
      labelAnimation
        ? ''
        : placeholder || t(`fields.${getPropertyName(name)}.placeholder`),
    [labelAnimation, name, placeholder, t],
  );

  return { preparedPlaceholder, onChangeHandler };
};
