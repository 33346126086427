import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { mq } from '../../../../../../utils/mediaQueries';
import Button from '../../../../../../components/UI/Button/Button';

export const MembersContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-top: 1px solid ${({ theme }) => theme.color.light1};
`;

export const MembersListContainer = styled.ul`
  display: grid;
  gap: 1rem;
  grid-auto-columns: auto;
  grid-template-columns: 1fr;
  margin: 0;
  padding: 0;

  ${mq.medium(css`
    grid-template-columns: 1fr 1fr;
  `)}
`;

export const AddFamilyMemberButton = styled(Button)`
  align-self: flex-start;
  color: ${({ theme }) => theme.color.secondary3};
`;
export const Title = styled.h2`
  color: ${({ theme }) => theme.color.dark2};
  white-space: nowrap;
  font: normal bold 0.875rem/1.25rem ${({ theme }) => theme.font.gotham};
  margin-top: 1.5rem;
`;
