import axios, { AxiosResponse } from 'axios';

import config from '../../../config/config';
import { ICreditCardDetails } from '../../../models/interfaces/CreditCardDetails';
import { IPaymentIntentModel } from '../../../models/interfaces/PaymentIntentModel';
import { ICreditCardsInfoResponse } from './models';

export const capturePaymentRequest = (
  paymentId: string,
  creditCardDetails: ICreditCardDetails,
  cancellationToken: AbortSignal,
): Promise<AxiosResponse<void>> =>
  axios.post(`api/payments/${paymentId}/capture`, creditCardDetails, {
    baseURL: config.paymentApi.baseUrl,
    signal: cancellationToken,
  });

const creditCardsMainUrl = 'api/creditCards';

export const getCardsOnFile = (
  cancellationToken: AbortSignal,
): Promise<AxiosResponse<ICreditCardsInfoResponse>> =>
  axios.get(creditCardsMainUrl, {
    baseURL: config.paymentApi.baseUrl,
    signal: cancellationToken,
  });

export const replaceCreditCard = (
  creditCardId: string,
  cancellationToken: AbortSignal,
): Promise<AxiosResponse<ICreditCardsInfoResponse>> =>
  axios.put(
    `${creditCardsMainUrl}/${creditCardId}`,
    {},
    {
      baseURL: config.paymentApi.baseUrl,
      signal: cancellationToken,
    },
  );

export const addNewCreditCard = (
  cancellationToken: AbortSignal,
): Promise<AxiosResponse<ICreditCardsInfoResponse>> =>
  axios.post(
    creditCardsMainUrl,
    {},
    {
      baseURL: config.paymentApi.baseUrl,
      signal: cancellationToken,
    },
  );

export const deleteCreditCardRequest = (
  creditCardId: string,
  cancellationToken: AbortSignal,
): Promise<AxiosResponse<void>> =>
  axios.delete(`${creditCardsMainUrl}/${creditCardId}`, {
    baseURL: config.paymentApi.baseUrl,
    signal: cancellationToken,
  });

export const getPaymentIntent = (
  paymentId: string,
): Promise<AxiosResponse<IPaymentIntentModel>> =>
  axios.get(`api/payments/${paymentId}`, {
    baseURL: config.paymentApi.baseUrl,
  });
