import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

import MainLogo from '../../../assets/images/logo.png';
import MainLogoMobile from '../../../assets/images/logo-mobile.png';
import { mq } from '../../../utils/mediaQueries';

export const Logo = styled.div`
  width: 1.813rem;
  height: 2rem;
  transition: all 0.2s ease;
  background-size: cover;
  background-image: url(${MainLogoMobile});
  ${mq.xSmall(css`
    width: 10.375rem;
    height: 2.25rem;
    background-image: url(${MainLogo});
  `)}
`;
