import { t } from 'i18next';

import { MenuOption } from '../../../../../../components/UI/Menu/models';
import { Membership } from '../../../../../../models/types/Membership';
import { MenuActions } from './actionsContainer.models';
import { RenewButton } from './actionsContainer.styles';

export const renderMenuOptions = (
  membership: Membership,
  manualRenewButtonVisible: boolean,
): MenuOption[] => [
  {
    canonicalName: MenuActions.Renew,
    component: (
      <RenewButton>{t('buttons.renew').toLocaleUpperCase()}</RenewButton>
    ),
    isHidden: !manualRenewButtonVisible,
  },
];
