import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import {
  SecondSectionTitle,
  SectionWrapper,
} from '../../../../../../../components/Form/Form';
import Spinner from '../../../../../../../components/Spinner/Spinner';
import { StandardText } from '../../../../../../../components/Typography/Typography';
import InfoComponentMobile from '../../../../../../../components/UI/InfoComponent/InfoComponentMobile';
import { CustomForm } from '../../../../../../../models/types/CustomForm';
import { MobileMax } from '../../../../../../../utils/mediaQueries';
import MembershipPlan from './MembershipPlan/MembershipPlan';
import { useMembershipPlanForm } from './membershipPlanForm.hooks';
import { MembershipPlanFields } from './membershipPlanForm.models';
import { Header } from './membershipPlanForm.styles';

type MembershipPlanFormProps = CustomForm & {
  isRenewal?: boolean;
};

const MembershipPlanForm = ({
  className,
  disabled,
  isRenewal,
}: MembershipPlanFormProps) => {
  const { t } = useTranslation();
  const {
    selectedMembershipPlanPeriod,
    membershipPlans,
    membersPlansLoading,
    isPlanGroupUnavailable,
  } = useMembershipPlanForm();

  const showInfoComponent = isPlanGroupUnavailable || isRenewal;

  return (
    <Spinner isVisible={membersPlansLoading} size="30%">
      <SectionWrapper className={className}>
        <Header>
          <SecondSectionTitle>
            {t('headers.membership-plan')}
          </SecondSectionTitle>
          {showInfoComponent && (
            <MobileMax>
              <InfoComponentMobile
                tooltip={
                  isRenewal
                    ? t(
                        'phrase.to-select-another-membership-choose-new-project',
                      )
                    : t('phrase.you-will-see-product-available-you')
                }
              />
            </MobileMax>
          )}
        </Header>
        <StandardText>{t('phrase.membership-plan-description')}</StandardText>
        <Field
          component={MembershipPlan}
          disabled={disabled}
          isRenewal={isRenewal}
          membershipPlans={membershipPlans}
          name={MembershipPlanFields.MembershipPlan}
          selectedMembershipPlanPeriod={selectedMembershipPlanPeriod}
        />
      </SectionWrapper>
    </Spinner>
  );
};

export default MembershipPlanForm;
