import { Trans, useTranslation } from 'react-i18next';

import config from '../../config/config';
import { isPropertyEmpty } from '../../utils/propertyHelpers';

import {
  TextLinkButton,
  Wrapper,
  Text,
  BottomContainer,
  ButtonsContainer,
  StyledButton,
} from './cookieConsent.styles';
import { useCookieConsents } from './cookieConsents.hooks';

const CookieConsent = () => {
  const { t } = useTranslation();
  const { acceptHandle, declineHandle, value } = useCookieConsents();

  if (!isPropertyEmpty(value)) {
    return null;
  }
  return (
    <Wrapper>
      <Text>
        <Trans
          i18nKey="phrase.cookie-consent-part1"
          components={{
            myLink: (
              <TextLinkButton
                onClick={() => window.open(config.privacyPolicyLink, '_blank')}
              />
            ),
          }}
        />
      </Text>
      <BottomContainer>
        <Text>{t('phrase.cookie-consent-part2')}</Text>
        <ButtonsContainer>
          <StyledButton onClick={acceptHandle}>
            {t('buttons.accept')}
          </StyledButton>
          <StyledButton styleType="lighter-blue" onClick={declineHandle}>
            {t('buttons.decline')}
          </StyledButton>
        </ButtonsContainer>
      </BottomContainer>
    </Wrapper>
  );
};

export default CookieConsent;
