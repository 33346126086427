import axios, { AxiosResponse } from 'axios';

import { IIndividualFamilyMembers } from '../../../models/interfaces/IndividualFamilyMembers';
import { IIndividualPrimaryMember } from '../../../models/interfaces/IndividualPrimaryMember';
import {
  prepareQueryParams,
  QueryParamItem,
} from '../../../utils/queryParamsHelpers';

const MainUrl = '/api/members';

export const getPrimaryMemberModel = (
  cancelToken?: AbortSignal,
): Promise<AxiosResponse<IIndividualPrimaryMember>> =>
  axios.get(`${MainUrl}/primary`, {
    signal: cancelToken,
  });

export const getFamilyMembers = (
  membershipPlanId?: string,
  cancelToken?: AbortSignal,
): Promise<AxiosResponse<IIndividualFamilyMembers>> => {
  const queryParams: QueryParamItem[] = [
    {
      name: 'membershipPlanId',
      value: membershipPlanId,
    },
  ];
  const params = prepareQueryParams({ queryParams });

  return axios.get(`${MainUrl}/family${params}`, {
    signal: cancelToken,
  });
};
