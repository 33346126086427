import { useCallback } from 'react';

import { useActions } from '../../../../hooks/useActions';
import { useNavigateSearch } from '../../../../hooks/useNavigationSearch';
import { RoutePath } from '../../../../models/enums/RoutePath';
import { updateModalState } from '../../../../store/redux-slices/modals';
import { PURCHASE_FAILURE_ACTIVE_MEMBERSHIP_MODAL_NAME } from './PurchaseFailureActiveMembershipModal';

export const usePurchaseFailure = () => {
  const navigate = useNavigateSearch();
  const UpdateModalState = useActions(updateModalState);

  const seeYourPlanClick = useCallback(() => {
    UpdateModalState(PURCHASE_FAILURE_ACTIVE_MEMBERSHIP_MODAL_NAME);
    navigate(RoutePath.Profile);
  }, [UpdateModalState, navigate]);

  return {
    seeYourPlanClick,
  };
};
