import { EventType, PublicClientApplication } from '@azure/msal-browser';

import { msalConfig } from '../../config/authConfig';

export const PCA = new PublicClientApplication(msalConfig);

if (!PCA.getActiveAccount() && PCA.getAllAccounts().length > 0) {
  PCA.setActiveAccount(PCA.getAllAccounts()[0]);
}

PCA.addEventCallback((event: any) => {
  if (
    (event.eventType === EventType.LOGIN_SUCCESS ||
      event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
      event.eventType === EventType.SSO_SILENT_SUCCESS) &&
    event.payload.account
  ) {
    PCA.setActiveAccount(event.payload.account);
  }
});

export const isAuthenticated = () => !!PCA.getActiveAccount();

export const getUserEmail = () =>
  PCA.getActiveAccount()?.idTokenClaims?.extension_email ||
  PCA.getActiveAccount()?.idTokenClaims?.email;
