import { t } from 'i18next';

import { getPropertyName } from '../../../../utils/getPropertyName';
import { FormFieldWrapper } from '../../../Form/Form';
import { ErrorLabel } from '../../Field/Field';
import { IInputWithButtonProps } from '../../InputWithButton/InputWithButton';

import { IFormInputProps } from '../FormInput/FormInput';
import { useFormInputWithButton } from './formInputWithButton.hooks';
import { StyledFormInputWithButton } from './formInputWithButton.styles';

export type IFormInputWithButtonProps = IFormInputProps &
  IInputWithButtonProps & {
    onButtonClick?: (value: string) => void;
    isClearable?: boolean;
  };

const FormInputWithButton = ({
  className,
  disabled,
  input,
  id,
  label,
  meta,
  onlyNumber,
  labelAnimation = true,
  onBlur,
  onClick,
  onButtonClick,
  buttonLabel,
  isClearable,
  ...props
}: IFormInputWithButtonProps) => {
  const {
    onBlurHandler,
    onChange,
    preparedPlaceholder,
    value,
    buttonClickHandler,
    clearValueHandler,
  } = useFormInputWithButton({
    onBlur,
    input,
    placeholder: props.placeholder,
    labelAnimation,
    onButtonClick,
  });

  return (
    <FormFieldWrapper className={className} error={meta.touched && meta.error}>
      <StyledFormInputWithButton
        {...props}
        name={input.name}
        error={meta.error && meta.touched}
        disabled={disabled}
        placeholder={preparedPlaceholder}
        {...input}
        onClick={isClearable ? clearValueHandler : buttonClickHandler}
        labelAnimation={labelAnimation}
        onChange={onChange}
        buttonLabel={isClearable ? t(`buttons.clear`) : buttonLabel}
        onBlur={onBlurHandler}
        label={label || t(`fields.${getPropertyName(input.name)}.label`)}
        value={value || ''}
      />
      {meta.touched && meta.error && <ErrorLabel>{meta.error}</ErrorLabel>}
    </FormFieldWrapper>
  );
};

export default FormInputWithButton;
