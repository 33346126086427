/* eslint-disable no-undefined */
import styled from '@emotion/styled';

import { THEME } from '../../../config/theme';

import CheckIcon from '../../../assets/icons/check-icon.svg';

export const SelectContainer = styled.div`
  width: 100%;
  margin: 0;
  height: 100%;
`;

export const customStyles = {
  // AVAILABLE STYLES
  // clearIndicator
  // container
  // control
  // dropdownIndicator
  // group
  // groupHeading
  // indicatorsContainer
  // indicatorSeparator
  // input
  // loadingIndicator
  // loadingMessage
  // menu
  // menuList
  // menuPortal
  // multiValue
  // multiValueLabel
  // multiValueRemove
  // noOptionsMessage
  // option
  // placeholder
  // singleValue
  // valueContainer

  container: (styles: any) => ({
    ...styles,
    height: '100%',
    width: '100%',
    fontFamily: THEME.font.gotham,
  }),
  control: (styles: any, { isFocused, selectProps }: any) => {
    const { menuIsOpen, isError } = selectProps;

    return {
      ...styles,
      fontFamily: THEME.font.gotham,
      transition: 'none',
      outline: '0',
      height: '100%',
      paddingLeft: '1rem',
      paddingRight: '1rem',
      backgroundColor: THEME.color.white,
      borderColor: isError
        ? THEME.color.error
        : isFocused
        ? THEME.color.secondary2
        : THEME.color.input_border,
      borderRadius: menuIsOpen ? '0.25rem 0.25rem 0 0' : '0.25rem 0.25rem',
      borderBottom: menuIsOpen && '0',
      paddingBottom: menuIsOpen && '0.063rem',
      boxShadow: menuIsOpen ? '0 0.313rem 0.438rem rgba(0, 0, 0, 0.1)' : 'none',
      ':focused': {
        borderColor: isError ? THEME.color.error : THEME.color.secondary2,
      },

      '&:hover': {
        borderColor: isError ? THEME.color.error : THEME.color.secondary2,
      },
      width: '100%',
      flex: '1 1 0%',
      flexWrap: 'nowrap',
    };
  },
  clearIndicator: (styles: any) => ({
    ...styles,
    position: 'absolute',
    right: '1.5rem',
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  menu: (styles: any) => ({
    ...styles,
    fontFamily: THEME.font.gotham,
    margin: '0',
    zIndex: '10000000',
    border: `0.063rem solid ${THEME.color.secondary2}`,
    backgroundColor: THEME.color.white,
    borderRadius: '0 0 0.25rem 0.25rem',
    borderTop: '0',
    boxShadow: '0 0.313rem 0.438rem rgba(0, 0, 0, 0.1)',
    flex: '1 1 0%',
    maxHeight: '13rem',
    overflowY: 'auto',
  }),
  menuPortal: (styles: any) => ({
    ...styles,
    zIndex: '100000',
    position: 'static',
  }),
  option: (styles: any, { isSelected, isDisabled }: any) => ({
    ...styles,
    fontFamily: THEME.font.gotham,
    backgroundColor: THEME.color.white,
    color: isSelected ? THEME.color.secondary3 : THEME.color.black,
    fontWeight: isSelected ? 'bold' : 'normal',
    fontSize: '0.875rem',
    lineHeight: '1.5rem',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    boxShadow: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: isDisabled ? 'not-allowed' : 'default',
    ':after': {
      content: `''`,
      background: `url(${CheckIcon}) 50% no-repeat`,
      height: '1rem',
      margin: '0',
      opacity: `${isSelected ? '1' : '0'}`,
      transition: 'all 0.2s ease',
      width: '1rem',
      visibility: `${isSelected ? 'visible' : 'hidden'}`,
    },

    ':hover': {
      backgroundColor: 'rgba(185,211,220,0.2)',
    },
    ':active': {
      ...styles[':active'],
      backgroundColor: !isDisabled
        ? isSelected
          ? THEME.color.secondary3
          : THEME.color.black
        : undefined,
    },
  }),
  input: (styles: any) => ({
    ...styles,
    overflow: 'hidden',
  }),

  placeholder: (styles: any) => ({
    ...styles,
    marginLeft: '0',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  }),
  singleValue: (styles: any) => ({
    ...styles,
    '> div': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      display: 'initial',
    },
  }),
};
