import { Field } from 'react-final-form';

import { MemberType } from '../../../models/enums/MemberType';
import { CustomForm } from '../../../models/types/CustomForm';
import { getFieldPropertiesForGroup } from '../../../utils/formGroupFieldHelpers';
import { FormColumn } from '../../UI/Form/FormColumn/FormColumn';
import { FormInput } from '../../UI/Form/FormInput/FormInput';
import { FormRow } from '../../UI/Form/FormRow/FormRow';
import { FormSelect } from '../../UI/Form/FormSelect/FormSelect';
import FormDateOfBirth from '../../UI/Form/FormMemberDateOfBirth/FormMemberDateOfBirth';
import { useFamilyMemberForm } from './familyMemberForm.hooks';
import { FamilyMemberFields } from './familyMemberForm.models';
import { ProductType } from '../../../models/enums/ProductType';

type FamilyMemberFormProps = CustomForm & {
  name?: string;
  memberTypes: MemberType[];
  membershipPlanId?: string;
  birthOfDateErrorIsHidden?: boolean;
  renewedMembershipId?: string;
  productType?: ProductType;
};

const FamilyMemberForm = ({
  className,
  disabled,
  name,
  memberTypes,
  membershipPlanId,
  birthOfDateErrorIsHidden,
  renewedMembershipId,
  productType,
}: FamilyMemberFormProps) => {
  const { memberType, memberTypeOptions } = useFamilyMemberForm({
    name,
    memberTypes,
  });

  return (
    <FormColumn className={className}>
      <FormRow>
        <Field
          component={FormInput}
          disabled={disabled}
          {...getFieldPropertiesForGroup({
            inputId: 'family-member-first-name-input',
            name: FamilyMemberFields.FirstName,
            groupName: name,
          })}
        />
        <Field
          component={FormInput}
          disabled={disabled}
          {...getFieldPropertiesForGroup({
            inputId: 'family-member-last-name-input',
            name: FamilyMemberFields.LastName,
            groupName: name,
          })}
        />
      </FormRow>
      <FormRow>
        <Field
          component={FormDateOfBirth}
          disabled={disabled}
          isErrorHidden={birthOfDateErrorIsHidden}
          renewedMembershipId={renewedMembershipId}
          {...getFieldPropertiesForGroup({
            inputId: 'family-member-date-of-birth-input',
            name: FamilyMemberFields.DateOfBirth,
            groupName: name,
          })}
          memberType={memberType}
          memberPlanId={membershipPlanId}
          productType={productType}
        />
        <Field
          component={FormSelect}
          disabled={disabled}
          setDefaultValue
          options={memberTypeOptions}
          {...getFieldPropertiesForGroup({
            inputId: 'address-city-input',
            name: FamilyMemberFields.Dependence,
            groupName: name,
          })}
        />
      </FormRow>
    </FormColumn>
  );
};

export default FamilyMemberForm;
