import { AnySchema } from 'ajv';

import { FieldErrorMessage } from '../../../../../../../../../models/interfaces/FieldErrorMessage';
import { ISecondaryMember } from '../../../../../../../../../models/interfaces/SecondaryMember';
import { validateFormData } from '../../../../../../../../../utils/validations/validateFormData';
import { FamilyMemberFields } from './familyMemberForm.models';

export const familyMemberFormSchema: AnySchema | any = {
  type: 'object',
  properties: {
    [FamilyMemberFields.FirstName]: {
      type: 'string',
    },
    [FamilyMemberFields.DateOfBirth]: {
      type: 'string',
      format: 'date',
    },
  },

  required: [
    FamilyMemberFields.FirstName,
    FamilyMemberFields.LastName,
    FamilyMemberFields.DateOfBirth,
    FamilyMemberFields.Dependence,
  ],
};

export const validateFamilyMemberForm = (
  data: ISecondaryMember,
  additionalErrors?: FieldErrorMessage[],
) => validateFormData(data, additionalErrors, familyMemberFormSchema);
