import { useTranslation } from 'react-i18next';

import { WithSidebarPage } from '../../components/Page/Page';

import { Memberships } from './Memberships/Memberships';
import AddCreditCardModal from './Modals/AddCreditCardModal/AddCreditCardModal';
import UpdateAutoRenewFlagModal from './Modals/UpdateAutoRenewFlagModal/UpdateAutoRenewFlagModal';
import DeleteCreditCardModal from './Modals/DeleteCreditCardModal/DeleteCreditCardModal';
import EditMemberDetailsModal from './Modals/EditMemberDetailsModal/EditMemberDetailsModal';
import ReplaceCreditCardModal from './Modals/ReplaceCreditCardModal/ReplaceCreditCardModal';

import { Profile } from './Profile/Profile';
import { useProfilePage } from './profilePage.hooks';
import RenewalNotAvailableModal from './Modals/RenewalNotAvailableModal/RenewalNotAvailableModal';
import { ADD_CREDIT_CARD_AUTO_RENEW_MODAL } from './Modals/AddCreditCardModal/addCreditCard.consts';
import { REPLACE_CREDIT_CARD_DETAILS_AUTO_RENEW_MODAL } from './Modals/ReplaceCreditCardModal/replaceCreditCard.consts';

export const ProfilePage = () => {
  const { t } = useTranslation();
  const {
    isPersonLoading,
    areCreditCardsLoading,
    profile,
    memberships,
    areMembershipsLoading,
    creditCard,
    loadMembershipsHandler,
    updateMemberDetailsHandler,
    loadPersonHandler,
    replaceCreditCardHandler,
    deleteCreditCardHandler,
    loadCreditCardsHandler,
    addCreditCardHandler,
    updateAutoRenewFlagHandler,
    renewMembershipHandler,
  } = useProfilePage();

  return (
    <WithSidebarPage>
      <EditMemberDetailsModal onEditedMember={loadPersonHandler} />
      <ReplaceCreditCardModal onCreditCardReplaced={loadCreditCardsHandler} />
      <ReplaceCreditCardModal
        modalName={REPLACE_CREDIT_CARD_DETAILS_AUTO_RENEW_MODAL}
        title={t('headers.credit-card-is-missing')}
        description={t('phrase.to-turn-on-update-credit-card')}
        onCreditCardReplaced={loadCreditCardsHandler}
      />
      <AddCreditCardModal onCreditCardAdded={loadCreditCardsHandler} />
      <AddCreditCardModal
        modalName={ADD_CREDIT_CARD_AUTO_RENEW_MODAL}
        title={t('headers.credit-card-is-missing')}
        description={t('phrase.to-turn-on-update-credit-card')}
        onCreditCardAdded={loadCreditCardsHandler}
      />
      <UpdateAutoRenewFlagModal
        onAutoRenewFlagUpdated={() => {
          loadPersonHandler();
          loadMembershipsHandler();
        }}
      />
      <RenewalNotAvailableModal />
      <DeleteCreditCardModal onCreditCardDeleted={loadCreditCardsHandler} />
      <Profile
        creditCard={creditCard}
        isPersonLoading={isPersonLoading}
        areCreditCardsLoading={areCreditCardsLoading}
        profile={profile}
        onEditMemberDetails={updateMemberDetailsHandler}
        onReplaceCreditCard={replaceCreditCardHandler}
        onDeleteCreditCard={deleteCreditCardHandler}
        onAddCreditCard={addCreditCardHandler}
      />
      <Memberships
        memberships={memberships}
        areMembershipsLoading={areMembershipsLoading}
        onFamilyMemberAdded={loadMembershipsHandler}
        onFamilyMemberRemoved={loadMembershipsHandler}
        onUpdateAutoRenewFlag={updateAutoRenewFlagHandler}
        onRenew={renewMembershipHandler}
      />
    </WithSidebarPage>
  );
};
