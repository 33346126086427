import { ICreateIndividualMembership } from '../../../../models/interfaces/CreateIndividualMembership';
import { ICreateMembershipMemberDetails } from '../../../../models/interfaces/ICreateMembershipMemberDetails';
import { ICreateMembershipPayment } from '../../../../models/interfaces/ICreateMembershipPayment';
import { ICreateMembershipPlan } from '../../../../models/interfaces/ICreateMembershipPlan';
import { IMembershipPlansTreeModel } from '../../../../models/interfaces/MembershipPlansTreeModel';
import { parseDate } from '../../../../utils/dateHelpers';
import { findMembershipPlanInfoById } from '../../../../utils/membershipPlanHelpers';

export const prepareCreateMembershipPlan = (
  plans: IMembershipPlansTreeModel,
  suggestion: ICreateIndividualMembership,
): ICreateMembershipPlan => ({
  membershipPlanInfo: findMembershipPlanInfoById(
    plans,
    suggestion?.membershipPlanId,
  ),
});

export const prepareCreateMembershipMemberDetails = (
  suggestion: ICreateIndividualMembership,
): ICreateMembershipMemberDetails => ({
  billingAddress: suggestion?.billingAddress,
  billingAddressSameAsResidenceAddress:
    suggestion?.billingAddressSameAsResidenceAddress,
  primaryMember: {
    ...suggestion?.primaryMember,
    dateOfBirth: parseDate(suggestion?.primaryMember?.dateOfBirth),
  },
  residenceAddress: suggestion?.residenceAddress,
  secondaryMembers: suggestion?.secondaryMembers?.map((x) => ({
    ...x,
    dateOfBirth: parseDate(x.dateOfBirth),
  })),
});

export const prepareCreateMembershipPayment = (
  suggestion: ICreateIndividualMembership,
): ICreateMembershipPayment => ({
  autoRenewal: suggestion?.autoRenewal,
});
