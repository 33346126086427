import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

import { mq } from '../../utils/mediaQueries';
import Button from '../UI/Button/Button';
import { ButtonProps } from '../UI/Button/button.models';

export const Wrapper = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.color.white};
  box-shadow: 0px -4px 8px rgba(153, 172, 188, 0.15);
  position: fixed;
  bottom: 0;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  z-index: 101;

  ${mq.xSmall(css`
    padding: 1.5rem 2.5rem;
  `)}
`;

export const Text = styled.p`
  font: normal normal 0.75rem/1.5rem ${({ theme }) => theme.font.gotham};
  color: ${({ theme }) => theme.color.black};
`;

export const BottomContainer = styled.div`
  display: flex;
  gap: 2.5rem;
  align-items: center;
  flex-direction: column;

  ${mq.small(css``)}

  ${mq.medium(css`
    flex-direction: row;
  `)}
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-self: flex-end;
  width: 100%;

  ${mq.xSmall(css`
    width: auto;
  `)}

  ${mq.medium(css`
    align-self: center;
    gap: 2rem;
  `)}
`;

export const StyledButton = styled(Button)`
  width: 100%;

  ${mq.xSmall(css`
    width: 12.5rem;
  `)}
`;

export const TextLinkButton = styled((props: ButtonProps) => (
  <Button {...props} styleType="link" />
))`
  font: normal normal 0.75rem/1.5rem ${({ theme }) => theme.font.gotham};
  color: ${({ theme }) => theme.color.secondary3};
`;
