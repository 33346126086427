import { createSelector } from 'reselect';

import { IAAPlansState } from '.';
import { IMembershipPlansTreeModel } from '../../../../models/interfaces/MembershipPlansTreeModel';

import { RootState } from '../../index';

export const aaPlansSelector = (state: RootState) => state.plans;

export const aaMembershipPlansSelector = createSelector(
  aaPlansSelector,
  (plans: IAAPlansState): IMembershipPlansTreeModel => plans.membershipPlans,
);

export const aaPromoCodePlanSelector = createSelector(
  aaPlansSelector,
  (
    plans: IAAPlansState,
  ): { isPromoCodeApplied: boolean; appliedPromoCode: string } => ({
    isPromoCodeApplied: plans.membershipPlans?.isPromoCodeApplied,
    appliedPromoCode: plans.membershipPlans?.appliedPromoCode,
  }),
);
