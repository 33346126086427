import { useMemo } from 'react';

import Checkbox from '../Checkbox/Checkbox';
import { useCheckboxGroup } from './checkboxGroup.hooks';
import { ICheckboxGroupProps } from './checkboxGroup.models';
import { Wrapper } from './checkboxGroup.styles';

const CheckboxGroup = <T,>({
  items = [],
  value,
  onChange,
  labelRenderer,
}: ICheckboxGroupProps<T>) => {
  const { onChangeHandler, isItemChecked, prepareLabel } = useCheckboxGroup({
    value,
    onChange,
    labelRenderer,
  });

  const checkboxesList = useMemo(
    () =>
      items.map((item) => (
        <Checkbox
          label={prepareLabel(item, item.label)}
          disabled={item.disabled}
          checked={isItemChecked(item)}
          onChange={(evt) => onChangeHandler(evt, item)}
          key={item.id}
        />
      )),

    [isItemChecked, items, onChangeHandler, prepareLabel],
  );

  return <Wrapper>{checkboxesList}</Wrapper>;
};

export default CheckboxGroup;
