import { Field } from 'react-final-form';

import { FormColumn } from '../../UI/Form/FormColumn/FormColumn';
import { FormInput } from '../../UI/Form/FormInput/FormInput';
import { FormRow } from '../../UI/Form/FormRow/FormRow';
import { FormSelect } from '../../UI/Form/FormSelect/FormSelect';
import { CustomForm } from '../../../models/types/CustomForm';
import { AddressFields } from './addressForm.models';
import { getFieldPropertiesForGroup } from '../../../utils/formGroupFieldHelpers';
import { useAddressForm } from './addressForm.hooks';

type AddressFormProps = CustomForm & {
  name?: string;
};

const AddressForm = ({ className, disabled, name }: AddressFormProps) => {
  const { countriesPromiseOptions } = useAddressForm({ name });
  return (
    <FormColumn className={className}>
      <FormRow>
        <Field
          component={FormInput}
          disabled={disabled}
          {...getFieldPropertiesForGroup({
            inputId: 'address-line-1-input',
            name: AddressFields.AddressLine1,
            groupName: name,
          })}
        />
      </FormRow>
      <FormRow>
        <Field
          component={FormInput}
          disabled={disabled}
          {...getFieldPropertiesForGroup({
            inputId: 'address-line-2-input',
            name: AddressFields.AddressLine2,
            groupName: name,
          })}
        />
      </FormRow>
      <FormRow>
        <Field
          component={FormInput}
          disabled={disabled}
          {...getFieldPropertiesForGroup({
            inputId: 'address-zip-code-input',
            name: AddressFields.ZipCode,
            groupName: name,
          })}
        />
        <Field
          component={FormInput}
          disabled={disabled}
          {...getFieldPropertiesForGroup({
            inputId: 'address-city-input',
            name: AddressFields.City,
            groupName: name,
          })}
        />
      </FormRow>
      <FormRow>
        <Field
          component={FormInput}
          disabled={disabled}
          {...getFieldPropertiesForGroup({
            inputId: 'address-state-input',
            name: AddressFields.StateRegion,
            groupName: name,
          })}
        />
        <Field
          component={FormSelect}
          disabled={disabled}
          promiseOptions={countriesPromiseOptions}
          {...getFieldPropertiesForGroup({
            inputId: 'address-country-input',
            name: AddressFields.Country,
            groupName: name,
          })}
        />
      </FormRow>
    </FormColumn>
  );
};

export default AddressForm;
