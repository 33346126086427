import { Trans, useTranslation } from 'react-i18next';

import {
  SimpleModal,
  ModalTitle,
  ButtonsContainer,
} from '../../../../components/ModalCommon/ModalCommon';
import { StandardText } from '../../../../components/Typography/Typography';
import Button from '../../../../components/UI/Button/Button';
import { DELETE_CREDIT_CARD_MODAL } from './deleteCreditCardModal.const';
import { useDeleteCreditCardModal } from './deleteCreditCardModal.hooks';

type DeleteCreditCardModalModalProps = {
  onCreditCardDeleted?: () => void;
};

const DeleteCreditCardModal = ({
  onCreditCardDeleted,
}: DeleteCreditCardModalModalProps) => {
  const { t } = useTranslation();

  const { onCancelHandler, deleteCreditCardHandler, isDeleting } =
    useDeleteCreditCardModal({ onCreditCardDeleted });

  return (
    <SimpleModal disabled={isDeleting} name={DELETE_CREDIT_CARD_MODAL}>
      <ModalTitle>{t('headers.remove-credit-card')}</ModalTitle>
      <Trans>
        <StandardText>{t('phrase.confirm-remove-credit-card')}</StandardText>
      </Trans>
      <ButtonsContainer>
        <Button
          disabled={isDeleting}
          styleType="lighter-blue"
          onClick={onCancelHandler}
        >
          {t(`buttons.cancel`)}
        </Button>
        <Button
          isLoading={isDeleting}
          styleType="background-delete"
          onClick={deleteCreditCardHandler}
        >
          {t(`buttons.remove`)}
        </Button>
      </ButtonsContainer>
    </SimpleModal>
  );
};

export default DeleteCreditCardModal;
