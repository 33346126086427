import styled from '@emotion/styled/macro';

import FormCheckbox from '../../UI/Form/FormCheckbox/FormCheckbox';

export const StyledFormCheckbox = styled(FormCheckbox)`
  margin-top: 1rem;
`;

export const Wrapper = styled.div`
  width: 100%;
`;
