import {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FieldInputProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { useDebounce } from '../../../../hooks/useDebounce';

import { getPropertyName } from '../../../../utils/getPropertyName';

type UseFormInput = {
  onBlur?: () => void;
  input: FieldInputProps<string, HTMLElement>;
  labelAnimation?: boolean;
  placeholder?: string;
  parse?: (value: string) => string;
  format?: (value: string) => string;
};

export const useFormInput = ({
  onBlur,
  input,
  labelAnimation,
  placeholder,
  parse,
  format,
}: UseFormInput) => {
  const { t } = useTranslation();
  const { debounce } = useDebounce();
  const internalActions = useRef(false);

  const [inputValue, setInputValue] = useState<string>(input.value);

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      internalActions.current = true;
      const parsedValue = parse ? parse(e.target.value) : e.target.value;
      setInputValue(parsedValue);
      debounce(() => {
        input.onChange(parsedValue);
        internalActions.current = false;
      }, 300);
    },
    [debounce, input, parse],
  );

  useEffect(() => {
    if (!internalActions.current) {
      setInputValue(input.value);
    }
  }, [input.value]);

  const onBlurHandler = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      onBlur && onBlur();
      input.onBlur(event);
    },
    [onBlur, input],
  );
  const preparedPlaceholder = useMemo(
    () =>
      labelAnimation
        ? ' '
        : placeholder || t(`fields.${getPropertyName(input.name)}.placeholder`),
    [input.name, labelAnimation, placeholder, t],
  );

  return {
    onChange,
    onBlurHandler,
    preparedPlaceholder,
    inputValue: format ? format(inputValue) : inputValue,
  };
};
