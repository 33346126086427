import { useTranslation } from 'react-i18next';

import { MenuWithIcon } from '../../../../../../components/UI/MenuWithIcon/MenuWithIcon';
import { Membership } from '../../../../../../models/types/Membership';
import { useMobileMediaQuery } from '../../../../../../utils/mediaQueries';
import { useActionsContainer } from './actionsContainer.hooks';
import { MenuActions } from './actionsContainer.models';
import { RenewButton, Wrapper } from './actionsContainer.styles';
import { renderMenuOptions } from './actionsContainer.utils';

type ActionsContainerProps = {
  manualRenewButtonVisible?: boolean;
  onRenew?: (membershipId: string) => void;
  membership?: Membership;
};

const ActionsContainer = ({
  manualRenewButtonVisible,
  onRenew,
  membership,
}: ActionsContainerProps) => {
  const { t } = useTranslation();
  const isMobile = useMobileMediaQuery();
  const { handleMenuOptionClick } = useActionsContainer({ onRenew });

  return (
    <Wrapper>
      {!isMobile ? (
        <>
          {manualRenewButtonVisible && (
            <RenewButton
              onClick={() =>
                handleMenuOptionClick(MenuActions.Renew, membership)
              }
            >
              {t('buttons.renew').toLocaleUpperCase()}
            </RenewButton>
          )}
        </>
      ) : (
        manualRenewButtonVisible && (
          <MenuWithIcon
            options={renderMenuOptions(membership, manualRenewButtonVisible)}
            onElementClick={(option) =>
              handleMenuOptionClick(option, membership)
            }
          />
        )
      )}
    </Wrapper>
  );
};

export default ActionsContainer;
