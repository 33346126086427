import { createSliceSaga, SagaType } from 'redux-toolkit-saga';
import { PayloadAction } from '@reduxjs/toolkit';
import { put } from 'typed-redux-saga/macro';
import axios, { AxiosError } from 'axios';

import {
  AcceptedStatusCodes,
  AccessForbiddenCodes,
  AuthCodes,
  NotFoundCodes,
} from './models';
import {
  setAccessError,
  setAuthError,
  setError,
  setNotFoundError,
} from '../../redux-slices/errors';
import { Log } from '../../../utils/logger';

interface IHandleResponseErrorPayload {
  error?: AxiosError;
}

const errorSlice = createSliceSaga({
  caseSagas: {
    *handlerApiError({
      payload: { error },
    }: PayloadAction<IHandleResponseErrorPayload>) {
      if (axios.isCancel(error)) {
        return;
      }

      if (AuthCodes.includes(error?.response?.status)) {
        Log.error(`Auth error: ${error.message}`);
        yield put(setAuthError(error.message));
        return;
      }

      if (NotFoundCodes.includes(error?.response?.status)) {
        Log.error(`Not found error: ${error.message}`);
        yield put(setNotFoundError(error.message));
        return;
      }

      if (AccessForbiddenCodes.includes(error?.response?.status)) {
        Log.error(`Access forbidden error: ${error.message}`);
        yield put(setAccessError(error.message));
        return;
      }

      if (!AcceptedStatusCodes.includes(error?.response?.status)) {
        Log.error(`Something went wrong for the error: ${error.message}`);
        yield put(setError(error.message));
      }
    },
  },
  name: 'error-saga',
  sagaType: SagaType.TakeLatest,
});

export default errorSlice.saga;
export const { handlerApiError } = errorSlice.actions;
export const { actions } = errorSlice;
