import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

import { mq } from '../../utils/mediaQueries';

import { Modal } from '../Modal/Modal';
import { ModalContainer } from '../Modal/modal.styles';
import { SectionTitle } from '../Typography/Typography';
import Button from '../UI/Button/Button';

export const SimpleModal = styled(Modal)`
  width: 31.25rem;
  text-align: center;

  ${ModalContainer} {
    margin: 2rem 1rem;

    ${mq.small(css`
      margin: 2rem 1rem;
    `)}

    ${mq.xSmall(css`
      margin: 2rem 1rem;
    `)}
  }
`;

export const ModalTitle = styled(SectionTitle)`
  margin-bottom: 1.5rem;
`;

export const ModalButton = styled(Button)`
  margin-top: 2.5rem;
  min-width: 9.25rem;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 1rem;
  width: 100%;
  align-self: center;
  margin-top: 2.5rem;

  & > * {
    width: 100%;
  }
  ${mq.xSmall(css`
    width: auto;
    gap: 1.5rem;
    & > * {
      width: auto;
    }
  `)}
`;
