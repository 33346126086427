import { IWizardStep } from '../../../../store/redux-slices/wizard/models';
import { ArrowContainer, StepLabel, Wrapper } from './wizardStep.styles';
import { ReactComponent as Arrow } from '../../../../assets/icons/arrow-icon.svg';

type WizardStepType = {
  step: IWizardStep;
  isActive?: boolean;
  isArrowVisible?: boolean;
  isDisabled?: boolean;
  onClick?: (step: string) => void;
};

const WizardStep = ({
  step,
  isActive,
  isDisabled,
  isArrowVisible,
  onClick,
}: WizardStepType) => (
  <Wrapper>
    {isArrowVisible && (
      <ArrowContainer>
        <Arrow />
      </ArrowContainer>
    )}
    <StepLabel
      onClick={() => onClick && onClick(step.canonicalName)}
      isActive={isActive}
      isDisabled={isDisabled}
    >
      {step.caption}
    </StepLabel>
  </Wrapper>
);

export default WizardStep;
