import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import FormErrorProvider from '../../../../components/FormErrorProvider/FormErrorProvider';
import Spinner from '../../../../components/Spinner/Spinner';
import { reCaptchaArgs } from '../../../../config/config';
import AddMembershipPaymentForm from './AddMembershipPaymentForm/AddMembershipPaymentForm';
import { useAddMembershipPaymentPage } from './addMembershipPaymentPage.hooks';

const AddMembershipPaymentPage = () => {
  const { showSpinner } = useAddMembershipPaymentPage();

  if (showSpinner) {
    return <Spinner size="30%" />;
  }

  return (
    <GoogleReCaptchaProvider reCaptchaKey={reCaptchaArgs.publicKey}>
      <FormErrorProvider>
        <AddMembershipPaymentForm />
      </FormErrorProvider>
    </GoogleReCaptchaProvider>
  );
};

export default AddMembershipPaymentPage;
