import { useMemo } from 'react';
import { FieldRenderProps, useField } from 'react-final-form';

import { MemberType } from '../../../models/enums/MemberType';
import { getFormGroupPath } from '../../../utils/formGroupFieldHelpers';
import { getOptionsFromArray } from '../../../utils/getOptionsFromArray';
import { FamilyMemberFields } from './familyMemberForm.models';

type UseFamilyMemberFormProps = {
  name: string;
  memberTypes: MemberType[];
};

export const useFamilyMemberForm = ({
  name,
  memberTypes,
}: UseFamilyMemberFormProps) => {
  const {
    input: { value: memberType },
  }: FieldRenderProps<MemberType> = useField(
    getFormGroupPath(name, FamilyMemberFields.Dependence),
  );

  const memberTypeOptions = useMemo(
    () => (memberTypes ? getOptionsFromArray(memberTypes) : []),
    [memberTypes],
  );

  return { memberType, memberTypeOptions };
};
