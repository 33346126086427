import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

import { mq } from '../../utils/mediaQueries';

export const PageTitle = styled.h1`
  text-align: center;
  font: normal 500 1.5rem/2.25rem ${({ theme }) => theme.font.gotham};
  color: ${({ theme }) => theme.color.primary};
  letter-spacing: 0.08em;

  ${mq.small(css`
    letter-spacing: 0.11em;
  `)};

  ${({ theme }) =>
    mq.xSmall(css`
      font: normal normal 2rem/2.5rem ${theme.font.gotham};
    `)};
`;

export const SmallPageTitle = styled.h1`
  text-align: center;
  font: normal normal 1.5rem/1.5rem ${({ theme }) => theme.font.gotham};
  color: ${({ theme }) => theme.color.primary};
`;

export const SecondPageTitle = styled.h1`
  font: normal 600 1.5rem/2rem ${({ theme }) => theme.font.gotham};
  color: ${({ theme }) => theme.color.black};
`;

export const SectionTitle = styled.h2`
  font: normal bold 1rem/1.125rem ${({ theme }) => theme.font.gotham};
  color: ${({ theme }) => theme.color.secondary5};
`;

export const SectionSubTitle = styled.h3`
  font: normal bold 1rem/1.5rem ${({ theme }) => theme.font.gotham};
  color: ${({ theme }) => theme.color.white};
  letter-spacing: 0.031rem;
`;

export const StandardText = styled.p`
  font: normal normal 1rem/1.5rem ${({ theme }) => theme.font.gotham};
  color: ${({ theme }) => theme.color.black};
`;

export const StandardTextResponsive = styled.p`
  font: normal normal 0.875rem/1.5rem ${({ theme }) => theme.font.gotham};
  color: ${({ theme }) => theme.color.black};

  ${({ theme }) =>
    mq.xSmall(css`
      font: normal normal 1rem/1.5rem ${theme.font.gotham};
    `)}
`;

export const StandardTextBold = styled.p`
  font: normal bold 1rem/1.5rem ${({ theme }) => theme.font.gotham};
  color: ${({ theme }) => theme.color.black};
`;
