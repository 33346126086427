import styled from '@emotion/styled/macro';

import Button from '../../../../../../components/UI/Button/Button';

export const Wrapper = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  display: flex;
`;

export const RenewButton = styled(Button)`
  width: 100%;
`;
RenewButton.defaultProps = {
  styleType: 'secondary',
};
