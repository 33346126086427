import { AnySchema } from 'ajv';
import { t } from 'i18next';

import { IAddress } from '../../../models/interfaces/Address';
import { FieldErrorMessage } from '../../../models/interfaces/FieldErrorMessage';
import { validateFormData } from '../../../utils/validations/validateFormData';
import { AddressFields } from './addressForm.models';

export const addressFormSchema: AnySchema | any = {
  type: 'object',
  properties: {
    [AddressFields.AddressLine1]: {
      type: 'string',
    },
    [AddressFields.ZipCode]: {
      type: 'string',
      maxLength: 10,
      errorMessage: t('validation.invalid-zip-code'),
    },
  },

  required: [
    AddressFields.AddressLine1,
    AddressFields.ZipCode,
    AddressFields.City,
    AddressFields.StateRegion,
    AddressFields.Country,
  ],
};

export const validateAddressForm = (
  data: IAddress,
  additionalErrors?: FieldErrorMessage[],
) => validateFormData(data, additionalErrors, addressFormSchema);
