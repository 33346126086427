import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

import { StandardTextBold } from '../../Typography/Typography';

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

type CardNumberProps = {
  disabled?: boolean;
};

export const CardNumber = styled(StandardTextBold)<CardNumberProps>`
  white-space: nowrap;
  margin-left: 0.5rem;

  ${({ disabled, theme }) =>
    disabled &&
    css`
      color: ${theme.color.dark2};
    `}
`;
