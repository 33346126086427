import { useMsal } from '@azure/msal-react';

import { getFirstLetters } from '../utils/textHelpers';

export const useAccountInfo = () => {
  const { instance } = useMsal();
  const account = instance.getActiveAccount();
  const firstName = account?.idTokenClaims?.given_name;
  const lastName = account?.idTokenClaims?.family_name;
  const fullName = `${firstName} ${lastName}`;

  const shortName = getFirstLetters(fullName || account.username);

  return {
    firstName,
    lastName,
    shortName,
    fullName,
  };
};
