import { Trans, useTranslation } from 'react-i18next';

import {
  SimpleModal,
  ModalTitle,
  ButtonsContainer,
} from '../../../../../../../components/ModalCommon/ModalCommon';
import { StandardText } from '../../../../../../../components/Typography/Typography';
import Button from '../../../../../../../components/UI/Button/Button';
import { REMOVE_KE_FAMILY_MEMBER_MODAL } from './removeKEFamilyMemberModal.const';
import { useRemoveKEFamilyMemberModal } from './removeKEFamilyMemberModal.hooks';
import { StyledStandardText } from './removeKEFamilyMemberModal.styles';

type RemoveKEFamilyMemberModalProps = {
  onFamilyMemberDeleted?: () => void;
  id?: string;
};

const RemoveKEFamilyMemberModal = ({
  onFamilyMemberDeleted,
  id,
}: RemoveKEFamilyMemberModalProps) => {
  const { t } = useTranslation();

  const { member, onCancelHandler, removeFamilyMemberHandler, memberRemoving } =
    useRemoveKEFamilyMemberModal({ onFamilyMemberDeleted, id });

  return (
    <SimpleModal
      disabled={memberRemoving}
      name={REMOVE_KE_FAMILY_MEMBER_MODAL + id}
    >
      <ModalTitle>{t('headers.remove-family-member')}</ModalTitle>
      <Trans>
        <StandardText>
          <Trans>
            {t('phrase.are-you-sure-remove-family-member', {
              firstName: member?.firstName,
              lastName: member?.lastName,
            })}
          </Trans>
        </StandardText>
        <StyledStandardText>
          {t('phrase.removed-family-member-will-lose-access-to-benefits')}
        </StyledStandardText>
      </Trans>
      <ButtonsContainer>
        <Button
          disabled={memberRemoving}
          styleType="lighter-blue"
          onClick={onCancelHandler}
        >
          {t(`buttons.cancel`)}
        </Button>
        <Button
          isLoading={memberRemoving}
          styleType="background-delete"
          onClick={removeFamilyMemberHandler}
        >
          {t(`buttons.remove`)}
        </Button>
      </ButtonsContainer>
    </SimpleModal>
  );
};

export default RemoveKEFamilyMemberModal;
