import { ICallbackSaga } from '../../../models/interfaces/CallbackSaga';
import { ICancellationTokenSaga } from '../../../models/interfaces/CancellationTokenSaga';

export interface ISubmitHubSpotFormPayload
  extends ICancellationTokenSaga,
    ICallbackSaga {
  data?: any;
}

export type HubSpotField = {
  objectTypeId: string;
  name: string;
  value: string;
};

export type HubSpotContext = {
  hutk?: string;
  pageUri?: string;
  pageName?: string;
};

export type HubSpotRequestObject = {
  fields: HubSpotField[];
  context?: HubSpotContext;
};

export enum HubSpotObjectType {
  Contact = '0-1',
  Company = '0-2',
  Deal = '0-3',
  Ticket = '0-5',
}
