import { useCallback } from 'react';

import { useActions } from '../../../../hooks/useActions';
import { updateModalState } from '../../../../store/redux-slices/modals';
import { PURCHASE_FAILURE_INCORRECT_PLAN_MODAL_NAME } from './PurchaseFailureIncorrectPlanModal';

export const usePurchaseFailure = () => {
  const UpdateModalState = useActions(updateModalState);

  const selectDifferentPlanClick = useCallback(() => {
    UpdateModalState(PURCHASE_FAILURE_INCORRECT_PLAN_MODAL_NAME);
  }, [UpdateModalState]);

  return {
    selectDifferentPlanClick,
  };
};
