import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import Tooltip from '@mui/material/Tooltip';
import { forwardRef } from 'react';

import { mq } from '../../../utils/mediaQueries';

import { IMainTooltipProps } from './Tooltip';

export const StyledTooltip = styled(
  forwardRef((props: IMainTooltipProps, ref) => (
    <Tooltip
      classes={{
        popper: props.className,
        tooltip: 'custom-tooltip-label',
        arrow: 'custom-tooltip-arrow',
      }}
      {...props}
      ref={ref}
    />
  )),
)`
  & .custom-tooltip-label {
    font-size: 0.875rem;
    line-height: 1.313rem;
    max-width: ${({ width }) => width || '16rem'};
    color: ${({ theme }) => theme.color.white};
    text-align: center;
    background-color: ${({ theme }) => theme.color.dark1};

    ${({ width }) =>
      mq.xSmall(css`
        max-width: ${width || 'auto'};
      `)}
  }

  & .custom-tooltip-arrow {
    color: ${({ theme }) => theme.color.dark1};
  }
`;
