import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

import { mq } from '../../utils/mediaQueries';
import { Modal } from '../Modal/Modal';

export const ModalForm = styled(Modal)`
  width: 100%;

  ${mq.medium(css`
    width: 47.25rem;
  `)}
`;
