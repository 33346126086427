import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, putResolve } from 'redux-saga/effects';
import { createSliceSaga, SagaType } from 'redux-toolkit-saga';
import { call } from 'typed-redux-saga/macro';

import { Log } from '../../../utils/logger';
import { setCreditCards } from '../../redux-slices/payment';
import { updateProcessState } from '../../redux-slices/processes';
import {
  CREDIT_CARDS_LOAD_PROCESSING,
  CREDIT_CARD_ADD_PROCESSING,
  CREDIT_CARD_DELETE_PROCESSING,
  CREDIT_CARD_REPLACE_PROCESSING,
  PAYMENT_PROCESSING,
} from '../../redux-slices/processes/constants';
import {
  addNewCreditCard,
  capturePaymentRequest,
  deleteCreditCardRequest,
  getCardsOnFile,
  replaceCreditCard,
} from './api';
import {
  IAddCreditCardPayload,
  ICapturePaymentPayload,
  ICreditCardsInfoResponse,
  IDeleteCreditCardPayload,
  ILoadCreditCardsPayload,
  IReplaceExistingCreditCardPayload,
} from './models';

const paymentSlice = createSliceSaga({
  caseSagas: {
    *capturePayment({
      payload: {
        creditCardDetails,
        paymentId,
        cancellationToken,
        success,
        error,
      },
    }: PayloadAction<ICapturePaymentPayload>) {
      try {
        yield put(updateProcessState(PAYMENT_PROCESSING));
        yield call(
          capturePaymentRequest,
          paymentId,
          creditCardDetails,
          cancellationToken,
        );
        success?.();
      } catch (err: any) {
        Log.error(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(PAYMENT_PROCESSING));
      }
    },
    *loadCreditCardList({
      payload: { cancellationToken, success, error },
    }: PayloadAction<ILoadCreditCardsPayload>) {
      try {
        yield put(updateProcessState(CREDIT_CARDS_LOAD_PROCESSING));
        const response: AxiosResponse<ICreditCardsInfoResponse> = yield* call(
          getCardsOnFile,
          cancellationToken,
        );
        yield putResolve(setCreditCards(response.data.creditCards));
        success?.();
      } catch (err: any) {
        Log.error(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(CREDIT_CARDS_LOAD_PROCESSING));
      }
    },
    *replaceExistingCreditCard({
      payload: { cancellationToken, creditCardId, success, error },
    }: PayloadAction<IReplaceExistingCreditCardPayload>) {
      try {
        yield put(updateProcessState(CREDIT_CARD_REPLACE_PROCESSING));
        const response = yield* call(
          replaceCreditCard,
          creditCardId,
          cancellationToken,
        );
        success?.(response.data);
      } catch (err: any) {
        Log.error(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(CREDIT_CARD_REPLACE_PROCESSING));
      }
    },
    *addCreditCard({
      payload: { cancellationToken, success, error },
    }: PayloadAction<IAddCreditCardPayload>) {
      try {
        yield put(updateProcessState(CREDIT_CARD_ADD_PROCESSING));
        const response = yield* call(addNewCreditCard, cancellationToken);
        success?.(response.data);
      } catch (err: any) {
        Log.error(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(CREDIT_CARD_ADD_PROCESSING));
      }
    },
    *deleteCreditCard({
      payload: { cancellationToken, creditCardId, success, error },
    }: PayloadAction<IDeleteCreditCardPayload>) {
      try {
        yield put(updateProcessState(CREDIT_CARD_DELETE_PROCESSING));
        yield call(deleteCreditCardRequest, creditCardId, cancellationToken);
        success?.();
      } catch (err: any) {
        Log.error(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(CREDIT_CARD_DELETE_PROCESSING));
      }
    },
  },
  name: 'payment-saga',
  sagaType: SagaType.TakeLatest,
});

export default paymentSlice.saga;
export const {
  capturePayment,
  loadCreditCardList,
  replaceExistingCreditCard,
  addCreditCard,
  deleteCreditCard,
} = paymentSlice.actions;
export const { actions } = paymentSlice;
