import { SpinnerMode } from '../../Spinner/spinner.models';
import { ButtonStyleType } from './button.models';

export const spinnerModeByButtonType = (
  buttonType: ButtonStyleType,
): SpinnerMode => {
  switch (buttonType) {
    case 'light1':
    case 'icon':
    case 'lighter-blue':
    case 'link':
      return 'dark';
    default:
      return 'light';
  }
};
