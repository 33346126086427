import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';

import { store } from '../index';

import config from '../config/config';

export const browserHistory = createBrowserHistory();
const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    connectionString: config.applicationinsights?.connectionString,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
  },
});
appInsights.loadAppInsights();

appInsights.addTelemetryInitializer((envelope) => {
  envelope.data.environment = config.environment;
  envelope.data.ApplicationName = config.applicationinsights.applicationName;
  envelope.data.user = store.getState().auth?.user;
});

// log 4xx ajax responses as success instead of error
appInsights.addTelemetryInitializer((envelope) => {
  if (
    envelope.baseData.responseCode &&
    envelope.baseData.responseCode >= 400 &&
    envelope.baseData.responseCode < 500
  ) {
    envelope.baseData.success = true;
  }
});

export { reactPlugin, appInsights };
