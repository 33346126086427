import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { clearState } from '../../../globalActions';
import { ProductType } from '../../../../models/enums/ProductType';
import { DOCMembership } from '../../../../models/types/DOCMembership';
import { IDOCMemberships } from '../../../../models/interfaces/IDOCMemberships';

export interface IDOCMembershipState {
  docMemberships?: {
    active: DOCMembership[];
    expired: DOCMembership[];
  };
}

const initialState: IDOCMembershipState = {
  docMemberships: {
    active: [],
    expired: [],
  },
};

const docMembershipSlice = createSlice({
  initialState,
  name: 'docMembership',
  reducers: {
    setDOCMemberships: (state, { payload }: PayloadAction<IDOCMemberships>) => {
      const memberships: DOCMembership[] = payload.memberships.map((x) => ({
        ...x,
        productType: ProductType.DutyOfCare,
      }));
      state.docMemberships.active = memberships.filter(
        (membership) => !membership.isExpired,
      );
      state.docMemberships.expired = memberships.filter(
        (membership) => membership.isExpired,
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearState, () => initialState);
  },
});

export const { setDOCMemberships } = docMembershipSlice.actions;
export default docMembershipSlice.reducer;
export const membershipReducerName = docMembershipSlice.name;
