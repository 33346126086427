import { all } from 'redux-saga/effects';

import wizardSaga from './wizard/index';
import membershipSaga from './membership/index';
import paymentSaga from './payment/index';
import errorSaga from './error/index';
import membersSaga from './members/index';
import profileSaga from './profile/index';
import personalDataSaga from './personalData/index';
import membershipPromotionSaga from './membershipPromotion';
import hubSpotSliceSaga from './hubSpot';
import plansSagasRoot from './plans';
import partnershipSagasRoot from './partnership';

export default function* rootSaga() {
  yield all([
    wizardSaga(),
    paymentSaga(),
    errorSaga(),
    membersSaga(),
    profileSaga(),
    personalDataSaga(),
    membershipPromotionSaga(),
    hubSpotSliceSaga(),
    ...plansSagasRoot,
    ...membershipSaga,
    ...partnershipSagasRoot,
  ]);
}
