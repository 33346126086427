import styled from '@emotion/styled';
import Menu from '@mui/material/Menu';

export const StyledMenu = styled(Menu)`
  ${({ theme }) => ({
    '& .MuiPaper-root': {
      minWidth: '14rem',
    },
    '& .MuiMenuItem-root ': {
      paddingTop: '0.75rem',
      paddingBottom: '0.75rem',
    },
  })}
`;
