import { ChangeEvent, useCallback, useMemo } from 'react';
import { FieldInputProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { useRunOnce } from '../../../../hooks/useRunOnce';
import { getPropertyName } from '../../../../utils/getPropertyName';

type UseFormCheckboxProps = {
  input: FieldInputProps<boolean, HTMLElement>;
  onChange?: (value: ChangeEvent<HTMLInputElement>) => void;
  label?: string;
};

export const useFormCheckbox = ({
  input,
  onChange,
  label,
}: UseFormCheckboxProps) => {
  const { t } = useTranslation();

  useRunOnce(() => {
    if (!input.checked) {
      input.onChange(false);
    }
  });

  const onChangeHandler = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      input.onChange(e);
      onChange && onChange(e);
    },
    [input, onChange],
  );

  const currentLabel = useMemo(
    () => label || t(`fields.${getPropertyName(input.name)}.label`),
    [input.name, label, t],
  );

  return { onChangeHandler, currentLabel };
};
