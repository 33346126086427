import { createSliceSaga, SagaType } from 'redux-toolkit-saga';
import { PayloadAction } from '@reduxjs/toolkit';

import { call, put } from 'typed-redux-saga/macro';

import { AxiosResponse } from 'axios';

import { updateProcessState } from '../../../redux-slices/processes';
import { Log } from '../../../../utils/logger';
import { MEMBERSHIP_PLANS_LOAD_PROCESSING } from '../../../redux-slices/processes/constants';
import { ILoadMembershipPlansPayload } from './models';
import { getAAMembershipPlans } from './api';
import { setAAMembershipPlans } from '../../../redux-slices/plans/airAmbulance';
import { IMembershipPlansTreeModel } from '../../../../models/interfaces/MembershipPlansTreeModel';

const aaPlansSlice = createSliceSaga({
  caseSagas: {
    *loadAAMembershipPlans({
      payload: { success, error, promoCode, effectiveDate, cancellationToken },
    }: PayloadAction<ILoadMembershipPlansPayload>) {
      try {
        yield put(updateProcessState(MEMBERSHIP_PLANS_LOAD_PROCESSING));
        const response: AxiosResponse<IMembershipPlansTreeModel> = yield* call(
          getAAMembershipPlans,
          promoCode,
          effectiveDate,
          cancellationToken,
        );
        yield put(setAAMembershipPlans(response.data));
        success?.(response.data.isPromoCodeApplied);
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(MEMBERSHIP_PLANS_LOAD_PROCESSING));
      }
    },
  },
  name: 'aa-plans-saga',
  sagaType: SagaType.TakeLatest,
});

export default aaPlansSlice.saga;
export const { loadAAMembershipPlans } = aaPlansSlice.actions;
export const { actions } = aaPlansSlice;
