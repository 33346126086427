import { createSliceSaga, SagaType } from 'redux-toolkit-saga';
import { put, call } from 'typed-redux-saga/macro';
import { PayloadAction } from '@reduxjs/toolkit';

import { AxiosResponse } from 'axios';

import { Log } from '../../../../utils/logger';
import { ILoadDOCMembershipsPayload } from './models';
import { updateProcessState } from '../../../redux-slices/processes';
import { MEMBERSHIPS_DOC_LOAD_PROCESSING } from '../../../redux-slices/processes/constants';
import { getDOCMemberships } from './api';
import { IDOCMemberships } from '../../../../models/interfaces/IDOCMemberships';
import { setDOCMemberships } from '../../../redux-slices/membership/dutyOfCare';

const docMembershipSlice = createSliceSaga({
  caseSagas: {
    *loadDOCMemberships({
      payload: { error, success, cancellationToken },
    }: PayloadAction<ILoadDOCMembershipsPayload>) {
      try {
        yield put(updateProcessState(MEMBERSHIPS_DOC_LOAD_PROCESSING));

        const response: AxiosResponse<IDOCMemberships> = yield* call(
          getDOCMemberships,
          cancellationToken,
        );

        yield put(
          setDOCMemberships({ memberships: response.data.memberships }),
        );
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(MEMBERSHIPS_DOC_LOAD_PROCESSING));
      }
    },
  },
  name: 'ke-membership-saga',
  sagaType: SagaType.TakeLatest,
});

export default docMembershipSlice.saga;
export const { loadDOCMemberships } = docMembershipSlice.actions;
export const { actions } = docMembershipSlice;
