import { useTranslation } from 'react-i18next';

import ErrorPage from '../../components/ErrorPage/ErrorPage';
import { useNotFoundPage } from './notFoundPage.hooks';
import ErrorImage from '../../assets/images/404.png';

const NotFoundPage = () => {
  const { t } = useTranslation();
  const { navigateHomePage } = useNotFoundPage();

  return (
    <ErrorPage
      iconPath={ErrorImage}
      title={t('headers.page-not-found')}
      description={t('phrase.page-could-not-be-found')}
      buttonLabel={t('buttons.go-to-home-page')}
      buttonAction={navigateHomePage}
    />
  );
};

export default NotFoundPage;
