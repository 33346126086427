import { AxiosResponse } from 'axios';
import { call, putResolve, select } from 'typed-redux-saga/macro';

import { isAuthenticated } from '../../../../common/auth/initAuth';

import { ICreateIndividualMembership } from '../../../../models/interfaces/CreateIndividualMembership';
import { ICreateMembershipMemberDetails } from '../../../../models/interfaces/ICreateMembershipMemberDetails';

import { ICreateMembershipPlan } from '../../../../models/interfaces/ICreateMembershipPlan';
import { Membership } from '../../../../models/types/Membership';
import {
  createdMembershipIdSelector,
  createMembershipDataSelector,
  createMembershipPlanSelector,
} from '../../../redux-slices/membership/airAmbulance/selectors';
import { capturePayment } from '../../payment';
import { ICapturePaymentPayload } from '../../payment/models';
import { checkAAPlanAvailabilityRequest } from '../../plans/airAmbulance/api';
import { IMembershipPlanAvailability } from '../../plans/airAmbulance/models';
import {
  createMembershipRequest,
  getMembershipDetails,
  validateNewMembership,
} from './api';
import {
  ICreateMembershipPayload,
  ICreateMembershipResult,
  IValidateNewMembershipRequest,
  IValidateNewMembershipResponse,
} from './models';

export function* checkIfLoginRequired(
  data: ICreateMembershipPlan,
  cancellationToken: AbortSignal,
) {
  const isAuth = yield* call(isAuthenticated);

  if (isAuth) {
    return false;
  }

  const requestData: IValidateNewMembershipRequest = {
    emailAddress: data.emailAddress,
    membershipPlanId: data.membershipPlanInfo.membershipPlan.planId,
    promoCode: data.promoCode,
  };
  const response: AxiosResponse<IValidateNewMembershipResponse> = yield* call(
    validateNewMembership,
    requestData,
    cancellationToken,
  );

  return response.data.loginRequired;
}

export function* checkPlanAvailability(
  data: ICreateMembershipPlan,
  cancellationToken: AbortSignal,
  renewMembershipId?: string,
) {
  const isAuth = yield* call(isAuthenticated);

  if (!isAuth) {
    return { isAvailable: true };
  }

  const availabilityResponse: AxiosResponse<IMembershipPlanAvailability> =
    yield* call(
      checkAAPlanAvailabilityRequest,
      data.membershipPlanInfo.membershipPlan.planId,
      cancellationToken,
      renewMembershipId,
    );

  return availabilityResponse.data;
}

export function* prepareMembershipData(data: ICreateMembershipMemberDetails) {
  const createMembershipPlan = yield* select(createMembershipPlanSelector);
  const result: ICreateIndividualMembership = {
    ...data,
    membershipPlanId:
      createMembershipPlan.membershipPlanInfo.membershipPlan.planId,
    promoCode: createMembershipPlan.promoCode,
    primaryMember: {
      ...data.primaryMember,
      emailAddress: createMembershipPlan.emailAddress,
    },
  };
  return result;
}

export function* createMembership(
  autoRenewal: boolean,
  memberAgreedTermsAndConditions: boolean,
  handlePayment: boolean,
  reCaptchaToken: string,
  cancellationToken: AbortSignal,
  renewedMembershipId?: string,
) {
  const storageMembershipData = yield* select(createMembershipDataSelector);
  const membershipData: ICreateIndividualMembership = {
    ...storageMembershipData,
    renewedMembershipId,
    memberAgreedTermsAndConditions,
    autoRenewal,
  };

  const response: AxiosResponse<ICreateMembershipResult> = yield* call(
    createMembershipRequest,
    membershipData,
    reCaptchaToken,
    handlePayment,
    cancellationToken,
  );

  return response.data;
}

export function* createMembershipPayment(
  { success, error, data, cancellationToken }: ICreateMembershipPayload,
  paymentId: string,
  isPaymentCaptureRequired: boolean,
) {
  if (!isPaymentCaptureRequired) {
    return;
  }

  const paymentPayload: ICapturePaymentPayload = {
    success,
    error,
    cancellationToken,
    creditCardDetails: {
      cardHolderName: data.cardHolderName,
      existingCreditCardId: data.existingCreditCardId,
    },
    paymentId,
  };

  return yield* putResolve(capturePayment(paymentPayload));
}

export function* needLoadCreatedMembership(membershipsList: Membership[]) {
  const createdMembershipId = yield* select(createdMembershipIdSelector);

  if (!createdMembershipId) {
    return false;
  }
  return !membershipsList.some(
    (membership) => membership.membershipId === createdMembershipId,
  );
}

export function* getCreatedMembershipDetails(cancellationToken: AbortSignal) {
  const createdMembershipId = yield* select(createdMembershipIdSelector);

  if (!createdMembershipId) {
    return null;
  }

  const response = yield* call(
    getMembershipDetails,
    createdMembershipId,
    cancellationToken,
  );

  return response.data;
}
