import { createSliceSaga, SagaType } from 'redux-toolkit-saga';
import { put, call, select } from 'typed-redux-saga/macro';
import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';

import { IMemberships } from 'models/interfaces/IMemberships';

import { changeToNextStep } from '../../wizard';
import { Log } from '../../../../utils/logger';
import {
  clearCreateMembershipProcessData,
  setCreateMembershipMemberDetails,
  setCreateMembershipPlan,
  setMembershipSummary,
  setOrderPaymentDetails,
  setAAMemberships,
  setMembershipRenewalSuggestion,
  setCreatedMembershipId,
} from '../../../redux-slices/membership/airAmbulance';
import {
  IAddAAFamilyMemberPayload,
  ICreateMembershipPayload,
  ILoadMembershipRenewalSuggestionPayload,
  ILoadAAMembershipsPayload,
  ILoadMembershipSummaryPayload,
  ILoadOrderPaymentDetailsPayload,
  IRemoveAAFamilyMemberPayload,
  ISubmitCreateMembershipDetailsPayload,
  ISubmitCreateMembershipPlanPayload,
  IUpdateMembershipRenewFlagPayload,
  ICreateMembershipResult,
} from './models';
import { updateProcessState } from '../../../redux-slices/processes';
import {
  MEMBERSHIP_SUMMARY_LOAD_PROCESSING,
  NEW_MEMBERSHIP_VALIDATION_PROCESSING,
  MEMBERSHIP_CREATING_PROCESSING,
  MEMBERSHIP_MEMBER_DETAILS_CREATING_PROCESSING,
  ORDER_PAYMENT_DETAILS_LOAD_PROCESSING,
  FAMILY_MEMBER_ADDING_PROCESSING,
  FAMILY_MEMBER_REMOVING_PROCESSING,
  MEMBERSHIP_AUTO_RENEW_FLAG_UPDATE_PROCESSING,
  MEMBERSHIP_SUGGESTION_LOAD_PROCESSING,
  MEMBERSHIPS_AA_LOAD_PROCESSING,
} from '../../../redux-slices/processes/constants';
import {
  analyzeNewMembership,
  createAASecondaryMember,
  deleteAASecondaryMember,
  getMembershipOrderSummary,
  getMembershipRenewalSuggestion,
  getMemberships,
  getOrderPaymentDetails,
  putMembershipToBeRenewed,
} from './api';
import {
  createMembership,
  prepareMembershipData,
  checkIfLoginRequired,
  checkPlanAvailability,
  needLoadCreatedMembership,
  getCreatedMembershipDetails,
} from './utils';
import { IRetailMembershipOrderSummaryModel } from '../../../../models/interfaces/RetailMembershipOrderSummaryModel';
import {
  createMembershipPlanSelector,
  effectiveDateMembershipSuggestionSelector,
  selectedMembershipPromoCodeSelector,
} from '../../../redux-slices/membership/airAmbulance/selectors';
import { IIndividualMembershipPaymentDetails } from '../../../../models/interfaces/IndividualMembershipPaymentDetails';
import { resetWizard } from '../../../redux-slices/wizard';
import { IMembershipPlanAvailability } from '../../plans/airAmbulance/models';
import { setError } from '../../../redux-slices/errors';
import { IMembershipPlansTreeModel } from '../../../../models/interfaces/MembershipPlansTreeModel';
import { getAAMembershipPlans } from '../../plans/airAmbulance/api';
import { getPaymentIntent } from '../../payment/api';
import { IPaymentIntentModel } from '../../../../models/interfaces/PaymentIntentModel';
import {
  getUserEmail,
  isAuthenticated,
} from '../../../../common/auth/initAuth';

const aaMembershipSlice = createSliceSaga({
  caseSagas: {
    *submitCreateMemberShipPlan({
      payload: { data, renewedMembershipId, success, error, cancellationToken },
    }: PayloadAction<ISubmitCreateMembershipPlanPayload>) {
      try {
        yield put(updateProcessState(NEW_MEMBERSHIP_VALIDATION_PROCESSING));

        const loginRequired: boolean = yield* call(
          checkIfLoginRequired,
          data,
          cancellationToken,
        );
        if (loginRequired) {
          success?.({ needLogin: true });

          return;
        }

        yield put(setCreateMembershipPlan(data));

        const planAvailability: IMembershipPlanAvailability = yield* call(
          checkPlanAvailability,
          data,
          cancellationToken,
          renewedMembershipId,
        );

        if (!planAvailability.isAvailable) {
          error(planAvailability);
          return;
        }

        yield put(changeToNextStep());
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(NEW_MEMBERSHIP_VALIDATION_PROCESSING));
      }
    },
    *loadMembershipSummary({
      payload: {
        autoRenewal,
        error,
        success,
        cancellationToken,
        renewMembershipId,
      },
    }: PayloadAction<ILoadMembershipSummaryPayload>) {
      try {
        const userEmail: any = yield* call(getUserEmail);
        yield put(updateProcessState(MEMBERSHIP_SUMMARY_LOAD_PROCESSING));
        const createMembershipPlan = yield* select(
          createMembershipPlanSelector,
        );
        const response: AxiosResponse<IRetailMembershipOrderSummaryModel> =
          yield* call(
            getMembershipOrderSummary,
            createMembershipPlan.membershipPlanInfo.membershipPlan.planId,
            autoRenewal,
            createMembershipPlan.promoCode,
            createMembershipPlan.emailAddress || userEmail,
            cancellationToken,
            renewMembershipId,
          );
        yield put(setMembershipSummary(response.data));
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(MEMBERSHIP_SUMMARY_LOAD_PROCESSING));
      }
    },
    *submitCreateMemberShipMemberDetails({
      payload: { data, error, success, cancellationToken },
    }: PayloadAction<ISubmitCreateMembershipDetailsPayload>) {
      try {
        yield put(
          updateProcessState(MEMBERSHIP_MEMBER_DETAILS_CREATING_PROCESSING),
        );
        const membershipData = yield* call(prepareMembershipData, data);
        yield call(analyzeNewMembership, membershipData, cancellationToken);
        yield put(setCreateMembershipMemberDetails(data));
        yield put(changeToNextStep());
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(
          updateProcessState(MEMBERSHIP_MEMBER_DETAILS_CREATING_PROCESSING),
        );
      }
    },
    *submitCreateMembership({
      payload: {
        data,
        renewedMembershipId,
        reCaptchaToken,
        handlePayment,
        cancellationToken,
        success,
        error,
      },
    }: PayloadAction<ICreateMembershipPayload>) {
      try {
        const isAuth = yield call(isAuthenticated);
        yield put(updateProcessState(MEMBERSHIP_CREATING_PROCESSING));

        const response: ICreateMembershipResult = yield* call(
          createMembership,
          data.autoRenewal,
          data.memberAgreedTermsAndConditions,
          handlePayment,
          reCaptchaToken,
          cancellationToken,
          renewedMembershipId,
        );

        let paymentIntent: IPaymentIntentModel;

        if (response.isPaymentCaptureRequired) {
          const paymentIntentResponse = yield* call(
            getPaymentIntent,
            response.paymentId,
          );
          paymentIntent = paymentIntentResponse.data;
        }

        if (isAuth) {
          yield put(setCreatedMembershipId(response.membershipId));
        }
        success?.({ ...response, paymentIntent });
      } catch (err: any) {
        Log.errorException(err);
        yield put(setError(err && err.response && err.response.data));
      } finally {
        yield put(updateProcessState(MEMBERSHIP_CREATING_PROCESSING));
      }
    },
    *loadOrderPaymentDetails({
      payload: { error, success, cancellationToken },
    }: PayloadAction<ILoadOrderPaymentDetailsPayload>) {
      try {
        yield put(updateProcessState(ORDER_PAYMENT_DETAILS_LOAD_PROCESSING));
        const promoCode = yield* select(selectedMembershipPromoCodeSelector);
        const effectiveDate = yield* select(
          effectiveDateMembershipSuggestionSelector,
        );

        const response: AxiosResponse<IIndividualMembershipPaymentDetails> =
          yield* call(
            getOrderPaymentDetails,
            promoCode,
            effectiveDate,
            cancellationToken,
          );
        yield put(setOrderPaymentDetails(response.data));
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(ORDER_PAYMENT_DETAILS_LOAD_PROCESSING));
      }
    },
    *clearMembershipProcess() {
      yield put(clearCreateMembershipProcessData());
      yield put(resetWizard());
    },
    *loadAAMemberships({
      payload: { error, success, cancellationToken },
    }: PayloadAction<ILoadAAMembershipsPayload>) {
      try {
        yield put(updateProcessState(MEMBERSHIPS_AA_LOAD_PROCESSING));

        const response: AxiosResponse<IMemberships> = yield* call(
          getMemberships,
          cancellationToken,
        );

        const memberships = [...response.data.memberships];
        const needLoadMembership = yield* call(
          needLoadCreatedMembership,
          memberships,
        );

        if (needLoadMembership) {
          const createdMembershipDetails = yield* call(
            getCreatedMembershipDetails,
            cancellationToken,
          );
          memberships.push(createdMembershipDetails);
        }

        yield put(setAAMemberships({ memberships }));
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(MEMBERSHIPS_AA_LOAD_PROCESSING));
      }
    },
    *addAAFamilyMember({
      payload: { error, success, data, membershipId, cancellationToken },
    }: PayloadAction<IAddAAFamilyMemberPayload>) {
      try {
        yield put(updateProcessState(FAMILY_MEMBER_ADDING_PROCESSING));
        yield call(
          createAASecondaryMember,
          membershipId,
          data,
          cancellationToken,
        );
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(FAMILY_MEMBER_ADDING_PROCESSING));
      }
    },
    *removeAAFamilyMember({
      payload: { error, success, membershipId, memberId, cancellationToken },
    }: PayloadAction<IRemoveAAFamilyMemberPayload>) {
      try {
        yield put(updateProcessState(FAMILY_MEMBER_REMOVING_PROCESSING));
        yield call(
          deleteAASecondaryMember,
          membershipId,
          memberId,
          cancellationToken,
        );
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(FAMILY_MEMBER_REMOVING_PROCESSING));
      }
    },
    *updateMembershipRenewFlag({
      payload: { error, success, membershipId, toBeRenewed, cancellationToken },
    }: PayloadAction<IUpdateMembershipRenewFlagPayload>) {
      try {
        yield put(
          updateProcessState(MEMBERSHIP_AUTO_RENEW_FLAG_UPDATE_PROCESSING),
        );
        const response = yield* call(
          putMembershipToBeRenewed,
          membershipId,
          toBeRenewed,
          cancellationToken,
        );

        success?.(response.data);
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(
          updateProcessState(MEMBERSHIP_AUTO_RENEW_FLAG_UPDATE_PROCESSING),
        );
      }
    },
    *loadMembershipRenewalSuggestion({
      payload: { error, success, membershipId, cancellationToken },
    }: PayloadAction<ILoadMembershipRenewalSuggestionPayload>) {
      try {
        yield put(updateProcessState(MEMBERSHIP_SUGGESTION_LOAD_PROCESSING));
        const result = yield* call(
          getMembershipRenewalSuggestion,
          membershipId,
          cancellationToken,
        );

        const plansResponse: AxiosResponse<IMembershipPlansTreeModel> =
          yield* call(getAAMembershipPlans, null, null, cancellationToken);
        yield put(
          setMembershipRenewalSuggestion({
            plans: plansResponse.data,
            suggestion: result.data,
          }),
        );
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(MEMBERSHIP_SUGGESTION_LOAD_PROCESSING));
      }
    },
  },
  name: 'aa-membership-saga',
  sagaType: SagaType.TakeLatest,
});

export default aaMembershipSlice.saga;
export const {
  submitCreateMemberShipPlan,
  loadMembershipSummary,
  submitCreateMemberShipMemberDetails,
  submitCreateMembership,
  loadOrderPaymentDetails,
  clearMembershipProcess,
  loadAAMemberships,
  addAAFamilyMember,
  removeAAFamilyMember,
  updateMembershipRenewFlag,
  loadMembershipRenewalSuggestion,
} = aaMembershipSlice.actions;
export const { actions } = aaMembershipSlice;
