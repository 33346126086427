import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import CreditCardDetailsForm from '../../../../../components/Forms/CreditCardDetailsForm/CreditCardDetailsForm';
import ModalFormPage from '../../../../../components/ModalFormPage/modalFormPage';
import { withStripeElements } from '../../../../../components/Stripe/withStripeElements';
import { useAddCreditCardModalForm } from './addCreditCardModalForm.hooks';

type AddCreditCardModalFormProps = {
  onCreditCardAdded?: () => void;
  title?: string;
  description?: string;
  modalName: string;
};

const AddCreditCardModalForm = ({
  onCreditCardAdded,
  title,
  description,
  modalName,
}: AddCreditCardModalFormProps) => {
  const { t } = useTranslation();
  const { onCancelHandler, submitHandler, validateForm, creditCardAdding } =
    useAddCreditCardModalForm({
      onCreditCardAdded,
      modalName,
    });

  return (
    <Form
      keepDirtyOnReinitialize
      onSubmit={submitHandler}
      validate={validateForm}
    >
      {({ ...renderProps }) => (
        <ModalFormPage
          handleSubmit={renderProps.handleSubmit}
          onCancel={onCancelHandler}
          title={title || t('headers.add-credit-card')}
          description={description}
          isProcessing={creditCardAdding}
        >
          <CreditCardDetailsForm disabled={creditCardAdding} />
        </ModalFormPage>
      )}
    </Form>
  );
};

export default withStripeElements(AddCreditCardModalForm);
