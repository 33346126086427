import { IAddressLines } from '../../../models/interfaces/Address';
import { TooltipAddress } from './profile.styles';

export const getAddress = (addressLines: IAddressLines) => (
  <TooltipAddress>
    <div>{addressLines.line1}</div>
    <div>{addressLines.line2}</div>
    <div>{addressLines.line3}</div>
  </TooltipAddress>
);
