import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';

import ArrowDown from '../../../../assets/icons/arrow-down-icon.svg';
import ArrowUp from '../../../../assets/icons/arrow-up-icon.svg';

type Props = {
  isMenuOpen: boolean;
};

export const Indicator = styled('div', {
  shouldForwardProp: isPropValid,
})`
  width: 1rem;
  height: 1rem;
  transition: all 0.2s ease;
  ${({ isMenuOpen }: Props) =>
    isMenuOpen
      ? `background: url(${ArrowUp}) 50% no-repeat;`
      : `background: url(${ArrowDown}) 50% no-repeat;`};
`;
