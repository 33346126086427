import { TooltipProps } from '@mui/material/Tooltip';
import { forwardRef } from 'react';

import { StyledTooltip } from './tooltip.styles';

export interface IMainTooltipProps extends TooltipProps {
  className?: string;
  isShowing?: boolean;
  width?: string;
}

export type TooltipPlacement =
  | 'bottom-end'
  | 'bottom-start'
  | 'bottom'
  | 'left-end'
  | 'left-start'
  | 'left'
  | 'right-end'
  | 'right-start'
  | 'right'
  | 'top-end'
  | 'top-start'
  | 'top';

const MainTooltip = forwardRef(
  (
    {
      children,
      className,
      isShowing = true,
      placement = 'top',
      arrow = true,
      ...props
    }: IMainTooltipProps,
    ref,
  ) => {
    if (!isShowing || !props.title) {
      return children;
    }

    return (
      <StyledTooltip
        ref={ref}
        className={className}
        placement={placement}
        arrow={arrow}
        {...props}
      >
        <div>{children}</div>
      </StyledTooltip>
    );
  },
);

export default MainTooltip;
