import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

import { StandardText } from '../../../../../components/Typography/Typography';
import { mq } from '../../../../../utils/mediaQueries';

export const StyledStandardText = styled(StandardText)`
  margin-top: 2.5rem;

  ${mq.xSmall(css`
    margin-top: 3.5rem;
  `)}
`;
