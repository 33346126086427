import { useTranslation } from 'react-i18next';

import { Name, ShortName, Wrapper } from './headerLoggedUser.styles';
import { useHeaderLoggedUser } from './headerLoggedUser.hooks';

const HeaderLoggedUser = () => {
  const { t } = useTranslation();
  const { handleNavigateToProfile, firstName, shortName } =
    useHeaderLoggedUser();

  return (
    <Wrapper>
      <Name>{t('phrase.welcome-user', { userName: firstName })}</Name>
      {shortName && (
        <ShortName onClick={handleNavigateToProfile}>{shortName}</ShortName>
      )}
    </Wrapper>
  );
};

export default HeaderLoggedUser;
