import { useTranslation } from 'react-i18next';

import {
  SimpleModal,
  ModalTitle,
  ModalButton,
} from '../../../../components/ModalCommon/ModalCommon';

import { StandardText } from '../../../../components/Typography/Typography';
import config from '../../../../config/config';
import { usePurchaseFailure } from './purchaseFailureIncorrectPlanModal.hooks';
import { Email } from './purchaseFailureIncorrectPlanModal.styles';

export const PURCHASE_FAILURE_INCORRECT_PLAN_MODAL_NAME =
  'PURCHASE_FAILURE_INCORRECT_PLAN_MODAL_NAME';

const PurchaseFailureIncorrectPlanModal = () => {
  const { t } = useTranslation();
  const { selectDifferentPlanClick } = usePurchaseFailure();

  return (
    <SimpleModal name={PURCHASE_FAILURE_INCORRECT_PLAN_MODAL_NAME}>
      <ModalTitle>{t('headers.purchase-failure')}</ModalTitle>
      <StandardText>
        {t('phrase.you-cannot-purchase-selected-plan')}
      </StandardText>
      <Email>{`${t('properties.email')}: ${
        config.contact.airAmbulanceEmail
      }`}</Email>
      <StandardText>{`${t('properties.phone')}: ${
        config.contact.operationsCenterPhone
      }`}</StandardText>
      <ModalButton onClick={selectDifferentPlanClick}>
        {t(`buttons.select-different-plan`)}
      </ModalButton>
    </SimpleModal>
  );
};

export default PurchaseFailureIncorrectPlanModal;
