import { useActions } from '../../../../../hooks/useActions';
import {
  Membership,
  FamilyMember,
} from '../../../../../models/types/Membership';
import { updateModalState } from '../../../../../store/redux-slices/modals';
import { ADD_SR_FAMILY_MEMBER_MODAL } from './Modals/AddSRFamilyMemberModal/addSRFamilyMemberModal.const';
import { AddSRFamilyMemberModalDetails } from './Modals/AddSRFamilyMemberModal/addSRFamilyMemberModal.models';
import { REMOVE_SR_FAMILY_MEMBER_MODAL } from './Modals/RemoveSRFamilyMemberModal/removeSRFamilyMemberModal.const';
import { RemoveSRFamilyMemberModalDetails } from './Modals/RemoveSRFamilyMemberModal/removeSRFamilyMemberModal.models';

export const useSRMembership = (membership: Membership) => {
  const UpdateModalState = useActions(updateModalState);

  const {
    familyMembers,
    membershipId,
    metadata: { canAddMemberTypes },
  } = membership;

  const addFamilyMemberHandler = () => {
    const details: AddSRFamilyMemberModalDetails = {
      membership,
    };
    UpdateModalState([ADD_SR_FAMILY_MEMBER_MODAL + membershipId, details]);
  };

  const removeFamilyMemberHandler = (member: FamilyMember) => {
    const details: RemoveSRFamilyMemberModalDetails = {
      membership,
      member,
    };
    UpdateModalState([REMOVE_SR_FAMILY_MEMBER_MODAL + membershipId, details]);
  };

  const showFamilyMembers =
    !!familyMembers?.length || !!canAddMemberTypes?.length;

  return {
    addFamilyMemberHandler,
    removeFamilyMemberHandler,
    showFamilyMembers,
  };
};
