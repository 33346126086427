import { useCallback, useMemo } from 'react';
import { useAlert } from 'react-alert';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useSelector } from 'react-redux';

import {
  useFormErrorsActions,
  useFormErrorsValues,
} from '../../../../../components/FormErrorProvider/FormErrorProvider.hooks';

import { useActions } from '../../../../../hooks/useActions';
import { useNavigateSearch } from '../../../../../hooks/useNavigationSearch';
import { useProcessing } from '../../../../../hooks/useProcessing';
import { useRouteParams } from '../../../../../hooks/useRouteParams';
import useStripeProvider from '../../../../../hooks/useStripeProvider';
import { useCancellationToken } from '../../../../../hooks/useTokenCancellation';
import { RoutePath } from '../../../../../models/enums/RoutePath';

import { ICreateMembershipPayment } from '../../../../../models/interfaces/ICreateMembershipPayment';
import {
  createMembershipPaymentSelector,
  isSelectedMembershipPlanPeriodRenewal,
  memberDetailsPrimaryMemberNameSelector,
  orderPaymentDetailsSelector,
} from '../../../../../store/redux-slices/membership/airAmbulance/selectors';
import {
  MEMBERSHIP_CREATING_PROCESSING,
  ORDER_PAYMENT_DETAILS_LOAD_PROCESSING,
  PAYMENT_PROCESSING,
} from '../../../../../store/redux-slices/processes/constants';
import { submitCreateMembership } from '../../../../../store/saga-slices/membership/airAmbulance';
import { ICreateMembershipPayload } from '../../../../../store/saga-slices/membership/airAmbulance/models';
import { useMobileMediaQuery } from '../../../../../utils/mediaQueries';
import { AddMembershipPaymentFormFields } from './addMembershipPaymentForm.models';
import { validateAddMembershipPaymentForm } from './addMembershipPaymentForm.validation';
import { AutoRenewFormFields } from './Forms/AutoRenewForm/autoRenewForm.models';
import { PaymentExistingCardFields } from './Forms/PaymentUseExistingCardForm/paymentUseExistingCardForm.models';

export const useAddMembershipPaymentForm = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const alert = useAlert();
  const navigate = useNavigateSearch();

  const SubmitCreateMembership = useActions(submitCreateMembership);

  const { membershipId } = useRouteParams();
  const orderPaymentDetails = useSelector(orderPaymentDetailsSelector);
  const memberName = useSelector(memberDetailsPrimaryMemberNameSelector);
  const createMembershipPayment = useSelector(createMembershipPaymentSelector);

  const isRenewalPlan = useSelector(isSelectedMembershipPlanPeriodRenewal);

  const isMobile = useMobileMediaQuery();

  const { validateErrors } = useFormErrorsActions();
  const { errors } = useFormErrorsValues();

  const membershipCreating = useProcessing([
    MEMBERSHIP_CREATING_PROCESSING,
    PAYMENT_PROCESSING,
  ]);

  const isDataLoading = useProcessing(ORDER_PAYMENT_DETAILS_LOAD_PROCESSING);

  const cancellationToken = useCancellationToken();

  const stripe = useStripeProvider();

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      // eslint-disable-next-line no-console
      console.log('Execute recaptcha not yet available');
      return;
    }

    const token = await executeRecaptcha('EnrollAAMembership');
    return token;
  }, [executeRecaptcha]);

  const successResponseHandler = useCallback(() => {
    navigate(RoutePath.AddMembershipConfirmation, {}, { memberName });
  }, [memberName, navigate]);

  const onPaymentHandler = useCallback(
    (paymentId: string, values: ICreateMembershipPayment) => {
      if (values.existingCreditCardId) {
        stripe.confirmCardPayment(
          values.paymentIntentInfo,
          values.existingCreditCardId,
          successResponseHandler,
          ({ alertErrorMessage }) => alert.error(alertErrorMessage),
        );
        return;
      }
      stripe.payWithStripe(
        values.paymentIntentInfo,
        values,
        successResponseHandler,
        ({ alertErrorMessage }) => alert.error(alertErrorMessage),
      );
    },
    [alert, stripe, successResponseHandler],
  );

  const onSubmitHandler = useCallback(
    (values: ICreateMembershipPayment) => {
      handleReCaptchaVerify().then((token) => {
        const payload: ICreateMembershipPayload = {
          reCaptchaToken: token,
          data: values,
          handlePayment: true,
          cancellationToken,
          renewedMembershipId: membershipId,
          success: ({ paymentId, isPaymentCaptureRequired, paymentIntent }) => {
            if (isPaymentCaptureRequired) {
              onPaymentHandler(paymentId, {
                ...values,
                paymentIntentInfo: paymentIntent,
              });
              return;
            }
            navigate(RoutePath.AddMembershipConfirmation, {}, { memberName });
          },
        };

        SubmitCreateMembership(payload);
      });
    },
    [
      SubmitCreateMembership,
      cancellationToken,
      handleReCaptchaVerify,
      memberName,
      membershipId,
      navigate,
      onPaymentHandler,
    ],
  );

  const validateForm = useCallback(
    (data: ICreateMembershipPayment) => {
      const internalErrors =
        !data.existingCreditCardId && errors.filter((x) => x.internalError);
      return {
        ...validateAddMembershipPaymentForm(data, validateErrors(data)),
        ...internalErrors,
      };
    },
    [errors, validateErrors],
  );

  const initForm = useMemo(
    () => ({
      [AutoRenewFormFields.AutoRenewal]: isRenewalPlan,
      [AddMembershipPaymentFormFields.IsPaymentRequired]:
        orderPaymentDetails?.isPaymentRequired,
      [PaymentExistingCardFields.ExistingCreditCardId]: null,
      ...createMembershipPayment,
    }),
    [
      createMembershipPayment,
      isRenewalPlan,
      orderPaymentDetails?.isPaymentRequired,
    ],
  );

  return {
    onSubmitHandler,
    validateForm,
    initForm,
    membershipCreating,
    isDataLoading,
    orderPaymentDetails,
    isMobile,
    isRenewalPlan,
  };
};
