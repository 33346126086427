import { useAlert } from 'react-alert';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useModalDetails } from '../../../../hooks/useModalDetails';
import { UPDATE_AUTO_RENEW_FLAG_MODAL } from './updateAutoRenewFlagModal.consts';
import { Membership } from '../../../../models/types/Membership';
import { useActions } from '../../../../hooks/useActions';
import { updateMembershipRenewFlag } from '../../../../store/saga-slices/membership/airAmbulance';
import { IUpdateMembershipRenewFlagPayload } from '../../../../store/saga-slices/membership/airAmbulance/models';

import { updateModalState } from '../../../../store/redux-slices/modals';
import { useProcessing } from '../../../../hooks/useProcessing';
import { MEMBERSHIP_AUTO_RENEW_FLAG_UPDATE_PROCESSING } from '../../../../store/redux-slices/processes/constants';
import { IValidationErrorResponse } from '../../../../models/interfaces/ValidationErrorResponse';
import { RENEWAL_NOT_AVAILABLE_MODAL } from '../RenewalNotAvailableModal/renewalNotAvailableModal.const';
import { boolToOnOff } from '../../../../utils/propertyHelpers';
import { ADD_CREDIT_CARD_AUTO_RENEW_MODAL } from '../AddCreditCardModal/addCreditCard.consts';
import { IPutMembershipToBeRenewedResult } from '../../../../models/interfaces/PutMembershipToBeRenewedResult';
import { REPLACE_CREDIT_CARD_DETAILS_AUTO_RENEW_MODAL } from '../ReplaceCreditCardModal/replaceCreditCard.consts';
import { firstCreditCardSelector } from '../../../../store/redux-slices/payment/selectors';

type UseUpdateAutoRenewFlagModalProps = {
  onAutoRenewFlagUpdated?: () => void;
};

export const useUpdateAutoRenewFlagModal = ({
  onAutoRenewFlagUpdated,
}: UseUpdateAutoRenewFlagModalProps) => {
  const { t } = useTranslation();
  const alert = useAlert();

  const UpdateMembershipRenewFlag = useActions(updateMembershipRenewFlag);
  const UpdateModalState = useActions(updateModalState);

  const autoRenewFlagUpdating = useProcessing(
    MEMBERSHIP_AUTO_RENEW_FLAG_UPDATE_PROCESSING,
  );

  const { membership }: { membership: Membership } = useModalDetails(
    UPDATE_AUTO_RENEW_FLAG_MODAL,
  );

  const creditCard = useSelector(firstCreditCardSelector);

  const switchRenewFlagSuccessHandler = (
    data: IPutMembershipToBeRenewedResult,
  ) => {
    alert.success(
      <Trans>
        {t(`phrase.membership-auto-renew-flag-was-updated-successfully`, {
          value: t(
            `properties.${boolToOnOff(!membership?.autoRenewal)}`,
          ).toLowerCase(),
        })}
      </Trans>,
    );
    onAutoRenewFlagUpdated?.();

    if (data.isCreditCardMissing) {
      UpdateModalState(ADD_CREDIT_CARD_AUTO_RENEW_MODAL);
      return;
    }

    if (data.isCreditCardExpired && !!creditCard) {
      UpdateModalState([
        REPLACE_CREDIT_CARD_DETAILS_AUTO_RENEW_MODAL,
        { creditCardId: creditCard?.creditCardId },
      ]);
      return;
    }
    UpdateModalState(UPDATE_AUTO_RENEW_FLAG_MODAL);
  };

  const switchRenewFlagHandler = () => {
    const payload: IUpdateMembershipRenewFlagPayload = {
      membershipId: membership?.membershipId,
      toBeRenewed: !membership?.autoRenewal,
      success: switchRenewFlagSuccessHandler,
      error: (err: IValidationErrorResponse) => {
        UpdateModalState(RENEWAL_NOT_AVAILABLE_MODAL);
        alert.error(
          t('phrase.membership-auto-renew-flag-was-not-updated-successfully', {
            value: t(
              `properties.${boolToOnOff(!membership?.autoRenewal)}`,
            ).toLowerCase(),
          }),
        );
      },
    };

    UpdateMembershipRenewFlag(payload);
  };

  const cancelHandler = () => {
    UpdateModalState(UPDATE_AUTO_RENEW_FLAG_MODAL);
  };

  return {
    membership,
    switchRenewFlagHandler,
    cancelHandler,
    autoRenewFlagUpdating,
  };
};
