import { useTranslation } from 'react-i18next';

import { CustomComponent } from '../../../../models/types/CustomComponent';
import {
  MainContainer,
  MemberNumber,
  MembershipCardButton,
  ProfileIcon,
  ProfileName,
  ProfileNameContainer,
  ProfileTextContainer,
  ProfileWrapper,
  Title,
} from './profileLogoSection.styles';
import { downloadMembershipCard } from './profileLogoSection.utils';

type ProfileLogoSectionProps = CustomComponent & {
  initials: string;
  prefix: string;
  firstName: string;
  lastName: string;
  memberNumber?: string;
};

const ProfileLogoSection = ({
  initials,
  prefix,
  firstName,
  lastName,
  className,
  memberNumber,
}: ProfileLogoSectionProps) => {
  const { t } = useTranslation();
  return (
    <ProfileWrapper className={className}>
      <Title>{t('headers.profile-details')}</Title>
      <MainContainer>
        <ProfileNameContainer>
          <ProfileIcon>{initials}</ProfileIcon>
          <ProfileTextContainer>
            <ProfileName>
              {prefix} {firstName} {lastName}
            </ProfileName>
            {memberNumber && (
              <MemberNumber>{`${t('phrase.member-no', {
                value: memberNumber,
              })}`}</MemberNumber>
            )}
          </ProfileTextContainer>
        </ProfileNameContainer>
        <MembershipCardButton onClick={downloadMembershipCard}>
          {t('buttons.membership-card')}
        </MembershipCardButton>
      </MainContainer>
    </ProfileWrapper>
  );
};

export default ProfileLogoSection;
