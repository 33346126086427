import { useCallback } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  useApiResponseError,
  useFormErrorsActions,
} from '../../../../../components/FormErrorProvider/FormErrorProvider.hooks';
import { AddressInformationsInitState } from '../../../../../components/Forms/AddressInformationsForm/addressInformations.models';

import { useActions } from '../../../../../hooks/useActions';
import { useProcessing } from '../../../../../hooks/useProcessing';
import { useCancellationToken } from '../../../../../hooks/useTokenCancellation';
import { IUpdatePersonModel } from '../../../../../models/interfaces/UpdatePersonModel';
import { updateModalState } from '../../../../../store/redux-slices/modals';
import {
  personNameSelector,
  updatePersonSelector,
} from '../../../../../store/redux-slices/personalData/selectors';
import { PERSON_UPDATE_PROCESSING } from '../../../../../store/redux-slices/processes/constants';
import { updatePersonDetails } from '../../../../../store/saga-slices/personalData';
import { IUpdatePersonDetailsPayload } from '../../../../../store/saga-slices/personalData/models';
import { includeRemovedProperties } from '../../../../../utils/formInitValuesHelpers';
import { EDIT_MEMBER_DETAILS_MODAL } from '../editMemberDetailsModal.consts';
import { validateEditMemberDetailsForm } from './editMemberDetailsModalForm.validation';

type UseEditMemberDetailsModalFormProps = {
  onEditedMember?: () => void;
};

export const useEditMemberDetailsModalForm = ({
  onEditedMember,
}: UseEditMemberDetailsModalFormProps = {}) => {
  const { t } = useTranslation();
  const alert = useAlert();

  const person = useSelector(updatePersonSelector);
  const personName = useSelector(personNameSelector);
  const personUpdating = useProcessing(PERSON_UPDATE_PROCESSING);

  const initForm = person || {
    ...AddressInformationsInitState,
  };

  const UpdatePersonDetails = useActions(updatePersonDetails);
  const UpdateModalState = useActions(updateModalState);
  const cancellationToken = useCancellationToken();

  const { validateErrors } = useFormErrorsActions();
  const { handleResponseError } = useApiResponseError();

  const validateForm = (data: IUpdatePersonModel) => {
    const currentData = includeRemovedProperties(
      {
        ...AddressInformationsInitState,
      },
      data,
    );
    return validateEditMemberDetailsForm(
      currentData,
      validateErrors(currentData),
    );
  };

  const submitForm = (values: IUpdatePersonModel) => {
    const payload: IUpdatePersonDetailsPayload = {
      data: values,
      cancellationToken,
      error: (err) => {
        alert.error(t('phrase.member-was-not-updated-successfully'));
        handleResponseError(err, values);
      },
      success: () => {
        UpdateModalState(EDIT_MEMBER_DETAILS_MODAL);
        onEditedMember && onEditedMember();
        alert.success(t('phrase.member-was-updated-successfully'));
      },
    };

    UpdatePersonDetails(payload);
  };

  const onCancelHandler = useCallback(() => {
    UpdateModalState(EDIT_MEMBER_DETAILS_MODAL);
  }, [UpdateModalState]);

  return {
    person,
    submitForm,
    validateForm,
    personUpdating,
    onCancelHandler,
    initForm,
    personName,
  };
};
