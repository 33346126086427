import { useTranslation } from 'react-i18next';

import { Navigate } from 'react-router';

import { Page } from '../../components/Page/Page';

import {
  PageTitle,
  SectionTitle,
  StandardText,
  StandardTextBold,
} from '../../components/Typography/Typography';
import { ReactComponent as ConfirmIcon } from '../../assets/icons/confirm-icon.svg';
import { Wrapper } from './confirmationPage.styles';
import Button from '../../components/UI/Button/Button';
import { useConfirmationPage } from './confirmationPage.hooks';
import config from '../../config/config';
import { RoutePath } from '../../models/enums/RoutePath';

const ConfirmationPage = () => {
  const { t } = useTranslation();
  const { visitMemberPortalHandler, memberName } = useConfirmationPage();

  if (!memberName) {
    return <Navigate to={RoutePath.AddMembership} />;
  }

  return (
    <Page>
      <PageTitle>{t('headers.air-ambulance-membership')}</PageTitle>
      <Wrapper>
        <ConfirmIcon />
        <SectionTitle>
          {t('phrase.your-membership-confirmed', { name: memberName })}
        </SectionTitle>
        <StandardTextBold>{t('phrase.check-your-email')}</StandardTextBold>
        <StandardText>
          {t('phrase.contact-with-our-team', {
            number: config.contact.phone,
          })}
        </StandardText>
      </Wrapper>
      <Button onClick={visitMemberPortalHandler}>
        {t('buttons.visit-member-portal')}
      </Button>
    </Page>
  );
};

export default ConfirmationPage;
