import { SectionTitle } from '../../../../../../../../components/Typography/Typography';
import { IMembershipPlanGroupItemModel } from '../../../../../../../../models/interfaces/MembershipPlanGroupItemModel';
import { useMembershipPlanItem } from './membershipPlanItem.hooks';
import {
  Description,
  Price,
  PriceContainer,
  Wrapper,
} from './membershipPlanItem.styles';

type MembershipPlanItemProps = {
  data: IMembershipPlanGroupItemModel;
};

const MembershipPlanItem = ({ data }: MembershipPlanItemProps) => {
  const { overwrittenPrice } = useMembershipPlanItem(data);
  return (
    <Wrapper>
      <SectionTitle>{data?.planName}</SectionTitle>
      <PriceContainer>
        <Price overwritten={overwrittenPrice}>{data?.regularPrice}</Price>
        {overwrittenPrice && <Price>{data?.price}</Price>}
      </PriceContainer>
      <Description>{data?.planTypeDescription}</Description>
    </Wrapper>
  );
};

export default MembershipPlanItem;
