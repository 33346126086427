import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker as MUIDatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { ClassNames } from '@emotion/react';

import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_DATE_MASK,
} from '../../../utils/dateHelpers';

import { CustomField } from '../../../models/types/CustomField';
import { ReactComponent as ArrowDown } from '../../../assets/icons/arrow-down-icon.svg';
import { ReactComponent as ArrowLeft } from '../../../assets/icons/arrow-left-icon.svg';
import { ReactComponent as ArrowRight } from '../../../assets/icons/arrow-right-icon.svg';
import { ReactComponent as CalendarIcon } from '../../../assets/icons/calendar-icon.svg';
import { ReactComponent as CalendarDisabledIcon } from '../../../assets/icons/calendar-disabled-icon.svg';
import { CustomFieldLabel } from '../../../models/types/CustomFieldLabel';
import DatePickerInput from './DatePickerInput/DatePickerInput';
import { useDatePicker } from './datePicker.hooks';
import { DatePickerView } from './datePicker.models';
import { datePickerStyles } from './datePicker.styles';

export type DatePickerProps = CustomField &
  CustomFieldLabel & {
    onChange?: (value: string) => void;
    value: string;
    isError?: boolean;
    onBlur?: () => void;
    onInputChange?: () => void;
    onClose?: () => void;
    views?: DatePickerView[];
    autoComplete: string;
    autoCorrect: string;
    inputFormat?: string;
    outputFormat?: string;
    mask?: string;
    format?: string;
  };

const DatePicker = ({
  className,
  disabled,
  onChange,
  value,
  isLabelHidden,
  label,
  placeholder,
  labelAnimation,
  isError,
  onBlur,
  onInputChange,
  onClose,
  views,
  autoComplete,
  autoCorrect,
  outputFormat,
  mask,
  format,
  ...props
}: DatePickerProps) => {
  const { onChangeHandler, currentValue } = useDatePicker({
    onChange,
    inputFormat: props.inputFormat,
    outputFormat,
    value,
  });

  return (
    <ClassNames>
      {({ css }) => (
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <MUIDatePicker
            {...props}
            inputFormat={format || DEFAULT_DATE_FORMAT}
            mask={mask || DEFAULT_DATE_MASK}
            disableMaskedInput={false}
            disabled={disabled}
            onChange={onChangeHandler}
            onClose={onClose}
            views={views || ['year', 'month', 'day']}
            value={currentValue}
            components={{
              OpenPickerIcon: disabled ? CalendarDisabledIcon : CalendarIcon,
              RightArrowIcon: ArrowRight,
              LeftArrowIcon: ArrowLeft,
              SwitchViewIcon: ArrowDown,
            }}
            PopperProps={{
              className: css([datePickerStyles]),
            }}
            InputAdornmentProps={{ position: 'start' }}
            renderInput={(params) => (
              <DatePickerInput
                {...params}
                disabled={disabled}
                autoComplete={autoComplete}
                autoCorrect={autoCorrect}
                onBlur={onBlur}
                onChange={onInputChange}
                label={label}
                isError={isError}
                isLabelHidden={isLabelHidden}
                placeholder={placeholder}
                labelAnimation={labelAnimation}
              />
            )}
          />
        </LocalizationProvider>
      )}
    </ClassNames>
  );
};

export default DatePicker;
