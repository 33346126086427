import styled from '@emotion/styled';

import { SecondPageTitle } from '../../../components/Typography/Typography';

import arrowDownIcon from '../../../assets/icons/arrow-down-blue-icon.svg';
import Button from '../../../components/UI/Button/Button';

type ToggleButtonProps = {
  isOpen: boolean;
};
type ExpandableSectionProps = {
  isOpen: boolean;
};

export const Root = styled.div`
  display: flex;
  gap: 1.25rem;
  flex-direction: column;
  width: 100%;
`;
export const MembershipSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.875rem;
  width: 100%;
`;

export const SectionTitleWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

export const SectionTitle = styled(SecondPageTitle)`
  margin: 0;
`;

export const ToggleButton = styled(Button)<ToggleButtonProps>`
  align-items: center;
  display: flex;
  font-weight: normal;
  gap: 0.5rem;
  padding: 0;
  text-transform: capitalize;
  &::after {
    content: '';
    width: 1.5rem;
    height: 1.5rem;
    background: url(${arrowDownIcon});
    background-repeat: no-repeat;
    background-position: center right;
    transition: all 0.35s ease;
    transform: rotate(${({ isOpen }) => (isOpen ? '0' : '180')}deg);
  }
`;
ToggleButton.defaultProps = {
  styleType: 'link',
};

export const ExpandableSectionWrapper = styled.div<ExpandableSectionProps>`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 0;
  max-height: ${({ isOpen }) => (isOpen ? '100%' : '0')};
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  transition: all 0.25s linear;
  overflow: hidden;
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  width: 100%;
`;
