import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Gender } from 'models/enums/Gender';

import { IProfile } from '../../../models/interfaces/IProfile';
import { IProfileModel } from '../../../models/interfaces/ProfileModel';
import { mapAddress } from '../../../utils/addressHelpers';

import { clearState } from '../../globalActions';

export interface IProfileState {
  profile: IProfileModel;
}

const initialState: IProfileState = {
  profile: {
    personId: '',
    firstName: '',
    memberNumber: null,
    lastName: '',
    emailAddress: '',
    phone: '',
    gender: '' as Gender,
    prefix: '',
    dateOfBirth: '',
    initials: '',
    residenceAddress: null,
    billingAddress: null,
    isCreditCardRequired: false,
  },
};

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setProfile: (state, { payload }: PayloadAction<IProfile>) => {
      state.profile = {
        ...initialState.profile,
        ...payload,
        initials: `${payload.firstName[0]}${payload.lastName[0]}`,
        residenceAddress: mapAddress(payload.residenceAddress),
        billingAddress: mapAddress(payload.billingAddress),
      };
    },

    clearProfile: (state) => {
      state.profile = initialState.profile;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearState, () => initialState);
  },
});

export const { setProfile, clearProfile } = profileSlice.actions;
export default profileSlice.reducer;
export const profileReducerName = profileSlice.name;
