import axios, { AxiosResponse } from 'axios';

import { IProfile } from '../../../models/interfaces/IProfile';

const MainUrl = '/api';

export const getProfile = (
  cancelToken?: AbortSignal,
): Promise<AxiosResponse<IProfile>> =>
  axios.get(`${MainUrl}/profile`, {
    signal: cancelToken,
  });
