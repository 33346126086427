import { css } from '@emotion/react';

export const GlobalFonts = css`
  @font-face {
    font-family: 'Gotham';
    font-weight: 500;
    font-style: normal;
    font-display: swap;
    src: url('/fonts/gothamBook.otf') format('opentype');
  }

  @font-face {
    font-family: 'Gotham';
    font-weight: 700;
    font-style: normal;
    font-display: swap;
    src: url('/fonts/gothamBold.otf') format('opentype');
  }

  @font-face {
    font-family: 'Gotham';
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    src: url('/fonts/gothamLight.otf') format('opentype');
  }
`;
