import { t } from 'i18next';

import InfoComponentMobile from '../../../../../components/UI/InfoComponent/InfoComponentMobile';

import Tooltip from '../../../../../components/UI/Tooltip/Tooltip';
import { IFamilyMemberInfo } from '../../../../../models/interfaces/FamilyMemberInfo';
import { MobileMax } from '../../../../../utils/mediaQueries';
import {
  MemberName,
  MemberType as MemberTypeText,
  TypeContainer,
  Wrapper,
} from './familyMemberLabel.styles';

type FamilyMemberLabelProps = {
  disabled?: boolean;
  member: IFamilyMemberInfo;
};

const FamilyMemberLabel = ({ member }: FamilyMemberLabelProps) => (
  <Wrapper>
    <MemberName isAvailable={!member.isOverAged}>
      {[member.firstName, member.lastName].join(' ')}
    </MemberName>
    <TypeContainer>
      <Tooltip
        title={
          member.isOverAged &&
          `${t('phrase.member-not-eligible-to-be-enrolled', {
            name: member.firstName,
            age: member.maxMemberAge,
          })}`
        }
      >
        <MemberTypeText isAvailable={!member.isOverAged}>
          {`${t(`enums.memberType.${member.dependence}`)}`}
        </MemberTypeText>
      </Tooltip>
      <MobileMax>
        <InfoComponentMobile
          tooltipWidth="13rem"
          tooltip={`${t('phrase.member-not-eligible-to-be-enrolled', {
            name: member.firstName,
            age: member.maxMemberAge,
          })}`}
        />
      </MobileMax>
    </TypeContainer>
  </Wrapper>
);

export default FamilyMemberLabel;
