import { useIsAuthenticated } from '@azure/msal-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FieldRenderProps, useField } from 'react-final-form';
import { useSelector } from 'react-redux';

import { useProcessing } from '../../../../../../../hooks/useProcessing';

import { firstCreditCardSelector } from '../../../../../../../store/redux-slices/payment/selectors';
import {
  CREDIT_CARDS_LOAD_PROCESSING,
  ORDER_PAYMENT_DETAILS_LOAD_PROCESSING,
} from '../../../../../../../store/redux-slices/processes/constants';
import { PaymentExistingCardFields } from '../PaymentUseExistingCardForm/paymentUseExistingCardForm.models';
import { PaymentType } from './paymentSection.models';

export const usePaymentSection = () => {
  const [currentPaymentType, setCurrentPaymentType] =
    useState<PaymentType>(null);
  const isAuth = useIsAuthenticated();

  const dataLoading = useProcessing([
    CREDIT_CARDS_LOAD_PROCESSING,
    ORDER_PAYMENT_DETAILS_LOAD_PROCESSING,
  ]);

  const {
    input: { onChange: existingCreditCardIdChange },
  }: FieldRenderProps<string> = useField(
    PaymentExistingCardFields.ExistingCreditCardId,
  );

  const firstCreditCard = useSelector(firstCreditCardSelector);

  const onChangePaymentTypeHandler = useCallback(
    (paymentType: PaymentType) => {
      setCurrentPaymentType(paymentType);
      if (paymentType === PaymentType.UseExistingCreditCard) {
        existingCreditCardIdChange(firstCreditCard?.creditCardId);
        return;
      }

      existingCreditCardIdChange(null);
    },
    [existingCreditCardIdChange, firstCreditCard?.creditCardId],
  );

  useEffect(() => {
    if (firstCreditCard && !firstCreditCard?.isExpired) {
      setCurrentPaymentType(PaymentType.UseExistingCreditCard);
      existingCreditCardIdChange(firstCreditCard?.creditCardId);
      return;
    }

    setCurrentPaymentType(PaymentType.PayWithDifferentCard);
    existingCreditCardIdChange(null);
  }, [existingCreditCardIdChange, firstCreditCard]);

  const onlyCardDetailsForm = useMemo(
    () => !isAuth || !firstCreditCard,
    [firstCreditCard, isAuth],
  );

  return {
    currentPaymentType,
    onChangePaymentTypeHandler,
    onlyCardDetailsForm,
    dataLoading,
  };
};
