import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IErrorsState {
  error: string;
  accessError: string;
  notFoundError: string;
  authError: string;
}

const initState: IErrorsState = {
  error: null,
  accessError: null,
  notFoundError: null,
  authError: null,
};

const errorsSlice = createSlice({
  name: 'errors',
  initialState: initState,
  reducers: {
    setError: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
    setAccessError: (state, { payload }: PayloadAction<string>) => {
      state.accessError = payload;
    },
    setNotFoundError: (state, { payload }: PayloadAction<string>) => {
      state.notFoundError = payload;
    },
    setAuthError: (state, { payload }: PayloadAction<string>) => {
      state.authError = payload;
    },
    resetError: () => initState,
  },
});

export const {
  setError,
  setAccessError,
  resetError,
  setNotFoundError,
  setAuthError,
} = errorsSlice.actions;
export default errorsSlice.reducer;
