import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

import NeedHelpInfo from '../../components/NeedHelpInfo/NeedHelpInfo';

import { mq } from '../../utils/mediaQueries';

export const StyledNeedHelpInfo = styled(NeedHelpInfo)`
  margin-top: 2.5rem;

  ${mq.xSmall(
    css`
      margin-top: 4rem;
    `,
  )}
`;
