import { useCallback } from 'react';
import { FieldRenderProps, useField } from 'react-final-form';

import { useCancellationToken } from '../../../hooks/useTokenCancellation';
import { getCountries } from '../../../store/saga-slices/optionsSearch/api';
import { getFormGroupPath } from '../../../utils/formGroupFieldHelpers';
import { AddressFields } from './addressForm.models';

type UseAddressFormProp = {
  name?: string;
};

export const useAddressForm = ({ name }: UseAddressFormProp) => {
  const cancellationToken = useCancellationToken();

  const {
    input: { value },
  }: FieldRenderProps<string> = useField(
    getFormGroupPath(name, AddressFields.Country),
  );

  const countriesPromiseOptions = useCallback(
    () =>
      getCountries(cancellationToken).then(({ data }) => {
        const result = data.map((country) => ({
          label: country,
          value: country,
        }));

        if (!result.some((x) => x.value === value)) {
          result.push({ label: value, value });
        }
        return result;
      }),
    [cancellationToken, value],
  );

  return { countriesPromiseOptions };
};
