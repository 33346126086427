import { FieldRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { MemberType } from '../../../../models/enums/MemberType';
import { ProductType } from '../../../../models/enums/ProductType';

import { getPropertyName } from '../../../../utils/getPropertyName';

import { FormFieldWrapper } from '../../../Form/Form';
import DatePicker, { DatePickerProps } from '../../DatePicker/DatePicker';
import { ErrorLabel } from '../../Field/Field';
import { useFormDateOfBirth } from './formMemberDateOfBirth.hooks';

interface IFormDateOfBirthProps
  extends FieldRenderProps<string>,
    DatePickerProps {
  className?: string;
  defaultValue?: string;
  disabled?: boolean;
  isLabelHidden?: boolean;
  memberType: MemberType;
  memberPlanId: string;
  isErrorHidden?: boolean;
  renewedMembershipId?: string;
  productType?: ProductType;
}

const FormMemberDateOfBirth = ({
  className,
  datePickerAdditionalClassName,
  input,
  isLabelHidden,
  meta: { error, touched },
  memberPlanId,
  memberType,
  isErrorHidden,
  renewedMembershipId,
  productType,
  ...props
}: IFormDateOfBirthProps) => {
  const { t } = useTranslation();

  const { dateValue, onChangeHandler } = useFormDateOfBirth({
    input,
    memberPlanId,
    memberType,
    renewedMembershipId,
    productType,
  });

  return (
    <FormFieldWrapper
      className={className}
      error={!isErrorHidden && touched && error}
    >
      <DatePicker
        {...props}
        onChange={onChangeHandler}
        onBlur={() => input.onBlur()}
        onClose={() => input.onBlur()}
        value={dateValue}
        isError={!isErrorHidden && touched && error}
        label={props.label || t(`fields.${getPropertyName(input.name)}.label`)}
      />
      {!isErrorHidden && touched && error && <ErrorLabel>{error}</ErrorLabel>}
    </FormFieldWrapper>
  );
};

export default FormMemberDateOfBirth;
