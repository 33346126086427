import { useMsal } from '@azure/msal-react';

import { enrollRedirectRequest } from '../../../../config/authConfig';
import { useActions } from '../../../../hooks/useActions';
import { useModalDetails } from '../../../../hooks/useModalDetails';
import { updateModalState } from '../../../../store/redux-slices/modals';
import { LOGIN_CONFIRMATION_MODAL_NAME } from './LoginConfirmationModal';

export const useLoginConfirmationLogin = () => {
  const { instance } = useMsal();
  const UpdateModalState = useActions(updateModalState);

  const state = useModalDetails(LOGIN_CONFIRMATION_MODAL_NAME);

  const onLoginHandler = () => {
    instance.loginRedirect({
      ...enrollRedirectRequest,
      state: JSON.stringify(state),
    });
  };
  const onCancelHandler = () => {
    UpdateModalState(LOGIN_CONFIRMATION_MODAL_NAME);
  };

  return { onLoginHandler, onCancelHandler };
};
