import { useMemo } from 'react';

import CheckCardItem from './CheckCardItem/CheckCardItem';
import { CheckCardListProps } from './checkCardList.models';
import { Wrapper } from './checkCardList.styles';

const CheckCardList = <T,>({
  className,
  items = [],
  onChange,
  renderComponent,
  checkedItem,
  disabled,
  readOnly,
}: CheckCardListProps<T>) => {
  const preparedItemsComponents = useMemo(
    () =>
      items.map((data) => (
        <CheckCardItem
          checked={data.id === checkedItem}
          id={data.id}
          key={data.id}
          onChange={onChange}
          disabled={disabled || data.disabled}
          readOnly={readOnly || data.readOnly}
        >
          {renderComponent && renderComponent(data)}
        </CheckCardItem>
      )),
    [checkedItem, disabled, items, onChange, readOnly, renderComponent],
  );
  return <Wrapper className={className}>{preparedItemsComponents}</Wrapper>;
};

export default CheckCardList;
