import { Global } from '@emotion/react';

import { Sidebar } from '../Sidebar/Sidebar';
import BurgerButton from '../UI/BurgerButton/BurgerButton';
import { useBurgerMenuSidebar } from './burgerMenuSidebar.hooks';
import { GlobalStyles, StyledNavigation } from './burgerMenuSidebar.styles';

const MainSidebar = () => {
  const { isOpen, onClickHandler, closeSidebar } = useBurgerMenuSidebar();

  return (
    <>
      <Global styles={GlobalStyles(isOpen)} />
      <div>
        <BurgerButton onClick={onClickHandler} isOpen={isOpen} />
        <Sidebar isOpen={isOpen}>
          <StyledNavigation onClick={closeSidebar} />
        </Sidebar>
      </div>
    </>
  );
};

export default MainSidebar;
