import styled from '@emotion/styled/macro';

import CreditCardDetailsForm from '../../../../../../../components/Forms/CreditCardDetailsForm/CreditCardDetailsForm';
import { StandardText } from '../../../../../../../components/Typography/Typography';

export const Description = styled(StandardText)`
  color: ${({ theme }) => theme.color.dark1};
`;

export const StyledCreditCardDetailsForm = styled(CreditCardDetailsForm)`
  margin-top: 2rem;
`;
