import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';

import {
  useApiResponseError,
  useFormErrorsValues,
} from '../../../../../components/FormErrorProvider/FormErrorProvider.hooks';
import { useActions } from '../../../../../hooks/useActions';
import { useModalDetails } from '../../../../../hooks/useModalDetails';
import { useProcessing } from '../../../../../hooks/useProcessing';
import useStripeProvider from '../../../../../hooks/useStripeProvider';
import { useCancellationToken } from '../../../../../hooks/useTokenCancellation';
import { ICardSetupIntentModel } from '../../../../../models/interfaces/CardSetupIntentModel';
import { IStripeCreditCardInfo } from '../../../../../models/interfaces/StripeCreditCardInfo';
import { updateModalState } from '../../../../../store/redux-slices/modals';
import { CREDIT_CARD_REPLACE_PROCESSING } from '../../../../../store/redux-slices/processes/constants';
import { replaceExistingCreditCard } from '../../../../../store/saga-slices/payment';
import { IReplaceExistingCreditCardPayload } from '../../../../../store/saga-slices/payment/models';

type UseReplaceCreditCardModalForm = {
  onCreditCardReplaced?: () => void;
  modalName: string;
};

export const useReplaceCreditCardModalForm = ({
  onCreditCardReplaced,
  modalName,
}: UseReplaceCreditCardModalForm) => {
  const UpdateModalState = useActions(updateModalState);
  const ReplaceExistingCreditCard = useActions(replaceExistingCreditCard);
  const cancellationToken = useCancellationToken();
  const alert = useAlert();
  const { t } = useTranslation();

  const creditCardReplacing = useProcessing(CREDIT_CARD_REPLACE_PROCESSING);

  const { creditCardId }: { creditCardId: string } = useModalDetails(modalName);

  const { handleResponseError } = useApiResponseError();
  const { errors } = useFormErrorsValues();

  const stripe = useStripeProvider();

  const submitHandler = (values: IStripeCreditCardInfo) => {
    const payload: IReplaceExistingCreditCardPayload = {
      cancellationToken,
      creditCardId,
      success: async (setupIntent: ICardSetupIntentModel) => {
        stripe.submitCardWithStripe(
          setupIntent,
          values,
          () => {
            alert.success(t('phrase.credit-card-was-replaced-successfully'));
            UpdateModalState(modalName);
            onCreditCardReplaced && onCreditCardReplaced();
          },
          () =>
            alert.error(t('phrase.credit-card-was-not-replaced-successfully')),
        );
      },
      error: (err) => {
        alert.error(t('phrase.credit-card-was-not-replaced-successfully'));
        handleResponseError(err, values);
      },
    };

    ReplaceExistingCreditCard(payload);
  };

  const validateForm = () => errors;

  const onCancelHandler = () => {
    UpdateModalState(modalName);
  };

  return { onCancelHandler, submitHandler, validateForm, creditCardReplacing };
};
