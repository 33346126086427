import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';

import { useActions } from '../../../../../../../hooks/useActions';
import { useModalDetails } from '../../../../../../../hooks/useModalDetails';
import { useProcessing } from '../../../../../../../hooks/useProcessing';
import { updateModalState } from '../../../../../../../store/redux-slices/modals';
import { FAMILY_MEMBER_REMOVING_PROCESSING } from '../../../../../../../store/redux-slices/processes/constants';
import { removeAAFamilyMember } from '../../../../../../../store/saga-slices/membership/airAmbulance';
import { IRemoveAAFamilyMemberPayload } from '../../../../../../../store/saga-slices/membership/airAmbulance/models';
import { REMOVE_AA_FAMILY_MEMBER_MODAL } from './removeAAFamilyMemberModal.const';
import { RemoveAAFamilyMemberModalDetails } from './removeAAFamilyMemberModal.models';

type UseRemoveAAFamilyMemberModalProps = {
  onFamilyMemberDeleted?: () => void;
  id?: string;
};

export const useRemoveAAFamilyMemberModal = ({
  onFamilyMemberDeleted,
  id,
}: UseRemoveAAFamilyMemberModalProps) => {
  const { t } = useTranslation();
  const UpdateModalState = useActions(updateModalState);
  const alert = useAlert();
  const RemoveFamilyMember = useActions(removeAAFamilyMember);

  const memberRemoving = useProcessing(FAMILY_MEMBER_REMOVING_PROCESSING);

  const { member, membership }: RemoveAAFamilyMemberModalDetails =
    useModalDetails(REMOVE_AA_FAMILY_MEMBER_MODAL + id);

  const removeFamilyMemberHandler = () => {
    const payload: IRemoveAAFamilyMemberPayload = {
      memberId: member?.memberId,
      membershipId: membership?.membershipId,
      success: () => {
        onFamilyMemberDeleted?.();
        UpdateModalState(REMOVE_AA_FAMILY_MEMBER_MODAL + id);
        alert.success(t('phrase.member-was-removed-successfully'));
      },
      error: () => {
        alert.error(t('phrase.member-was-not-removed-successfully'));
      },
    };

    RemoveFamilyMember(payload);
  };

  const onCancelHandler = () => {
    UpdateModalState(REMOVE_AA_FAMILY_MEMBER_MODAL + id);
  };

  return { member, onCancelHandler, removeFamilyMemberHandler, memberRemoving };
};
