import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

import { mq } from '../../utils/mediaQueries';

export const RootPage = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 3rem 1fr 5.5rem;
  background-color: ${({ theme }) => theme.color.white};
  height: 100vh;

  ${mq.small(css`
    grid-template-rows: 3rem 1fr 3.5rem;
  `)};

  ${mq.xSmall(css`
    grid-template-rows: 7rem 1fr 3.5rem;
  `)};

  ${mq.large(css`
    grid-template-rows: 7rem 1fr 8rem;
  `)};

  @supports (-webkit-touch-callout: none) {
    height: fill-available;
  }
`;

export const FullLayoutPage = styled.div`
  max-width: 100%;
  margin: 0 auto;
  height: 100%;
  padding: 0 1rem;
  display: flex;
  flex-direction: column;

  ${mq.xSmall(css`
    padding: 0;
  `)};
`;

export const MiddleLayoutPage = styled(FullLayoutPage)`
  padding: 0 1rem;

  ${mq.xSmall(css`
    padding: 0 2.25rem;
    max-width: 48.375rem;
  `)};
`;

export const Page = styled.div`
  height: 100%;
  padding: 2rem 0 6.25rem;
  align-items: center;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  ${mq.xSmall(css`
    padding: 5rem 0 7rem;
  `)};
`;

export const AuthPage = styled.div`
  max-width: 26.25rem;
  width: 26.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
`;

export const WithSidebarPage = styled.div`
  align-items: start;
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: row;
  padding: 1rem;
  gap: 64px;

  ${mq.medium(css`
    grid-auto-columns: 1fr;
    padding: 2.5rem;
  `)};
  ${mq.large(css`
    gap: 3.5rem;
    grid-auto-columns: 26rem auto;
    grid-auto-flow: column;
  `)};
`;
