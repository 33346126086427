import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useMatch } from 'react-router-dom';

import { useActions } from '../../hooks/useActions';
import { useClickBackButtonBrowser } from '../../hooks/useClickBackButtonBrowser';
import { RootState } from '../../store/redux-slices';
import { IWizardStep } from '../../store/redux-slices/wizard/models';
import {
  activeStepIndexSelector,
  activeStepSelector,
  activeStepUrlSelector,
} from '../../store/redux-slices/wizard/selectors';
import {
  changeToLastAvailableVisibleStep,
  changeToPrevStep,
} from '../../store/saga-slices/wizard';

export const useWizardWrapper = () => {
  const wizardPath = useSelector<RootState, string>(activeStepUrlSelector);
  const activeStep: IWizardStep = useSelector(activeStepSelector);
  const location = useLocation();
  const pathNameParts = location.pathname.split('/');
  const ChangeToPrevStep = useActions(changeToPrevStep);
  const activeStepIndex = useSelector(activeStepIndexSelector);

  pathNameParts.splice(pathNameParts.length - 1, 1, wizardPath);

  const pathToLoad = pathNameParts.join('/');
  const routesMatch = useMatch(pathToLoad);
  const { state } = useLocation();

  const moveBackHandler = useCallback(() => {
    activeStepIndex !== 0 && ChangeToPrevStep();
  }, [ChangeToPrevStep, activeStepIndex]);

  useClickBackButtonBrowser(moveBackHandler);

  const ChangeToLastAvailableVisibleStep = useActions(
    changeToLastAvailableVisibleStep,
  );

  useEffect(() => {
    if (!activeStep.isAvailable) {
      ChangeToLastAvailableVisibleStep();
    }
  }, [ChangeToLastAvailableVisibleStep, activeStep.isAvailable]);

  return { routesMatch, state, wizardPath };
};
