import { useIsAuthenticated } from '@azure/msal-react';

import { CustomComponent } from '../../models/types/CustomComponent';
import AuthNavigation from './AuthNavigation/AuthNavigation';
import NoAuthNavigation from './NoAuthNavigation/NoAuthNavigation';

export type NavigationProps = CustomComponent & {
  onClick?: () => void;
};

const Navigation = (props: NavigationProps) => {
  const isAuth = useIsAuthenticated();
  return isAuth ? (
    <AuthNavigation {...props} />
  ) : (
    <NoAuthNavigation {...props} />
  );
};

export default Navigation;
