import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { StripeElementStyle } from '@stripe/stripe-js';

import { THEME } from '../../config/theme';
import {
  FieldWrapper,
  StyledFieldLabel as FieldLabel,
} from '../UI/Field/Field';

type StripeInputWrapperProps = {
  isError?: boolean;
};

export const StripeInputWrapper = styled(FieldWrapper)<StripeInputWrapperProps>`
  border: 0.063rem solid
    ${({ theme, isError }) =>
      isError ? theme.color.error : theme.color.input_border};
  border-radius: 4px;
  font: normal normal 0.875rem/1.5rem ${({ theme }) => theme.font.gotham};
  color: ${({ theme }) => theme.color.black};
  box-sizing: border-box;
  justify-content: center;
  width: 100%;
`;

export const customStyles: StripeElementStyle = {
  base: {
    iconColor: THEME.color.black,
    color: THEME.color.black,
    fontWeight: '400',
    fontFamily: THEME.font.gotham,
    fontSize: '16px',
    '::placeholder': {
      color: THEME.color.black,
    },
    ':disabled': {
      color: THEME.color.dark2,
    },
  },
  invalid: {
    iconColor: THEME.color.error,
    color: THEME.color.error,
  },
};

export const StyledFieldLabel = styled(FieldLabel)`
  background: ${({ theme }) => theme.color.white};

  ${({ labelActive }) =>
    css`
      width: ${!labelActive ? '90%' : 'auto'};
    `}
`;
