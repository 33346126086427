import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

import { SectionWrapper } from '../../../../../../../components/Form/Form';
import { mq } from '../../../../../../../utils/mediaQueries';

export const StyledSectionWrapper = styled(SectionWrapper)`
  margin-top: 1.5rem;

  ${mq.xSmall(css`
    margin-top: 2rem;
  `)}
`;
