import MaterialMenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';

import { StyledMenu } from './menu.styles';
import { MenuProps } from './models';

const Menu = ({
  anchorEl,
  onElementClick,
  options = [],
  isOpen,
  onMenuClose,
}: MenuProps) => {
  const { t } = useTranslation();

  const currentOptions = options
    .filter((x) => !x.isHidden)
    .map(
      (option) =>
        option.canonicalName && (
          <MaterialMenuItem
            key={option.canonicalName}
            disabled={option.disabled}
            onClick={() =>
              onElementClick && onElementClick(option.canonicalName)
            }
          >
            {option.component ||
              option.label ||
              t(`enums.actions.${option.canonicalName}`)}
          </MaterialMenuItem>
        ),
    );

  return (
    <StyledMenu
      id="lock-menu"
      anchorEl={anchorEl}
      open={isOpen}
      onClose={onMenuClose}
      MenuListProps={{
        'aria-labelledby': 'lock-button',
        role: 'listbox',
      }}
    >
      {currentOptions}
    </StyledMenu>
  );
};

export default Menu;
