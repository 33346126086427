import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import FamilyMemberForm from '../../../../../../../components/Forms/FamilyMemberForm/FamilyMemberForm';
import { ModalForm } from '../../../../../../../components/ModalForm/ModalForm';
import ModalFormPage from '../../../../../../../components/ModalFormPage/modalFormPage';
import { withErrorProvider } from '../../../../../../../hoc/withErrorProvider';
import { ADD_SR_FAMILY_MEMBER_MODAL } from './addSRFamilyMemberModal.const';
import { useAddSRFamilyMemberModalForm } from './addSRFamilyMemberModal.hooks';

type AddSRFamilyMemberModalProps = {
  onFamilyMemberAdded?: () => void;
  id?: string;
};

const AddSRFamilyMemberModal = ({
  onFamilyMemberAdded,
  id,
}: AddSRFamilyMemberModalProps) => {
  const { t } = useTranslation();

  const { validate, onCancel, membership, submitHandler, memberAdding } =
    useAddSRFamilyMemberModalForm({ onFamilyMemberAdded, id });

  return (
    <ModalForm name={ADD_SR_FAMILY_MEMBER_MODAL + id}>
      <Form
        keepDirtyOnReinitialize
        onSubmit={submitHandler}
        validate={validate}
      >
        {({ ...renderProps }) => (
          <ModalFormPage
            handleSubmit={renderProps.handleSubmit}
            onCancel={onCancel}
            isProcessing={memberAdding}
            title={t('headers.add-family-member')}
          >
            <FamilyMemberForm
              memberTypes={membership?.metadata?.canAddMemberTypes}
              membershipPlanId={membership?.membershipPlanId}
              disabled={memberAdding}
              productType={membership.productType}
            />
          </ModalFormPage>
        )}
      </Form>
    </ModalForm>
  );
};

export default withErrorProvider(AddSRFamilyMemberModal);
