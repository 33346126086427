import styled from '@emotion/styled/macro';

import { css } from '@emotion/react';

import Button from '../../../../../components/UI/Button/Button';
import RemoveIconGray from '../../../../../assets/icons/remove-gray-icon.svg';
import RemoveIconRed from '../../../../../assets/icons/remove-icon.svg';

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
`;

type RemoveIconProps = {
  disabled?: boolean;
};
export const RemoveButton = styled(Button)<RemoveIconProps>`
  align-items: center;
  background: url(${RemoveIconRed});
  ${({ disabled }) =>
    disabled &&
    css`
      background: url(${RemoveIconGray});
    `}
  background-repeat: no-repeat;
  background-position: center left;
  display: flex;
  font-weight: normal;
  gap: 1rem;
`;

export const AddButton = styled(Button)`
  font-weight: 500;
`;
