import FormErrorProvider from '../../../../components/FormErrorProvider/FormErrorProvider';
import AddMembershipMemberDetailsForm from './AddMembershipMemberDetailsForm/AddMembershipMemberDetailsForm';

const AddMembershipMemberDetailsPage = () => (
  <FormErrorProvider>
    <AddMembershipMemberDetailsForm />
  </FormErrorProvider>
);

export default AddMembershipMemberDetailsPage;
