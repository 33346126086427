import { useTranslation } from 'react-i18next';
import { FieldRenderProps } from 'react-final-form';

import { getPropertyName } from '../../../../utils/getPropertyName';
import { useFormInput } from './formInput.hooks';
import { StyledFormInput } from './formInput.styles';
import { FormFieldWrapper } from '../../../Form/Form';
import { ErrorLabel } from '../../Field/Field';
import { IInputProps } from '../../Input/Input';

export interface IFormInputProps extends FieldRenderProps<string>, IInputProps {
  onBlur?: () => void;
}

export const FormInput = ({
  className,
  disabled,
  input,
  inputId,
  label,
  meta,
  labelAnimation = true,
  onBlur,
  ...props
}: IFormInputProps) => {
  const { t } = useTranslation();
  const { onBlurHandler, onChange, preparedPlaceholder, inputValue } =
    useFormInput({
      onBlur,
      input,
      placeholder: props.placeholder,
      labelAnimation,
      format: props.formatValue,
      parse: props.parseValue,
    });

  return (
    <FormFieldWrapper className={className} error={meta.touched && meta.error}>
      <StyledFormInput
        {...props}
        name={input.name}
        error={meta.error && meta.touched}
        disabled={disabled}
        placeholder={preparedPlaceholder}
        {...input}
        labelAnimation={labelAnimation}
        onChange={onChange}
        onBlur={onBlurHandler}
        label={label || t(`fields.${getPropertyName(input.name)}.label`)}
        value={inputValue || ''}
      />
      {meta.touched && meta.error && <ErrorLabel>{meta.error}</ErrorLabel>}
    </FormFieldWrapper>
  );
};
