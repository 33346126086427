import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import { RoutePath } from 'models/enums/RoutePath';

import { useNavigateSearch } from '../../hooks/useNavigationSearch';

export const useConfirmationPage = () => {
  const { state }: { state: any } = useLocation();
  const navigate = useNavigateSearch();

  const visitMemberPortalHandler = useCallback(() => {
    navigate(RoutePath.Profile);
  }, [navigate]);

  return { visitMemberPortalHandler, memberName: state?.memberName };
};
