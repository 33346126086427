export const LOGIN_PROCESSING = 'LOGIN_PROCESSING';
export const SEND_RESET_PASSWORD_EMAIL_PROCESSING =
  'SEND_RESET_PASSWORD_EMAIL_PROCESSING';
export const RESET_PASSWORD_PROCESSING = 'RESET_PASSWORD_PROCESSING';

export const PRIMARY_MEMBER_LOAD_PROCESSING = ' PRIMARY_MEMBER_LOAD_PROCESSING';
export const FAMILY_MEMBERS_LOAD_PROCESSING = ' FAMILY_MEMBERS_LOAD_PROCESSING';

export const MEMBERSHIP_PLANS_LOAD_PROCESSING =
  'MEMBERSHIP_PLANS_LOAD_PROCESSING';
export const MEMBERSHIP_SUMMARY_LOAD_PROCESSING =
  'MEMBERSHIP_SUMMARY_LOAD_PROCESSING';
export const NEW_MEMBERSHIP_VALIDATION_PROCESSING =
  'NEW_MEMBERSHIP_VALIDATION_PROCESSING';
export const MEMBERSHIP_ANALYZE_PROCESSING = 'MEMBERSHIP_ANALYZE_PROCESSING';
export const PAYMENT_PROCESSING = 'PAYMENT_PROCESSING';

export const MEMBERSHIP_CREATING_PROCESSING = 'MEMBERSHIP_CREATING_PROCESSING';
export const MEMBERSHIP_SUGGESTION_LOAD_PROCESSING =
  'MEMBERSHIP_SUGGESTION_LOAD_PROCESSING';
export const MEMBERSHIP_AUTO_RENEW_FLAG_UPDATE_PROCESSING =
  'MEMBERSHIP_AUTO_RENEW_FLAG_UPDATE_PROCESSING';
export const MEMBERSHIP_MEMBER_DETAILS_CREATING_PROCESSING =
  'MEMBERSHIP_MEMBER_DETAILS_CREATING_PROCESSING';
export const ORDER_PAYMENT_DETAILS_LOAD_PROCESSING =
  'ORDER_PAYMENT_DETAILS_LOAD_PROCESSING';

export const PROFILE_LOAD_PROCESSING = 'PROFILE_LOAD_PROCESSING';
export const MEMBERSHIPS_AA_LOAD_PROCESSING = 'MEMBERSHIPS_AA_LOAD_PROCESSING';
export const MEMBERSHIPS_KE_LOAD_PROCESSING = 'MEMBERSHIPS_KE_LOAD_PROCESSING';
export const MEMBERSHIPS_SR_LOAD_PROCESSING = 'MEMBERSHIPS_SR_LOAD_PROCESSING';
export const MEMBERSHIPS_DOC_LOAD_PROCESSING =
  'MEMBERSHIPS_DOC_LOAD_PROCESSING';
export const FAMILY_MEMBER_ADDING_PROCESSING =
  'FAMILY_MEMBER_ADDING_PROCESSING';
export const FAMILY_MEMBER_REMOVING_PROCESSING =
  'FAMILY_MEMBER_REMOVING_PROCESSING';

export const PERSON_DETAILS_LOAD_PROCESSING = 'PERSON_DETAILS_LOAD_PROCESSING';
export const PERSON_UPDATE_PROCESSING = 'PERSON_UPDATE_PROCESSING';

export const CREDIT_CARDS_LOAD_PROCESSING = 'CREDIT_CARDS_LOAD_PROCESSING';
export const CREDIT_CARD_REPLACE_PROCESSING = 'CREDIT_CARD_REPLACE_PROCESSING';
export const CREDIT_CARD_ADD_PROCESSING = 'CREDIT_CARD_ADD_PROCESSING';
export const CREDIT_CARD_DELETE_PROCESSING = 'CREDIT_CARD_DELETE_PROCESSING';

export const MEMBERSHIP_PROMOTION_LOAD_PROCESSING =
  'MEMBERSHIP_PROMOTION_LOAD_PROCESSING';
export const PARTNERSHIP_INFO_LOADING = 'PARTNERSHIP_INFO_LOADING';
