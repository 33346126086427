import { useMsal } from '@azure/msal-react';
import { useCallback } from 'react';

import { useActions } from '../../../hooks/useActions';
import { clearState } from '../../../store/globalActions';

type UseAuthNavigationProps = {
  onClick?: () => void;
};
export const useAuthNavigation = ({ onClick }: UseAuthNavigationProps) => {
  const ClearState = useActions(clearState);
  const { instance } = useMsal();

  const logoutHandler = useCallback(() => {
    ClearState();
    instance.logoutRedirect();
    onClick && onClick();
  }, [ClearState, instance, onClick]);

  return {
    logoutHandler,
  };
};
