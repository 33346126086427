import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IPartnershipInfoModel } from '../../../../models/interfaces/PartnershipModel';

export interface IAAPartnershipState {
  partnershipInfo?: IPartnershipInfoModel;
}

const initialState: IAAPartnershipState = {
  partnershipInfo: null,
};

const aaPartnershipSlice = createSlice({
  initialState,
  name: 'aaPartnership',
  reducers: {
    setPartnershipInfo: (
      state,
      { payload }: PayloadAction<IPartnershipInfoModel>,
    ) => {
      state.partnershipInfo = payload;
    },
    clearPartnershipInfo: (state) => {
      state.partnershipInfo = initialState.partnershipInfo;
    },
  },
});

export const { clearPartnershipInfo, setPartnershipInfo } =
  aaPartnershipSlice.actions;
export default aaPartnershipSlice.reducer;
export const membershipReducerName = aaPartnershipSlice.name;
