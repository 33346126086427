import styled from '@emotion/styled/macro';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const CreditCardTypeText = styled.span`
  font: 700 1rem/1rem ${({ theme }) => theme.font.gotham};
  color: ${({ theme }) => theme.color.black1};
`;
