import axios, { AxiosResponse } from 'axios';

import config from '../../../../config/config';
import { IMemberships } from '../../../../models/interfaces/IMemberships';

const MainUrl = '/api/memberships';

export const getDOCMemberships = (
  cancelToken?: AbortSignal,
): Promise<AxiosResponse<IMemberships>> =>
  axios.get(MainUrl, {
    signal: cancelToken,
    baseURL: config.dutyOfCareApi.baseUrl,
  });
