import { useCallback, useMemo, useState } from 'react';
import { useField } from 'react-final-form';
import { useSelector } from 'react-redux';

import { useActions } from '../../../../../../../hooks/useActions';
import { ISecondaryMember } from '../../../../../../../models/interfaces/SecondaryMember';
import { familyMembersSelector } from '../../../../../../../store/redux-slices/members/selectors';
import { createMembershipMemberDetailsFamilyMembersSelector } from '../../../../../../../store/redux-slices/membership/airAmbulance/selectors';
import { updateModalState } from '../../../../../../../store/redux-slices/modals';
import { FAMILY_ENROLLMENT_MODAL_NAME } from '../../../../../Modals/FamilyEnrollmentModal/FamilyEnrollmentModal';
import { FamilyMembersFields } from '../FamilyMembersForm/familyMembersForm.models';

export const useFamilyMembersSection = () => {
  const UpdateModalState = useActions(updateModalState);
  const familyMembers = useSelector(familyMembersSelector);
  const addedFamilyMembers = useSelector(
    createMembershipMemberDetailsFamilyMembersSelector,
  );
  const [familyMembersModalShowed, setFamilyMembersModalShowed] =
    useState<boolean>();

  const {
    input: { onChange: onChangeFamilyMembers },
  } = useField(FamilyMembersFields.SecondaryMembers);

  const onAddFamilyMemberHandler = useCallback(() => {
    UpdateModalState(FAMILY_ENROLLMENT_MODAL_NAME);
    setFamilyMembersModalShowed(true);
  }, [UpdateModalState]);

  const shouldShowFamilyMembersModal = useMemo(
    () =>
      familyMembers &&
      familyMembers.length > 0 &&
      !addedFamilyMembers &&
      !familyMembersModalShowed,
    [addedFamilyMembers, familyMembers, familyMembersModalShowed],
  );

  const onSelectFamilyMembersHandler = useCallback(
    (selectedFamilyMembers: ISecondaryMember[]) => {
      onChangeFamilyMembers(selectedFamilyMembers);
    },
    [onChangeFamilyMembers],
  );

  return {
    onAddFamilyMemberHandler,
    shouldShowFamilyMembersModal,
    familyMembers,
    onSelectFamilyMembersHandler,
  };
};
