import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

import { mq } from '../../utils/mediaQueries';
import Button from '../UI/Button/Button';
import { ButtonProps } from '../UI/Button/button.models';

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr;
`;

export const TopBannerContentContainer = styled.div`
  background-color: ${({ theme }) => theme.color.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: sticky;
  top: 3rem;
  z-index: 20;
  height: 5.5rem;

  ${mq.small(css`
    top: 3rem;
    height: 4rem;
  `)}

  ${mq.xSmall(css`
    top: 7rem;
    height: 3.5rem;
  `)}
`;

export const BannerText = styled.h2`
  font: normal normal 1em/1.5rem ${({ theme }) => theme.font.gotham};
  color: ${({ theme }) => theme.color.white};
  text-align: center;

  padding: 0rem 1rem;

  ${mq.xSmall(css`
    font-size: 1.125rem;
  `)}
`;

export const BottomBannerContentContainer = styled.div`
  background-color: ${({ theme }) => theme.color.primary};
  color: ${({ theme }) => theme.color.white};
  width: 100%;
  z-index: 20;
  padding: 1rem 1.5rem;
  font: normal normal 0.813rem/1.5rem ${({ theme }) => theme.font.gotham};
  bottom: 0;
  border-bottom: 1px solid ${({ theme }) => theme.color.primary40};

  ${mq.xLarge(css`
    font-size: 0.875rem;
    padding: 1rem 5rem;
  `)}
`;

export const TextLinkButton = styled((props: ButtonProps) => (
  <Button {...props} styleType="link" />
))`
  font: normal bold 0.875rem/1.5rem ${({ theme }) => theme.font.gotham};
  color: ${({ theme }) => theme.color.white};
`;
