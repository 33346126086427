import { createSelector } from 'reselect';

import { RootState } from '../index';
import { IPaymentState } from './index';
import { ICreditCardInfo } from '../../../models/interfaces/CreditCardInfo';

export const paymentSelector = (state: RootState) => state.payment;

export const creditCardsSelector = createSelector(
  paymentSelector,
  (paymentState: IPaymentState): ICreditCardInfo[] => paymentState.creditCards,
);

export const firstCreditCardSelector = createSelector(
  creditCardsSelector,
  (creditCards: ICreditCardInfo[]): ICreditCardInfo =>
    creditCards && creditCards.length > 0 && creditCards[0],
);
