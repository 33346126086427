import { ProductType } from '../../../../../models/enums/ProductType';
import { AAMembership, AAMembershipProps } from '../AirAmbulance/AAMembership';
import { DOCMembership, DOCMembershipProps } from '../DutyOfCare/DOCMembership';
import {
  KEMembership,
  KEMembershipProps,
} from '../KidnapAndExtortion/KEMembership';
import {
  SRMembership,
  SRMembershipProps,
} from '../SecurityResponse/SRMembership';

type MembershipProps = AAMembershipProps &
  KEMembershipProps &
  SRMembershipProps &
  DOCMembershipProps;

export const Membership = (props: MembershipProps) => {
  const {
    membership: { productType },
  } = props;
  switch (productType) {
    case ProductType.AirAmbulance:
      return <AAMembership {...props} />;
    case ProductType.KidnapAndExtortion:
      return <KEMembership {...props} />;
    case ProductType.SecurityResponse:
      return <SRMembership {...props} />;
    case ProductType.DutyOfCare:
      return <DOCMembership {...props} />;
    default:
      return null;
  }
};
