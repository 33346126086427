import { AnySchema } from 'ajv';
import { t } from 'i18next';

import { FieldErrorMessage } from '../../../../../models/interfaces/FieldErrorMessage';
import { ICreateMembershipPayment } from '../../../../../models/interfaces/ICreateMembershipPayment';
import { validateFormData } from '../../../../../utils/validations/validateFormData';
import { AgreementFields } from './Forms/AgreementForm/agreementForm.models';

const schema: AnySchema | any = {
  type: 'object',
  properties: {
    [AgreementFields.MemberAgreedTermsAndConditions]: {
      type: 'boolean',
      enum: [true],
      errorMessage: t('validation.need-accept-terms-and-conditions'),
    },
  },
  required: [],
};

export const validateAddMembershipPaymentForm = (
  data: ICreateMembershipPayment,
  additionalErrors?: FieldErrorMessage[],
) => validateFormData(data, additionalErrors, schema);
