import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IFamilyMemberInfo } from '../../../models/interfaces/FamilyMemberInfo';
import { IIndividualFamilyMembers } from '../../../models/interfaces/IndividualFamilyMembers';
import { IIndividualPrimaryMember } from '../../../models/interfaces/IndividualPrimaryMember';
import { parseDate } from '../../../utils/dateHelpers';
import { clearState } from '../../globalActions';

export interface IMembersState {
  primaryMember: IIndividualPrimaryMember;
  familyMembers: IFamilyMemberInfo[];
}

const initialState: IMembersState = {
  primaryMember: null,
  familyMembers: null,
};

const membersSlice = createSlice({
  name: 'members',
  initialState,
  reducers: {
    setPrimaryMember: (
      state,
      { payload }: PayloadAction<IIndividualPrimaryMember>,
    ) => {
      state.primaryMember = {
        ...payload,
        primaryMember: {
          ...payload.primaryMember,
          dateOfBirth: parseDate(payload.primaryMember.dateOfBirth),
        },
      };
    },
    setFamilyMembers: (
      state,
      { payload }: PayloadAction<IIndividualFamilyMembers>,
    ) => {
      state.familyMembers = payload.members.map((member) => ({
        ...member,
        dateOfBirth: parseDate(member.dateOfBirth),
      }));
    },
    clearPrimaryMember: (state) => {
      state.primaryMember = initialState.primaryMember;
    },
    clearFamilyMembers: (state) => {
      state.familyMembers = initialState.familyMembers;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearState, () => initialState);
  },
});

export const {
  setPrimaryMember,
  clearPrimaryMember,
  clearFamilyMembers,
  setFamilyMembers,
} = membersSlice.actions;
export default membersSlice.reducer;
export const membersReducerName = membersSlice.name;
