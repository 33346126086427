import { withAITracking } from '@microsoft/applicationinsights-react-js';

import { reactPlugin } from './utils/appInsights';
import AppRoutes from './AppRoutes';
import Layout from './components/Layout/Layout';
import ErrorHandler from './components/ErrorHandler/ErrorHandler';
import CookieConsent from './components/CookieConsent/CookieConsent';

const Main = () => (
  <ErrorHandler>
    <Layout>
      <AppRoutes />
      <CookieConsent />
    </Layout>
  </ErrorHandler>
);

export default withAITracking(reactPlugin, Main);
