import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useProcessing } from '../../hooks/useProcessing';

import { useActions } from '../../hooks/useActions';
import { useCancellationToken } from '../../hooks/useTokenCancellation';

import {
  CREDIT_CARDS_LOAD_PROCESSING,
  MEMBERSHIPS_AA_LOAD_PROCESSING,
  MEMBERSHIPS_KE_LOAD_PROCESSING,
  PERSON_DETAILS_LOAD_PROCESSING,
} from '../../store/redux-slices/processes/constants';
import { ILoadProfilePayload } from '../../store/saga-slices/profile/models';
import { ILoadAAMembershipsPayload } from '../../store/saga-slices/membership/airAmbulance/models';
import { ILoadCreditCardsPayload } from '../../store/saga-slices/payment/models';

import { loadCreditCardList } from '../../store/saga-slices/payment';

import { firstCreditCardSelector } from '../../store/redux-slices/payment/selectors';
import { Membership } from '../../models/types/Membership';
import { updateModalState } from '../../store/redux-slices/modals';
import { EDIT_MEMBER_DETAILS_MODAL } from './Modals/EditMemberDetailsModal/editMemberDetailsModal.consts';
import { loadPersonDetails } from '../../store/saga-slices/personalData';
import { profileSelector } from '../../store/redux-slices/personalData/selectors';
import { clearPerson } from '../../store/redux-slices/personalData';
import { REPLACE_CREDIT_CARD_DETAILS_MODAL } from './Modals/ReplaceCreditCardModal/replaceCreditCard.consts';
import { DELETE_CREDIT_CARD_MODAL } from './Modals/DeleteCreditCardModal/deleteCreditCardModal.const';
import { ADD_CREDIT_CARD_MODAL } from './Modals/AddCreditCardModal/addCreditCard.consts';
import { UPDATE_AUTO_RENEW_FLAG_MODAL } from './Modals/UpdateAutoRenewFlagModal/updateAutoRenewFlagModal.consts';
import { useNavigateSearch } from '../../hooks/useNavigationSearch';
import { RoutePath } from '../../models/enums/RoutePath';
import { AddMembershipAvailableParams } from '../AddMembershipPage/addMembership.routes';
import { loadMemberships } from '../../store/saga-slices/membership/common';
import { membershipsSelector } from '../../store/redux-slices/membership/common/selectors';

export const useProfilePage = () => {
  const cancellationToken = useCancellationToken();

  const navigate = useNavigateSearch();

  const LoadPerson = useActions(loadPersonDetails);
  const ClearPerson = useActions(clearPerson);
  const LoadMemberships = useActions(loadMemberships);
  const LoadCreditCards = useActions(loadCreditCardList);
  const UpdateModalState = useActions(updateModalState);

  const isPersonLoading = useProcessing(PERSON_DETAILS_LOAD_PROCESSING);
  const areMembershipsLoading = useProcessing([
    MEMBERSHIPS_AA_LOAD_PROCESSING,
    MEMBERSHIPS_KE_LOAD_PROCESSING,
  ]);

  const areCreditCardsLoading = useProcessing(CREDIT_CARDS_LOAD_PROCESSING);

  const profile = useSelector(profileSelector);
  const memberships = useSelector(membershipsSelector);
  const creditCard = useSelector(firstCreditCardSelector);

  const loadPersonHandler = useCallback(() => {
    const payload: ILoadProfilePayload = {
      cancellationToken,
    };

    LoadPerson(payload);
  }, [LoadPerson, cancellationToken]);

  const loadCreditCardsHandler = useCallback(() => {
    const payload: ILoadCreditCardsPayload = {
      cancellationToken,
    };

    LoadCreditCards(payload);
  }, [LoadCreditCards, cancellationToken]);

  useEffect(() => {
    loadPersonHandler();
  }, [loadPersonHandler]);

  const loadMembershipsHandler = useCallback(() => {
    const payload: ILoadAAMembershipsPayload = {
      cancellationToken,
    };

    LoadMemberships(payload);
  }, [LoadMemberships, cancellationToken]);

  useEffect(() => {
    loadMembershipsHandler();
  }, [loadMembershipsHandler]);

  useEffect(() => {
    loadCreditCardsHandler();
  }, [loadCreditCardsHandler]);

  useEffect(() => () => ClearPerson(), [ClearPerson]);

  const updateMemberDetailsHandler = () => {
    UpdateModalState(EDIT_MEMBER_DETAILS_MODAL);
  };

  const replaceCreditCardHandler = (creditCardId: string) => {
    UpdateModalState([REPLACE_CREDIT_CARD_DETAILS_MODAL, { creditCardId }]);
  };

  const deleteCreditCardHandler = (creditCardId: string) => {
    UpdateModalState([DELETE_CREDIT_CARD_MODAL, { creditCardId }]);
  };

  const addCreditCardHandler = () => {
    UpdateModalState(ADD_CREDIT_CARD_MODAL);
  };

  const updateAutoRenewFlagHandler = (membership: Membership) => {
    UpdateModalState([UPDATE_AUTO_RENEW_FLAG_MODAL, { membership }]);
  };

  const renewMembershipHandler = (membershipId: string) => {
    navigate(RoutePath.AddMembershipPlanFullPath, {
      searchParams: {
        [AddMembershipAvailableParams.MembershipId]: membershipId,
      },
    });
  };

  return {
    areMembershipsLoading,
    creditCard,
    memberships,
    profile,
    loadMembershipsHandler,
    updateMemberDetailsHandler,
    loadPersonHandler,
    replaceCreditCardHandler,
    deleteCreditCardHandler,
    loadCreditCardsHandler,
    areCreditCardsLoading,
    isPersonLoading,
    addCreditCardHandler,
    updateAutoRenewFlagHandler,
    renewMembershipHandler,
  };
};
