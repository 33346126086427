import { useTranslation } from 'react-i18next';

import {
  SimpleModal,
  ModalTitle,
  ModalButton,
} from '../../../../components/ModalCommon/ModalCommon';

import { StandardText } from '../../../../components/Typography/Typography';
import { useActions } from '../../../../hooks/useActions';
import { useModalDetails } from '../../../../hooks/useModalDetails';
import { updateModalState } from '../../../../store/redux-slices/modals';
import { StyledStandardText } from './noFamilyMembersConfirmModal.styles';

export const NO_FAMILY_MEMBERS_CONFIRM_MODAL_NAME =
  'NO_FAMILY_MEMBERS_CONFIRM_MODAL_NAME';

type NoFamilyMembersConfirmModalProps = {
  onConfirm?: (values: any, confirmed: boolean) => void;
};

const NoFamilyMembersConfirmModal = ({
  onConfirm,
}: NoFamilyMembersConfirmModalProps) => {
  const { t } = useTranslation();

  const details = useModalDetails(NO_FAMILY_MEMBERS_CONFIRM_MODAL_NAME);
  const UpdateModalState = useActions(updateModalState);

  const handleConfirm = () => {
    UpdateModalState(NO_FAMILY_MEMBERS_CONFIRM_MODAL_NAME);
    onConfirm && onConfirm(details, true);
  };

  return (
    <SimpleModal name={NO_FAMILY_MEMBERS_CONFIRM_MODAL_NAME}>
      <ModalTitle>{t('headers.confirmation-required')}</ModalTitle>
      <StandardText>
        {t('phrase.are-you-sure-continue-without-family-members')}
      </StandardText>
      <StyledStandardText>{`${t(
        'phrase.to-add-family-member-later-contact-with-customer-service',
      )}`}</StyledStandardText>
      <ModalButton onClick={handleConfirm}>{t(`buttons.confirm`)}</ModalButton>
    </SimpleModal>
  );
};

export default NoFamilyMembersConfirmModal;
