import { createSelector } from 'reselect';

import { MembershipPlanPeriodsType } from 'models/enums/MembershipPlanPeriodsType';

import { IAAMembershipState } from './index';

import { RootState } from '../../index';
import { ICreateMembershipPlan } from '../../../../models/interfaces/ICreateMembershipPlan';
import { IMembershipPlanGroupItemModel } from '../../../../models/interfaces/MembershipPlanGroupItemModel';
import { IRetailMembershipOrderSummaryModel } from '../../../../models/interfaces/RetailMembershipOrderSummaryModel';
import { ICreateMembershipMemberDetails } from '../../../../models/interfaces/ICreateMembershipMemberDetails';
import { ICreateIndividualMembership } from '../../../../models/interfaces/CreateIndividualMembership';
import { IIndividualMembershipPaymentDetails } from '../../../../models/interfaces/IndividualMembershipPaymentDetails';
import { MembershipsType } from '../../../../models/types/MembershipState';
import { ISecondaryMember } from '../../../../models/interfaces/SecondaryMember';
import { ICreateMembershipPayment } from '../../../../models/interfaces/ICreateMembershipPayment';
import { ICreateMembershipAdditionalDataSuggestion } from '../../../../models/interfaces/CreateMembershipAdditionalDataSuggestion';

export const aaMembershipSelector = (state: RootState) => state.aaMembership;

export const createMembershipPlanSelector = createSelector(
  aaMembershipSelector,
  (membershipState: IAAMembershipState): ICreateMembershipPlan =>
    membershipState.createMembershipPlan,
);

export const createMembershipMemberDetailsSelector = createSelector(
  aaMembershipSelector,
  (membershipState: IAAMembershipState): ICreateMembershipMemberDetails =>
    membershipState.createMembershipMemberDetails,
);

export const createMembershipPaymentSelector = createSelector(
  aaMembershipSelector,
  (membershipState: IAAMembershipState): ICreateMembershipPayment =>
    membershipState.createMembershipPayment,
);

export const createMembershipMemberDetailsFamilyMembersSelector =
  createSelector(
    aaMembershipSelector,
    (membershipState: IAAMembershipState): ISecondaryMember[] =>
      membershipState?.createMembershipMemberDetails?.secondaryMembers,
  );

export const selectedMembershipPlanSelector = createSelector(
  createMembershipPlanSelector,
  (
    createMembershipPlan: ICreateMembershipPlan,
  ): IMembershipPlanGroupItemModel =>
    createMembershipPlan?.membershipPlanInfo?.membershipPlan,
);

export const selectedMembershipPromoCodeSelector = createSelector(
  createMembershipPlanSelector,
  (createMembershipPlan: ICreateMembershipPlan): string =>
    createMembershipPlan?.promoCode,
);

export const membershipSummarySelector = createSelector(
  aaMembershipSelector,
  (membershipState: IAAMembershipState): IRetailMembershipOrderSummaryModel =>
    membershipState.summary,
);

export const createMembershipDataSelector = createSelector(
  createMembershipPlanSelector,
  createMembershipMemberDetailsSelector,
  (
    createMembershipPlan: ICreateMembershipPlan,
    createMembershipMemberDetails: ICreateMembershipMemberDetails,
  ): ICreateIndividualMembership => ({
    membershipPlanId:
      createMembershipPlan.membershipPlanInfo.membershipPlan.planId,
    promoCode: createMembershipPlan.promoCode,
    ...createMembershipMemberDetails,
    primaryMember: {
      ...createMembershipMemberDetails.primaryMember,
      emailAddress: createMembershipPlan.emailAddress,
    },
  }),
);

export const orderPaymentDetailsSelector = createSelector(
  aaMembershipSelector,
  (membershipState: IAAMembershipState): IIndividualMembershipPaymentDetails =>
    membershipState.orderPaymentDetails,
);

export const memberDetailsPrimaryMemberNameSelector = createSelector(
  createMembershipMemberDetailsSelector,
  (createMembershipMemberDetails: ICreateMembershipMemberDetails): string =>
    createMembershipMemberDetails?.primaryMember?.firstName,
);

export const aaMembershipsSelector = createSelector(
  aaMembershipSelector,
  (membershipState: IAAMembershipState): MembershipsType =>
    membershipState.aaMemberships,
);

export const areDataLoadedSelector = createSelector(
  aaMembershipSelector,
  (membershipState: IAAMembershipState): boolean =>
    !!membershipState.createMembershipPlan &&
    !!membershipState.createMembershipMemberDetails,
);

export const createdMembershipIdSelector = createSelector(
  aaMembershipSelector,
  (membershipState: IAAMembershipState): string =>
    membershipState.createdAAMembershipId,
);

export const membershipAdditionalSuggestionSelector = createSelector(
  aaMembershipSelector,
  (
    membershipState: IAAMembershipState,
  ): ICreateMembershipAdditionalDataSuggestion =>
    membershipState.additionalSuggestionData,
);

export const effectiveDateMembershipSuggestionSelector = createSelector(
  membershipAdditionalSuggestionSelector,
  (membershipSuggestion: ICreateMembershipAdditionalDataSuggestion): string =>
    membershipSuggestion?.effectiveDate,
);

export const selectedMembershipPlanPeriodSuggestionSelector = createSelector(
  aaMembershipSelector,
  (membershipState: IAAMembershipState): MembershipPlanPeriodsType =>
    membershipState.renewalSuggestionTabOption,
);

export const isSelectedMembershipPlanPeriodRenewal = createSelector(
  selectedMembershipPlanSelector,
  (membershipState: IMembershipPlanGroupItemModel): boolean => {
    switch (membershipState.termType) {
      case MembershipPlanPeriodsType.Days_14:
      case MembershipPlanPeriodsType.Days_30:
        return false;
      case MembershipPlanPeriodsType.Annual:
        return true;
      default:
        return true;
    }
  },
);
