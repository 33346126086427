import { IFormMessageProps } from './formMessage.models';
import { Message, Wrapper } from './formMessage.styles';

const FormMessage = ({
  status = 'default',
  message,
  className,
}: IFormMessageProps) => (
  <Wrapper className={className}>
    <Message status={status}>{message}</Message>
  </Wrapper>
);

export default FormMessage;
