import { ICreateMembershipPlanInput } from '../models/interfaces/ICreateMembershipPlan';
import { IMembershipPlansTreeModel } from '../models/interfaces/MembershipPlansTreeModel';

export const findMembershipPlanInfoById = (
  currentMembershipPlans: IMembershipPlansTreeModel,
  membershipPlanId: string,
): ICreateMembershipPlanInput => {
  const result: ICreateMembershipPlanInput = {};
  if (!currentMembershipPlans || !membershipPlanId) {
    return null;
  }

  for (let i = 0; i < currentMembershipPlans.groups.length; i++) {
    const group = currentMembershipPlans.groups[i];
    for (let j = 0; j < group.items.length; j++) {
      const plan = group.items[j];
      if (plan.planId === membershipPlanId) {
        result.membershipPlan = plan;
        result.membershipPlanGroup = group.id;
        break;
      }
    }

    if (result.membershipPlan) {
      break;
    }
  }

  return result;
};
