import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IMemberships } from '../../../../models/interfaces/IMemberships';
import { Membership } from '../../../../models/types/Membership';

import { clearState } from '../../../globalActions';
import { ProductType } from '../../../../models/enums/ProductType';

export interface IKEMembershipState {
  keMemberships?: {
    active: Membership[];
    expired: Membership[];
  };
}

const initialState: IKEMembershipState = {
  keMemberships: {
    active: [],
    expired: [],
  },
};

const keMembershipSlice = createSlice({
  initialState,
  name: 'keMembership',
  reducers: {
    setKEMemberships: (state, { payload }: PayloadAction<IMemberships>) => {
      const memberships: Membership[] = payload.memberships.map((x) => ({
        ...x,
        productType: ProductType.KidnapAndExtortion,
      }));
      state.keMemberships.active = memberships.filter(
        (membership) => !membership.isExpired,
      );
      state.keMemberships.expired = memberships.filter(
        (membership) => membership.isExpired,
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearState, () => initialState);
  },
});

export const { setKEMemberships } = keMembershipSlice.actions;
export default keMembershipSlice.reducer;
export const membershipReducerName = keMembershipSlice.name;
