import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

import { ModalContainer } from '../../../../components/Modal/modal.styles';

import { SimpleModal } from '../../../../components/ModalCommon/ModalCommon';
import {
  SectionTitle,
  StandardText,
  StandardTextResponsive,
} from '../../../../components/Typography/Typography';
import { mq } from '../../../../utils/mediaQueries';

export const StyledSectionTitle = styled(SectionTitle)`
  text-align: left;
`;

export const StyledModal = styled(SimpleModal)`
  ${ModalContainer} {
    align-items: flex-start;
  }
`;

export const Description = styled(StandardText)`
  margin: 2.5rem 0;
  text-align: left;
`;

export const SkipText = styled(StandardTextResponsive)`
  margin-top: 2.5rem;
  color: ${({ theme }) => theme.color.dark1};
`;

export const StyledButtonsContainer = styled.div`
  display: flex;
  gap: 1.5rem;
  width: 100%;
  align-self: center;
  flex-direction: column;
  margin-top: 2.5rem;

  ${mq.small(css`
    flex-direction: row;
  `)}
  ${mq.xSmall(css`
    width: auto;
  `)}
`;
