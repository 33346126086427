import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ICreditCardInfo } from '../../../models/interfaces/CreditCardInfo';
import { clearState } from '../../globalActions';

export interface IPaymentState {
  creditCards: ICreditCardInfo[];
}

const initialState: IPaymentState = {
  creditCards: null,
};

const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    setCreditCards: (state, { payload }: PayloadAction<ICreditCardInfo[]>) => {
      state.creditCards = payload;
    },
    clearCreditCards: (state) => {
      state.creditCards = initialState.creditCards;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearState, () => initialState);
  },
});

export const { clearCreditCards, setCreditCards } = paymentSlice.actions;
export default paymentSlice.reducer;
export const plansReducerName = paymentSlice.name;
