import { useMemo } from 'react';

import { IWizardStep } from '../../../store/redux-slices/wizard/models';
import { StepperList, StepperWrapper } from './wizardStepper.styles';
import WizardStep from './WizardStep/WizardStep';
import { CustomComponent } from '../../../models/types/CustomComponent';
import { useWizardStepper } from './wizardStepper.hooks';

const WizardStepper = ({ className }: CustomComponent) => {
  const {
    activeStepCn,
    checkIfStepShouldDisabled,
    stepsDefinitions,
    ChangeStepByCn,
  } = useWizardStepper();

  const steps = useMemo(
    () =>
      stepsDefinitions.map((stepDef: IWizardStep, index: number) => (
        <WizardStep
          key={stepDef.canonicalName}
          isArrowVisible={index > 0}
          isActive={stepDef.canonicalName === activeStepCn}
          step={stepDef}
          isDisabled={checkIfStepShouldDisabled(stepDef, index)}
          onClick={ChangeStepByCn}
        />
      )),
    [ChangeStepByCn, activeStepCn, checkIfStepShouldDisabled, stepsDefinitions],
  );

  return (
    <StepperWrapper className={className}>
      <StepperList>{steps}</StepperList>
    </StepperWrapper>
  );
};

export default WizardStepper;
