import { useMsal } from '@azure/msal-react';

type UseNoAuthNavigationProps = {
  onClick?: () => void;
};
export const useNoAuthNavigation = ({ onClick }: UseNoAuthNavigationProps) => {
  const { instance } = useMsal();

  const loginHandler = () => {
    instance.loginRedirect();
    onClick && onClick();
  };

  return {
    loginHandler,
  };
};
