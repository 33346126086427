export interface AppConfig {
  contact: {
    phone: string;
    email: string;
    operationsCenterEmail: string;
    operationsCenterPhone: string;
    airAmbulanceEmail: string;
    airAmbulancePhone: string;
    addressLine1: string;
    addressLine2: string;
  };
  b2c: {
    authority: string;
    authorityDomain: string;
    clientId: string;
  };
  applicationinsights: {
    connectionString: string;
    applicationName: string;
  };
  environment: string;
  api: {
    baseUrl: string;
    scope: string;
  };
  paymentApi: {
    baseUrl: string;
    stripePublishableKey: string;
    scope: string;
  };
  peopleManagementApi: {
    baseUrl: string;
    scope: string;
  };
  kidnapAndExtortionApi: {
    baseUrl: string;
    scope: string;
  };
  securityResponseApi: {
    baseUrl: string;
    scope: string;
  };
  dutyOfCareApi: {
    baseUrl: string;
    scope: string;
  };
  termsAndConditionsLink: string;
  privacyPolicyLink: string;
  downloadMembershipCardLink: string;
  hubSpot: {
    tracking: boolean;
    apiFormSubmitUrl: string;
    formId: string;
    portalId: string;
    manualHubspotFormSubmit: string;
  };
}

interface ITagManager {
  gtmId: string;
}
export const tagManagerArgs: ITagManager = {
  gtmId: process.env.REACT_APP_GTM_ID,
};

interface IReCaptchaArgs {
  publicKey: string;
}
export const reCaptchaArgs: IReCaptchaArgs = {
  publicKey: process.env.REACT_APP_RECAPTCHA_KEY,
};

const config: AppConfig = {
  environment: process.env.REACT_APP_ENVIRONMENT,
  applicationinsights: {
    connectionString:
      process.env.REACT_APP_APPLICATIONINSIGHTS_CONNECTION_STRING,
    applicationName: 'GlobalGuardian.Member.App',
  },
  b2c: {
    clientId: process.env.REACT_APP_B2C_CLIENT_ID,
    authority: process.env.REACT_APP_B2C_AUTHORITY,
    authorityDomain: process.env.REACT_APP_B2C_AUTHORITY_DOMAIN,
  },
  api: {
    baseUrl: process.env.REACT_APP_MEMBER_API_BASE_URL,
    scope: `${process.env.REACT_APP_B2C_SCOPE_DOMAIN}/membership-management-member-api/air-ambulance-member-api_u`,
  },
  paymentApi: {
    baseUrl: process.env.REACT_APP_PAYMENT_API_BASE_URL,
    stripePublishableKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
    scope: `${process.env.REACT_APP_B2C_SCOPE_DOMAIN}/paymentsbackend-public-api/payment-public-api_u`,
  },
  peopleManagementApi: {
    baseUrl: process.env.REACT_APP_PEOPLE_MANAGEMENT_API_BASE_URL,
    scope: `${process.env.REACT_APP_B2C_SCOPE_DOMAIN}/membership-management-member-api/people-management-member-api_u`,
  },
  kidnapAndExtortionApi: {
    baseUrl: process.env.REACT_APP_KIDNAP_AND_EXTORTION_API_BASE_URL,
    scope: `${process.env.REACT_APP_B2C_SCOPE_DOMAIN}/membership-management-member-api/kidnap-and-ransom-member-api_u`,
  },
  securityResponseApi: {
    baseUrl: process.env.REACT_APP_SECURITY_RESPONSE_API_BASE_URL,
    scope: `${process.env.REACT_APP_B2C_SCOPE_DOMAIN}/membership-management-member-api/security-response-member-api_u`,
  },
  dutyOfCareApi: {
    baseUrl: process.env.REACT_APP_DUTY_OF_CARE_API_BASE_URL,
    scope: `${process.env.REACT_APP_B2C_SCOPE_DOMAIN}/membership-management-member-api/duty-of-care-member-api_u`,
  },
  contact: {
    email: 'info@globalguardian.com',
    operationsCenterEmail: 'operationscenter@globalguardian.com',
    operationsCenterPhone: '+1 (703) 566‑9481',
    phone: '+1 (703) 566-9463',
    addressLine1: '8280 Greensboro Dr Suite 750',
    addressLine2: 'McLean, VA 22102',
    airAmbulanceEmail: 'airambulance@globalguardian.com',
    airAmbulancePhone: '+1 (703) 566-9463',
  },
  termsAndConditionsLink: process.env.REACT_APP_TERMS_AND_CONDITIONS_LINK,
  privacyPolicyLink: process.env.REACT_APP_PRIVACY_POLICY_LINK,
  downloadMembershipCardLink:
    process.env.REACT_APP_DOWNLOAD_MEMBERSHIP_CARD_LINK,
  hubSpot: {
    tracking: process.env.REACT_APP_HUBSPOT_TRACKING === 'true',
    apiFormSubmitUrl: process.env.REACT_APP_HUBSPOT_API_FORM_SUBMIT_URL,
    formId: process.env.REACT_APP_HUBSPOT_FORM_ID,
    portalId: process.env.REACT_APP_HUBSPOT_PORTAL_ID,
    manualHubspotFormSubmit: process.env.REACT_APP_HUBSPOT_MANUAL_FORM_SUBMIT,
  },
};

export default config;
