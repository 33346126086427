import { CustomComponent } from '../../../models/types/CustomComponent';
import { MessageStatus } from './statusMessage.models';
import { Icon, Message, Wrapper } from './statusMessage.styles';

type StatusMessageProps = CustomComponent & {
  status?: MessageStatus;
};

const StatusMessage = ({
  className,
  children,
  status = MessageStatus.Success,
}: StatusMessageProps) => (
  <Wrapper className={className}>
    <Icon status={status} />
    <Message status={status}>{children}</Message>
  </Wrapper>
);

export default StatusMessage;
