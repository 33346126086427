import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import {
  SectionTitle,
  SectionWrapper,
} from '../../../../../../../components/Form/Form';
import { FormInput } from '../../../../../../../components/UI/Form/FormInput/FormInput';
import { CustomForm } from '../../../../../../../models/types/CustomForm';
import { ContactInformationFields } from './contactInformation.models';

const ContactInformationForm = ({ className, disabled }: CustomForm) => {
  const { t } = useTranslation();
  return (
    <SectionWrapper className={className}>
      <SectionTitle>{t('headers.contact-information')}</SectionTitle>
      <Field
        component={FormInput}
        disabled={disabled}
        inputId="add-membership-contact-information-email-input"
        name={ContactInformationFields.EmailAddress}
      />
    </SectionWrapper>
  );
};

export default ContactInformationForm;
