import { t } from 'i18next';

import { RadioGroupContentItem } from '../../../../../../../components/UI/RadioGroupContent/radioGroupContent.models';
import CreditCardDetailsForm from '../../../../../../../components/Forms/CreditCardDetailsForm/CreditCardDetailsForm';
import PaymentExistingCardForm from '../PaymentUseExistingCardForm/PaymentUseExistingCardForm';

export enum PaymentType {
  UseExistingCreditCard = 'use-existing-credit-card',
  PayWithDifferentCard = 'pay-with-different-card',
}

export const paymentTypeOptions: RadioGroupContentItem[] = [
  {
    label: t(`phrase.${PaymentType.UseExistingCreditCard}`),
    id: PaymentType.UseExistingCreditCard,
    contentRenderer: ({ checked, disabled }) => (
      <PaymentExistingCardForm disabled={!checked || disabled} />
    ),
  },
  {
    label: t(`phrase.${PaymentType.PayWithDifferentCard}`),
    id: PaymentType.PayWithDifferentCard,
    contentRenderer: ({ disabled }) => (
      <CreditCardDetailsForm disabled={disabled} />
    ),
    contentHiddenIfNoActive: true,
  },
];
