import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

import { mq } from '../../../utils/mediaQueries';

import Button from '../Button/Button';
import { ButtonProps } from '../Button/button.models';

export const TextLinkButton = styled((props: ButtonProps) => (
  <Button {...props} styleType="link" />
))`
  font: normal bold 0.875rem/1.5rem ${({ theme }) => theme.font.gotham};
  color: ${({ theme }) => theme.color.secondary3};

  ${({ theme }) =>
    mq.small(css`
      font: normal bold 1rem/1.5rem ${theme.font.gotham};
    `)}
`;
