import { useCallback } from 'react';

import { MemberType } from '../../../../../../../models/enums/MemberType';
import { getCountOfItems } from '../../../../../../../utils/arrayHelpers';
import { FamilyMemberFields } from './FamilyMemberSection/FamilyMemberForm/familyMemberForm.models';

type UseFamilyMembersFormProps = {
  maxDependentMembersCount?: number;
  maxSpouseMembersCount?: number;
};

export const useFamilyMembersForm = ({
  maxDependentMembersCount,
  maxSpouseMembersCount,
}: UseFamilyMembersFormProps) => {
  const prepareMemberTypeOptions = useCallback(
    (fields: any, index: number) => {
      if (!fields.value) {
        return [MemberType.Dependent, MemberType.Spouse];
      }
      const resultMemberTypes: MemberType[] = [];
      const currentItem = fields.value[index][FamilyMemberFields.Dependence];

      if (
        getCountOfItems(
          fields.value,
          MemberType.Dependent,
          FamilyMemberFields.Dependence,
        ) < maxDependentMembersCount ||
        currentItem === MemberType.Dependent
      ) {
        resultMemberTypes.push(MemberType.Dependent);
      }

      if (
        getCountOfItems(
          fields.value,
          MemberType.Spouse,
          FamilyMemberFields.Dependence,
        ) < maxSpouseMembersCount ||
        currentItem === MemberType.Spouse
      ) {
        resultMemberTypes.push(MemberType.Spouse);
      }
      return resultMemberTypes;
    },
    [maxDependentMembersCount, maxSpouseMembersCount],
  );
  const canAddFamilyMember = useCallback(
    (fieldsCount: number) =>
      maxDependentMembersCount + maxSpouseMembersCount > fieldsCount,
    [maxDependentMembersCount, maxSpouseMembersCount],
  );

  return {
    canAddFamilyMember,
    prepareMemberTypeOptions,
  };
};
