/* eslint-disable no-console */
/* eslint-disable prefer-destructuring */

import { InteractionStatus } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { useEffect } from 'react';

const LoginPage = () => {
  const { instance, inProgress } = useMsal();

  useEffect(() => {
    if (inProgress === InteractionStatus.None) {
      instance.loginRedirect();
    }
  }, [inProgress, instance]);

  return <></>;
};

export default LoginPage;
