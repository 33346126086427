import { useMsal } from '@azure/msal-react';
import { useEffect } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { useSelector } from 'react-redux';

import { useActions } from '../../../hooks/useActions';

import { useNavigateSearch } from '../../../hooks/useNavigationSearch';
import { RoutePath } from '../../../models/enums/RoutePath';
import { clearState } from '../../../store/globalActions';
import { resetError } from '../../../store/redux-slices/errors';

import {
  authErrorSelector,
  errorsSelector,
  notFoundErrorSelector,
} from '../../../store/redux-slices/errors/selectors';

export const useActionErrorHandler = () => {
  const errors = useSelector(errorsSelector);
  const notFoundError = useSelector(notFoundErrorSelector);
  const authError = useSelector(authErrorSelector);

  const errorHandler = useErrorHandler();
  const navigate = useNavigateSearch();

  const ResetError = useActions(resetError);
  const Logout = useActions(clearState);

  const { instance } = useMsal();

  useEffect(() => {
    if (errors) {
      errorHandler(errors);
    }

    if (notFoundError) {
      navigate(RoutePath.NotFoundPage, null, notFoundError);
      ResetError();
    }

    if (authError) {
      navigate(RoutePath.LoggedOut, null, authError);
      Logout();
      ResetError();
      instance.logoutRedirect();
    }
  }, [
    Logout,
    ResetError,
    authError,
    errorHandler,
    errors,
    instance,
    navigate,
    notFoundError,
  ]);
};
