import { FieldRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { getPropertyName } from '../../../../utils/getPropertyName';
import { FormFieldWrapper } from '../../../Form/Form';
import { ErrorLabel } from '../../Field/Field';

import Switch, { ISwitchProps } from '../../Switch/Switch';
import { useFormSwitch } from './formSwitch.hooks';

interface IFormInputProps extends FieldRenderProps<boolean>, ISwitchProps {
  disabled?: boolean;
  id?: string;
  label?: string;
}

const FormSwitch = ({
  className,
  disabled,
  input: { onChange, name, checked, onBlur },
  meta: { touched, error },
  label,
  ...props
}: IFormInputProps) => {
  const { t } = useTranslation();
  const { onChangeHandler } = useFormSwitch({ checked, onChange });

  return (
    <FormFieldWrapper className={className} error={touched && error}>
      <Switch
        {...props}
        onChange={onChangeHandler}
        checked={checked}
        onBlur={onBlur}
        isError={touched && error}
        disabled={disabled}
        label={label || t(`fields.${getPropertyName(name)}.label`)}
      />
      {touched && error && <ErrorLabel>{error}</ErrorLabel>}
    </FormFieldWrapper>
  );
};

export default FormSwitch;
