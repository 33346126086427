import { Trans, useTranslation } from 'react-i18next';

import {
  SimpleModal,
  ModalTitle,
  ModalButton,
} from '../../../../components/ModalCommon/ModalCommon';
import { StandardText } from '../../../../components/Typography/Typography';
import { useActions } from '../../../../hooks/useActions';
import { updateModalState } from '../../../../store/redux-slices/modals';
import { RENEWAL_NOT_AVAILABLE_MODAL } from './renewalNotAvailableModal.const';

const RenewalNotAvailableModal = () => {
  const { t } = useTranslation();

  const UpdateModalState = useActions(updateModalState);
  return (
    <SimpleModal name={RENEWAL_NOT_AVAILABLE_MODAL}>
      <ModalTitle>{t('headers.renewal-is-not-available')}</ModalTitle>
      <Trans>
        <StandardText>
          <Trans>{t('phrase.membership-is-unavailable-for-renewal')}</Trans>
        </StandardText>
      </Trans>
      <ModalButton
        onClick={() => UpdateModalState(RENEWAL_NOT_AVAILABLE_MODAL)}
      >
        {t(`buttons.ok`)}
      </ModalButton>
    </SimpleModal>
  );
};

export default RenewalNotAvailableModal;
