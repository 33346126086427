import { Outlet } from 'react-router';

import { FullLayoutPage, MiddleLayoutPage } from './Page';

export const FullLayoutRoutePage = () => (
  <FullLayoutPage>
    <Outlet />
  </FullLayoutPage>
);

export const MiddleLayoutRoutePage = () => (
  <MiddleLayoutPage>
    <Outlet />
  </MiddleLayoutPage>
);
