import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { transparentize } from 'polished';
import ReactModal from 'react-modal';

import { THEME } from '../../config/theme';
import { mq } from '../../utils/mediaQueries';
import Button from '../UI/Button/Button';

export const modalOverlayClass = css`
  height: 100%;
  overflow: auto;
  background-color: ${transparentize(0.3, THEME.color.secondary1)};
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;
  padding: 1rem;
`;

export const StyledModal = styled(ReactModal)`
  background: ${THEME.color.white};
  border-radius: 0.5rem;
  min-width: 18rem;
  margin: auto;
  outline: none;
  position: relative;
`;

export const ModalContainer = styled.div`
  align-items: center;
  margin: 1.5rem 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${mq.small(css`
    margin: 2rem 1rem;
  `)}

  ${mq.xSmall(css`
    margin: 2.5rem;
  `)}
`;

export const StyledButton = styled(Button)`
  position: absolute;
  right: 1rem;
  top: 1rem;

  ${mq.xSmall(css`
    right: 1rem;
    top: 1.5rem;
  `)}
`;

export const GlobalStyles = (isOpen: boolean) =>
  css`
    body {
      ${isOpen &&
      css`
        position: fixed;
        overflow: hidden;
        touch-action: none;
        width: 100%;
      `}
    }
  `;
