import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import Spinner from '../../../components/Spinner/Spinner';

import { Membership as MembershipType } from '../../../models/types/Membership';
import InfoComponentMobile from '../../../components/UI/InfoComponent/InfoComponentMobile';

import { NoMembership } from './Membership/Common/NoMembership';
import {
  ExpandableSectionWrapper,
  MembershipSection,
  Root,
  SectionTitle,
  SectionTitleWrapper,
  ToggleButton,
} from './memberships.styles';
import { MembershipsType } from '../../../models/types/MembershipState';
import { Membership } from './Membership/Common/Membership';
import { DOCMembershipsType } from '../../../models/types/DOCMembershipState';
import { CommonMembership } from '../../../models/types/CommonMembership';

type Props = {
  areMembershipsLoading: boolean;
  onFamilyMemberAdded?: () => void;
  onFamilyMemberRemoved?: () => void;
  onUpdateAutoRenewFlag?: (membership: MembershipType) => void;
  memberships: MembershipsType | DOCMembershipsType;
  onRenew?: (membershipId: string) => void;
};

export const Memberships = ({
  memberships: { active, expired },
  areMembershipsLoading,
  onFamilyMemberAdded,
  onFamilyMemberRemoved,
  onUpdateAutoRenewFlag,
  onRenew,
}: Props) => {
  const { t } = useTranslation();
  const [isOpen, setToggle] = useState<boolean>(false);

  const toggleSection = () => setToggle(!isOpen);

  const hasActiveMemberships = !!active.length;
  const hasExpiredMemberships = !!expired.length;

  if (areMembershipsLoading) {
    return (
      <Root>
        <Spinner size="30%" />
      </Root>
    );
  }

  return (
    <Root>
      <MembershipSection>
        <SectionTitleWrapper>
          <SectionTitle>{t('headers.active-membership-details')}</SectionTitle>
          <InfoComponentMobile tooltip={t('phrase.upgrade-plan-info')} />
        </SectionTitleWrapper>
        {hasActiveMemberships ? (
          active.map((activeMembership: CommonMembership) => (
            <Membership
              key={`${activeMembership.membershipId}`}
              membership={activeMembership}
              onFamilyMemberAdded={onFamilyMemberAdded}
              onFamilyMemberRemoved={onFamilyMemberRemoved}
              onUpdateAutoRenewFlag={onUpdateAutoRenewFlag}
              onRenew={onRenew}
            />
          ))
        ) : (
          <NoMembership />
        )}
      </MembershipSection>

      {hasExpiredMemberships && (
        <MembershipSection>
          <SectionTitleWrapper>
            <SectionTitle>{t('headers.expired-memberships')} </SectionTitle>
            <ToggleButton isOpen={isOpen} onClick={toggleSection}>
              {isOpen ? t('buttons.hide') : t('buttons.show')}
            </ToggleButton>
          </SectionTitleWrapper>
          <ExpandableSectionWrapper isOpen={isOpen}>
            {expired.map((expiredMembership: MembershipType) => (
              <Membership
                key={`${expiredMembership.membershipId}`}
                membership={expiredMembership}
                onRenew={onRenew}
              />
            ))}
          </ExpandableSectionWrapper>
        </MembershipSection>
      )}
    </Root>
  );
};
