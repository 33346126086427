import { Trans } from 'react-i18next';
import { Outlet } from 'react-router';

import config from '../../config/config';

import { useBanners } from './banners.hooks';
import {
  BannerText,
  Wrapper,
  TopBannerContentContainer,
  BottomBannerContentContainer,
  TextLinkButton,
} from './banners.styles';

const Banners = () => {
  const { bannerInfo, isAuth } = useBanners();

  if (isAuth || !bannerInfo) {
    return <Outlet />;
  }

  return (
    <Wrapper>
      {!!bannerInfo?.promoBannerText && (
        <TopBannerContentContainer>
          <BannerText>{bannerInfo?.promoBannerText}</BannerText>
        </TopBannerContentContainer>
      )}
      <Outlet />
      {!!bannerInfo?.promoBannerFooterText && (
        <BottomBannerContentContainer>
          <Trans
            components={{
              privacyPolicyLink: (
                <TextLinkButton
                  onClick={() =>
                    window.open(config.privacyPolicyLink, '_blank')
                  }
                />
              ),
            }}
          >
            {bannerInfo.promoBannerFooterText}
          </Trans>
        </BottomBannerContentContainer>
      )}
    </Wrapper>
  );
};

export default Banners;
