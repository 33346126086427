import { useEffect } from 'react';

export const useOnClickOutside = (
  ref: any | any[],
  handler: (e: MouseEvent) => void,
) => {
  useEffect(() => {
    const listener = (event: MouseEvent) => {
      if (Array.isArray(ref)) {
        if (
          ref.some(
            (refNode) =>
              !refNode.current || refNode.current.contains(event.target),
          )
        ) {
          return;
        }
      } else {
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
      }

      handler(event);
    };
    document.addEventListener('mousedown', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
    };
  }, [ref, handler]);
};
