import { createSliceSaga, SagaType } from 'redux-toolkit-saga';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'typed-redux-saga/macro';
import { AxiosResponse } from 'axios';

import { setProfile } from '../../redux-slices/profile';

import { IProfile } from '../../../models/interfaces/IProfile';

import { updateProcessState } from '../../redux-slices/processes';
import { Log } from '../../../utils/logger';
import { PROFILE_LOAD_PROCESSING } from '../../redux-slices/processes/constants';

import { ILoadProfilePayload } from './models';
import { getProfile } from './api';

const profileSlice = createSliceSaga({
  caseSagas: {
    *loadProfile({
      payload: { success, error, cancellationToken },
    }: PayloadAction<ILoadProfilePayload>) {
      try {
        yield put(updateProcessState(PROFILE_LOAD_PROCESSING));
        const response: AxiosResponse<IProfile> = yield* call(
          getProfile,
          cancellationToken,
        );
        yield put(setProfile(response.data));
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(PROFILE_LOAD_PROCESSING));
      }
    },
  },
  name: 'profile-saga',
  sagaType: SagaType.TakeLatest,
});

export default profileSlice.saga;
export const { loadProfile } = profileSlice.actions;
export const { actions } = profileSlice;
