import { useTranslation } from 'react-i18next';

import { useMemo } from 'react';

import {
  SectionTitle,
  SectionWrapper,
} from '../../../../../../../components/Form/Form';

import { CustomForm } from '../../../../../../../models/types/CustomForm';
import { usePaymentSection } from './paymentSection.hooks';
import {
  Description,
  StyledCreditCardDetailsForm,
} from './paymentSection.styles';
import PaymentGroup from './PaymentGroup';

const PaymentSection = ({ className, disabled }: CustomForm) => {
  const { t } = useTranslation();
  const {
    currentPaymentType,
    onChangePaymentTypeHandler,
    onlyCardDetailsForm,
  } = usePaymentSection();

  const content = useMemo(
    () =>
      onlyCardDetailsForm ? (
        <>
          <Description>{t('phrase.add-credit-card-details')}</Description>
          <StyledCreditCardDetailsForm disabled={disabled} />
        </>
      ) : (
        <PaymentGroup
          disabled={disabled}
          checked={currentPaymentType}
          onChange={onChangePaymentTypeHandler}
        />
      ),
    [
      currentPaymentType,
      disabled,
      onChangePaymentTypeHandler,
      onlyCardDetailsForm,
      t,
    ],
  );

  return (
    <SectionWrapper className={className}>
      <SectionTitle>{t('headers.payment-details')}</SectionTitle>
      {content}
    </SectionWrapper>
  );
};

export default PaymentSection;
