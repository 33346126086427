import { createSelector } from 'reselect';

import { sortDateAsc, sortDateDesc } from '../../../../utils/arrayHelpers';
import { MembershipsType } from '../../../../models/types/MembershipState';
import { aaMembershipsSelector } from '../airAmbulance/selectors';
import { docMembershipsSelector } from '../dutyOfCare/selectors';
import { keMembershipsSelector } from '../kidnapAndExtortion/selectors';
import { srMembershipsSelector } from '../securityResponse/selectors';
import { DOCMembershipsType } from '../../../../models/types/DOCMembershipState';

export const membershipsSelector = createSelector(
  aaMembershipsSelector,
  keMembershipsSelector,
  srMembershipsSelector,
  docMembershipsSelector,
  (
    aaMemberships: MembershipsType,
    keMemberships: MembershipsType,
    srMemberships: MembershipsType,
    docMemberships: DOCMembershipsType,
  ): MembershipsType | DOCMembershipsType => {
    const activeMembers = [
      ...aaMemberships.active,
      ...keMemberships.active,
      ...srMemberships.active,
      ...docMemberships.active,
    ].sort((a, b) => sortDateAsc(a.expirationDate, b.expirationDate));

    const expiredMembers = [
      ...aaMemberships.expired,
      ...keMemberships.expired,
      ...srMemberships.expired,
      ...docMemberships.expired,
    ].sort((a, b) => sortDateDesc(a.expirationDate, b.expirationDate));

    return {
      active: activeMembers,
      expired: expiredMembers,
    };
  },
);
