import { useCallback } from 'react';

import { Membership } from '../../../../../../models/types/Membership';
import { MenuActions } from './actionsContainer.models';

type UseActionsContainerProps = {
  onRenew?: (membershipId: string) => void;
};

export const useActionsContainer = ({ onRenew }: UseActionsContainerProps) => {
  const handleMenuOptionClick = useCallback(
    (option: MenuActions | string, membership: Membership) => {
      switch (option) {
        case MenuActions.Renew:
          onRenew?.(membership?.membershipId);
          break;
      }
    },
    [onRenew],
  );

  return {
    handleMenuOptionClick,
  };
};
