import { Trans } from 'react-i18next';

import {
  StandardText,
  StandardTextBold,
} from '../../../../../../../../../components/Typography/Typography';
import { TextContainer, Wrapper } from './pricingOverviewItem.styles';
type PricingOverviewItemProps = {
  label?: string;
  description?: string;
  value?: number;
};

const PricingOverviewItem = ({
  label,
  value,
  description,
}: PricingOverviewItemProps) => (
  <Wrapper>
    <TextContainer>
      <StandardTextBold>{label}</StandardTextBold>
      {description && (
        <Trans>
          <StandardText>{description}</StandardText>
        </Trans>
      )}
    </TextContainer>
    <StandardText>{`${value < 0 ? '- ' : ''}$${Math.abs(value)}`}</StandardText>
  </Wrapper>
);

export default PricingOverviewItem;
