import { css, SerializedStyles } from '@emotion/react';
import { ReactNode } from 'react';

import Responsive, { MediaQueryTypes, useMediaQuery } from 'react-responsive';

import { Breakpoint } from '../models/types/Breakpoints';

type MediaQueries = Record<
  Breakpoint,
  (cssHelper: SerializedStyles) => SerializedStyles
>;

export const breakpoints: Record<Breakpoint, number> = {
  small: 375,
  xSmall: 575.5,
  medium: 768,
  mobileMax: 991,
  large: 992,
  xLarge: 1200,
  xxLarge: 1280,
  xxxLarge: 1400,
};

export const mq = Object.keys(breakpoints).reduce<MediaQueries>(
  (acc, label) => {
    const prefix = 'min-width:';
    const suffix = 'px';

    acc[label] = (cls: any) =>
      css`
        @media (${prefix + breakpoints[label] + suffix}) {
          ${cls};
        }
      `;

    return acc;
  },
  {} as MediaQueries,
);

type ResponsiveType = MediaQueryTypes & {
  children?: ReactNode;
};

export const Mobile = (props: ResponsiveType) => (
  <Responsive {...props} maxWidth={breakpoints.xSmall} />
);

export const MobileMax = (props: ResponsiveType) => (
  <Responsive {...props} maxWidth={breakpoints.mobileMax} />
);

export const NotMobile = (props: ResponsiveType) => (
  <Responsive {...props} minWidth={breakpoints.xSmall} />
);

export const Tablet = (props: ResponsiveType) => (
  <Responsive {...props} maxWidth={breakpoints.medium} />
);

export const Desktop = (props: ResponsiveType) => (
  <Responsive {...props} minWidth={breakpoints.large} />
);

export const DesktopLarge = (props: ResponsiveType) => (
  <Responsive {...props} minWidth={breakpoints.xLarge} />
);

export const useMobileMediaQuery = () =>
  useMediaQuery({ query: `(max-width: ${breakpoints.xSmall}px)` });
export const useTabletMediaQuery = () =>
  useMediaQuery({ query: `(max-width: ${breakpoints.mobileMax}px)` });
export const useDesktopMediaQuery = () =>
  useMediaQuery({ query: `(max-width: ${breakpoints.large}px)` });
