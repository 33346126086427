/* eslint-disable no-undefined */
import { merge } from 'lodash';

export const includeRemovedProperties = <T>(
  initialValues: object,
  values: T,
): T => {
  if (!initialValues) return values;
  return merge(prepareInitValuesWithEmptyFields(initialValues, values), values);
};

const prepareInitValuesWithEmptyFields = <T>(
  initialValues: object,
  values: T,
) =>
  Object.keys(initialValues).reduce((currentObject, key) => {
    currentObject[key] = prepareInitValue(values[key], key, initialValues);
    return currentObject;
  }, {});

const prepareInitValue = (value: any, key: string, initialValues: object) => {
  if (isDate(value) || isArrayWithProperty(value)) {
    return value;
  }
  if (isArrayWithObject(value)) {
    return value.map((propertyValue: string, index: number) =>
      includeRemovedProperties(initialValues[key][index], propertyValue),
    );
  }
  if (isObject(value)) {
    return includeRemovedProperties(initialValues[key], value);
  }

  return value === undefined ? initialValues[key] : value;
};

const isDate = (value: any) => value instanceof Date;

const isArrayWithProperty = (value: any) =>
  Array.isArray(value) && typeof value[0] !== 'object';

const isArrayWithObject = (value: any) =>
  Array.isArray(value) && typeof value[0] === 'object';

const isObject = (value: any) => typeof value === 'object' && value !== null;
