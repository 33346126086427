import { AnySchema } from 'ajv';

import { FieldErrorMessage } from '../../../../../../../models/interfaces/FieldErrorMessage';
import { IMember } from '../../../../../../../models/interfaces/Member';
import { validateFormData } from '../../../../../../../utils/validations/validateFormData';
import { MemberDetailsFields } from './memberDetailsForm.models';

export const memberDetailsFormSchema: AnySchema | any = {
  type: 'object',
  properties: {
    [MemberDetailsFields.DateOfBirth]: {
      type: 'string',
      format: 'date',
    },
    [MemberDetailsFields.PhoneNumber]: {
      type: 'string',
      format: 'phone',
    },
  },
  required: [
    MemberDetailsFields.FirstName,
    MemberDetailsFields.LastName,
    MemberDetailsFields.DateOfBirth,
  ],
};

export const validateMemberDetailsForm = (
  data: IMember,
  additionalErrors?: FieldErrorMessage[],
) => validateFormData(data, additionalErrors, memberDetailsFormSchema);
