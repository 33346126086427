import { useTranslation } from 'react-i18next';

import { IRetailMembershipOrderSummaryModel } from '../../../../../../../../models/interfaces/RetailMembershipOrderSummaryModel';
import { DescriptionText, Wrapper } from './orderOverview.styles';
import OverviewProperty from './OverviewProperty/OverviewProperty';
import OverviewTerm from './OverviewTerm/OverviewTerm';

type OrderOverviewProps = {
  data: IRetailMembershipOrderSummaryModel;
};
const OrderOverview = ({ data }: OrderOverviewProps) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <OverviewProperty
        title={t('properties.plan')}
        value={data?.membershipPlanTypeName}
      >
        <DescriptionText>{data?.membershipPlanTypeDescription}</DescriptionText>
      </OverviewProperty>
      <OverviewProperty
        title={t('properties.type')}
        value={data?.membershipGroupName}
      >
        <DescriptionText>{data?.membershipGroupDescription}</DescriptionText>
      </OverviewProperty>
      <OverviewProperty title={t('properties.term')} value={data?.term}>
        <OverviewTerm
          startDate={data?.effectiveDate}
          expirationDate={data?.expirationDate}
          autoRenewDate={data?.renewalDate}
        />
      </OverviewProperty>
      <OverviewProperty title={t('properties.accountEmailAddress')}>
        <DescriptionText>{data?.accountEmailAddress}</DescriptionText>
      </OverviewProperty>
    </Wrapper>
  );
};

export default OrderOverview;
