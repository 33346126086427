import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { CustomForm } from '../../../../../../../models/types/CustomForm';
import { ReactComponent as ErrorIcon } from '../../../../../../../assets/icons/error-icon2.svg';
import {
  CardNumber,
  ExpiredCardText,
  Wrapper,
  CardWrapper,
} from './paymentUseExistingCardForm.styles';
import { firstCreditCardSelector } from '../../../../../../../store/redux-slices/payment/selectors';
import CreditCardType from '../../../../../../../components/CreditCard/CreditCardType/CreditCardType';

const PaymentExistingCardForm = ({ disabled, className }: CustomForm) => {
  const { t } = useTranslation();
  const creditCard = useSelector(firstCreditCardSelector);

  if (!creditCard) {
    return null;
  }

  return (
    <Wrapper className={className}>
      <CardWrapper>
        <CreditCardType creditCardType={creditCard?.cardType} />
        <CardNumber disabled={disabled || creditCard.isExpired}>
          **** **** **** {creditCard.cardLastDigits}
        </CardNumber>
      </CardWrapper>
      {creditCard.isExpired && (
        <ExpiredCardText>
          <ErrorIcon />
          {t('phrase.this-credit-card-is-expired')}
        </ExpiredCardText>
      )}
    </Wrapper>
  );
};

export default PaymentExistingCardForm;
