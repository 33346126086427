import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

import { mq } from '../../utils/mediaQueries';

import { ModalTitle } from '../ModalCommon/ModalCommon';
import Button from '../UI/Button/Button';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;
`;

export const Title = styled(ModalTitle)`
  margin-bottom: 0;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: flex-end;
  margin-top: 2.5rem;
  ${mq.xSmall(css`
    gap: 1.5rem;
  `)}
`;

export const CancelButton = styled(Button)`
  width: 100%;
  ${mq.xSmall(css`
    width: auto;
  `)}
`;

export const SaveButton = styled(Button)`
  width: 100%;
  ${mq.xSmall(css`
    width: 13.5rem;
  `)}
`;
