import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

import { mq } from '../../utils/mediaQueries';

import { StandardText } from '../Typography/Typography';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const Name = styled(StandardText)`
  color: ${({ theme }) => theme.color.white};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 12.5rem;
  ${mq.xSmall(css`
    max-width: 20rem;
  `)}
`;

export const ShortName = styled.div`
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 2rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.color.hover};
  color: ${({ theme }) => theme.color.secondary1};
  font: normal bold 1rem/1rem ${({ theme }) => theme.font.gotham};
  border: 1px solid ${({ theme }) => theme.color.secondary2};
  cursor: pointer;

  ${({ theme }) =>
    mq.xSmall(css`
      font: normal bold 1.875rem/1.875rem ${theme.font.gotham};
      height: 4rem;
      width: 4rem;
    `)}
`;
