import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

import { Alignment } from '../../../models/types/Alignment';

type StyledInputType = {
  disabled?: boolean;
  readOnly?: boolean;
  alignment?: Alignment;
};

export const StyledInput = styled.input<StyledInputType>`
  font: normal normal 1rem/1.5rem ${({ theme }) => theme.font.gotham};
  color: ${({ theme }) => theme.color.black};
  padding: 1rem;
  border: 0.063rem solid ${({ theme }) => theme.color.input_border};
  border-radius: 0.25rem;
  box-sizing: border-box;
  z-index: 1;
  background: none;
  height: 100%;
  width: 100%;
  opacity: 1;
  -webkit-text-fill-color: ${({ theme }) => theme.color.black};

  &:focus {
    outline: none;
  }

  ${({ theme, disabled }) =>
    disabled &&
    css`
      color: ${theme.color.dark2};
      -webkit-text-fill-color: ${theme.color.dark2};
    `}

  ${({ readOnly }) =>
    readOnly &&
    css`
      padding: 0;
    `}

    ${({ alignment }) =>
    alignment &&
    css`
      text-align: ${alignment};
    `}
`;

export const InputContainer = styled.div``;
