import FormErrorProvider from '../../../../components/FormErrorProvider/FormErrorProvider';
import { ModalForm } from '../../../../components/ModalForm/ModalForm';
import { EDIT_MEMBER_DETAILS_MODAL } from './editMemberDetailsModal.consts';
import { useEditMemberDetailsModal } from './editMemberDetailsModal.hooks';
import EditMemberDetailsModalForm from './EditMemberDetailsModalForm/EditMemberDetailsModalForm';

type EditMemberDetailsModalProps = {
  onEditedMember?: () => void;
};

const EditMemberDetailsModal = ({
  onEditedMember,
}: EditMemberDetailsModalProps) => {
  const { onModalShowHandler, dataLoading } = useEditMemberDetailsModal();

  return (
    <FormErrorProvider>
      <ModalForm
        onModalShow={onModalShowHandler}
        name={EDIT_MEMBER_DETAILS_MODAL}
        isLoading={dataLoading}
      >
        <EditMemberDetailsModalForm onEditedMember={onEditedMember} />
      </ModalForm>
    </FormErrorProvider>
  );
};

export default EditMemberDetailsModal;
