import { MemberType } from '../../../../models/enums/MemberType';
import { ProductType } from '../../../../models/enums/ProductType';
import { analyzeAAMemberAge } from '../airAmbulance/api';
import { analyzeKEMemberAge } from '../kidnapAndExtortion/api';
import { analyzeSRMemberAge } from '../securityResponse/api';

export const analyzeMemberAge = (
  membershipPlanId: string,
  memberType: MemberType,
  dateOfBirth: string,
  cancelToken?: AbortSignal,
  renewedMembershipId?: string,
  productType?: ProductType,
) => {
  switch (productType) {
    case ProductType.KidnapAndExtortion:
      return analyzeKEMemberAge(
        membershipPlanId,
        memberType,
        dateOfBirth,
        cancelToken,
        renewedMembershipId,
      );
    case ProductType.SecurityResponse:
      return analyzeSRMemberAge(
        membershipPlanId,
        memberType,
        dateOfBirth,
        cancelToken,
        renewedMembershipId,
      );
    default:
      return analyzeAAMemberAge(
        membershipPlanId,
        memberType,
        dateOfBirth,
        cancelToken,
        renewedMembershipId,
      );
  }
};
