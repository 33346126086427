import { createSelector } from 'reselect';

import { RootState } from '../..';
import { IAAPartnershipState } from '.';
import { IPartnershipInfoModel } from '../../../../models/interfaces/PartnershipModel';

export const aaPartnershipSelector = (state: RootState) => state.aaPartnership;

export const partnershipInfoSelector = createSelector(
  aaPartnershipSelector,
  (partnershipState: IAAPartnershipState): IPartnershipInfoModel =>
    partnershipState.partnershipInfo,
);

export const partnershipLogoSelector = createSelector(
  partnershipInfoSelector,
  (partnershipInfo: IPartnershipInfoModel): string =>
    partnershipInfo?.partnerLogo,
);
