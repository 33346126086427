import { createSelector } from 'reselect';

import { RootState } from '../../index';
import { IDOCMembershipState } from '.';
import { DOCMembershipsType } from '../../../../models/types/DOCMembershipState';

export const docMembershipSelector = (state: RootState) => state.docMembership;

export const docMembershipsSelector = createSelector(
  docMembershipSelector,
  (membershipState: IDOCMembershipState): DOCMembershipsType =>
    membershipState.docMemberships,
);
