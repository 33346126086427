import { CustomForm } from '../../../models/types/CustomForm';
import { Wrapper } from './creditCardDetailsForm.styles';
import StripeCardDetailsForm from '../../Stripe/StripeCardDetailsForm';

const CreditCardDetailsForm = ({ disabled, className }: CustomForm) => (
  <Wrapper className={className}>
    <StripeCardDetailsForm disabled={disabled} />
  </Wrapper>
);

export default CreditCardDetailsForm;
