import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

import { mq } from '../../../../../../utils/mediaQueries';

export const TitleSection = styled.div`
  align-items: base;
  color: ${({ theme }) => theme.color.dark2};
  display: flex;
  font: normal bold 0.875rem/1.25rem ${({ theme }) => theme.font.gotham};
  flex-direction: column;
  gap: 0.5rem;
  margin: 0;
  padding: 1rem 0;

  ${mq.medium(css`
    white-space: nowrap;
  `)}
`;

export const TitleContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: column;

  ${mq.xSmall(css`
    flex-direction: row;
  `)}
`;

export const Title = styled.h3`
  font: normal bold 1.25rem/1.25rem ${({ theme }) => theme.font.gotham};
  color: ${({ theme }) => theme.color.black};
  word-wrap: break-word;
`;

export const OrganizationName = styled(Title)`
  color: ${({ theme }) => theme.color.dark1};
`;

export const OrganizationConnector = styled(Title)`
  color: ${({ theme }) => theme.color.dark1};
  display: none;
  ${mq.xSmall(css`
    display: block;
  `)}
`;
