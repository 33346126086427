import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

import Navigation from '../Navigation/Navigation';

export const StyledNavigation = styled(Navigation)`
  flex-direction: column;
  padding: 4rem 3rem;
  align-items: center;
`;

export const GlobalStyles = (isOpen: boolean) =>
  css`
    body {
      overflow: ${isOpen ? 'hidden' : 'visible'};
    }
  `;
