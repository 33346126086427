import { useTranslation } from 'react-i18next';

import config from 'config/config';

import {
  SimpleModal,
  ModalTitle,
  ModalButton,
} from '../../../../components/ModalCommon/ModalCommon';

import { StandardText } from '../../../../components/Typography/Typography';
import { useActions } from '../../../../hooks/useActions';
import { useModalDetails } from '../../../../hooks/useModalDetails';
import { ICreateMembershipPayment } from '../../../../models/interfaces/ICreateMembershipPayment';
import { updateModalState } from '../../../../store/redux-slices/modals';
import { Icon } from './paymentFailureModal.styles';

export const PAYMENT_FAILURE_MODAL_NAME = 'PAYMENT_FAILURE_MODAL_NAME';

type PaymentFailureModalProps = {
  onRetryPaymentClick?: (
    paymentId: string,
    values: ICreateMembershipPayment,
  ) => void;
};

const PaymentFailureModal = ({
  onRetryPaymentClick,
}: PaymentFailureModalProps) => {
  const { t } = useTranslation();
  const UpdateModalState = useActions(updateModalState);
  const details = useModalDetails(PAYMENT_FAILURE_MODAL_NAME);

  const onRetryPaymentClickHandler = () => {
    onRetryPaymentClick &&
      onRetryPaymentClick(details?.paymentId, details?.values);
    UpdateModalState(PAYMENT_FAILURE_MODAL_NAME);
  };

  return (
    <SimpleModal name={PAYMENT_FAILURE_MODAL_NAME}>
      <ModalTitle>{t('headers.payment-failure')}</ModalTitle>
      <Icon />
      <StandardText>
        {t('phrase.your-payment-want-not-successfully', {
          number: config.contact.phone,
        })}
      </StandardText>
      <ModalButton onClick={onRetryPaymentClickHandler}>
        {t(`buttons.retry-payment`)}
      </ModalButton>
    </SimpleModal>
  );
};

export default PaymentFailureModal;
