import styled from '@emotion/styled';

import copyIcon from '../../../../../../assets/icons/copy-icon.svg';
import Button from '../../../../../../components/UI/Button/Button';

export const CopyToClipboard = styled(Button)`
  align-items: center;
  background: url(${copyIcon});
  background-repeat: no-repeat;
  background-position: center left;
  display: flex;
  font-weight: normal;
  gap: 1rem;
  height: 1.25rem;
  margin: 0 0 0 0.25rem;
  width: 1.25rem;
`;
CopyToClipboard.defaultProps = {
  styleType: 'link',
};

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;
export const PromoCodeWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;
