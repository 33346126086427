import { createSelector } from 'reselect';

import { IMembershipPromotionState } from '.';
import { IMembershipPromotion } from '../../../models/interfaces/MembershipPromotion';
import { RootState } from '../index';

export const membershipPromotionSelector = (state: RootState) =>
  state.membershipPromotion;

export const bannerInfoSelector = createSelector(
  membershipPromotionSelector,
  (membershipPromotionState: IMembershipPromotionState): IMembershipPromotion =>
    membershipPromotionState.bannerInfo,
);
