import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

import { mq } from '../../utils/mediaQueries';

type WrapperType = {
  isOpen?: boolean;
};

export const Sidebar = styled.div<WrapperType>`
  display: flex;
  flex-direction: column;
  height: 100vh;
  z-index: 999;

  background: ${({ theme }) => theme.color.primary};
  position: absolute;
  top: 3rem;
  left: 0;

  transition: transform 0.3s ease-in-out;
  width: 100%;
  ${mq.xSmall(css`
    width: 20rem;
    top: 7rem;
  `)}

  transform: ${({ isOpen }) =>
    isOpen ? css`translateX(0)` : css`translateX(-100%)`};
`;
