import { useIsAuthenticated } from '@azure/msal-react';

import { Mobile, NotMobile } from '../../utils/mediaQueries';
import BurgerMenuSidebar from '../BurgerMenuSidebar/BurgerMenuSidebar';
import HeaderLoggedUser from '../HeaderLoggedUser/HeaderLoggedUser';
import Navigation from '../Navigation/Navigation';
import { Logo } from '../UI/Logo/Logo';
import { RightContainer, Wrapper, MainWrapper } from './pageHeader.styles';

const PageHeader = () => {
  const isAuth = useIsAuthenticated();

  return (
    <MainWrapper>
      <Wrapper>
        <Logo />
        <RightContainer>
          {isAuth && <HeaderLoggedUser />}
          <Mobile>
            <BurgerMenuSidebar />
          </Mobile>
          <NotMobile>
            <Navigation />
          </NotMobile>
        </RightContainer>
      </Wrapper>
    </MainWrapper>
  );
};

export default PageHeader;
