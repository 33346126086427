import { IAddress, IAddressLines } from '../models/interfaces/Address';

export const mapAddress = (address: IAddress): IAddressLines => {
  if (!address) {
    return null;
  }

  const {
    addressLine1 = '',
    addressLine2 = '',
    addressLine3 = '',
    zipCode = '',
    city = '',
    stateRegion = '',
    country = '',
  } = address;

  return {
    line1: `${addressLine1} ${addressLine2} ${addressLine3}`,
    line2: `${city} ${zipCode}, ${stateRegion}`,
    line3: `${country}`,
  };
};
