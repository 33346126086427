/* eslint-disable no-underscore-dangle */
export type IHubSpotPushParams = [(string | object)?];

declare global {
  interface Window {
    _hsq: { push: (params: IHubSpotPushParams) => void };
  }
}

export const useHubSpotTracking = () => {
  const hsq: any =
    typeof window !== 'undefined' && window._hsq ? window._hsq : [];

  const setTrackPageView = () => {
    hsq.push(['trackPageView']);
  };

  const setPathPage = (path: string): void => {
    hsq.push(['setPath', path]);
    setTrackPageView();
  };

  return { setPathPage };
};
