import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

import Checkbox from '../../Checkbox/Checkbox';
import { VisibleCheckbox } from '../../Checkbox/checkbox.styles';

type StyledCheckboxProps = {
  isError?: boolean;
};

export const StyledCheckbox = styled(Checkbox)<StyledCheckboxProps>`
  ${({ isError, theme }) =>
    isError &&
    css`
      ${VisibleCheckbox} {
        border-color: ${theme.color.error};
      }
    `}
`;
