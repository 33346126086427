import { AnySchema } from 'ajv';

import { FieldErrorMessage } from '../../../../../models/interfaces/FieldErrorMessage';
import { ICreateMembershipPlan } from '../../../../../models/interfaces/ICreateMembershipPlan';
import { validateFormData } from '../../../../../utils/validations/validateFormData';
import { ContactInformationFields } from './Forms/ContactInformationForm/contactInformation.models';

const schema: AnySchema | any = {
  type: 'object',
  properties: {
    [ContactInformationFields.EmailAddress]: {
      type: 'string',
      format: 'email',
    },
  },
  if: {
    properties: {
      [ContactInformationFields.IsAuth]: {
        enum: [false],
      },
    },
  },
  then: {
    required: [ContactInformationFields.EmailAddress],
  },
};

export const validateAddMembershipPlanForm = (
  data: ICreateMembershipPlan,
  additionalErrors?: FieldErrorMessage[],
) => validateFormData(data, additionalErrors, schema);
