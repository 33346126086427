import { useIsAuthenticated } from '@azure/msal-react';
import { t } from 'i18next';
import { useEffect, useMemo } from 'react';
import { useAlert } from 'react-alert';
import { FieldRenderProps, useField } from 'react-final-form';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';

import { translatePlanPeriodPlanFromType } from 'utils/translatePlanPeriodPlan';

import { useActions } from '../../../../../../../hooks/useActions';
import { useProcessing } from '../../../../../../../hooks/useProcessing';
import { useRouteParams } from '../../../../../../../hooks/useRouteParams';
import { useCancellationToken } from '../../../../../../../hooks/useTokenCancellation';
import { clearAAMembershipPlans } from '../../../../../../../store/redux-slices/plans/airAmbulance';
import { aaMembershipPlansSelector } from '../../../../../../../store/redux-slices/plans/airAmbulance/selectors';
import { MEMBERSHIP_PLANS_LOAD_PROCESSING } from '../../../../../../../store/redux-slices/processes/constants';
import { loadAAMembershipPlans } from '../../../../../../../store/saga-slices/plans/airAmbulance';
import { ILoadMembershipPlansPayload } from '../../../../../../../store/saga-slices/plans/airAmbulance/models';
import { findMembershipPlanInfoById } from '../../../../../../../utils/membershipPlanHelpers';
import { PromotionCodeFields } from '../PromotionCodeForm/promotionCode.models';
import { MembershipPlanFields } from './membershipPlanForm.models';
import {
  effectiveDateMembershipSuggestionSelector,
  selectedMembershipPlanPeriodSuggestionSelector,
} from '../../../../../../../store/redux-slices/membership/airAmbulance/selectors';

export const useMembershipPlanForm = () => {
  const LoadMembershipPlans = useActions(loadAAMembershipPlans);
  const ClearMembershipPlans = useActions(clearAAMembershipPlans);
  const isAuth = useIsAuthenticated();
  const alert = useAlert();

  const { membershipPlanId } = useRouteParams();

  const membershipPlans = useSelector(aaMembershipPlansSelector);
  const membersPlansLoading = useProcessing(MEMBERSHIP_PLANS_LOAD_PROCESSING);
  const effectiveDateSuggestion = useSelector(
    effectiveDateMembershipSuggestionSelector,
  );
  const selectedMembershipPlanPeriodType = useSelector(
    selectedMembershipPlanPeriodSuggestionSelector,
  );

  const cancellationToken = useCancellationToken();

  const {
    input: { value: promoCode, onChange: onChangePromoCode },
  }: FieldRenderProps<string> = useField(PromotionCodeFields.PromoCode);

  const {
    input: { onChange: onChangeMembershipPlan, value: membershipPlan },
  }: FieldRenderProps<string> = useField(MembershipPlanFields.MembershipPlan);

  useEffect(() => {
    const canLoadMembershipPlanFromRouteParams =
      !membershipPlan && membershipPlanId && membershipPlans && !isAuth;

    if (canLoadMembershipPlanFromRouteParams) {
      onChangeMembershipPlan(
        findMembershipPlanInfoById(membershipPlans, membershipPlanId),
      );
    }
  }, [
    isAuth,
    membershipPlan,
    membershipPlanId,
    membershipPlans,
    onChangeMembershipPlan,
  ]);

  useEffect(() => {
    const payload: ILoadMembershipPlansPayload = {
      promoCode,
      effectiveDate: effectiveDateSuggestion,
      cancellationToken,
      success: (isPromoCodeApplied: boolean) => {
        if (promoCode && !isPromoCodeApplied) {
          alert.error(
            <Trans>
              {`${t('phrase.promo-code-not-found', { value: promoCode })}`}
            </Trans>,
          );
          onChangePromoCode('');
        }
      },
    };

    LoadMembershipPlans(payload);
  }, [
    LoadMembershipPlans,
    cancellationToken,
    promoCode,
    onChangePromoCode,
    alert,
    effectiveDateSuggestion,
  ]);

  useEffect(() => () => ClearMembershipPlans(), [ClearMembershipPlans]);

  const isPlanGroupUnavailable = useMemo(
    () => membershipPlans?.groups?.some((group) => !group.isAvailable),
    [membershipPlans?.groups],
  );

  return {
    membershipPlans,
    membersPlansLoading,
    isPlanGroupUnavailable,
    selectedMembershipPlanPeriod: translatePlanPeriodPlanFromType(
      selectedMembershipPlanPeriodType,
    ),
  };
};
