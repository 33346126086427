import { useIsAuthenticated } from '@azure/msal-react';
import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  useApiResponseError,
  useFormErrorsActions,
} from '../../../../../components/FormErrorProvider/FormErrorProvider.hooks';
import { AddressInformationsInitState } from '../../../../../components/Forms/AddressInformationsForm/addressInformations.models';
import { useActions } from '../../../../../hooks/useActions';
import { useProcessing } from '../../../../../hooks/useProcessing';
import { useRouteParams } from '../../../../../hooks/useRouteParams';
import { ICreateMembershipMemberDetails } from '../../../../../models/interfaces/ICreateMembershipMemberDetails';
import { primaryMemberSelector } from '../../../../../store/redux-slices/members/selectors';
import {
  createMembershipMemberDetailsSelector,
  selectedMembershipPlanSelector,
} from '../../../../../store/redux-slices/membership/airAmbulance/selectors';
import { updateModalState } from '../../../../../store/redux-slices/modals';
import {
  FAMILY_MEMBERS_LOAD_PROCESSING,
  MEMBERSHIP_MEMBER_DETAILS_CREATING_PROCESSING,
  PRIMARY_MEMBER_LOAD_PROCESSING,
} from '../../../../../store/redux-slices/processes/constants';
import {
  loadFamilyMembers,
  loadPrimaryMember,
} from '../../../../../store/saga-slices/members';
import {
  ILoadFamilyMembersPayload,
  ILoadPrimaryMemberPayload,
} from '../../../../../store/saga-slices/members/models';
import { submitCreateMemberShipMemberDetails } from '../../../../../store/saga-slices/membership/airAmbulance';
import { ISubmitCreateMembershipDetailsPayload } from '../../../../../store/saga-slices/membership/airAmbulance/models';
import { includeRemovedProperties } from '../../../../../utils/formInitValuesHelpers';
import { useMobileMediaQuery } from '../../../../../utils/mediaQueries';
import { NO_FAMILY_MEMBERS_CONFIRM_MODAL_NAME } from '../../../Modals/NoFamilyMembersConfirmModal.ts/NoFamilyMembersConfirmModal.ts';
import { AddMembershipMemberDetailsFields } from './addMembershipMemberDetailsForm.models';
import { validateAddMembershipMemberDetailsForm } from './addMembershipMemberDetailsForm.validation';

export const useAddMembershipMemberDetailsForm = () => {
  const isMobile = useMobileMediaQuery();

  const LoadPrimaryMember = useActions(loadPrimaryMember);
  const LoadFamilyMembers = useActions(loadFamilyMembers);
  const SubmitCreateMemberShipMemberDetails = useActions(
    submitCreateMemberShipMemberDetails,
  );
  const UpdateModalState = useActions(updateModalState);

  const { validateErrors } = useFormErrorsActions();
  const { handleResponseError } = useApiResponseError();

  const isAuth = useIsAuthenticated();
  const primaryMemberData = useSelector(primaryMemberSelector);
  const selectedMembershipPlan = useSelector(selectedMembershipPlanSelector);
  const createMembershipMemberDetails = useSelector(
    createMembershipMemberDetailsSelector,
  );

  const memberDetailsCreating = useProcessing(
    MEMBERSHIP_MEMBER_DETAILS_CREATING_PROCESSING,
  );
  const dataLoading = useProcessing([
    PRIMARY_MEMBER_LOAD_PROCESSING,
    FAMILY_MEMBERS_LOAD_PROCESSING,
  ]);

  const { membershipId } = useRouteParams();

  const initState = useMemo(
    () => ({
      ...AddressInformationsInitState,
      ...primaryMemberData,
      ...createMembershipMemberDetails,
      [AddMembershipMemberDetailsFields.PrimaryMember]:
        createMembershipMemberDetails?.primaryMember ||
        primaryMemberData?.primaryMember ||
        {},
    }),
    [createMembershipMemberDetails, primaryMemberData],
  );

  useEffect(() => {
    const payload: ILoadPrimaryMemberPayload = {};
    if (isAuth && !membershipId) {
      LoadPrimaryMember(payload);
    }
  }, [LoadPrimaryMember, isAuth, membershipId]);

  const shouldVisibleFamilyMembers = useMemo(
    () =>
      selectedMembershipPlan.maxDependentMembersCount +
        selectedMembershipPlan.maxSpouseMembersCount >
      0,
    [selectedMembershipPlan],
  );

  useEffect(() => {
    const payload: ILoadFamilyMembersPayload = {
      membershipPlanId: selectedMembershipPlan.planId,
    };

    if (shouldVisibleFamilyMembers && isAuth) {
      LoadFamilyMembers(payload);
    }
  }, [
    LoadFamilyMembers,
    isAuth,
    selectedMembershipPlan.planId,
    shouldVisibleFamilyMembers,
  ]);

  const validateForm = useCallback(
    (data: ICreateMembershipMemberDetails) => {
      const currentData = includeRemovedProperties(
        {
          ...AddressInformationsInitState,
          [AddMembershipMemberDetailsFields.PrimaryMember]: {},
        },
        data,
      );
      return validateAddMembershipMemberDetailsForm(
        currentData,
        validateErrors(currentData),
      );
    },
    [validateErrors],
  );

  const submitHandler = useCallback(
    (values: ICreateMembershipMemberDetails, confirmed?: boolean) => {
      if (
        shouldVisibleFamilyMembers &&
        !confirmed &&
        (!values.secondaryMembers || values.secondaryMembers.length === 0)
      ) {
        UpdateModalState([NO_FAMILY_MEMBERS_CONFIRM_MODAL_NAME, values]);
        return;
      }

      const payload: ISubmitCreateMembershipDetailsPayload = {
        data: { ...values, renewedMembershipId: membershipId },
        error: (err: any) => {
          handleResponseError(err, values);
        },
      };

      SubmitCreateMemberShipMemberDetails(payload);
    },
    [
      SubmitCreateMemberShipMemberDetails,
      UpdateModalState,
      handleResponseError,
      membershipId,
      shouldVisibleFamilyMembers,
    ],
  );

  return {
    isMobile,
    validateForm,
    initState,
    submitHandler,
    shouldVisibleFamilyMembers,
    maxDependentMembersCount: selectedMembershipPlan.maxDependentMembersCount,
    maxSpouseMembersCount: selectedMembershipPlan.maxSpouseMembersCount,
    memberDetailsCreating,
    disabledPrimaryMemberForm:
      !!primaryMemberData?.primaryMember?.personId || !!membershipId,
    dataLoading,
    isAuth,
  };
};
