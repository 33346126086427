import i18next from '../../../../i18n/i18n';
import { RoutePath } from '../../../../models/enums/RoutePath';
import { Wizard } from '../models';

export enum WizardStepsCn {
  PlanPage = 'membership-plan-page',
  MemberDetailsPage = 'membership-member-details-page',
  PaymentPage = 'membership-payment-page',
}

export const ADD_MEMBERSHIP_WIZARD_NAME = 'ADD_MEMBERSHIP_WIZARD_NAME';

export const addMembershipWizard: Wizard = {
  steps: [
    {
      order: 0,
      caption: i18next.t('wizard.add-membership.plan-page'),
      canonicalName: WizardStepsCn.PlanPage,
      url: RoutePath.AddMembershipPlan,
      isValid: false,
      isAvailable: true,
      isAuth: false,
    },
    {
      order: 1,
      caption: i18next.t('wizard.add-membership.member-details-page'),
      canonicalName: WizardStepsCn.MemberDetailsPage,
      url: RoutePath.AddMembershipMemberDetails,
      isValid: false,
      isAvailable: true,
      isAuth: false,
    },
    {
      order: 2,
      caption: i18next.t('wizard.add-membership.payment-page'),
      canonicalName: WizardStepsCn.PaymentPage,
      url: RoutePath.AddMembershipPayment,
      isValid: false,
      isAvailable: true,
      isAuth: false,
    },
  ],
  activeStep: WizardStepsCn.PlanPage,
};
