import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { NumberFormInput } from 'components/UI/Form/FormInput/NumberFormInput';

import { FormRow } from '../../../../../../../components/UI/Form/FormRow/FormRow';
import { FormValue } from '../../../../../../../components/UI/Form/FormValue/FormValue';
import { CustomForm } from '../../../../../../../models/types/CustomForm';
import { getFieldPropertiesForGroup } from '../../../../../../../utils/formGroupFieldHelpers';
import {
  formatPhoneNumber,
  parsePhoneNumber,
  PHONE_NUMBER_MAX_LENGTH,
} from '../../../../../../../utils/phoneNumberHelpers';
import {
  MemberPersonalDetailsFormFields,
  MemberTitleOptions,
} from './memberPersonalDetailsForm.models';
import {
  Description,
  StyledFormColumn,
  StyledFormSelect,
} from './memberPersonalDetailsForm.styles';

type MemberPersonalDetailsFormProps = CustomForm & {
  personName: string;
};

const MemberPersonalDetailsForm = ({
  disabled,
  name,
  className,
  personName,
}: MemberPersonalDetailsFormProps) => {
  const { t } = useTranslation();
  return (
    <StyledFormColumn className={className}>
      <FormRow>
        <FormRow isSubRow>
          <Field
            component={StyledFormSelect}
            disabled={disabled}
            label={t('fields.title.label')}
            isClearable
            options={MemberTitleOptions}
            {...getFieldPropertiesForGroup({
              inputId: 'add-membership-member-title-input',
              name: MemberPersonalDetailsFormFields.Prefix,
              groupName: name,
            })}
          />
          <FormValue>{personName}</FormValue>
        </FormRow>
        <Field
          component={NumberFormInput}
          disabled={disabled}
          label={t('fields.phoneNumberOptional.label')}
          {...getFieldPropertiesForGroup({
            inputId: 'add-membership-member-phone-input',
            name: MemberPersonalDetailsFormFields.Phone,
            groupName: name,
          })}
          formatValue={formatPhoneNumber}
          parseValue={parsePhoneNumber}
          maxLength={PHONE_NUMBER_MAX_LENGTH}
        />
      </FormRow>
      <FormRow>
        <Description>
          {t('phrase.if-you-want-change-name-email-date-of-birth')}
        </Description>
      </FormRow>
    </StyledFormColumn>
  );
};

export default MemberPersonalDetailsForm;
