import { useCallback } from 'react';

import { IInputProps } from './Input';

export const useInput = ({
  charsBeforeValue,
  onlyNumber,
  onChange,
}: IInputProps) => {
  const onChangeHandler = useCallback(
    (evt: any) => {
      if (charsBeforeValue) {
        evt.target.value = evt.target.value.replace(charsBeforeValue, '');
      }

      if (onlyNumber) {
        const numberValue = Number.parseInt(
          evt.target.value ? evt.target.value : '0',
          10,
        );
        evt.target.value = Number.isNaN(numberValue) ? 0 : numberValue;
      }
      onChange(evt);
    },
    [charsBeforeValue, onChange, onlyNumber],
  );
  return { onChangeHandler };
};
