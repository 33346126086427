import { ReactElement } from 'react';

import FieldLabel from '../../Field/FieldLabel';

import {
  Container,
  SelectedCounter,
  SelectedItemValue,
  SelectWrapper,
  SearchWrapper,
} from './valueContainer.styles';

type Props = {
  children: ReactElement & ReactElement[];
  selectProps: {
    placeholder?: string;
    menuIsOpen?: boolean;
    label?: string;
    isDisabled?: boolean;
    isLabelHidden?: boolean;
    labelAnimation?: boolean;
    currentValue: string | string[] | any;
  };
};

export const ValueContainer = ({
  children,
  selectProps: {
    menuIsOpen,
    placeholder,
    label,
    isDisabled,
    labelAnimation,
    isLabelHidden,
    currentValue,
  },
}: Props) => {
  const [values] = children;

  const searchInput =
    children.length > 0 ? children[children.length - 1] : null;

  return (
    <>
      {!isLabelHidden && (
        <FieldLabel
          disabled={isDisabled}
          labelActive={!labelAnimation || currentValue || menuIsOpen}
        >
          {label}
        </FieldLabel>
      )}
      {Array.isArray(values) ? (
        <Container>
          <SelectWrapper>
            {label && <SelectedItemValue>{placeholder}</SelectedItemValue>}
            <SelectedCounter>{values.length}</SelectedCounter>
          </SelectWrapper>
          <SearchWrapper>{searchInput}</SearchWrapper>
        </Container>
      ) : (
        children
      )}
    </>
  );
};
