import { useEffect } from 'react';
import { useLocation } from 'react-router';

import { Log } from '../../utils/logger';
import { useNavigationHomePage } from '../../hooks/useNavigationHomePage';

export const useNotFoundPage = () => {
  const { navigateHomePage } = useNavigationHomePage();
  const { pathname, state } = useLocation();

  useEffect(() => {
    Log.warning(`Page (${pathname}) not found. ${state}`);
  }, [pathname, state]);

  return { navigateHomePage };
};
