import { Field } from 'react-final-form';

import { FormColumn } from '../UI/Form/FormColumn/FormColumn';
import { FormInput } from '../UI/Form/FormInput/FormInput';
import { FormRow } from '../UI/Form/FormRow/FormRow';

import { customStyles } from './StripeInputWrapper.styles';

import {
  StripeCardDetailsFields,
  StripeFieldOptions,
} from './stripeCardDetailsForm.models';
import { CardNumber } from './Fields/CardNumber/CardNumber';
import { useStripeCardDetailsForm } from './stripeCardDetailsForm.hooks';
import { CustomComponent } from '../../models/types/CustomComponent';
import { CardExpiry } from './Fields/CardExpiry/CardExpiry';
import { CardCvc } from './Fields/CardCvc/CardCvc';

const StripeCardDetailsForm = ({ disabled }: CustomComponent) => {
  const { changeFocusState, onChangeValue, isLabelActive, getFieldError } =
    useStripeCardDetailsForm();

  const options: StripeFieldOptions = {
    disabled,
    style: {
      ...customStyles,
    },
  };

  return (
    <div>
      <FormColumn>
        <FormRow isSubRow>
          <Field
            component={FormInput}
            inputId="card-details-card-holder-name"
            disabled={disabled}
            autoComplete="off"
            autoCorrect="off"
            name={StripeCardDetailsFields.CardHolderName}
          />
        </FormRow>
        <FormRow>
          <CardNumber
            name={StripeCardDetailsFields.CardNumber}
            changeFocusState={changeFocusState}
            isLabelActive={isLabelActive(StripeCardDetailsFields.CardNumber)}
            onChange={onChangeValue}
            disabled={disabled}
            options={options}
            error={getFieldError(StripeCardDetailsFields.CardNumber)}
          />
        </FormRow>
        <FormRow isSubRow>
          <CardExpiry
            name={StripeCardDetailsFields.CardExpiration}
            changeFocusState={changeFocusState}
            isLabelActive={isLabelActive(
              StripeCardDetailsFields.CardExpiration,
            )}
            onChange={onChangeValue}
            disabled={disabled}
            options={options}
            error={getFieldError(StripeCardDetailsFields.CardExpiration)}
          />
          <CardCvc
            name={StripeCardDetailsFields.Cvc}
            changeFocusState={changeFocusState}
            isLabelActive={isLabelActive(StripeCardDetailsFields.Cvc)}
            onChange={onChangeValue}
            disabled={disabled}
            options={options}
            error={getFieldError(StripeCardDetailsFields.Cvc)}
          />
        </FormRow>
      </FormColumn>
    </div>
  );
};

export default StripeCardDetailsForm;
