import { useTranslation } from 'react-i18next';

import config from '../../config/config';
import { CustomComponent } from '../../models/types/CustomComponent';
import { StyledStandardText } from './needHelpInfo.styles';

const NeedHelpInfo = ({ className }: CustomComponent) => {
  const { t } = useTranslation();
  return (
    <StyledStandardText className={className}>
      {t('phrase.need-help', { phone: config.contact.phone })}
    </StyledStandardText>
  );
};

export default NeedHelpInfo;
