import { InputHTMLAttributes, ReactNode } from 'react';

import { CustomField } from '../../../models/types/CustomField';

import {
  CheckboxContainer,
  Label,
  NativeCheckbox,
  StyledCheckMark,
  VisibleCheckbox,
  Wrapper,
} from './checkbox.styles';

export interface ICheckboxProps
  extends InputHTMLAttributes<HTMLInputElement>,
    CustomField {
  defaultChecked?: boolean;
  label?: ReactNode | string;
}

const Checkbox = ({
  checked,
  className,
  defaultChecked,
  disabled,
  label,
  onChange,
  ...props
}: ICheckboxProps) => (
  <Wrapper className={className}>
    <CheckboxContainer>
      <NativeCheckbox
        {...props}
        checked={checked}
        defaultChecked={defaultChecked}
        disabled={disabled}
        onChange={onChange}
        type="checkbox"
      />
      <VisibleCheckbox checked={checked} disabled={disabled}>
        {checked && <StyledCheckMark />}
      </VisibleCheckbox>
    </CheckboxContainer>
    <Label disabled={disabled} htmlFor={props.id}>
      {label}
    </Label>
  </Wrapper>
);

export default Checkbox;
