import { Route, Routes } from 'react-router';

import WizardWrapper from '../../components/Wizard/WizardWrapper';
import { RoutePath } from '../../models/enums/RoutePath';
import AddMembershipMemberDetailsPage from './AddMembershipWizardPages/AddMembershipMemberDetailsPage/AddMembershipMemberDetailsPage';
import AddMembershipPaymentPage from './AddMembershipWizardPages/AddMembershipPaymentPage/AddMembershipPaymentPage';
import AddMembershipPlanPage from './AddMembershipWizardPages/AddMembershipPlanPage/AddMembershipPlanPage';

const AddMembershipRoutes = () => (
  <Routes>
    <Route path={RoutePath.Wizard} element={<WizardWrapper isStepperVisible />}>
      <Route
        path={RoutePath.AddMembershipPlan}
        element={<AddMembershipPlanPage />}
      />
      <Route
        path={RoutePath.AddMembershipMemberDetails}
        element={<AddMembershipMemberDetailsPage />}
      />
      <Route
        path={RoutePath.AddMembershipPayment}
        element={<AddMembershipPaymentPage />}
      />
    </Route>
  </Routes>
);

export enum AddMembershipAvailableParams {
  Email = 'email',
  MembershipPlanId = 'membershipPlanId',
  PromoCode = 'promoCode',
  MembershipId = 'membershipId',
}

export default AddMembershipRoutes;
