import { useIsAuthenticated } from '@azure/msal-react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useActions } from '../../../../hooks/useActions';
import { useProcessing } from '../../../../hooks/useProcessing';
import { useCancellationToken } from '../../../../hooks/useTokenCancellation';
import { orderPaymentDetailsSelector } from '../../../../store/redux-slices/membership/airAmbulance/selectors';
import {
  CREDIT_CARDS_LOAD_PROCESSING,
  ORDER_PAYMENT_DETAILS_LOAD_PROCESSING,
} from '../../../../store/redux-slices/processes/constants';
import { loadOrderPaymentDetails } from '../../../../store/saga-slices/membership/airAmbulance';
import { ILoadOrderPaymentDetailsPayload } from '../../../../store/saga-slices/membership/airAmbulance/models';
import { loadCreditCardList } from '../../../../store/saga-slices/payment';
import { ILoadCreditCardsPayload } from '../../../../store/saga-slices/payment/models';

export const useAddMembershipPaymentPage = () => {
  const LoadOrderPaymentDetails = useActions(loadOrderPaymentDetails);
  const LoadCreditCards = useActions(loadCreditCardList);

  const orderPaymentDetails = useSelector(orderPaymentDetailsSelector);

  const isAuth = useIsAuthenticated();
  const cancellationToken = useCancellationToken();

  const isDataLoading = useProcessing([
    ORDER_PAYMENT_DETAILS_LOAD_PROCESSING,
    CREDIT_CARDS_LOAD_PROCESSING,
  ]);

  useEffect(() => {
    const payload: ILoadOrderPaymentDetailsPayload = {
      cancellationToken,
    };
    LoadOrderPaymentDetails(payload);
  }, [LoadOrderPaymentDetails, cancellationToken]);

  useEffect(() => {
    if (!isAuth || orderPaymentDetails?.isPaymentRequired) {
      return;
    }
    const payload: ILoadCreditCardsPayload = {
      cancellationToken,
    };

    LoadCreditCards(payload);
  }, [
    LoadCreditCards,
    cancellationToken,
    isAuth,
    orderPaymentDetails?.isPaymentRequired,
  ]);

  const showSpinner = isDataLoading || !orderPaymentDetails;
  return { showSpinner };
};
