import Amex from '../assets/icons/amex.svg';
import Visa from '../assets/icons/visa.svg';
import DinersClub from '../assets/icons/dinersClub.svg';
import Discover from '../assets/icons/discover.svg';
import Mastercard from '../assets/icons/mastercard.svg';

export const creditCardTypeImages = {
  amex: Amex,
  visa: Visa,
  dinersclub: DinersClub,
  discover: Discover,
  mastercard: Mastercard,
};
