import { CustomComponent } from '../../../models/types/CustomComponent';
import CreditCardType from '../CreditCardType/CreditCardType';
import { CardNumber, CardWrapper } from './creditCardTypeNumber.styles';
type CreditCardTypeNumberProps = CustomComponent & {
  creditCardType?: string;
  creditCardNumber?: string;
  isExpired?: boolean;
};

const CreditCardTypeNumber = ({
  disabled,
  creditCardNumber,
  isExpired,
  creditCardType,
  className,
}: CreditCardTypeNumberProps) => (
  <CardWrapper className={className}>
    <CreditCardType creditCardType={creditCardType} />
    <CardNumber disabled={disabled || isExpired}>
      **** **** **** {creditCardNumber}
    </CardNumber>
  </CardWrapper>
);

export default CreditCardTypeNumber;
