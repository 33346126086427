import { MembershipPlanPeriods } from 'models/enums/MembershipPlanPeriods';
import { MembershipPlanPeriodsType } from 'models/enums/MembershipPlanPeriodsType';

export const translatePlanPeriodPlanToType = (
  plan: MembershipPlanPeriods,
): MembershipPlanPeriodsType | null => {
  switch (plan) {
    case MembershipPlanPeriods.Annual:
      return MembershipPlanPeriodsType.Annual;
    case MembershipPlanPeriods.Days_14:
      return MembershipPlanPeriodsType.Days_14;
    case MembershipPlanPeriods.Days_30:
      return MembershipPlanPeriodsType.Days_30;
    default:
      return null;
  }
};

export const translatePlanPeriodPlanFromType = (
  plan: MembershipPlanPeriodsType,
): MembershipPlanPeriods | null => {
  switch (plan) {
    case MembershipPlanPeriodsType.Annual:
      return MembershipPlanPeriods.Annual;
    case MembershipPlanPeriodsType.Days_14:
      return MembershipPlanPeriods.Days_14;
    case MembershipPlanPeriodsType.Days_30:
      return MembershipPlanPeriods.Days_30;
    default:
      return null;
  }
};
