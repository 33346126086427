import { ChangeEvent, ReactNode, useCallback } from 'react';

import { ICheckboxGroupItem } from './checkboxGroup.models';

type UseCheckboxGroup<T> = {
  value: ICheckboxGroupItem<T>[];
  onChange: (values: ICheckboxGroupItem<T>[]) => void;
  labelRenderer?: (data: ICheckboxGroupItem<T>) => ReactNode;
};

export const useCheckboxGroup = <T,>({
  onChange,
  value = [],
  labelRenderer,
}: UseCheckboxGroup<T>) => {
  const onChangeHandler = useCallback(
    (evt: ChangeEvent<HTMLInputElement>, item: ICheckboxGroupItem<T>) => {
      if (evt.target.checked) {
        onChange([...value, item]);
        return;
      }

      onChange(
        value.filter(
          (valueItem: ICheckboxGroupItem<T>) => valueItem.id !== item.id,
        ),
      );
    },
    [onChange, value],
  );

  const isItemChecked = useCallback(
    (item: ICheckboxGroupItem<T>) =>
      value.some((valueItem) => valueItem.id === item.id),
    [value],
  );

  const prepareLabel = useCallback(
    (data: ICheckboxGroupItem<T>, label: string | ReactNode) =>
      labelRenderer ? labelRenderer(data) : label,
    [labelRenderer],
  );

  return { onChangeHandler, isItemChecked, prepareLabel };
};
