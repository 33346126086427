import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

import { ReactComponent as CheckMark } from '../../../assets/icons/radio-check-mark-icon.svg';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const RadioContainer = styled.div`
  position: relative;
  width: 1.125rem;
  height: 1.125rem;
`;

export const NativeRadio = styled.input`
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 1.125rem;
  height: 1.125rem;
  cursor: pointer;
  z-index: 1;
  margin: 0;
`;
type RadioProps = {
  checked: boolean;
  disabled?: boolean;
};

export const VisibleRadio = styled.div<RadioProps>`
  position: relative;
  border: 1px solid
    ${({ theme, checked }) =>
      checked ? theme.color.secondary5 : theme.color.input_border};
  border-radius: 1em;
  cursor: pointer;
  width: 1.125rem;
  height: 1.125rem;
  transition: all 0.2s ease;
  fill: ${({ theme }) => theme.color.white};

  background-color: ${({ theme, checked }) =>
    checked && theme.color.secondary5};

  ${({ disabled, checked, theme }) =>
    disabled &&
    css`
      border: 1px solid ${theme.color.input_border};
      ${checked &&
      css`
        background-color: ${theme.color.dark2};
      `};
    `}
`;

export const StyledCheckMark = styled(CheckMark)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
`;

export const Label = styled.label<RadioProps>`
  color: ${({ theme }) => theme.color.dark1};
  font: normal normal 1rem/1.5rem ${({ theme }) => theme.font.gotham};
  ${({ checked }) =>
    checked &&
    css`
      font-weight: 500;
    `}
  ${({ theme, disabled }) =>
    disabled &&
    css`
      color: ${theme.color.input_border};
    `}
`;
