import { useCallback, useState } from 'react';

export const useBurgerMenuSidebar = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onClickHandler = useCallback(() => {
    setIsOpen((state) => !state);
  }, []);

  const closeSidebar = useCallback(() => {
    setIsOpen(false);
  }, []);

  return {
    isOpen,
    onClickHandler,
    closeSidebar,
  };
};
