import { FieldRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { MainSelect } from '../../MainSelect/MainSelect';
import { getPropertyName } from '../../../../utils/getPropertyName';
import { MainSelectPropType } from '../../MainSelect/mainSelect.types';
import { ErrorLabel } from '../../Field/Field';
import { FormFieldWrapper } from '../../../Form/Form';
import { useFormSelect } from './formSelect.hooks';

interface IFormSelectProps<T>
  extends FieldRenderProps<T>,
    MainSelectPropType<T> {
  setDefaultValue?: boolean;
}

export const FormSelect = <T,>({
  className,
  disabled,
  input: { onChange, value, name, onBlur },
  inputId,
  isLabelHidden,
  label,
  meta: { error, touched },
  options,
  placeholder,
  selectAdditionalClassName,
  setDefaultValue,
  labelAnimation = true,
  ...props
}: IFormSelectProps<T>) => {
  const { t } = useTranslation();

  const { onChangeHandler, preparedPlaceholder } = useFormSelect({
    onChange,
    options,
    labelAnimation,
    name,
    placeholder,
    promiseOptions: props.promiseOptions,
    setDefaultValue,
    value,
  });

  return (
    <FormFieldWrapper className={className} error={touched && error}>
      <MainSelect
        currentValue={value}
        isDisabled={disabled}
        onChange={onChangeHandler as any}
        options={options}
        label={label || t(`fields.${getPropertyName(name)}.label`)}
        labelAnimation
        isError={touched && error}
        placeholder={preparedPlaceholder}
        onBlur={onBlur}
        {...props}
      />
      {touched && error && <ErrorLabel>{error}</ErrorLabel>}
    </FormFieldWrapper>
  );
};
