import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

import { Line } from '../../../../../../components/UI/Line/Line';
import { mq } from '../../../../../../utils/mediaQueries';

export const SummaryContainer = styled.div`
  border: 0.063rem solid ${({ theme }) => theme.color.secondary2};
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  padding: 0;
`;

export const OverviewContainer = styled.div`
  padding: 1rem 1rem 1.5rem;

  ${mq.xSmall(css`
    padding: 1.5rem;
  `)}
`;

export const StyledLine = styled(Line)`
  background-color: ${({ theme }) => theme.color.secondary2};
  margin: 1.5rem 0;
`;
