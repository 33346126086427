import { Trans, useTranslation } from 'react-i18next';

import {
  SimpleModal,
  ModalTitle,
  ButtonsContainer,
} from '../../../../components/ModalCommon/ModalCommon';
import { StandardText } from '../../../../components/Typography/Typography';
import Button from '../../../../components/UI/Button/Button';
import { boolToOnOff } from '../../../../utils/propertyHelpers';
import { UPDATE_AUTO_RENEW_FLAG_MODAL } from './updateAutoRenewFlagModal.consts';
import { useUpdateAutoRenewFlagModal } from './updateAutoRenewFlagModal.hooks';

type UpdateAutoRenewFlagModalModalProps = {
  onAutoRenewFlagUpdated?: () => void;
};

const UpdateAutoRenewFlagModal = ({
  onAutoRenewFlagUpdated,
}: UpdateAutoRenewFlagModalModalProps) => {
  const { t } = useTranslation();

  const {
    membership,
    switchRenewFlagHandler,
    cancelHandler,
    autoRenewFlagUpdating,
  } = useUpdateAutoRenewFlagModal({ onAutoRenewFlagUpdated });

  return (
    <SimpleModal
      disabled={autoRenewFlagUpdating}
      name={UPDATE_AUTO_RENEW_FLAG_MODAL}
    >
      <ModalTitle>
        {t('headers.turn-auto-renewal', {
          value: t(
            `properties.${boolToOnOff(!membership?.autoRenewal)}`,
          ).toUpperCase(),
        })}
      </ModalTitle>
      <Trans>
        <StandardText>
          <Trans>
            {t('phrase.confirm-turn-auto-renewal', {
              value: t(
                `properties.${boolToOnOff(!membership?.autoRenewal)}`,
              ).toLowerCase(),
            })}
          </Trans>
        </StandardText>
      </Trans>
      <ButtonsContainer>
        <Button
          disabled={autoRenewFlagUpdating}
          styleType="lighter-blue"
          onClick={cancelHandler}
        >
          {t(`buttons.cancel`)}
        </Button>
        <Button
          disabled={autoRenewFlagUpdating}
          onClick={switchRenewFlagHandler}
        >
          {t(`buttons.confirm`)}
        </Button>
      </ButtonsContainer>
    </SimpleModal>
  );
};

export default UpdateAutoRenewFlagModal;
