import axios, { AxiosResponse } from 'axios';

import { IMemberships } from 'models/interfaces/IMemberships';

import { ICreateIndividualMembership } from '../../../../models/interfaces/CreateIndividualMembership';
import { IIndividualMembershipPaymentDetails } from '../../../../models/interfaces/IndividualMembershipPaymentDetails';
import { IPutMembershipToBeRenewedResult } from '../../../../models/interfaces/PutMembershipToBeRenewedResult';

import { IRetailMembershipOrderSummaryModel } from '../../../../models/interfaces/RetailMembershipOrderSummaryModel';
import { ISecondaryMember } from '../../../../models/interfaces/SecondaryMember';
import { Membership } from '../../../../models/types/Membership';

import {
  prepareQueryParams,
  QueryParamItem,
} from '../../../../utils/queryParamsHelpers';
import {
  ICreateMembershipResult,
  IValidateNewMembershipRequest,
  IValidateNewMembershipResponse,
} from './models';
import { ICreateMembershipSuggestion } from '../../../../models/interfaces/CreateMembershipSuggestion';

const MainUrl = '/api/memberships';

export const getMembershipOrderSummary = (
  membershipPlanId: string,
  autoRenewal: boolean,
  promoCode: string,
  email: string,
  cancelToken?: AbortSignal,
  renewMembershipId?: string,
): Promise<AxiosResponse<IRetailMembershipOrderSummaryModel>> => {
  const queryParams: QueryParamItem[] = [
    {
      name: 'membershipPlanId',
      value: membershipPlanId,
    },
    {
      name: 'autoRenewal',
      value: autoRenewal,
    },
    {
      name: 'promoCode',
      value: promoCode,
    },
    {
      name: 'email',
      value: email,
    },
    {
      name: 'renewMembershipId',
      value: renewMembershipId,
    },
  ];
  const params = prepareQueryParams({ queryParams });
  return axios.get(`${MainUrl}/orderSummary${params}`, {
    signal: cancelToken,
  });
};

export const validateNewMembership = (
  data: IValidateNewMembershipRequest,
  cancelToken?: AbortSignal,
): Promise<AxiosResponse<IValidateNewMembershipResponse>> =>
  axios.post(`${MainUrl}/validatenew`, data, {
    signal: cancelToken,
  });

export const analyzeNewMembership = (
  data: ICreateIndividualMembership,
  cancelToken?: AbortSignal,
): Promise<AxiosResponse<void>> =>
  axios.post(`${MainUrl}/newmembershipanalysis`, data, {
    signal: cancelToken,
  });

export const createMembershipRequest = (
  data: ICreateIndividualMembership,
  reCaptchaToken: string,
  handlePayment?: boolean,
  cancelToken?: AbortSignal,
): Promise<AxiosResponse<ICreateMembershipResult>> => {
  const queryParams: QueryParamItem[] = [
    {
      name: 'handlePayment',
      value: handlePayment,
    },
    {
      name: 'reCaptchaToken',
      value: reCaptchaToken,
    },
  ];
  const params = prepareQueryParams({ queryParams });
  return axios.post(`${MainUrl}${params}`, data, {
    signal: cancelToken,
  });
};

export const getOrderPaymentDetails = (
  promoCode?: string,
  effectiveDate?: string,
  cancelToken?: AbortSignal,
): Promise<AxiosResponse<IIndividualMembershipPaymentDetails>> => {
  const queryParams: QueryParamItem[] = [
    {
      name: 'promoCode',
      value: promoCode,
    },
    {
      name: 'effectiveDate',
      value: effectiveDate,
    },
  ];
  const params = prepareQueryParams({ queryParams });
  return axios.get(`${MainUrl}/orderPaymentDetails${params}`, {
    signal: cancelToken,
  });
};

export const getMemberships = (
  cancelToken?: AbortSignal,
): Promise<AxiosResponse<IMemberships>> =>
  axios.get(MainUrl, {
    signal: cancelToken,
  });

export const createAASecondaryMember = (
  membershipId: string,
  data: ISecondaryMember,
  cancelToken?: AbortSignal,
): Promise<AxiosResponse<string>> =>
  axios.post(`${MainUrl}/${membershipId}/secondarymember`, data, {
    signal: cancelToken,
  });

export const deleteAASecondaryMember = (
  membershipId: string,
  memberId: string,
  cancelToken?: AbortSignal,
): Promise<AxiosResponse<void>> =>
  axios.delete(`${MainUrl}/${membershipId}/secondarymember/${memberId}`, {
    signal: cancelToken,
  });

export const putMembershipToBeRenewed = (
  membershipId: string,
  toBeRenewed: boolean,
  cancelToken?: AbortSignal,
): Promise<AxiosResponse<IPutMembershipToBeRenewedResult>> =>
  axios.put(
    `${MainUrl}/${membershipId}/toberenewed`,
    { toBeRenewed },
    {
      signal: cancelToken,
    },
  );

export const getMembershipRenewalSuggestion = (
  membershipId: string,
  cancelToken?: AbortSignal,
): Promise<AxiosResponse<ICreateMembershipSuggestion>> =>
  axios.get(`${MainUrl}/${membershipId}/renewalsuggestion`, {
    signal: cancelToken,
  });

export const getMembershipDetails = (
  membershipId: string,
  cancelToken?: AbortSignal,
): Promise<AxiosResponse<Membership>> =>
  axios.get(`${MainUrl}/${membershipId}`, {
    signal: cancelToken,
  });
