import { FieldRenderProps } from 'react-final-form';

import { getOptionsFromEnum } from 'utils/getOptionsFromEnum';

import { MembershipPlanPeriods } from 'models/enums/MembershipPlanPeriods';

import { ICreateMembershipPlanInput } from '../../../../../../../../models/interfaces/ICreateMembershipPlan';

import { IMembershipPlanGroupItemModel } from '../../../../../../../../models/interfaces/MembershipPlanGroupItemModel';
import { IMembershipPlanGroupModel } from '../../../../../../../../models/interfaces/MembershipPlanGroupModel';

import { IMembershipPlansTreeModel } from '../../../../../../../../models/interfaces/MembershipPlansTreeModel';

import { CustomForm } from '../../../../../../../../models/types/CustomForm';
import MembershipPlanItem from '../MembershipPlanItem/MembershipPlanItem';
import { useMembershipPlan } from './membershipPlan.hooks';

import {
  StyledCheckCardList,
  StyledContainer,
  StyledContent,
  StyledStandardText,
  StyledTabOptions,
} from './membershipPlan.styles';

type MembershipPlanProps = CustomForm &
  FieldRenderProps<ICreateMembershipPlanInput> & {
    membershipPlans: IMembershipPlansTreeModel;
    isRenewal?: boolean;
    selectedMembershipPlanPeriod?: MembershipPlanPeriods;
  };

const MembershipPlan = ({
  disabled,
  isRenewal,
  membershipPlans,
  input,
  selectedMembershipPlanPeriod,
}: MembershipPlanProps) => {
  const {
    membershipPlansGroupsOptions,
    membershipGroupPlanOptions,
    onPlanGroupChangeHandler,
    planGroup,
    currentValue,
    onMembershipGroupPlanChangeHandler,
    groupDescription,
    membershipPlanPeriodsTypeHandler,
    membershipPlanPeriodPlan,
    membershipPeriodOptions,
  } = useMembershipPlan({
    membershipPlans,
    input,
    isRenewal,
    selectedMembershipPlanPeriod,
  });
  return (
    <StyledContainer>
      <StyledTabOptions
        disabled={disabled}
        options={membershipPeriodOptions}
        onChange={membershipPlanPeriodsTypeHandler}
        value={membershipPlanPeriodPlan}
        isDivider
      />
      <StyledContent>
        <StyledStandardText>{groupDescription}</StyledStandardText>
        <StyledTabOptions
          disabled={disabled}
          options={membershipPlansGroupsOptions}
          onChange={onPlanGroupChangeHandler}
          value={planGroup}
          valueIdSelector={(value: IMembershipPlanGroupModel) => value?.id}
          isDivider
        />
        <StyledCheckCardList
          renderComponent={(data: IMembershipPlanGroupItemModel) => (
            <MembershipPlanItem data={data} />
          )}
          disabled={disabled}
          onChange={onMembershipGroupPlanChangeHandler}
          items={membershipGroupPlanOptions}
          checkedItem={currentValue}
        />
      </StyledContent>
    </StyledContainer>
  );
};

export default MembershipPlan;
