import { useTranslation } from 'react-i18next';

import { ProductType } from '../../../../../../models/enums/ProductType';
import {
  OrganizationConnector,
  OrganizationName,
  Title,
  TitleContainer,
  TitleSection,
} from './membershipTitle.styles';

type MembershipTitleProps = {
  organizationName: string;
  productType: ProductType;
};

const MembershipTitle = ({
  organizationName,
  productType,
}: MembershipTitleProps) => {
  const { t } = useTranslation();
  return (
    <TitleSection>
      <TitleContainer>
        {organizationName && (
          <>
            <OrganizationName>{organizationName}</OrganizationName>
            <OrganizationConnector>{' - '}</OrganizationConnector>
          </>
        )}
        <Title>
          {t(`enums.productType.${productType}`).toLocaleUpperCase()}
        </Title>
      </TitleContainer>
    </TitleSection>
  );
};

export default MembershipTitle;
