import { useTranslation } from 'react-i18next';

import ErrorPage from '../../components/ErrorPage/ErrorPage';
import { useLoggedOutPage } from './loggedOutPage.hooks';

const LoggedOutPage = () => {
  const { t } = useTranslation();
  const { buttonClickHandler } = useLoggedOutPage();

  return (
    <ErrorPage
      title={t('headers.you-have-been-logged-out')}
      description={t('phrase.we-have-logged-you-out')}
      buttonLabel={t('buttons.log-in')}
      buttonAction={buttonClickHandler}
    />
  );
};

export default LoggedOutPage;
