import axios, { AxiosResponse } from 'axios';

import config from '../../../config/config';
import { IMembershipPromotion } from '../../../models/interfaces/MembershipPromotion';

const MainUrl = '/api/membershippromotion';

export const getMembershipPromotionBanner = (
  cancelToken?: AbortSignal,
): Promise<AxiosResponse<IMembershipPromotion>> =>
  axios.get(`${MainUrl}`, {
    signal: cancelToken,
    baseURL: config.peopleManagementApi.baseUrl,
  });
