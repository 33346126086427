import styled from '@emotion/styled/macro';

import { FormColumn } from '../../../../../../../components/UI/Form/FormColumn/FormColumn';

import { FormSelect } from '../../../../../../../components/UI/Form/FormSelect/FormSelect';

export const StyledFormSelect = styled(FormSelect)`
  max-width: 6.188rem;
  min-width: 6.188rem;
`;

export const StyledFormColumn = styled(FormColumn)`
  gap: 1rem;
`;

export const Description = styled.p`
  font: normal normal 0.875rem/1.125rem ${({ theme }) => theme.font.gotham};
`;
