import { useTranslation } from 'react-i18next';

import {
  SectionTitle,
  SectionWrapper,
} from '../../../../../../components/Form/Form';
import Spinner from '../../../../../../components/Spinner/Spinner';
import OrderOverview from './PaymentSections/OrderOverview/OrderOverview';
import PricingOverview from './PaymentSections/PricingOverview/PricingOverview';
import PricingTotal from './PaymentSections/PricingTotal/PricingTotal';
import { usePaymentSummary } from './paymentSummary.hooks';
import {
  OverviewContainer,
  SummaryContainer,
  StyledLine,
} from './paymentSummary.styles';

const PaymentSummary = () => {
  const { t } = useTranslation();
  const { membershipSummary, dataLoading } = usePaymentSummary();
  return (
    <Spinner isVisible={dataLoading} size="30%">
      <SectionWrapper>
        <SectionTitle>{t('headers.order-summary')}</SectionTitle>
        <SummaryContainer>
          <OverviewContainer>
            <OrderOverview data={membershipSummary} />
            <StyledLine />
            <PricingOverview data={membershipSummary} />
          </OverviewContainer>
          <PricingTotal totalPrice={membershipSummary?.totalDueToday} />
        </SummaryContainer>
      </SectionWrapper>
    </Spinner>
  );
};

export default PaymentSummary;
