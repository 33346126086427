import FormErrorProvider from '../../../../components/FormErrorProvider/FormErrorProvider';
import AddMembershipPlanForm from './AddMembershipPlanForm/AddMembershipPlanForm';

const AddMembershipPlanPage = () => (
  <FormErrorProvider>
    <AddMembershipPlanForm />
  </FormErrorProvider>
);

export default AddMembershipPlanPage;
