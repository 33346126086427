import { InteractionType } from '@azure/msal-browser';
import {
  MsalAuthenticationTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react';
import { Navigate, useLocation } from 'react-router';

import { loginRequest } from '../../config/authConfig';

import { RoutePath } from '../../models/enums/RoutePath';
import { CustomComponent } from '../../models/types/CustomComponent';

const AuthRoute = ({ children }: CustomComponent) => {
  const location = useLocation();

  return (
    <>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Silent}
        loadingComponent={() => <div />}
        authenticationRequest={loginRequest}
      >
        {children}
      </MsalAuthenticationTemplate>
      <UnauthenticatedTemplate>
        <Navigate
          to={RoutePath.AddMembershipPlanFullPath}
          replace
          state={{ path: location.pathname }}
        />
      </UnauthenticatedTemplate>
    </>
  );
};

export default AuthRoute;
