import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { AlertType } from 'react-alert';

import Button from '../UI/Button/Button';
import { ButtonProps } from '../UI/Button/button.models';
import { ReactComponent as CloseIcon } from '../../assets/icons/close-icon.svg';
import { mq } from '../../utils/mediaQueries';

type WrapperType = {
  type: AlertType;
};

export const Wrapper = styled.div<WrapperType>`
  background-color: ${({ theme }) => theme.color.light1};
  min-width: 20rem;
  padding: 1.5rem 2.5rem 1.5rem 1.5rem;
  border-radius: 0.5rem;
  color: ${({ theme }) => theme.color.white};
  font-size: 1rem;
  position: relative;
  margin: 4rem 1rem !important;

  ${mq.large(css`
    margin: 8rem 2.5rem !important;
  `)};

  ${({ type, theme }) => {
    switch (type) {
      case 'success':
        return css`
          background-color: ${theme.color.success2};
        `;
      case 'info':
        return css`
          background-color: ${theme.color.warning};
        `;
      case 'error':
        return css`
          background-color: ${theme.color.error};
        `;
    }
  }}
`;

export const CloseButton = styled((props: ButtonProps) => (
  <Button {...props} styleType="icon">
    <CloseIcon />
  </Button>
))`
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
`;
