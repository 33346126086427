import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

import { mq } from '../../utils/mediaQueries';

import { SectionTitle as Title } from '../Typography/Typography';
import { StyledFieldLabel, FieldWrapper } from '../UI/Field/Field';

export const FormPage = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const AuthFormPage = styled.form`
  max-width: 26.25rem;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 2.5rem auto;
`;

export const SectionWrapper = styled.div`
  width: 100%;
  margin-top: 2.5rem;

  ${mq.xSmall(css`
    margin-top: 3.5rem;
  `)}
`;

export const SectionTitle = styled(Title)`
  margin-bottom: 1.5rem;
`;

export const SecondSectionTitle = styled(Title)`
  margin-bottom: 1rem;
`;

export const SectionHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
`;

export const SecondSectionHeader = styled(SectionHeader)`
  margin-bottom: 1rem;
`;

type FormFieldWrapperType = {
  error?: boolean;
};

export const FormFieldWrapper = styled.div<FormFieldWrapperType>`
  width: 100%;
  ${({ error, theme }) =>
    error &&
    css`
      ${FieldWrapper} > :not(:placeholder-shown) ~ ${StyledFieldLabel},
      ${FieldWrapper} > ${StyledFieldLabel},
      ${StyledFieldLabel} {
        color: ${theme.color.error};
      }
    `}
`;
