import FormErrorProvider from '../components/FormErrorProvider/FormErrorProvider';

export const withErrorProvider =
  <P extends object>(Component: React.ComponentType<P>): React.FC<P> =>
  (props: P) =>
    (
      <FormErrorProvider>
        <Component {...(props as P)} />
      </FormErrorProvider>
    );
