import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { mq } from '../../../../../utils/mediaQueries';
import noMembershipImg from '../../../../../assets/icons/no-membership-icon.svg';
import clockIcon from '../../../../../assets/icons/clock-icon.svg';

export const NoMembershipContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  gap: 1.5rem;
`;

export const NoMembershipIcon = styled.div`
  background: url(${noMembershipImg});
  height: 8.5rem;
  width: 7.5rem;
`;

export const MembershipContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.color.secondary2};
  border-radius: 0.5rem;
  display: flex;
  gap: 1.5rem;
  flex-direction: column;
  padding: 1rem;

  ${mq.small(css`
    padding: 1.5rem;
  `)}
`;

export const MembershipHeader = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const MembershipSection = styled.div`
  align-items: start;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const MembershipSectionItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const ItemInfo = styled.span`
  color: ${({ theme }) => theme.color.dark2};
  white-space: nowrap;
  font: normal bold 0.875rem/1.25rem ${({ theme }) => theme.font.gotham};
`;

export const SectionItemTitle = styled.h5`
  color: ${({ theme }) => theme.color.black};
  font: normal bold 1rem/1.25rem ${({ theme }) => theme.font.gotham};
  margin: 0;
`;

export const SectionItemDescription = styled.p`
  color: ${({ theme }) => theme.color.dark1};
  font: normal normal 0.875rem/1.25rem ${({ theme }) => theme.font.gotham};
`;

export const MembershipDetails = styled.div`
  border-top: 1px solid ${({ theme }) => theme.color.light1};
  display: grid;
  gap: 1.5rem;
  padding: 1.5rem 0 0;
  grid-template-columns: repeat(2, 1fr);

  ${mq.medium(css`
    & > :not(:first-of-type) {
      place-self: end;
    }
    & > :nth-of-type(2) {
      place-self: center;
    }
    grid-template-columns: repeat(4, 1fr);
  `)}
`;

export const MembershipDetailItem = styled.div`
  color: ${({ theme }) => theme.color.black};
  display: flex;
  flex-direction: column;
  font: normal normal 1rem/1.25rem ${({ theme }) => theme.font.gotham};
  gap: 0.5rem;
`;

export const ExpirationDateWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
`;

export const ExpireIcon = styled.div`
  background: url(${clockIcon});
  height: 1.5rem;
  width: 1.5rem;
`;
