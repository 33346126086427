import axios, { AxiosError } from 'axios';

import config from '../config/config';
import { store } from '../index';
import { handlerApiError } from '../store/saga-slices/error';
import { PCA } from '../common/auth/initAuth';
import { loginRequest, scopesDictionary } from '../config/authConfig';
import { Log } from '../utils/logger';

(function () {
  axios.defaults.baseURL = config.api.baseUrl;
  axios.defaults.headers['Content-Type'] = 'application/json';
  axios.interceptors.request.use(
    async (axiosConfig) => {
      const account = PCA.getActiveAccount();
      if (!account) {
        return axiosConfig;
      }

      const response = await PCA.acquireTokenSilent({
        ...loginRequest,
        scopes: [
          ...loginRequest.scopes,
          ...scopesDictionary[axiosConfig.baseURL],
        ],
        account,
      }).catch((error) => {
        Log.errorException(error);
        PCA.logoutRedirect();
      });

      if (response) {
        const bearer = `Bearer ${response.accessToken}`;
        axiosConfig.headers.Authorization = bearer;
      }

      return axiosConfig;
    },
    (error) => {
      Promise.reject(error);
    },
  );
  axios.interceptors.response.use(
    (value) => value,
    (error: AxiosError) => {
      if (error.message === 'canceled') {
        throw error;
      }
      store.dispatch(handlerApiError({ error }));
      throw error;
    },
  );
})();
