import { useMemo } from 'react';

import { IMembershipPlanGroupItemModel } from '../../../../../../../../models/interfaces/MembershipPlanGroupItemModel';

export const useMembershipPlanItem = (data: IMembershipPlanGroupItemModel) => {
  const overwrittenPrice = useMemo(
    () => data?.regularPrice !== data?.price,
    [data],
  );
  return { overwrittenPrice };
};
