import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IPerson, personInit } from '../../../models/interfaces/Person';
import { clearState } from '../../globalActions';

export interface IPersonalDataState {
  person: IPerson;
}

const initialState: IPersonalDataState = {
  person: personInit,
};

const personalDataSlice = createSlice({
  name: 'personal-data',
  initialState,
  reducers: {
    setPerson: (state, { payload }: PayloadAction<IPerson>) => {
      state.person = payload;
    },
    clearPerson: (state) => {
      state.person = initialState.person;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearState, () => initialState);
  },
});

export const { setPerson, clearPerson } = personalDataSlice.actions;
export default personalDataSlice.reducer;
export const plansReducerName = personalDataSlice.name;
