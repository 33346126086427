import { combineReducers } from 'redux';

import processes from './processes';
import modals from './modals';
import wizard from './wizard';
import plans from './plans/airAmbulance';
import { membershipReducers } from './membership';
import errors from './errors';
import members from './members';
import payment from './payment';
import profile from './profile';
import personalData from './personalData';
import membershipPromotion from './membershipPromotion';
import { partnershipReducers } from './partnership';

const rootReducer = combineReducers({
  processes,
  modals,
  wizard,
  plans,
  errors,
  members,
  payment,
  profile,
  personalData,
  membershipPromotion,
  ...membershipReducers,
  ...partnershipReducers,
});

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;
