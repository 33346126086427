import { IUpdatePersonModel } from '../../../models/interfaces/UpdatePersonModel';

export function prepareUpdatePersonData(
  data: IUpdatePersonModel,
): IUpdatePersonModel {
  return {
    ...data,
    billingAddress: !data.billingAddressSameAsResidenceAddress
      ? data.billingAddress
      : null,
  };
}
