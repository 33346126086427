import { useRef, useState } from 'react';

type UseMenuWithIconProps = {
  onElementClick?: (option: string) => void;
};

export const useMenuWithIcon = ({ onElementClick }: UseMenuWithIconProps) => {
  const ref = useRef(null);
  const [menuVisible, setMenuVisible] = useState(false);

  const onElementClickHandler = (option: string) => {
    setMenuVisible(false);
    onElementClick?.(option);
  };

  return {
    ref,
    menuVisible,
    onElementClickHandler,
    setMenuVisible,
  };
};
