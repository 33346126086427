import { Cookies } from 'react-cookie';

import { isPropertyEmpty } from '../../../utils/propertyHelpers';
import {
  HubSpotContext,
  HubSpotField,
  HubSpotObjectType,
  HubSpotRequestObject,
} from './models';

export const prepareRequestObject = (data: any): HubSpotRequestObject => ({
  fields: prepareFields(data),
  context: prepareContext(),
});

const prepareFields = (data: any) => {
  const fields: HubSpotField[] = [];
  Object.keys(data).forEach((element) => {
    if (!isPropertyEmpty(data[element])) {
      fields.push({
        name: element,
        value: data[element],
        objectTypeId: HubSpotObjectType.Contact,
      });
    }
  });

  return fields;
};

const prepareContext = (): HubSpotContext => ({
  pageUri: document.URL,
  hutk: new Cookies().get('hubspotutk'),
});
