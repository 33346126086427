import { useCookies } from 'react-cookie';
import { useEffect } from 'react';

import TagManager from 'react-gtm-module';

import { tagManagerArgs } from '../../config/config';
import {
  CookieConsentsName,
  CookieConsentsValue,
} from '../CookieConsent/cookieConsent.models';

const GoogleTagManager = () => {
  const [cookie] = useCookies([CookieConsentsName]);

  useEffect(() => {
    if (cookie.cookie_consents === CookieConsentsValue.Accept) {
      TagManager.initialize(tagManagerArgs);
    }
  }, [cookie.cookie_consents]);

  return <></>;
};

export default GoogleTagManager;
