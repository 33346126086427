export enum AddressInformationsFields {
  BillingAddressSameAsResidenceAddress = 'billingAddressSameAsResidenceAddress',
  ResidenceAddress = 'residenceAddress',
  BillingAddress = 'billingAddress',
}

export const AddressInformationsInitState = {
  [AddressInformationsFields.BillingAddressSameAsResidenceAddress]: true,
  [AddressInformationsFields.ResidenceAddress]: {},
  [AddressInformationsFields.BillingAddress]: {},
};
