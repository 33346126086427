import { AnySchema } from 'ajv';

import { addressInformationsFormSchema } from '../../../../../components/Forms/AddressInformationsForm/addressInformations.validation';

import { FieldErrorMessage } from '../../../../../models/interfaces/FieldErrorMessage';
import { ICreateMembershipMemberDetails } from '../../../../../models/interfaces/ICreateMembershipMemberDetails';
import { validateFormData } from '../../../../../utils/validations/validateFormData';
import { AddMembershipMemberDetailsFields } from './addMembershipMemberDetailsForm.models';
import { familyMemberFormSchema } from './Forms/FamilyMembersForm/FamilyMemberSection/FamilyMemberForm/familyMemberForm.validation';
import { FamilyMembersFields } from './Forms/FamilyMembersForm/familyMembersForm.models';
import { memberDetailsFormSchema } from './Forms/MemberDetailsForm/memberDetailsForm.validation';

const schema: AnySchema | any = {
  type: 'object',
  properties: {
    [AddMembershipMemberDetailsFields.PrimaryMember]: memberDetailsFormSchema,
    [FamilyMembersFields.SecondaryMembers]: {
      type: 'array',
      items: familyMemberFormSchema,
    },
    ...addressInformationsFormSchema.properties,
  },
  dependencies: {
    ...addressInformationsFormSchema.dependencies,
  },
  required: [],
};

export const validateAddMembershipMemberDetailsForm = (
  data: ICreateMembershipMemberDetails,
  additionalErrors?: FieldErrorMessage[],
) => validateFormData(data, additionalErrors, schema);
