import { AnySchema } from 'ajv';

import { addressInformationsFormSchema } from '../../../../../components/Forms/AddressInformationsForm/addressInformations.validation';
import { FieldErrorMessage } from '../../../../../models/interfaces/FieldErrorMessage';
import { IUpdatePersonModel } from '../../../../../models/interfaces/UpdatePersonModel';
import { validateFormData } from '../../../../../utils/validations/validateFormData';
import { memberPersonalDetailsFormSchema } from './Forms/MemberPersonalDetailsForm/memberPersonalDetailsForm.validation';

const schema: AnySchema | any = {
  type: 'object',
  properties: {
    ...memberPersonalDetailsFormSchema.properties,
    ...addressInformationsFormSchema.properties,
  },
  dependencies: {
    ...addressInformationsFormSchema.dependencies,
  },
  required: [...memberPersonalDetailsFormSchema.required],
};

export const validateEditMemberDetailsForm = (
  data: IUpdatePersonModel,
  additionalErrors?: FieldErrorMessage[],
) => validateFormData(data, additionalErrors, schema);
