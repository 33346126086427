import { createSelector } from 'reselect';

import { IMembersState } from '.';
import { IFamilyMemberInfo } from '../../../models/interfaces/FamilyMemberInfo';
import { IIndividualPrimaryMember } from '../../../models/interfaces/IndividualPrimaryMember';
import { RootState } from '../index';

export const membersSelector = (state: RootState) => state.members;

export const primaryMemberSelector = createSelector(
  membersSelector,
  (membersState: IMembersState): IIndividualPrimaryMember =>
    membersState.primaryMember,
);

export const familyMembersSelector = createSelector(
  membersSelector,
  (membersState: IMembersState): IFamilyMemberInfo[] =>
    membersState.familyMembers,
);

export const primaryMemberNameSelector = createSelector(
  membersSelector,
  (membersState: IMembersState): string =>
    membersState?.primaryMember?.primaryMember?.firstName,
);
