import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { StandardText } from '../../../../../../../../components/Typography/Typography';
import CheckCardList from '../../../../../../../../components/UI/CheckCardList/CheckCardList';

import TabOptions from '../../../../../../../../components/UI/TabOptions/TabOptions';
import { mq } from '../../../../../../../../utils/mediaQueries';

export const StyledTabOptions = styled(TabOptions)<any>`
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  height: 2.375rem;

  ${mq.xSmall(css`
    margin-top: 2.5rem;
  `)}
`;

export const StyledStandardText = styled(StandardText)`
  color: ${({ theme }) => theme.color.dark1};
`;

export const StyledCheckCardList = styled(CheckCardList)<any>`
  margin-top: 1.5rem;

  ${mq.xSmall(css`
    margin-top: 2.5rem;
  `)}
`;

export const StyledContainer = styled.div`
  background-color: ${({ theme }) => theme.color.hover};
  border-radius: 0.25rem;
`;

export const StyledContent = styled.div`
  padding: 0rem 1rem 1rem 1rem;
`;
