import { useTranslation } from 'react-i18next';

import { formatDate } from '../../../../../../../../../utils/dateHelpers';
import { DescriptionText, RowContainer } from '../orderOverview.styles';

type OverviewTermProps = {
  startDate: string;
  expirationDate: string;
  autoRenewDate: string;
};
const OverviewTerm = ({
  expirationDate,
  startDate,
  autoRenewDate,
}: OverviewTermProps) => {
  const { t } = useTranslation();
  return (
    <div>
      <DescriptionText>{`${t('properties.startDate')}: ${formatDate(
        startDate,
      )}`}</DescriptionText>
      <RowContainer>
        <DescriptionText>{`${t('properties.expirationDate')}: ${formatDate(
          expirationDate,
        )}`}</DescriptionText>
        {!!autoRenewDate && (
          <DescriptionText>{`(${t('properties.autoRenews')} ${formatDate(
            autoRenewDate,
          )})`}</DescriptionText>
        )}
      </RowContainer>
    </div>
  );
};

export default OverviewTerm;
