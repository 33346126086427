import { useActions } from '../../../../../hooks/useActions';
import {
  Membership,
  FamilyMember,
} from '../../../../../models/types/Membership';
import { updateModalState } from '../../../../../store/redux-slices/modals';
import { ADD_KE_FAMILY_MEMBER_MODAL } from './Modals/AddKEFamilyMemberModal/addKEFamilyMemberModal.const';
import { AddKEFamilyMemberModalDetails } from './Modals/AddKEFamilyMemberModal/addKEFamilyMemberModal.models';
import { REMOVE_KE_FAMILY_MEMBER_MODAL } from './Modals/RemoveKEFamilyMemberModal/removeKEFamilyMemberModal.const';
import { RemoveKEFamilyMemberModalDetails } from './Modals/RemoveKEFamilyMemberModal/removeKEFamilyMemberModal.models';

export const useKEMembership = (membership: Membership) => {
  const UpdateModalState = useActions(updateModalState);

  const {
    familyMembers,
    membershipId,
    metadata: { canAddMemberTypes },
  } = membership;

  const addFamilyMemberHandler = () => {
    const details: AddKEFamilyMemberModalDetails = {
      membership,
    };
    UpdateModalState([ADD_KE_FAMILY_MEMBER_MODAL + membershipId, details]);
  };

  const removeFamilyMemberHandler = (member: FamilyMember) => {
    const details: RemoveKEFamilyMemberModalDetails = {
      membership,
      member,
    };
    UpdateModalState([REMOVE_KE_FAMILY_MEMBER_MODAL + membershipId, details]);
  };

  const showFamilyMembers =
    !!familyMembers?.length || !!canAddMemberTypes?.length;

  return {
    addFamilyMemberHandler,
    removeFamilyMemberHandler,
    showFamilyMembers,
  };
};
