import { StandardTextBold } from '../../../../../../../../../components/Typography/Typography';
import { CustomComponent } from '../../../../../../../../../models/types/CustomComponent';
import { TitleContainer, Wrapper } from './overviewProperty.styles';

type OverviewPropertyProps = CustomComponent & {
  title?: string;
  value?: string;
};

const OverviewProperty = ({
  title,
  children,
  value,
}: OverviewPropertyProps) => (
  <Wrapper>
    <TitleContainer>
      {title && (
        <StandardTextBold>{`${title}${value ? ':' : ''}`}</StandardTextBold>
      )}
      {value && <StandardTextBold>{value}</StandardTextBold>}
    </TitleContainer>
    {children}
  </Wrapper>
);

export default OverviewProperty;
