import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

import SuccessIcon from '../../../assets/icons/success-icon.svg';
import WarningIcon from '../../../assets/icons/warning-icon.svg';
import ErrorIcon from '../../../assets/icons/error-icon.svg';

import { StandardText } from '../../Typography/Typography';
import { MessageStatus } from './statusMessage.models';

type DefaultProps = {
  status?: MessageStatus;
};

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Icon = styled.div<DefaultProps>`
  height: 1.5rem;
  width: 1.5rem;
  margin-right: 0.5rem;

  ${({ status, theme }) => {
    switch (status) {
      case MessageStatus.Success:
        return css`
          background: url(${SuccessIcon}) 50% no-repeat;
        `;
      case MessageStatus.Error:
        return css`
          background: url(${ErrorIcon}) 50% no-repeat;
        `;
      case MessageStatus.Warning:
        return css`
          background: url(${WarningIcon}) 50% no-repeat;
        `;
    }
  }}
`;

export const Message = styled(StandardText)<DefaultProps>`
  ${({ status, theme }) => {
    switch (status) {
      case MessageStatus.Success:
        return css`
          color: ${theme.color.success};
        `;
      case MessageStatus.Error:
        return css`
          color: ${theme.color.error};
        `;
      case MessageStatus.Warning:
        return css`
          color: ${theme.color.warning};
        `;
    }
  }}
`;
