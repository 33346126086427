import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

import { IWizardStep } from '../../../../store/redux-slices/wizard/models';
import { mq } from '../../../../utils/mediaQueries';

export const Wrapper = styled.li`
  display: flex;
  align-items: center;
`;

export const ArrowContainer = styled.div`
  align-items: center;
  display: flex;
  margin: 0 0.5rem;
`;

type StepLabelType = {
  isActive?: boolean;
  step?: IWizardStep;
  isDisabled?: boolean;
};

export const StepLabel = styled.div<StepLabelType>`
  font: normal bold 0.75rem/0.875rem ${({ theme }) => theme.font.gotham};
  color: ${({ theme }) => theme.color.primary40};
  cursor: pointer;
  letter-spacing: 0.11em;
  white-space: nowrap;

  ${({ theme }) =>
    mq.small(css`
      font: normal bold 0.813rem/0.938rem ${theme.font.gotham};
    `)}

  ${({ theme }) =>
    mq.xSmall(css`
      font: normal bold 0.875rem/0.938rem ${theme.font.gotham};
    `)}


  ${({ isActive, theme }) =>
    isActive &&
    css`
      color: ${theme.color.primary};
    `}

  ${({ isDisabled, theme }) =>
    isDisabled &&
    css`
      color: ${theme.color.secondary2};
      pointer-events: none;
    `}
`;
