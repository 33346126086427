import { creditCardTypeImages } from '../../../config/creditCardsTypeImages';
import { CreditCardTypeText, Wrapper } from './creditCardType.styles';

interface ICreditCardType {
  creditCardType?: string;
}

const CreditCardType = ({ creditCardType }: ICreditCardType) => {
  const creditCardSrc = creditCardTypeImages[creditCardType?.toLowerCase()];

  if (!creditCardSrc) {
    return (
      <Wrapper>
        <CreditCardTypeText>{creditCardType}</CreditCardTypeText>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <img src={creditCardSrc} alt={creditCardType} />
    </Wrapper>
  );
};
export default CreditCardType;
