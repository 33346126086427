import axios, { AxiosResponse } from 'axios';

import config from '../../../../config/config';
import { IMemberships } from '../../../../models/interfaces/IMemberships';

import { ISecondaryMember } from '../../../../models/interfaces/SecondaryMember';

const MainUrl = '/api/memberships';

export const createSRSecondaryMember = (
  membershipId: string,
  data: ISecondaryMember,
  cancelToken?: AbortSignal,
): Promise<AxiosResponse<string>> =>
  axios.post(`${MainUrl}/${membershipId}/secondarymember`, data, {
    signal: cancelToken,
    baseURL: config.securityResponseApi.baseUrl,
  });

export const deleteSRSecondaryMember = (
  membershipId: string,
  memberId: string,
  cancelToken?: AbortSignal,
): Promise<AxiosResponse<void>> =>
  axios.delete(`${MainUrl}/${membershipId}/secondarymember/${memberId}`, {
    signal: cancelToken,
    baseURL: config.securityResponseApi.baseUrl,
  });

export const getSRMemberships = (
  cancelToken?: AbortSignal,
): Promise<AxiosResponse<IMemberships>> =>
  axios.get(MainUrl, {
    signal: cancelToken,
    baseURL: config.securityResponseApi.baseUrl,
  });
