import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IMemberships } from '../../../../models/interfaces/IMemberships';
import { Membership } from '../../../../models/types/Membership';

import { clearState } from '../../../globalActions';
import { ProductType } from '../../../../models/enums/ProductType';

export interface ISRMembershipState {
  srMemberships?: {
    active: Membership[];
    expired: Membership[];
  };
}

const initialState: ISRMembershipState = {
  srMemberships: {
    active: [],
    expired: [],
  },
};

const srMembershipSlice = createSlice({
  initialState,
  name: 'srMembership',
  reducers: {
    setSRMemberships: (state, { payload }: PayloadAction<IMemberships>) => {
      const memberships: Membership[] = payload.memberships.map((x) => ({
        ...x,
        productType: ProductType.SecurityResponse,
      }));
      state.srMemberships.active = memberships.filter(
        (membership) => !membership.isExpired,
      );
      state.srMemberships.expired = memberships.filter(
        (membership) => membership.isExpired,
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearState, () => initialState);
  },
});

export const { setSRMemberships } = srMembershipSlice.actions;
export default srMembershipSlice.reducer;
export const membershipReducerName = srMembershipSlice.name;
