import { useTranslation } from 'react-i18next';

import { TotalText, Wrapper } from './pricingTotal.styles';

type PricingTotalProps = {
  totalPrice: number;
};

const PricingTotal = ({ totalPrice }: PricingTotalProps) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <TotalText>{t('properties.totalDueToday')}</TotalText>
      <TotalText>${totalPrice}</TotalText>
    </Wrapper>
  );
};

export default PricingTotal;
