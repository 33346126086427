import { CustomField } from '../../../../models/types/CustomField';
import { StyledCheckMark, StyledCard } from './checkCardListItem.styles';

type CheckCardItemProps = CustomField & {
  id?: string;
  onChange?: (id: string) => void;
  checked?: boolean;
};

const CheckCardItem = ({
  id,
  className,
  onChange,
  children,
  checked,
  disabled,
  readOnly,
}: CheckCardItemProps) => (
  <StyledCard
    checked={checked}
    className={className}
    disabled={disabled}
    onClick={() => !disabled && !readOnly && onChange?.(id)}
  >
    {checked && <StyledCheckMark />}
    {children}
  </StyledCard>
);

export default CheckCardItem;
