import aaMembership from './airAmbulance';
import keMembership from './kidnapAndExtortion';
import srMembership from './securityResponse';
import docMembership from './dutyOfCare';

export const membershipReducers = {
  aaMembership,
  keMembership,
  srMembership,
  docMembership,
};
