import { useIsAuthenticated } from '@azure/msal-react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';

import { useActions } from '../../hooks/useActions';
import { useProcessing } from '../../hooks/useProcessing';
import { useRouteParams } from '../../hooks/useRouteParams';
import { useCancellationToken } from '../../hooks/useTokenCancellation';
import { RoutePath } from '../../models/enums/RoutePath';
import { areDataLoadedSelector } from '../../store/redux-slices/membership/airAmbulance/selectors';
import { MEMBERSHIP_SUGGESTION_LOAD_PROCESSING } from '../../store/redux-slices/processes/constants';
import {
  clearMembershipProcess,
  loadMembershipRenewalSuggestion,
} from '../../store/saga-slices/membership/airAmbulance';
import { ILoadMembershipRenewalSuggestionPayload } from '../../store/saga-slices/membership/airAmbulance/models';
import { IGetPartnershipInfoModel } from '../../store/saga-slices/partnership/airAmbulance/model';
import { getPartnershipInfo } from '../../store/saga-slices/partnership/airAmbulance';
import { clearPartnershipInfo } from '../../store/redux-slices/partnership/airAmbulance';

export const useMembershipPage = () => {
  const location = useLocation();
  const isAuth = useIsAuthenticated();
  const ClearMembershipProcess = useActions(clearMembershipProcess);
  const ClearPartnershipInfo = useActions(clearPartnershipInfo);
  const LoadMembershipSuggestion = useActions(loadMembershipRenewalSuggestion);
  const GetPartnershipInfo = useActions(getPartnershipInfo);
  const suggestionLoading = useProcessing(
    MEMBERSHIP_SUGGESTION_LOAD_PROCESSING,
  );
  const areDataLoaded = useSelector(areDataLoadedSelector);

  const cancellationToken = useCancellationToken();

  const { membershipId, promoCode } = useRouteParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!location.pathname.includes(RoutePath.Wizard)) {
      location.key !== 'default'
        ? navigate(-1)
        : navigate(RoutePath.AddMembershipPlanFullPath);
    }
  }, [location, navigate]);

  useEffect(() => {
    if (membershipId && !isAuth) {
      navigate(RoutePath.Login, {
        state: { path: location.pathname + location.search },
      });
    }
  }, [isAuth, location.pathname, location.search, membershipId, navigate]);

  useEffect(() => {
    if (!membershipId) {
      return;
    }

    const payload: ILoadMembershipRenewalSuggestionPayload = {
      membershipId,
      cancellationToken,
    };

    LoadMembershipSuggestion(payload);
  }, [LoadMembershipSuggestion, cancellationToken, membershipId]);

  useEffect(() => {
    if (!promoCode) {
      return;
    }

    const payload: IGetPartnershipInfoModel = {
      promoCode,
      cancellationToken,
    };

    GetPartnershipInfo(payload);
  }, [GetPartnershipInfo, cancellationToken, promoCode]);

  useEffect(
    () => () => {
      ClearMembershipProcess();
      ClearPartnershipInfo();
    },
    [ClearMembershipProcess, ClearPartnershipInfo],
  );

  return {
    canShowMembershipWizard:
      (!!membershipId && !suggestionLoading && areDataLoaded) || !membershipId,
  };
};
