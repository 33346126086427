import { Trans, useTranslation } from 'react-i18next';

import Button from '../../../../../components/UI/Button/Button';
import { useNavigateSearch } from '../../../../../hooks/useNavigationSearch';
import { RoutePath } from '../../../../../models/enums/RoutePath';

import { NoMembershipContainer, NoMembershipIcon } from './membership.styles';

export const NoMembership = () => {
  const { t } = useTranslation();
  const navigate = useNavigateSearch();
  const handleClick = () => {
    navigate(RoutePath.AddMembershipPlanFullPath);
  };

  return (
    <NoMembershipContainer>
      <NoMembershipIcon />
      <Trans>
        <span>{t('phrase.no-active-memberships')}</span>
      </Trans>
      <Button styleType="standard" onClick={handleClick}>
        {t('buttons.buy-membership')}
      </Button>
    </NoMembershipContainer>
  );
};
