/* eslint-disable no-console */
import {
  Configuration,
  LogLevel,
  RedirectRequest,
  SilentRequest,
} from '@azure/msal-browser';

// eslint-disable-next-line import/no-extraneous-dependencies
import { StringDict } from '@azure/msal-common';

import config from './config';

export const b2cPolicies = {
  names: {
    signUpSignIn: 'B2C_1A_SEAMLESSMIGRATION_SIGNUPORSIGNIN',
  },
  authorities: {
    signUpSignIn: {
      authority: `${config.b2c.authority}/B2C_1A_SEAMLESSMIGRATION_SIGNUPORSIGNIN`,
    },
    signInWithParams: (query: StringDict) =>
      ({
        authority: `${config.b2c.authority}/B2C_1A_SEAMLESSMIGRATION_SIGNUPORSIGNIN`,
        extraQueryParameters: query,
        redirectUri: `${window.location.protocol}//${window.location.hostname}${
          window.location.port ? `:${window.location.port}` : ''
        }/callback`,
        scopes: loginRequest.scopes,
      } as RedirectRequest),
  },
  authorityDomain: config.b2c.authorityDomain,
};

export const msalConfig: Configuration = {
  auth: {
    clientId: config.b2c.clientId,
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain],
    redirectUri: `${window.location.protocol}//${window.location.hostname}${
      window.location.port ? `:${window.location.port}` : ''
    }/callback`,
    postLogoutRedirectUri: '',
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: any, message: any, containsPii: any) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
        }
      },
    },
  },
};

export const loginRequest: SilentRequest = {
  scopes: ['offline_access', 'openid', 'email'],
  forceRefresh: false,
};

export const scopesDictionary: Record<string, string[]> = {
  [config.api.baseUrl]: [config.api.scope],
  [config.peopleManagementApi.baseUrl]: [config.peopleManagementApi.scope],
  [config.kidnapAndExtortionApi.baseUrl]: [config.kidnapAndExtortionApi.scope],
  [config.securityResponseApi.baseUrl]: [config.securityResponseApi.scope],
  [config.dutyOfCareApi.baseUrl]: [config.dutyOfCareApi.scope],
  [config.paymentApi.baseUrl]: [config.paymentApi.scope],
};

export const enrollRedirectRequest: RedirectRequest = {
  redirectUri: `${window.location.protocol}//${window.location.hostname}${
    window.location.port ? `:${window.location.port}` : ''
  }/enrollcallback`,
  scopes: loginRequest.scopes,
};
