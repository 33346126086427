import { useCallback, useEffect, useMemo, useState } from 'react';

import { ICheckboxGroupItem } from '../../../../components/UI/CheckboxGroup/checkboxGroup.models';
import { useActions } from '../../../../hooks/useActions';
import { IFamilyMemberInfo } from '../../../../models/interfaces/FamilyMemberInfo';
import { updateModalState } from '../../../../store/redux-slices/modals';
import { useMobileMediaQuery } from '../../../../utils/mediaQueries';
import { FAMILY_ENROLLMENT_MODAL_NAME } from './FamilyEnrollmentModal';

type UseFamilyEnrollmentProps = {
  members: IFamilyMemberInfo[];
  onSelectFamilyMembers?: (members: IFamilyMemberInfo[]) => void;
  onSkipSelectFamilyMembers?: () => void;
};

export const useFamilyEnrollment = ({
  members,
  onSelectFamilyMembers,
  onSkipSelectFamilyMembers,
}: UseFamilyEnrollmentProps) => {
  const UpdateModalState = useActions(updateModalState);
  const isMobile = useMobileMediaQuery();
  const [selectedMembers, setSelectedMembers] =
    useState<ICheckboxGroupItem<IFamilyMemberInfo>[]>();

  const preparedMembersItems: ICheckboxGroupItem<IFamilyMemberInfo>[] = useMemo(
    () =>
      members
        ? members.map((member) => ({
            id: member.personId,
            value: member,
            disabled: member.isOverAged,
          }))
        : [],
    [members],
  );

  useEffect(() => {
    if (preparedMembersItems && preparedMembersItems.length > 0) {
      setSelectedMembers(
        preparedMembersItems.filter((member) => !member.value.isOverAged),
      );
    }
  }, [preparedMembersItems]);

  const onChangeMembersHandler = useCallback(
    (value: ICheckboxGroupItem<IFamilyMemberInfo>[]) => {
      setSelectedMembers(value);
    },
    [],
  );

  const onSelectFamilyMembersHandler = useCallback(() => {
    const currentMembers =
      selectedMembers && selectedMembers.map((x) => x.value);
    onSelectFamilyMembers && onSelectFamilyMembers(currentMembers);
    UpdateModalState(FAMILY_ENROLLMENT_MODAL_NAME);
  }, [UpdateModalState, onSelectFamilyMembers, selectedMembers]);

  const onSkipFamilyMembers = useCallback(() => {
    UpdateModalState(FAMILY_ENROLLMENT_MODAL_NAME);
    onSkipSelectFamilyMembers && onSkipSelectFamilyMembers();
  }, [UpdateModalState, onSkipSelectFamilyMembers]);

  const isSelectingFamilyMembersAvailable = useMemo(
    () => selectedMembers && selectedMembers.length > 0,
    [selectedMembers],
  );

  return {
    onChangeMembersHandler,
    selectedMembers,
    preparedMembersItems,
    onSelectFamilyMembersHandler,
    onSkipFamilyMembers,
    isMobile,
    isSelectingFamilyMembersAvailable,
  };
};
