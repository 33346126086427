import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { SectionTitle } from '../../../../../../../components/Form/Form';

import { FormColumn } from '../../../../../../../components/UI/Form/FormColumn/FormColumn';
import { FormRow } from '../../../../../../../components/UI/Form/FormRow/FormRow';
import FormSwitch from '../../../../../../../components/UI/Form/FormSwitch/FormSwitch';
import { CustomForm } from '../../../../../../../models/types/CustomForm';
import { AutoRenewFormFields } from './autoRenewForm.models';
import { StyledSectionWrapper } from './autoRenewForm.styles';

type AutoRenewFormProps = CustomForm & {
  discountPrice: number;
};

const AutoRenewForm = ({
  disabled,
  className,
  discountPrice,
}: AutoRenewFormProps) => {
  const { t } = useTranslation();
  return (
    <StyledSectionWrapper className={className}>
      <SectionTitle>{t('headers.renewal')}</SectionTitle>
      <FormColumn>
        <FormRow>
          <Field
            component={FormSwitch}
            inputId="add-membership-auto-renew-input"
            disabled={disabled}
            label={t(
              !!discountPrice
                ? 'fields.autoRenewAndSave.label'
                : 'fields.autoRenew.label',
              { value: discountPrice },
            )}
            name={AutoRenewFormFields.AutoRenewal}
            isBoldLabel
            type="checkbox"
          />
        </FormRow>
      </FormColumn>
    </StyledSectionWrapper>
  );
};

export default AutoRenewForm;
