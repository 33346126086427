import { useTranslation } from 'react-i18next';

import { CustomComponent } from '../../../../../../../models/types/CustomComponent';
import { FamilyMember } from '../../../../../../../models/types/Membership';
import { formatDate } from '../../../../../../../utils/dateHelpers';
import {
  Title,
  MemberWrapper,
  StyledRemoveButton,
  MemberNumber,
  MemberInfo,
} from './member.styles';

type MemberProps = CustomComponent & {
  member: FamilyMember;
  onRemoveFamilyMember?: (member: FamilyMember) => void;
  canRemove?: boolean;
};

const Member = ({ member, onRemoveFamilyMember, canRemove }: MemberProps) => {
  const { t } = useTranslation();
  return (
    <MemberWrapper>
      {canRemove && (
        <StyledRemoveButton onRemove={() => onRemoveFamilyMember?.(member)} />
      )}
      <Title>
        {member.firstName} {member.lastName}
      </Title>
      {!member?.memberNumber && (
        <MemberNumber>
          {t('phrase.member-no', { value: member.memberNumber })}
        </MemberNumber>
      )}
      <MemberInfo>
        {member.memberType} &bull; {formatDate(member.dateOfBirth)}
      </MemberInfo>
    </MemberWrapper>
  );
};

export default Member;
