import { useTranslation } from 'react-i18next';

import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';

import WizardPage from '../../../../../components/Wizard/WizardPage/WizardPage';
import { FormPage } from '../../../../../components/Form/Form';
import MemberDetailsForm from './Forms/MemberDetailsForm/MemberDetailsForm';
import AddressInformationsForm from '../../../../../components/Forms/AddressInformationsForm/AddressInformationsForm';
import { useAddMembershipMemberDetailsForm } from './addMembershipMemberDetailsForm.hooks';
import Spinner from '../../../../../components/Spinner/Spinner';
import { AddMembershipMemberDetailsFields } from './addMembershipMemberDetailsForm.models';
import FamilyMembersSection from './Forms/FamilyMembersSection/FamilyMembersSection';
import { StyledStandardText } from './addMembershipMemberDetailsForm.styles';
import config from '../../../../../config/config';
import FormErrorActivator from '../../../../../components/FormErrorProvider/FormErrorActivator/FormErrorActivator';
import { focusOnErrorDecorator } from '../../../../../utils/focusOnErrorDecorator';
import NoFamilyMembersConfirmModal from '../../../Modals/NoFamilyMembersConfirmModal.ts/NoFamilyMembersConfirmModal.ts';
import { ICreateMembershipMemberDetails } from '../../../../../models/interfaces/ICreateMembershipMemberDetails';

const AddMembershipMemberDetailsForm = () => {
  const { t } = useTranslation();

  const {
    isMobile,
    validateForm,
    initState,
    submitHandler,
    shouldVisibleFamilyMembers,
    maxDependentMembersCount,
    maxSpouseMembersCount,
    memberDetailsCreating,
    disabledPrimaryMemberForm,
    dataLoading,
    isAuth,
  } = useAddMembershipMemberDetailsForm();
  return (
    <>
      <NoFamilyMembersConfirmModal onConfirm={submitHandler} />
      <Spinner isVisible={dataLoading} size="15%">
        <Form
          decorators={[focusOnErrorDecorator]}
          keepDirtyOnReinitialize
          onSubmit={(values: ICreateMembershipMemberDetails) =>
            submitHandler(values)
          }
          mutators={{
            ...arrayMutators,
          }}
          validate={validateForm}
          initialValues={initState}
        >
          {({ dirty, values, valid, ...renderProps }) => (
            <FormErrorActivator>
              <FormPage onSubmit={renderProps.handleSubmit}>
                <WizardPage
                  backButton
                  nextButtonLabel={t(
                    `buttons.${isMobile ? 'continue' : 'continue-to-payment'}`,
                  )}
                  isDirty={dirty}
                  canSubmit={valid}
                  isLoading={memberDetailsCreating}
                >
                  {isAuth && (
                    <StyledStandardText>
                      {t('phrase.review-current-contact-information', {
                        email: config.contact.airAmbulanceEmail,
                        phone: config.contact.airAmbulancePhone,
                      })}
                    </StyledStandardText>
                  )}
                  <MemberDetailsForm
                    name={AddMembershipMemberDetailsFields.PrimaryMember}
                    disabled={
                      memberDetailsCreating || disabledPrimaryMemberForm
                    }
                  />
                  {shouldVisibleFamilyMembers && (
                    <FamilyMembersSection
                      maxDependentMembersCount={maxDependentMembersCount}
                      maxSpouseMembersCount={maxSpouseMembersCount}
                      disabled={memberDetailsCreating}
                    />
                  )}
                  <AddressInformationsForm
                    disabled={
                      memberDetailsCreating || disabledPrimaryMemberForm
                    }
                  />
                </WizardPage>
              </FormPage>
            </FormErrorActivator>
          )}
        </Form>
      </Spinner>
    </>
  );
};

export default AddMembershipMemberDetailsForm;
