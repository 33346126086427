import { ButtonProps } from './button.models';
import {
  ButtonContentContainer,
  ButtonWrapper,
  Content,
  StyledSpinner,
} from './button.styles';
import { spinnerModeByButtonType } from './button.utils';

const Button = ({
  children,
  className,
  disabled,
  isLoading = false,
  onClick,
  styleType = 'standard',
  type = 'button',
}: ButtonProps) => (
  <ButtonWrapper
    disabled={disabled || isLoading}
    onClick={onClick}
    type={type}
    styleType={styleType}
    className={className}
  >
    <ButtonContentContainer>
      {isLoading && (
        <StyledSpinner
          delaySpinner={0}
          mode={spinnerModeByButtonType(styleType)}
        />
      )}
      <Content isVisible={!isLoading}>{children}</Content>
    </ButtonContentContainer>
  </ButtonWrapper>
);

export default Button;
