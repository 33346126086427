import axios, { AxiosResponse } from 'axios';

import config from '../../../../config/config';
import { MemberType } from '../../../../models/enums/MemberType';
import { IMemberAgeAnalysisResult } from '../../../../models/interfaces/MemberAgeAnalysisResult';
import {
  prepareQueryParams,
  QueryParamItem,
} from '../../../../utils/queryParamsHelpers';

const MainUrl = '/api/membershipplans';

export const analyzeKEMemberAge = (
  membershipPlanId: string,
  memberType: MemberType,
  dateOfBirth: string,
  cancelToken?: AbortSignal,
  renewedMembershipId?: string,
): Promise<AxiosResponse<IMemberAgeAnalysisResult>> => {
  const queryParams: QueryParamItem[] = [
    {
      name: 'dateOfBirth',
      value: dateOfBirth,
    },
    {
      name: 'renewedMembershipId',
      value: renewedMembershipId,
    },
  ];
  const params = prepareQueryParams({ queryParams });
  return axios.get(
    `${MainUrl}/${membershipPlanId}/member/${memberType}/memberageanalysis${params}`,
    {
      signal: cancelToken,
      baseURL: config.kidnapAndExtortionApi.baseUrl,
    },
  );
};
