import { useIsAuthenticated } from '@azure/msal-react';
import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { useFormErrorsActions } from '../../../../../components/FormErrorProvider/FormErrorProvider.hooks';
import { useActions } from '../../../../../hooks/useActions';
import { useProcessing } from '../../../../../hooks/useProcessing';
import { useRouteParams } from '../../../../../hooks/useRouteParams';
import { MembershipPlanUnavailabilityReason } from '../../../../../models/enums/MembershipPlanUnavailabilityReason';
import { ICreateMembershipPlan } from '../../../../../models/interfaces/ICreateMembershipPlan';
import { AddMembershipPlanRedirectState } from '../../../../../models/types/AddMembershipPlanRedirectState';
import { createMembershipPlanSelector } from '../../../../../store/redux-slices/membership/airAmbulance/selectors';
import { updateModalState } from '../../../../../store/redux-slices/modals';
import { NEW_MEMBERSHIP_VALIDATION_PROCESSING } from '../../../../../store/redux-slices/processes/constants';
import { submitHubSpotForm } from '../../../../../store/saga-slices/hubSpot';
import { ISubmitHubSpotFormPayload } from '../../../../../store/saga-slices/hubSpot/models';
import { submitCreateMemberShipPlan } from '../../../../../store/saga-slices/membership/airAmbulance';
import { ISubmitCreateMembershipPlanPayload } from '../../../../../store/saga-slices/membership/airAmbulance/models';
import { IMembershipPlanAvailability } from '../../../../../store/saga-slices/plans/airAmbulance/models';
import { LOGIN_CONFIRMATION_MODAL_NAME } from '../../../Modals/LoginConfirmationModal/LoginConfirmationModal';
import { PURCHASE_FAILURE_ACTIVE_MEMBERSHIP_MODAL_NAME } from '../../../Modals/PurchaseFailureActiveMembershipModal/PurchaseFailureActiveMembershipModal';
import { PURCHASE_FAILURE_INCORRECT_PLAN_MODAL_NAME } from '../../../Modals/PurchaseFailureIncorrectPlanModal/PurchaseFailureIncorrectPlanModal';
import { validateAddMembershipPlanForm } from './addMembershipPlanForm.validation';

export const useAddMembershipPlanForm = () => {
  const SubmitCreateMemberShipPlan = useActions(submitCreateMemberShipPlan);
  const isAuth = useIsAuthenticated();
  const UpdateModalState = useActions(updateModalState);
  const SubmitHubSpotForm = useActions(submitHubSpotForm);
  const { email, promoCode, membershipId } = useRouteParams();
  const state = useLocation().state as AddMembershipPlanRedirectState;

  const membershipIsValidation = useProcessing(
    NEW_MEMBERSHIP_VALIDATION_PROCESSING,
  );

  const prepareInitDataFromRouteParams = useMemo(() => {
    if (!isAuth) {
      return { emailAddress: email, promoCode };
    }
    return { promoCode };
  }, [email, isAuth, promoCode]);

  const { validateErrors } = useFormErrorsActions();

  const validateForm = useCallback(
    (data: ICreateMembershipPlan) =>
      validateAddMembershipPlanForm(data, validateErrors(data)),
    [validateErrors],
  );

  const createMembershipPlanData = useSelector(createMembershipPlanSelector);

  const initState = {
    ...prepareInitDataFromRouteParams,
    ...createMembershipPlanData,
    ...state,
    isAuth,
  };

  const handleError = useCallback(
    (error: IMembershipPlanAvailability) => {
      if (!error) return;

      if (!error.isAvailable) {
        switch (error.unavailabilityReason) {
          case MembershipPlanUnavailabilityReason.MembershipCollision:
            UpdateModalState(PURCHASE_FAILURE_ACTIVE_MEMBERSHIP_MODAL_NAME);
            return;
          case MembershipPlanUnavailabilityReason.MemberAgeNotMatch:
            UpdateModalState(PURCHASE_FAILURE_INCORRECT_PLAN_MODAL_NAME);
        }
      }
    },
    [UpdateModalState],
  );

  const handleHubSpotSubmit = useCallback(
    (values: ICreateMembershipPlan) => {
      const payload: ISubmitHubSpotFormPayload = {
        data: {
          email: values.emailAddress,
          promo_code: values.promoCode,
          air_ambulance_membership_type:
            values.membershipPlanInfo.membershipPlan.planName,
        },
      };

      SubmitHubSpotForm(payload);
    },
    [SubmitHubSpotForm],
  );

  const handleSubmit = useCallback(
    (values: ICreateMembershipPlan) => {
      handleHubSpotSubmit(values);

      const payload: ISubmitCreateMembershipPlanPayload = {
        data: values,
        renewedMembershipId: membershipId,
        error: handleError,
        success: (data) => {
          if (data?.needLogin) {
            UpdateModalState([LOGIN_CONFIRMATION_MODAL_NAME, values]);
          }
        },
      };

      SubmitCreateMemberShipPlan(payload);
    },
    [
      SubmitCreateMemberShipPlan,
      UpdateModalState,
      handleError,
      handleHubSpotSubmit,
      membershipId,
    ],
  );

  useEffect(() => {
    if (state?.needSubmit) {
      handleSubmit(state);
    }
  }, [handleSubmit, state]);

  const onLoggedInHandler = useCallback(
    (data: any) => {
      handleSubmit(data);
    },
    [handleSubmit],
  );

  return {
    handleSubmit,
    onLoggedInHandler,
    createMembershipPlanData,
    validateForm,
    membershipIsValidation,
    isAuth,
    initState,
    membershipId,
    canChangePromoCode: !promoCode,
  };
};
