import { useMemo } from 'react';

import { IMembershipPlanGroupModel } from 'models/interfaces/MembershipPlanGroupModel';

import { CustomField } from '../../../models/types/CustomField';

import TabOption, { ITabOptionProps } from './TabOption/TabOption';
import { useTabOptions } from './tabOptions.hooks';
import { Wrapper } from './tabOptions.styles';

export interface ITabOptionsProps<T> extends CustomField {
  onChange: (value: IMembershipPlanGroupModel | T) => void;
  options: ITabOptionProps<T>[];
  value?: IMembershipPlanGroupModel | T;
  valueIdSelector?: (
    value: IMembershipPlanGroupModel | T,
  ) => IMembershipPlanGroupModel | T;
  isDivider?: boolean;
}

const TabOptions = <T,>({
  className,
  disabled,
  onChange,
  options,
  value,
  readOnly,
  valueIdSelector,
  isDivider,
}: ITabOptionsProps<T>) => {
  const { isActive, getOptionKey, isDividerNeeded } = useTabOptions({
    valueIdSelector,
  });
  const renderOptions = useMemo(
    () =>
      options?.map((option, index) => (
        <TabOption
          {...option}
          key={getOptionKey(option.value)}
          disabled={
            disabled || option.disabled || option?.value?.items?.length === 0
          }
          isActive={isActive(option.value, value)}
          readOnly={readOnly || option.readOnly}
          onClick={onChange}
          isDivider={isDivider && isDividerNeeded(index)}
        />
      )),
    [
      disabled,
      getOptionKey,
      isActive,
      isDivider,
      isDividerNeeded,
      onChange,
      options,
      readOnly,
      value,
    ],
  );
  return <Wrapper className={className}>{renderOptions}</Wrapper>;
};

export default TabOptions;
