import FormErrorProvider from '../../../../components/FormErrorProvider/FormErrorProvider';
import { ModalForm } from '../../../../components/ModalForm/ModalForm';
import { REPLACE_CREDIT_CARD_DETAILS_MODAL } from './replaceCreditCard.consts';
import ReplaceCreditCardModalForm from './ReplaceCreditCardForm/ReplaceCreditCardModalForm';

type ReplaceCreditCardModalProps = {
  onCreditCardReplaced?: () => void;
  title?: string;
  description?: string;
  modalName?: string;
};

const ReplaceCreditCardModal = ({
  modalName = REPLACE_CREDIT_CARD_DETAILS_MODAL,
  ...props
}: ReplaceCreditCardModalProps) => (
  <FormErrorProvider>
    <ModalForm name={modalName} isLoading={false}>
      <ReplaceCreditCardModalForm {...props} modalName={modalName} />
    </ModalForm>
  </FormErrorProvider>
);

export default ReplaceCreditCardModal;
