import { Routes, Route } from 'react-router';

import { ProfilePage } from 'pages/ProfilePage/ProfilePage';

import LoginPage from 'pages/LoginPage/LoginPage';

import { RoutePath } from './models/enums/RoutePath';
import AddMembershipPage from './pages/AddMembershipPage/AddMembershipPage';
import ConfirmationPage from './pages/ConfirmationPage/ConfirmationPage';
import { MiddleLayoutRoutePage } from './components/Page/PageRouteLayout';
import AuthRoute from './components/AuthRoute/AuthRoute';
import Banner from './components/Banners/Banners';
import NotFoundPage from './pages/NotFoundPage/NotFoundPage';
import LoggedOutPage from './pages/LoggedOutPage/LoggedOutPage';
import Redirect from './components/Shared/Redirect';
import { useNavigationHomePage } from './hooks/useNavigationHomePage';
import CallbackPage from './pages/CallbackPage/CallbackPage';
import EnrollCallbackPage from './pages/EnrollCallbackPage/EnrollCallbackPage';
import SetPassword from './pages/SetPassword/SetPassword';

const AppRoutes = () => {
  const { homePagePath } = useNavigationHomePage();
  return (
    <Routes>
      <Route path={RoutePath.Login} element={<LoginPage />} />
      <Route path={RoutePath.Callback} element={<CallbackPage />} />
      <Route path={RoutePath.EnrollCallback} element={<EnrollCallbackPage />} />
      <Route
        path={RoutePath.Profile}
        element={
          <AuthRoute>
            <ProfilePage />
          </AuthRoute>
        }
      />
      <Route element={<Banner />}>
        <Route element={<MiddleLayoutRoutePage />}>
          <Route
            path={`${RoutePath.AddMembership}/*`}
            element={<AddMembershipPage />}
          />
          <Route
            path={RoutePath.AddMembershipConfirmation}
            element={<ConfirmationPage />}
          />
        </Route>
      </Route>
      <Route path={RoutePath.LoggedOut} element={<LoggedOutPage />} />
      <Route path={RoutePath.SetPassword} element={<SetPassword />} />
      <Route path={RoutePath.NotFoundPage} element={<NotFoundPage />} />
      <Route path="/" element={<Redirect to={homePagePath} />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default AppRoutes;
