import { createSliceSaga, SagaType } from 'redux-toolkit-saga';
import { put } from 'typed-redux-saga/macro';
import { PayloadAction } from '@reduxjs/toolkit';

import { ILoadMembershipsPayload } from './models';
import { loadAAMemberships } from '../airAmbulance';
import { loadKEMemberships } from '../kidnapAndExtortion';
import { loadSRMemberships } from '../securityResponse';
import { loadDOCMemberships } from '../dutyOfCare';

const membershipSlice = createSliceSaga({
  caseSagas: {
    *loadMemberships({ payload }: PayloadAction<ILoadMembershipsPayload>) {
      yield put(loadAAMemberships(payload));
      yield put(loadKEMemberships(payload));
      yield put(loadSRMemberships(payload));
      yield put(loadDOCMemberships(payload));
    },
  },
  name: 'membership-saga',
  sagaType: SagaType.TakeLatest,
});

export default membershipSlice.saga;
export const { loadMemberships } = membershipSlice.actions;
export const { actions } = membershipSlice;
