import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';

import { useFormErrorsActions } from '../../../../../../../components/FormErrorProvider/FormErrorProvider.hooks';
import { validateFamilyMemberForm } from '../../../../../../../components/Forms/FamilyMemberForm/familyMemberForm.validation';
import { useActions } from '../../../../../../../hooks/useActions';
import { useModalDetails } from '../../../../../../../hooks/useModalDetails';
import { useProcessing } from '../../../../../../../hooks/useProcessing';
import { useCancellationToken } from '../../../../../../../hooks/useTokenCancellation';
import { ISecondaryMember } from '../../../../../../../models/interfaces/SecondaryMember';
import { updateModalState } from '../../../../../../../store/redux-slices/modals';
import { FAMILY_MEMBER_ADDING_PROCESSING } from '../../../../../../../store/redux-slices/processes/constants';
import { addKEFamilyMember } from '../../../../../../../store/saga-slices/membership/kidnapAndExtortion';
import { IAddKEFamilyMemberPayload } from '../../../../../../../store/saga-slices/membership/kidnapAndExtortion/models';
import { ADD_KE_FAMILY_MEMBER_MODAL } from './addKEFamilyMemberModal.const';
import { AddKEFamilyMemberModalDetails } from './addKEFamilyMemberModal.models';

type UseAddKEFamilyMemberModalFormProps = {
  onFamilyMemberAdded?: () => void;
  id?: string;
};

export const useAddKEFamilyMemberModalForm = ({
  onFamilyMemberAdded,
  id,
}: UseAddKEFamilyMemberModalFormProps) => {
  const { t } = useTranslation();
  const alert = useAlert();

  const UpdateModalState = useActions(updateModalState);
  const AddFamilyMember = useActions(addKEFamilyMember);

  const memberAdding = useProcessing(FAMILY_MEMBER_ADDING_PROCESSING);

  const cancellationToken = useCancellationToken();

  const details: AddKEFamilyMemberModalDetails = useModalDetails(
    ADD_KE_FAMILY_MEMBER_MODAL + id,
  );
  const { validateErrors } = useFormErrorsActions();

  const onCancel = () => {
    UpdateModalState(ADD_KE_FAMILY_MEMBER_MODAL + id);
  };
  const validate = (data: ISecondaryMember) =>
    validateFamilyMemberForm(data, validateErrors(data));

  const submitHandler = (values: ISecondaryMember) => {
    const payload: IAddKEFamilyMemberPayload = {
      data: values,
      cancellationToken,
      membershipId: details?.membership?.membershipId,
      success: () => {
        UpdateModalState(ADD_KE_FAMILY_MEMBER_MODAL + id);
        onFamilyMemberAdded && onFamilyMemberAdded();
        alert.success(t('alerts.add-secondary-member-success'));
      },
      error: () => alert.error(t(`alerts.add-secondary-member-fail`)),
    };

    AddFamilyMember(payload);
  };

  return {
    validate,
    onCancel,
    membership: details?.membership,
    submitHandler,
    memberAdding,
  };
};
