export const getCountOfItems = (
  array: any[],
  selectedValue: any,
  propertyName?: string,
) =>
  array.filter((x) =>
    propertyName ? x[propertyName] === selectedValue : x === selectedValue,
  ).length;

export const sortAsc = (a: any, b: any) => a - b;

export const sortDateAsc = (a: any, b: any) =>
  sortAsc(new Date(a).getTime(), new Date(b).getTime());

export const sortDesc = (a: any, b: any) => b - a;

export const sortDateDesc = (a: any, b: any) =>
  sortDesc(new Date(a).getTime(), new Date(b).getTime());
