import { TextFieldProps } from '@mui/material/TextField';

import { CustomField } from '../../../../models/types/CustomField';
import { CustomFieldLabel } from '../../../../models/types/CustomFieldLabel';
import { FieldWrapper } from '../../Field/Field';
import { useDatePickerInput } from './datePickerInput.hooks';
import { StyledFieldLabel, StyledTextField } from './datePickerInput.styles';

type DatePickerInputProps = TextFieldProps &
  CustomFieldLabel &
  CustomField & {
    isError?: boolean;
  };

const DatePickerInput = ({
  labelAnimation = true,
  isLabelHidden,
  placeholder,
  disabled,
  label,
  isError,
  ...props
}: DatePickerInputProps) => {
  const { isFocused, onFocusInputHandler } = useDatePickerInput();

  return (
    <FieldWrapper>
      <StyledTextField
        {...props}
        $isError={isError}
        disabled={disabled}
        inputProps={{
          ...props.inputProps,
          onFocus: () => onFocusInputHandler(true),
          onBlur: () => onFocusInputHandler(false),
          placeholder: labelAnimation
            ? (isFocused && props.inputProps.placeholder) || ''
            : props.inputProps.placeholder,
        }}
      />
      {!isLabelHidden && (
        <StyledFieldLabel
          disabled={disabled}
          labelActive={!labelAnimation || !!props.inputProps.value || isFocused}
          inputLabelOffset={2}
        >
          {label}
        </StyledFieldLabel>
      )}
    </FieldWrapper>
  );
};

export default DatePickerInput;
