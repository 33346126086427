import { FallbackProps } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';

import ErrorPage from '../../components/ErrorPage/ErrorPage';
import { useNavigationHomePage } from '../../hooks/useNavigationHomePage';
import ErrorImage from '../../assets/images/403.png';

const AccessForbiddenPage = ({ resetErrorBoundary }: FallbackProps) => {
  const { t } = useTranslation();
  const { navigateHomePage } = useNavigationHomePage();

  const goToHomePageHandler = () => {
    navigateHomePage();
    resetErrorBoundary();
  };

  return (
    <ErrorPage
      iconPath={ErrorImage}
      title={t('headers.access-forbidden')}
      description={t(
        'phrase.tried-access-to-page-you-do-not-have-authorization',
      )}
      buttonLabel={t('buttons.go-to-home-page')}
      buttonAction={goToHomePageHandler}
    />
  );
};

export default AccessForbiddenPage;
