import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { mq } from '../../utils/mediaQueries';
import { StandardText } from '../Typography/Typography';

export const StyledStandardText = styled(StandardText)`
  margin-top: 2.5rem;
  color: ${({ theme }) => theme.color.dark1};
  text-align: center;

  ${mq.xSmall(
    css`
      margin-top: 4rem;
    `,
  )}
`;
