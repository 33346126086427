import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IMembershipPromotion } from '../../../models/interfaces/MembershipPromotion';

export interface IMembershipPromotionState {
  bannerInfo: IMembershipPromotion;
}

const initialState: IMembershipPromotionState = {
  bannerInfo: null,
};

const membershipPromotionSlice = createSlice({
  name: 'membership-promotion',
  initialState,
  reducers: {
    setBannerInfo: (
      state,
      { payload }: PayloadAction<IMembershipPromotion>,
    ) => {
      state.bannerInfo = payload;
    },
    clearBannerInfo: (state) => {
      state.bannerInfo = initialState.bannerInfo;
    },
  },
});

export const { clearBannerInfo, setBannerInfo } =
  membershipPromotionSlice.actions;
export default membershipPromotionSlice.reducer;
export const membersReducerName = membershipPromotionSlice.name;
