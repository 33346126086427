import { useTranslation } from 'react-i18next';
import { FieldArray } from 'react-final-form-arrays';

import { CustomField } from '../../../../../../../models/types/CustomField';
import { SectionWrapper } from '../../../../../../../components/Form/Form';
import FamilyMemberSection from './FamilyMemberSection/FamilyMemberSection';
import { StyledButton } from './familyMembersForm.styles';
import { FamilyMembersFields } from './familyMembersForm.models';
import { useFamilyMembersForm } from './familyMembersForm.hooks';

type FamilyMembersFormProps = CustomField & {
  maxDependentMembersCount?: number;
  maxSpouseMembersCount?: number;
  onAddFamilyMember?: () => void;
};

const FamilyMembersForm = ({
  disabled,
  maxDependentMembersCount,
  maxSpouseMembersCount,
  onAddFamilyMember,
}: FamilyMembersFormProps) => {
  const { t } = useTranslation();
  const { canAddFamilyMember, prepareMemberTypeOptions } = useFamilyMembersForm(
    {
      maxDependentMembersCount,
      maxSpouseMembersCount,
    },
  );
  return (
    <FieldArray name={FamilyMembersFields.SecondaryMembers}>
      {({ fields }) => (
        <>
          {fields.map((fieldName, index) => (
            <FamilyMemberSection
              memberTypes={prepareMemberTypeOptions(fields, index)}
              key={fieldName}
              disabled={disabled}
              readOnly={!!fields.value[index].personId}
              index={index}
              onRemove={() => fields.remove(index)}
              name={fieldName}
            />
          ))}
          {canAddFamilyMember(fields.length) && (
            <SectionWrapper>
              <StyledButton
                disabled={disabled}
                onClick={() =>
                  onAddFamilyMember ? onAddFamilyMember() : fields.push({})
                }
                styleType="lighter-blue"
              >
                {t('buttons.add-family-member')}
              </StyledButton>
            </SectionWrapper>
          )}
        </>
      )}
    </FieldArray>
  );
};

export default FamilyMembersForm;
