import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

import { mq } from '../../../utils/mediaQueries';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;

  ${mq.xSmall(css`
    gap: 1.5rem;
  `)}
`;
