import { useCallback } from 'react';

import config from '../../../../../../../config/config';

export const useAgreementForm = () => {
  const showTermsAndConditions = useCallback(() => {
    window.open(config.termsAndConditionsLink, '_blank');
  }, []);

  return { showTermsAndConditions };
};
