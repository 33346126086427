import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

import { mq } from '../../../../utils/mediaQueries';

type DefaultType = {
  isActive?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  isDivider?: boolean;
};

export const Wrapper = styled.div<DefaultType>`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 0.25rem;
  transition: background-color 0.3s ease;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  width: 100%;
  position: relative;

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: default;
    `}

  ${({ isActive, disabled, theme }) =>
    !isActive &&
    !disabled &&
    css`
      &:hover {
        background-color: ${theme.color.secondary2};
      }
    `}

  ${({ isActive, disabled, theme }) => {
    if (disabled && isActive) {
      return css`
        background-color: ${theme.color.dark2};
      `;
    }

    if (isActive) {
      return css`
        background-color: transparent;
        opacity: 1;
        ${mq.small(css`
          background-color: ${theme.color.secondary3};
        `)}
      `;
    }
  }}


  ${({ isDivider, theme }) => {
    if (isDivider) {
      return css`
        &::before {
          content: '';
          position: absolute;
          left: -0.25rem;
          top: 0rem;
          bottom: 0rem;
          width: 0.0625rem;
          background-color: ${theme.color.secondary2};
        }
      `;
    }
  }}
`;

export const Icon = styled.span<DefaultType>`
  font-size: 0.875rem;
  margin-right: 0.625rem;

  ${mq.xSmall(css`
    font-size: 1rem;
  `)}

  ${({ isActive, disabled, theme }) => {
    if (isActive && !disabled)
      return css`
        stroke: ${theme.color.white};
        path {
          stroke: ${theme.color.secondary3};
          fill: ${theme.color.white};
          opacity: 1;
        }
      `;

    if (!isActive && disabled)
      return css`
        path {
          fill: ${theme.color.dark2};
        }
      `;

    if (isActive && disabled)
      return css`
        path {
          fill: ${theme.color.white};
          stroke: ${theme.color.dark2};
        }
      `;
  }}

  ${({ readOnly, isActive }) =>
    readOnly &&
    !isActive &&
    css`
      path {
        opacity: 0.5;
      }
    `}
`;

export const Label = styled.span<DefaultType>`
  color: ${({ theme }) => theme.color.dark1};
  letter-spacing: 0;
  white-space: nowrap;
  font: normal normal 0.75rem/0.875rem ${({ theme }) => theme.font.gotham};
  opacity: 1;
  padding: 0 0.5rem;
  transition: color 0.3s ease;
  ${Wrapper}:not(:last-of-type) & {
    border-right: 0.063rem solid ${({ theme }) => theme.color.secondary2};
  }

  ${({ theme }) =>
    mq.small(css`
      font: normal bold 0.875rem/0.875rem ${theme.font.gotham};
      ${Wrapper}:not(:last-of-type) & {
        border-right: none;
      }
    `)}

  ${({ theme }) =>
    mq.xSmall(css`
      font: normal bold 1rem/1em ${theme.font.gotham};
      padding: 0 2rem;
    `)}


  ${({ isActive, disabled, readOnly, theme }) => {
    if (isActive)
      return css`
        color: ${theme.color.secondary3};
        font-weight: bold;
        ${mq.small(css`
          color: ${theme.color.white};
        `)}
      `;
    if (!isActive && disabled)
      return css`
        color: ${theme.color.dark2};
      `;

    if (!isActive && readOnly)
      return css`
        opacity: 0.5;
      `;
  }}
`;
