import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

import Button from '../../../../../../components/UI/Button/Button';

type AutoRenewFlagButtonProps = {
  disabled?: boolean;
};

export const AutoRenewFlagButton = styled(Button)<AutoRenewFlagButtonProps>`
  margin-left: 0.25rem;
  ${({ disabled }) =>
    !disabled &&
    css`
      &:hover {
        text-decoration: underline;
      }
    `}
`;

export const AutoRenewValueContainer = styled.div`
  font: normal bold 1rem/1rem ${({ theme }) => theme.font.gotham};
  color: ${({ theme }) => theme.color.secondary3};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
