import { useSelector } from 'react-redux';

import { useActions } from '../../../hooks/useActions';

import { IWizardStep } from '../../../store/redux-slices/wizard/models';
import {
  activeStepCnSelector,
  wizardSortedStepsSelector,
} from '../../../store/redux-slices/wizard/selectors';
import { changeStepByCn } from '../../../store/saga-slices/wizard';

export const useWizardStepper = () => {
  const stepsDefinitions: IWizardStep[] = useSelector(
    wizardSortedStepsSelector,
  );
  const activeStepCn: string = useSelector(activeStepCnSelector);

  const ChangeStepByCn = useActions(changeStepByCn);

  const checkIfStepShouldDisabled = (step: IWizardStep, index: number) =>
    (step.canonicalName !== activeStepCn &&
      index === 0 &&
      !stepsDefinitions[index].isValid) ||
    (index > 0 &&
      step.canonicalName !== activeStepCn &&
      !stepsDefinitions[index - 1].isValid);

  return {
    checkIfStepShouldDisabled,
    activeStepCn,
    stepsDefinitions,
    ChangeStepByCn,
  };
};
