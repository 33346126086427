import { useTranslation } from 'react-i18next';

import {
  ModalButton,
  ModalTitle,
  SimpleModal,
} from '../../../../components/ModalCommon/ModalCommon';
import { StandardText } from '../../../../components/Typography/Typography';
import { useLoginConfirmationLogin } from './loginConfirmationModal.hooks';
import { StyledButtonsContainer } from './loginConfirmationModal.styles';

export const LOGIN_CONFIRMATION_MODAL_NAME = 'LOGIN_CONFIRMATION_MODAL_NAME';

const LoginConfirmationModal = () => {
  const { t } = useTranslation();
  const { onCancelHandler, onLoginHandler } = useLoginConfirmationLogin();

  return (
    <SimpleModal name={LOGIN_CONFIRMATION_MODAL_NAME}>
      <ModalTitle>{t('headers.provided-email-already-exists')}</ModalTitle>
      <StandardText>{t('phrase.login-confirmation')}</StandardText>
      <StyledButtonsContainer>
        <ModalButton styleType="lighter-blue" onClick={onCancelHandler}>
          {t(`buttons.cancel`)}
        </ModalButton>
        <ModalButton onClick={onLoginHandler}>
          {t(`buttons.go-to-login-page`)}
        </ModalButton>
      </StyledButtonsContainer>
    </SimpleModal>
  );
};

export default LoginConfirmationModal;
