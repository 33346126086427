import { FallbackProps } from 'react-error-boundary';
import { useSelector } from 'react-redux';

import AccessForbiddenPage from '../../../pages/AccessForbiddenPage/AccessForbiddenPage';
import SomethingWentWrongPage from '../../../pages/SomethingWentWrongPage/SomethingWentWrongPage';
import { accessErrorSelector } from '../../../store/redux-slices/errors/selectors';

const FallbackComponent = (props: FallbackProps) => {
  const accessError = useSelector(accessErrorSelector);

  if (accessError) {
    return <AccessForbiddenPage {...props} />;
  }

  return <SomethingWentWrongPage {...props} />;
};

export default FallbackComponent;
