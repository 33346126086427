import { useTranslation } from 'react-i18next';

import { FamilyMember } from '../../../../../../models/types/Membership';
import {
  MembersContainer,
  MembersListContainer,
  AddFamilyMemberButton,
  Title,
} from './members.styles';
import Member from './Member/Member';
import { MemberWrapper } from './Member/member.styles';

type Props = {
  membersList: FamilyMember[];
  onAddFamilyMember?: () => void;
  onRemoveFamilyMember?: (member: FamilyMember) => void;
  canAddMember: boolean;
  canRemoveMember?: boolean;
};

export const Members = ({
  membersList,
  onAddFamilyMember,
  onRemoveFamilyMember,
  canAddMember,
  canRemoveMember,
}: Props) => {
  const { t } = useTranslation();
  return (
    <MembersContainer>
      <Title>{t('properties.family-members')}</Title>
      <MembersListContainer>
        {membersList.map((member) => (
          <Member
            key={member.memberId}
            member={member}
            onRemoveFamilyMember={onRemoveFamilyMember}
            canRemove={member.metadata?.canRemove && canRemoveMember}
          />
        ))}
        {canAddMember && (
          <MemberWrapper>
            <AddFamilyMemberButton styleType="link" onClick={onAddFamilyMember}>
              {t('buttons.add-family-member')}
            </AddFamilyMemberButton>
          </MemberWrapper>
        )}
      </MembersListContainer>
    </MembersContainer>
  );
};
