import { useCallback, useRef, useState } from 'react';

import { useOnClickOutside } from '../../../hooks/useOnClickOutside';

export const useInfoComponentMobile = () => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const ref = useRef();

  useOnClickOutside(ref, () => setShowTooltip(false));

  const onToggleTooltipHandler = useCallback(() => {
    setShowTooltip((state) => !state);
  }, []);

  return { showTooltip, ref, onToggleTooltipHandler };
};
