import { AlertTemplateProps } from 'react-alert';

import { CloseButton, Wrapper } from './alertTemplate.styles';

export type IAlertTemplateProps = AlertTemplateProps;

const AlertTemplate = ({
  close,
  message,
  options,
  style,
}: IAlertTemplateProps) => (
  <Wrapper type={options.type} style={style}>
    <CloseButton onClick={close} />
    {message}
  </Wrapper>
);

export default AlertTemplate;
