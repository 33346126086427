import { ReactComponent as MoreIcon } from '../../../assets/icons/more-icon.svg';
import { MenuOption } from '../Menu/models';
import Button from '../Button/Button';
import { StyledMenu } from './menuWithIcon.styles';
import { CustomComponent } from '../../../models/types/CustomComponent';
import { useMenuWithIcon } from './menuWithIcon.hooks';

export interface IMenuOptionsActionRenderer extends CustomComponent {
  onElementClick?: (option: string) => void;
  options?: MenuOption[];
}

export const MenuWithIcon = ({
  onElementClick,
  options,
}: IMenuOptionsActionRenderer) => {
  const { menuVisible, onElementClickHandler, ref, setMenuVisible } =
    useMenuWithIcon({ onElementClick });
  return (
    <div>
      <Button styleType="icon" onClick={() => setMenuVisible(true)}>
        <MoreIcon ref={ref} />
      </Button>
      <StyledMenu
        anchorEl={ref.current}
        isOpen={menuVisible}
        onMenuClose={() => setMenuVisible(false)}
        onElementClick={onElementClickHandler}
        options={options}
      />
    </div>
  );
};
