import { useCallback, useEffect } from 'react';
import { FieldInputProps } from 'react-final-form';

import { useCancellationToken } from '../../../../hooks/useTokenCancellation';

import { MemberType } from '../../../../models/enums/MemberType';
import { ProductType } from '../../../../models/enums/ProductType';
import { IApiValidationError } from '../../../../models/interfaces/ApiValidationError';
import { analyzeMemberAge } from '../../../../store/saga-slices/plans/common/utils';
import { isDateValid } from '../../../../utils/dateHelpers';
import { ValidationErrorCodes } from '../../../../utils/validations/validationCodes';
import { useFormErrorsActions } from '../../../FormErrorProvider/FormErrorProvider.hooks';

type UseFormDateOfBirthType = {
  memberType: MemberType;
  memberPlanId: string;
  input: FieldInputProps<string, HTMLElement>;
  renewedMembershipId?: string;
  productType?: ProductType;
};

export const useFormDateOfBirth = ({
  memberPlanId,
  memberType,
  renewedMembershipId,
  input: { name, onChange, value: currentValue },
  productType,
}: UseFormDateOfBirthType) => {
  const cancellationToken = useCancellationToken();

  const { addError, removeErrors } = useFormErrorsActions();

  const addDateOfBirthError = useCallback(
    (promoCodeValue: string, errorCode: string, errorMessageValues: any) => {
      const error: IApiValidationError = {
        PropertyName: name,
        ErrorCode: errorCode,
        ErrorMessageValues: errorMessageValues,
        InternalError: true,
      };

      addError(error, promoCodeValue);
    },
    [addError, name],
  );

  const analyzeCurrentAge = useCallback(
    (value: string) => {
      memberType &&
        memberPlanId &&
        analyzeMemberAge(
          memberPlanId,
          memberType,
          value,
          cancellationToken,
          renewedMembershipId,
          productType,
        ).then((result) => {
          !result.data.isApplicable &&
            addDateOfBirthError(
              value,
              result?.data?.maximumMemberAge
                ? ValidationErrorCodes.DateOfBirthDateRangeMinMax
                : ValidationErrorCodes.DateOfBirthDateRangeMin,
              {
                from: result.data.minimumMemberAge,
                to: result.data.maximumMemberAge,
              },
            );
        });
    },
    [
      addDateOfBirthError,
      cancellationToken,
      memberPlanId,
      memberType,
      productType,
      renewedMembershipId,
    ],
  );

  useEffect(() => {
    if (!isDateValid(currentValue)) {
      return;
    }
    removeErrors([name]);
    analyzeCurrentAge(currentValue);
  }, [analyzeCurrentAge, currentValue, name, removeErrors]);

  const onChangeHandler = useCallback(
    (value: string) => {
      onChange(value);
    },
    [onChange],
  );

  return {
    onChangeHandler,
    dateValue: currentValue,
  };
};
