import { isEmpty } from './textHelpers';

export type QueryParamItem = {
  name: string;
  value: string | boolean;
};

export type QueryParamsProps = {
  initQueryString?: string;
  queryParams: QueryParamItem[];
};

export function prepareQueryParams({
  initQueryString = '',
  queryParams,
}: QueryParamsProps) {
  const preparedQueryString = queryParams
    .filter((item) => !isEmpty(item.value))
    .map((item) => `${item.name}=${encodeURIComponent(item.value)}`)
    .join('&');

  const queryString = [initQueryString, preparedQueryString]
    .filter((x) => x)
    .join('&');

  return queryString && `?${queryString}`;
}
