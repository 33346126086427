import {
  StripeElementChangeEvent,
  StripeElementClasses,
  StripeElementStyle,
} from '@stripe/stripe-js';

import { CustomComponent } from '../../models/types/CustomComponent';

export enum StripeCardDetailsFields {
  CardHolderName = 'cardHolderName',
  CardNumber = 'cardNumber',
  CardExpiration = 'cardExpiry',
  Cvc = 'cardCvc',
}

export type CustomStripeField = CustomComponent & {
  name?: StripeCardDetailsFields;
  changeFocusState?: (name: StripeCardDetailsFields, state: boolean) => void;
  onChange?: (evt: StripeElementChangeEvent) => void;
  isLabelActive?: boolean;
  error?: string;
  options?: StripeFieldOptions;
};

export type StripeFieldOptions = {
  classes?: StripeElementClasses;
  style?: StripeElementStyle;
  placeholder?: string;
  showIcon?: boolean;
  iconStyle?: 'default' | 'solid';
  disabled?: boolean;
};

export type StripeInputOnChangeType = {
  elementType: StripeCardDetailsFields;
  empty: boolean;
  error:
    | undefined
    | {
        type: 'validation_error';
        code: string;
        message: string;
      };
};
