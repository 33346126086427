import { Gender } from '../enums/Gender';
import { MemberTitle } from '../enums/MemberTitle';
import { IAddress, addressInit } from './Address';

export interface IPerson {
  id: string;
  memberNumber: string;
  firstName: string;
  lastName: string;
  phone: string;
  emailAddress: string;
  dateOfBirth: string;
  gender: Gender;
  prefix: MemberTitle;
  residenceAddress: IAddress;
  billingAddress: IAddress;
  billingAddressSameAsResidenceAddress: boolean;
  isCreditCardRequired: boolean;
}

export const personInit: IPerson = {
  id: '',
  firstName: '',
  lastName: '',
  emailAddress: '',
  phone: '',
  gender: '' as Gender,
  prefix: '' as MemberTitle,
  dateOfBirth: '',
  memberNumber: null,
  residenceAddress: addressInit,
  billingAddressSameAsResidenceAddress: false,
  billingAddress: addressInit,
  isCreditCardRequired: false,
};
