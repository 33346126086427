import { css } from '@emotion/react';

import { THEME } from '../../../config/theme';

export const datePickerStyles = css`
  & .MuiPaper-elevation8 {
    box-shadow: 0 0.25rem 0.5rem 0 rgba(${THEME.color.black}, 0.1);
    border: 0.0625rem solid ${THEME.color.secondary2};
  }

  & .MuiPickersDay-root {
    font: normal normal 1rem/1rem ${THEME.font.gotham};
    color: ${THEME.color.black};
    &:hover {
      border-radius: '0.25rem';
      background-color: ${THEME.color.hover};
    }
    &.Mui-selected {
      border-radius: 0.25rem;
      background-color: ${THEME.color.secondary1};
      color: ${THEME.color.white};
      font-weight: 700;
    }
  }
  & .PrivatePickersFadeTransitionGroup-root {
    color: ${THEME.color.primary};
    font: normal 700 1rem/1rem ${THEME.font.gotham};
    letter-spacing: 0.0125rem;
    & .MuiTypography-caption {
      color: ${THEME.color.secondary1};
      font: normal 700 0.75rem/1rem ${THEME.font.gotham};
      letter-spacing: 0.0125rem;
    }
  }
  & .MuiPickersDay-today {
    background-color: transparent;
    color: ${THEME.color.black};
    font: normal 700 1rem/1rem ${THEME.font.gotham};
    border: none;
    border-radius: 0.25rem;
  }
  & .MuiYearPicker-root {
    & .PrivatePickersYear-yearButton {
      color: ${THEME.color.black};
    }
    & .Mui-selected {
      color: ${THEME.color.secondary1};
      font: normal 700 1rem/1rem ${THEME.font.gotham};
      background-color: transparent;
    }
  }
  & .MuiMonthPicker-root {
    & .PrivatePickersFadeTransitionGroup-root {
      color: ${THEME.color.black};
      font: normal normal 1rem/1rem ${THEME.font.gotham};
    }
    & .Mui-selected {
      font: normal 700 1rem/1rem ${THEME.font.gotham};
      background-color: transparent;
      color: ${THEME.color.secondary1};
    }
  }
`;
