import { ReactComponent as InfoIcon } from '../../../assets/icons/info-icon.svg';
import Tooltip from '../Tooltip/Tooltip';
import { InfoComponentType } from './InfoComponent';

const InfoComponentDesktop = ({ tooltip }: InfoComponentType) => (
  <Tooltip title={tooltip} placement="left">
    <InfoIcon />
  </Tooltip>
);

export default InfoComponentDesktop;
