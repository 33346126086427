import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

import RemoveButton from '../../../../../../../components/UI/RemoveButton/RemoveButton';
import { mq } from '../../../../../../../utils/mediaQueries';

export const MemberWrapper = styled.li`
  background: ${({ theme }) => theme.color.white};
  border: 1px solid ${({ theme }) => theme.color.light1};
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(153, 172, 188, 0.15);
  box-sizing: border-box;
  color: ${({ theme }) => theme.color.black};
  display: flex;
  flex-direction: column;
  font: normal 400 1rem/1.25rem ${({ theme }) => theme.font.gotham};
  list-style: none;
  padding: 0.75rem 1rem;
  justify-content: center;
  min-height: 4.75rem;
  position: relative;
`;

export const Title = styled.p`
  color: ${({ theme }) => theme.color.black};
  display: block;
  font: normal 500 1rem/1.5rem ${({ theme }) => theme.font.gotham};
  margin-bottom: 0.25rem;
`;

export const StyledRemoveButton = styled(RemoveButton)`
  position: absolute;
  right: 1rem;
  top: 1rem;
`;

export const MemberInfo = styled.p`
  font: normal normal 1rem/1.5rem ${({ theme }) => theme.font.gotham};
  color: ${({ theme }) => theme.color.black};
`;

export const MemberNumber = styled.p`
  font: normal normal 1rem/1.5rem ${({ theme }) => theme.font.gotham};
  color: ${({ theme }) => theme.color.dark2};
  word-wrap: break-word;
  max-width: 16rem;

  ${mq.xSmall(css`
    max-width: none;
  `)}
`;
