import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

import { ISwitchProps, SwitchNoStyled } from './Switch';

type StyledSwitchProps = ISwitchProps & {
  isError?: boolean;
  isBoldLabel?: boolean;
};

export const StyledSwitch = styled((props: StyledSwitchProps) => (
  <SwitchNoStyled
    {...props}
    classesSwitch={{
      disabled: 'custom-switch-disabled',
      root: 'custom-switch-root',
      thumb: 'custom-switch-thumb',
      checked: 'custom-switch-checked',
      switchBase: 'custom-switch-base',
      track: 'custom-switch-track',
    }}
    classesWrapper={{
      root: 'custom-switch-wrapper',
      label: 'custom-switch-label',
    }}
  />
))`
  & .custom-switch-disabled {
    .custom-switch-thumb {
      background-color: ${({ theme }) => theme.color.light1};
    }
  }

  & .custom-switch-root {
    width: 2rem;
    height: 1rem;
    margin-right: 0.5rem;
    padding: 0;
    border-radius: 6.25rem;
    ${({ isError, theme }) =>
      isError &&
      css`
        border: 0.063rem solid ${theme.color.error};
      `}
    background-color: ${({ theme, checked, disabled }) =>
      disabled
        ? theme.color.dark2
        : checked
        ? theme.color.secondary1
        : theme.color.main_background};
  }

  & .custom-switch-thumb {
    width: 0.9rem;
    height: 0.9rem;
    margin-left: 0.05rem;
    margin-top: 0.05rem;
    box-shadow: none;
    background-color: ${({ theme }) => theme.color.white};
    border: 1px solid ${({ theme }) => theme.color.main_background};
  }

  & .custom-switch-checked {
    color: ${({ theme }) => theme.color.white} !important;
    transform: translateX(1rem) !important;
  }

  & .custom-switch-base {
    padding: 0;
  }

  &.custom-switch-wrapper {
    margin-left: 0;
    margin-right: 0;
  }

  & .custom-switch-label {
    font: normal normal 1rem/1.5rem ${({ theme }) => theme.font.gotham};

    color: ${({ theme }) => theme.color.black};

    ${({ theme, isBoldLabel }) =>
      isBoldLabel &&
      css`
        color: ${theme.color.black};
        font-weight: bold;
      `}

    ${({ theme, isError }) =>
      isError &&
      css`
        color: ${theme.color.error};
      `}
  }

  & .custom-switch-track {
    background-color: ${({ theme, checked, disabled }) =>
      disabled
        ? theme.color.dark2
        : checked
        ? theme.color.secondary1
        : theme.color.main_background} !important;
  }
`;
