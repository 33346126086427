import axios, { AxiosResponse } from 'axios';

import { MemberType } from '../../../../models/enums/MemberType';
import { IMemberAgeAnalysisResult } from '../../../../models/interfaces/MemberAgeAnalysisResult';

import { IMembershipPlansTreeModel } from '../../../../models/interfaces/MembershipPlansTreeModel';
import {
  prepareQueryParams,
  QueryParamItem,
} from '../../../../utils/queryParamsHelpers';
import { IMembershipPlanAvailability } from './models';

const MainUrl = '/api/membershipplans';

export const getAAMembershipPlans = (
  promoCode: string,
  effectiveDate?: string,
  cancelToken?: AbortSignal,
): Promise<AxiosResponse<IMembershipPlansTreeModel>> => {
  const queryParams: QueryParamItem[] = [
    {
      name: 'promoCode',
      value: promoCode,
    },
    {
      name: 'effectiveDate',
      value: effectiveDate,
    },
  ];
  const params = prepareQueryParams({ queryParams });
  return axios.get(`${MainUrl}${params}`, {
    signal: cancelToken,
  });
};

export const analyzeAAMemberAge = (
  membershipPlanId: string,
  memberType: MemberType,
  dateOfBirth: string,
  cancelToken?: AbortSignal,
  renewedMembershipId?: string,
): Promise<AxiosResponse<IMemberAgeAnalysisResult>> => {
  const queryParams: QueryParamItem[] = [
    {
      name: 'dateOfBirth',
      value: dateOfBirth,
    },
    {
      name: 'renewedMembershipId',
      value: renewedMembershipId,
    },
  ];
  const params = prepareQueryParams({ queryParams });
  return axios.get(
    `${MainUrl}/${membershipPlanId}/member/${memberType}/memberageanalysis${params}`,
    {
      signal: cancelToken,
    },
  );
};

export const checkAAPlanAvailabilityRequest = (
  membershipPlanId: string,
  cancelToken?: AbortSignal,
  renewMembershipId?: string,
): Promise<AxiosResponse<IMembershipPlanAvailability>> => {
  const queryParams: QueryParamItem[] = [
    {
      name: 'renewMembershipId',
      value: renewMembershipId,
    },
  ];
  const params = prepareQueryParams({ queryParams });
  return axios.get(`${MainUrl}/${membershipPlanId}/availability${params}`, {
    signal: cancelToken,
  });
};
