import { useTranslation } from 'react-i18next';

import config from '../../config/config';
import { currentYear } from '../../utils/dateHelpers';
import { DesktopLarge } from '../../utils/mediaQueries';
import { SectionSubTitle } from '../Typography/Typography';
import {
  ContactLink,
  PropertiesContainer,
  Wrapper,
  ContactContentContainer,
  Copyright,
  ContactLine,
  ContactContainer,
  TermsContainer,
  Link,
} from './pageFooter.styles';

const PageFooter = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <DesktopLarge>
        <ContactContainer>
          <SectionSubTitle>{t('headers.contact')}</SectionSubTitle>
          <ContactContentContainer>
            <PropertiesContainer>
              <ContactLink href={`mailto:${config.contact.airAmbulanceEmail}`}>
                {config.contact.airAmbulanceEmail}
              </ContactLink>
              <ContactLink href={`tel:${config.contact.phone}`}>
                {config.contact.phone}
              </ContactLink>
            </PropertiesContainer>
            <PropertiesContainer>
              <ContactLine>{config.contact.addressLine1}</ContactLine>
              <ContactLine>{config.contact.addressLine2}</ContactLine>
            </PropertiesContainer>
          </ContactContentContainer>
        </ContactContainer>
      </DesktopLarge>
      <TermsContainer>
        <Link
          onClick={() => window.open(config.termsAndConditionsLink, '_blank')}
        >
          {t('phrase.aa-terms-and-conditions')}
        </Link>
        <Link onClick={() => window.open(config.privacyPolicyLink, '_blank')}>
          {t('phrase.privacy-policy')}
        </Link>
        <DesktopLarge>
          <Copyright>{t('phrase.copyright', { year: currentYear })}</Copyright>
        </DesktopLarge>
      </TermsContainer>
    </Wrapper>
  );
};

export default PageFooter;
