import { Trans, useTranslation } from 'react-i18next';

import { ICreditCardInfo } from '../../../../models/interfaces/CreditCardInfo';
import { DEFAULT_EXPIRATION_DATE_CARD_FORMAT } from '../../../../utils/creditCardHelpers';
import { formatDate } from '../../../../utils/dateHelpers';
import {
  PaymentContainer,
  DetailsTitle,
  DetailsContainer,
  Label,
  Value,
  FullName,
} from '../profile.styles';
import CreditCardTypeNumber from '../../../../components/CreditCard/CreditCardTypeNumber/CreditCardTypeNumber';
import Spinner from '../../../../components/Spinner/Spinner';
import PaymentTools from './PaymentTools/PaymentTools';

type PaymentSectionProps = {
  creditCard?: ICreditCardInfo;
  onReplaceCreditCard?: (creditCardId: string) => void;
  onDeleteCreditCard?: (creditCardId: string) => void;
  onAddCreditCard?: () => void;
  areCreditCardsLoading: boolean;
  canRemoveCard?: boolean;
};

const PaymentSection = ({
  creditCard,
  onReplaceCreditCard,
  onDeleteCreditCard,
  onAddCreditCard,
  areCreditCardsLoading,
  canRemoveCard,
}: PaymentSectionProps) => {
  const { t } = useTranslation();

  if (areCreditCardsLoading) {
    return (
      <PaymentContainer>
        <Spinner size="5rem" />
      </PaymentContainer>
    );
  }

  return (
    <PaymentContainer>
      <DetailsTitle>
        <p>{t('headers.payment-method')}</p>
        <PaymentTools
          creditCard={creditCard}
          onAddCreditCard={onAddCreditCard}
          onDeleteCreditCard={onDeleteCreditCard}
          onReplaceCreditCard={onReplaceCreditCard}
          canRemoveCard={canRemoveCard}
        />
      </DetailsTitle>
      {creditCard?.creditCardId && (
        <DetailsContainer>
          <Label>{t('phrase.cc-details')}</Label>

          <Value>
            {creditCard?.cardHolderName && (
              <FullName>{creditCard?.cardHolderName}</FullName>
            )}
            <CreditCardTypeNumber
              creditCardType={creditCard?.cardType}
              creditCardNumber={creditCard?.cardLastDigits}
            />
            <div>
              <Trans>
                {t('phrase.card-expiration', {
                  value: formatDate(
                    creditCard?.expirationDate,
                    DEFAULT_EXPIRATION_DATE_CARD_FORMAT,
                  ),
                })}
              </Trans>
            </div>
          </Value>
        </DetailsContainer>
      )}
    </PaymentContainer>
  );
};

export default PaymentSection;
