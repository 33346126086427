import { useTranslation } from 'react-i18next';

import MainTooltip from '../../../components/UI/Tooltip/Tooltip';

import { ICreditCardInfo } from '../../../models/interfaces/CreditCardInfo';
import { IProfileModel } from '../../../models/interfaces/ProfileModel';
import { formatDate } from '../../../utils/dateHelpers';
import Spinner from '../../../components/Spinner/Spinner';

import {
  DetailsWrapper,
  DetailsTitle,
  Root,
  DetailsContainer,
  Label,
  Value,
  EditButton,
  AddressContainer,
  EllipsisValue,
} from './profile.styles';
import PaymentSection from './PaymentSection/PaymentSection';
import { getAddress } from './profile.utils';
import ProfileLogoSection from './ProfileLogoSection/ProfileLogoSection';

type Props = {
  profile: IProfileModel;
  isPersonLoading: boolean;
  areCreditCardsLoading: boolean;
  creditCard: ICreditCardInfo;
  onEditMemberDetails?: () => void;
  onReplaceCreditCard?: (creditCardId: string) => void;
  onDeleteCreditCard?: (creditCardId: string) => void;
  onAddCreditCard?: () => void;
};

export const Profile = ({
  isPersonLoading,
  areCreditCardsLoading,
  profile,
  creditCard,
  onEditMemberDetails,
  onReplaceCreditCard,
  onDeleteCreditCard,
  onAddCreditCard,
}: Props) => {
  const { t } = useTranslation();
  if (isPersonLoading) {
    return (
      <Root>
        <Spinner size="30%" />
      </Root>
    );
  }

  const {
    firstName,
    lastName,
    prefix,
    emailAddress,
    dateOfBirth,
    phone,
    billingAddress,
    residenceAddress,
    isCreditCardRequired,
    memberNumber,
    initials,
  } = profile;

  return (
    <Root>
      <ProfileLogoSection
        initials={initials}
        prefix={prefix}
        firstName={firstName}
        lastName={lastName}
        memberNumber={memberNumber}
      />
      <DetailsWrapper>
        <div>
          <DetailsTitle>
            {t('headers.personal-details')}
            <MainTooltip title={t('phrase.edit-details-tooltip')}>
              <EditButton onClick={onEditMemberDetails} />
            </MainTooltip>
          </DetailsTitle>
          <DetailsContainer>
            <Label>{t('properties.email-address')}</Label>
            <MainTooltip width="auto" title={emailAddress}>
              <div>
                <EllipsisValue>{emailAddress}</EllipsisValue>
              </div>
            </MainTooltip>
            {phone && (
              <>
                <Label>{t('properties.phone-number')}</Label>
                <Value>{phone}</Value>
              </>
            )}
            <Label>{t('properties.dob')}</Label>
            <Value>{formatDate(dateOfBirth)}</Value>
          </DetailsContainer>
        </div>
        <div>
          <DetailsTitle>{t('properties.address')}</DetailsTitle>
          <DetailsContainer>
            {residenceAddress && (
              <>
                <Label>{t('phrase.residential-address')}</Label>
                <AddressContainer>
                  <MainTooltip title={getAddress(residenceAddress)}>
                    <div>
                      <EllipsisValue>{residenceAddress?.line1}</EllipsisValue>
                      <EllipsisValue>{residenceAddress?.line2}</EllipsisValue>
                      <EllipsisValue>{residenceAddress?.line3}</EllipsisValue>
                    </div>
                  </MainTooltip>
                </AddressContainer>
              </>
            )}
            {billingAddress && (
              <>
                <Label>{t('phrase.billing-address')}</Label>
                <AddressContainer>
                  <MainTooltip title={getAddress(billingAddress)}>
                    <div>
                      <EllipsisValue>{billingAddress?.line1}</EllipsisValue>
                      <EllipsisValue>{billingAddress?.line2}</EllipsisValue>
                      <EllipsisValue>{billingAddress?.line3}</EllipsisValue>
                    </div>
                  </MainTooltip>
                </AddressContainer>
              </>
            )}
          </DetailsContainer>
        </div>
        <PaymentSection
          creditCard={creditCard}
          areCreditCardsLoading={areCreditCardsLoading}
          onReplaceCreditCard={onReplaceCreditCard}
          onDeleteCreditCard={onDeleteCreditCard}
          onAddCreditCard={onAddCreditCard}
          canRemoveCard={!isCreditCardRequired}
        />
      </DetailsWrapper>
    </Root>
  );
};
