import { ReactNode } from 'react';

import { ClassNames, Global } from '@emotion/react';

import {
  GlobalStyles,
  ModalContainer,
  modalOverlayClass,
  StyledButton,
  StyledModal,
} from './modal.styles';
import { ReactComponent as CloseIcon } from '../../assets/icons/close-dark-icon.svg';
import Spinner from '../Spinner/Spinner';
import { useModal } from './modal.hooks';

type Props = {
  className?: string;
  disabled?: boolean;
  isLoading?: boolean;
  name: string;
  onModalShow?: () => void;
  overlayClassName?: string;
  onModalClose?: () => void;
  children: ReactNode;
};

export const Modal = ({
  children,
  className,
  disabled,
  isLoading,
  name,
  onModalShow,
  overlayClassName,
  onModalClose,
}: Props) => {
  const { closeModalHandler, modalsState } = useModal({
    onModalClose,
    onModalShow,
    name,
  });

  return (
    <ClassNames>
      {({ css }) => (
        <>
          <StyledModal
            ariaHideApp={false}
            className={className}
            isOpen={modalsState}
            overlayClassName={css([modalOverlayClass, overlayClassName])}
            preventScroll
          >
            <Global styles={GlobalStyles(modalsState)} />
            <Spinner isVisible={isLoading} size="10rem">
              <ModalContainer>{children}</ModalContainer>
            </Spinner>
            <StyledButton
              onClick={closeModalHandler}
              disabled={disabled}
              styleType="icon"
            >
              <CloseIcon />
            </StyledButton>
          </StyledModal>
        </>
      )}
    </ClassNames>
  );
};
