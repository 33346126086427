import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

import {
  SectionTitle,
  StandardText,
} from '../../../../../../../../components/Typography/Typography';
import { isPropertyEmpty } from '../../../../../../../../utils/propertyHelpers';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 32.7rem;
`;

type RegularPriceType = {
  overwritten?: boolean;
};

export const Price = styled((props: any) => (
  <SectionTitle {...props}>{`$${props.children || 0}`}</SectionTitle>
))<RegularPriceType>`
  color: ${({ theme }) => theme.color.secondary1};
  margin-top: 0.5rem;
  ${({ children }) =>
    isPropertyEmpty(children) &&
    css`
      opacity: 0;
    `}
  ${({ overwritten, theme }) =>
    overwritten &&
    css`
      text-decoration: line-through;
      font: normal normal 0.875rem/0.875rem ${theme.font.gotham};
      color: ${theme.color.dark2};
    `}
`;

export const PriceContainer = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 0.5rem;
`;

export const Description = styled(StandardText)`
  color: ${({ theme }) => theme.color.dark1};
  margin-top: 1rem;
`;
