import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';

import CreditCardDetailsForm from '../../../../../../../components/Forms/CreditCardDetailsForm/CreditCardDetailsForm';
import Radio from '../../../../../../../components/UI/Radio/Radio';
import {
  RadioContainer,
  RadioContent,
  Wrapper,
} from '../../../../../../../components/UI/RadioGroupContent/radioGroupContent.styles';
import { CustomComponent } from '../../../../../../../models/types/CustomComponent';
import { firstCreditCardSelector } from '../../../../../../../store/redux-slices/payment/selectors';
import { useMobileMediaQuery } from '../../../../../../../utils/mediaQueries';
import PaymentExistingCardForm from '../PaymentUseExistingCardForm/PaymentUseExistingCardForm';
import { PaymentType } from './paymentSection.models';

type PaymentGroupProps = CustomComponent & {
  onChange?: (paymentType: PaymentType) => void;
  checked?: PaymentType;
};

const PaymentGroup = ({ checked, disabled, onChange }: PaymentGroupProps) => {
  const { t } = useTranslation();
  const isMobile = useMobileMediaQuery();
  const firstCreditCard = useSelector(firstCreditCardSelector);

  const isExistingCreditCardChecked =
    checked === PaymentType.UseExistingCreditCard;

  return (
    <Wrapper>
      <RadioContainer>
        <Radio
          disabled={disabled || firstCreditCard?.isExpired}
          label={t(`phrase.${PaymentType.UseExistingCreditCard}`)}
          onChange={() => onChange(PaymentType.UseExistingCreditCard)}
          checked={isExistingCreditCardChecked}
        />
        <RadioContent>
          <PaymentExistingCardForm
            disabled={!isExistingCreditCardChecked || disabled}
          />
        </RadioContent>
      </RadioContainer>
      <RadioContainer>
        <Radio
          disabled={disabled}
          label={t(
            `phrase.${
              PaymentType.PayWithDifferentCard + (isMobile ? '-short' : '')
            }`,
          )}
          onChange={() => onChange(PaymentType.PayWithDifferentCard)}
          checked={checked === PaymentType.PayWithDifferentCard}
        />
        <RadioContent isHidden={checked !== PaymentType.PayWithDifferentCard}>
          <CreditCardDetailsForm disabled={disabled} />
        </RadioContent>
      </RadioContainer>
    </Wrapper>
  );
};

export default PaymentGroup;
