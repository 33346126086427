import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';

import { useActions } from '../../../../../../../hooks/useActions';
import { useModalDetails } from '../../../../../../../hooks/useModalDetails';
import { useProcessing } from '../../../../../../../hooks/useProcessing';
import { updateModalState } from '../../../../../../../store/redux-slices/modals';
import { FAMILY_MEMBER_REMOVING_PROCESSING } from '../../../../../../../store/redux-slices/processes/constants';
import { removeSRFamilyMember } from '../../../../../../../store/saga-slices/membership/securityResponse';
import { IRemoveSRFamilyMemberPayload } from '../../../../../../../store/saga-slices/membership/securityResponse/models';
import { REMOVE_SR_FAMILY_MEMBER_MODAL } from './removeSRFamilyMemberModal.const';
import { RemoveSRFamilyMemberModalDetails } from './removeSRFamilyMemberModal.models';

type UseRemoveSRFamilyMemberModalProps = {
  onFamilyMemberDeleted?: () => void;
  id?: string;
};

export const useRemoveSRFamilyMemberModal = ({
  onFamilyMemberDeleted,
  id,
}: UseRemoveSRFamilyMemberModalProps) => {
  const { t } = useTranslation();
  const UpdateModalState = useActions(updateModalState);
  const alert = useAlert();
  const RemoveFamilyMember = useActions(removeSRFamilyMember);

  const memberRemoving = useProcessing(FAMILY_MEMBER_REMOVING_PROCESSING);

  const { member, membership }: RemoveSRFamilyMemberModalDetails =
    useModalDetails(REMOVE_SR_FAMILY_MEMBER_MODAL + id);

  const removeFamilyMemberHandler = () => {
    const payload: IRemoveSRFamilyMemberPayload = {
      memberId: member?.memberId,
      membershipId: membership?.membershipId,
      success: () => {
        onFamilyMemberDeleted?.();
        UpdateModalState(REMOVE_SR_FAMILY_MEMBER_MODAL + id);
        alert.success(t('phrase.member-was-removed-successfully'));
      },
      error: () => {
        alert.error(t('phrase.member-was-not-removed-successfully'));
      },
    };

    RemoveFamilyMember(payload);
  };

  const onCancelHandler = () => {
    UpdateModalState(REMOVE_SR_FAMILY_MEMBER_MODAL + id);
  };

  return { member, onCancelHandler, removeFamilyMemberHandler, memberRemoving };
};
