import { Trans, useTranslation } from 'react-i18next';

import InfoComponentMobile from '../../../../../../components/UI/InfoComponent/InfoComponentMobile';

import Switch from '../../../../../../components/UI/Switch/Switch';

import { MobileMax } from '../../../../../../utils/mediaQueries';
import { boolToOnOff } from '../../../../../../utils/propertyHelpers';
import { AutoRenewValueContainer } from './autoRenewFlag.styles';

type AutoRenewFlagProps = {
  canRenew: boolean;
  isRenewableByPlanType: boolean;
  autoRenewal: boolean;
  onUpdateAutoRenewFlag?: () => void;
};

const AutoRenewFlag = ({
  canRenew,
  autoRenewal,
  onUpdateAutoRenewFlag,
  isRenewableByPlanType,
}: AutoRenewFlagProps) => {
  const { t } = useTranslation();

  return (
    <AutoRenewValueContainer>
      <Switch
        label={t(
          `properties.${boolToOnOff(autoRenewal && isRenewableByPlanType)}`,
        ).toLocaleLowerCase()}
        checked={autoRenewal && isRenewableByPlanType}
        onChange={onUpdateAutoRenewFlag}
        disabled={!canRenew || !isRenewableByPlanType}
      />
      {!canRenew && (
        <MobileMax>
          <InfoComponentMobile
            tooltipPlacement="top"
            tooltip={<Trans>{t('phrase.this-option-is-not-available')}</Trans>}
          />
        </MobileMax>
      )}
    </AutoRenewValueContainer>
  );
};

export default AutoRenewFlag;
