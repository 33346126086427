import { createSliceSaga, SagaType } from 'redux-toolkit-saga';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'typed-redux-saga/macro';
import { AxiosResponse } from 'axios';

import { updateProcessState } from '../../redux-slices/processes';
import { Log } from '../../../utils/logger';
import {
  FAMILY_MEMBERS_LOAD_PROCESSING,
  PRIMARY_MEMBER_LOAD_PROCESSING,
} from '../../redux-slices/processes/constants';
import { ILoadFamilyMembersPayload, ILoadPrimaryMemberPayload } from './models';
import { getFamilyMembers, getPrimaryMemberModel } from './api';
import { IIndividualPrimaryMember } from '../../../models/interfaces/IndividualPrimaryMember';
import { setFamilyMembers, setPrimaryMember } from '../../redux-slices/members';
import { IIndividualFamilyMembers } from '../../../models/interfaces/IndividualFamilyMembers';

const membersSlice = createSliceSaga({
  caseSagas: {
    *loadPrimaryMember({
      payload: { success, error, cancellationToken },
    }: PayloadAction<ILoadPrimaryMemberPayload>) {
      try {
        yield put(updateProcessState(PRIMARY_MEMBER_LOAD_PROCESSING));
        const response: AxiosResponse<IIndividualPrimaryMember> = yield* call(
          getPrimaryMemberModel,
          cancellationToken,
        );
        yield put(setPrimaryMember(response.data));
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(PRIMARY_MEMBER_LOAD_PROCESSING));
      }
    },
    *loadFamilyMembers({
      payload: { membershipPlanId, success, error, cancellationToken },
    }: PayloadAction<ILoadFamilyMembersPayload>) {
      try {
        yield put(updateProcessState(FAMILY_MEMBERS_LOAD_PROCESSING));
        const response: AxiosResponse<IIndividualFamilyMembers> = yield* call(
          getFamilyMembers,
          membershipPlanId,
          cancellationToken,
        );
        yield put(setFamilyMembers(response.data));
        success?.(response.data);
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(FAMILY_MEMBERS_LOAD_PROCESSING));
      }
    },
  },
  name: 'members-saga',
  sagaType: SagaType.TakeLatest,
});

export default membersSlice.saga;
export const { loadPrimaryMember, loadFamilyMembers } = membersSlice.actions;
export const { actions } = membersSlice;
