import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

import { mq } from '../../utils/mediaQueries';

import NeedHelpInfo from '../NeedHelpInfo/NeedHelpInfo';
import { SectionTitle, StandardText } from '../Typography/Typography';
import Button from '../UI/Button/Button';

export const Circle = styled.div`
  width: 11rem;
  height: 11rem;
  border-radius: 6rem;
  background-color: ${({ theme }) => theme.color.hover};
`;

export const Title = styled(SectionTitle)`
  margin: 2.5rem 2rem;
`;

export const StyledButton = styled(Button)`
  margin-top: 3rem;
`;

export const StyledNeedHelpInfo = styled(NeedHelpInfo)`
  margin-top: 2.5rem;

  ${mq.xSmall(
    css`
      margin-top: 2.5rem;
    `,
  )}
`;

export const Description = styled(StandardText)`
  text-align: center;
`;

export const Image = styled.img`
  height: 15rem;
  width: 27.5rem;
`;
