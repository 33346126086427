import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { FieldInputProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { getPropertyName } from '../../../../utils/getPropertyName';

type UseFormInputWithButtonType = {
  onBlur?: () => void;
  input: FieldInputProps<string, HTMLElement>;
  labelAnimation?: boolean;
  placeholder?: string;
  onButtonClick?: (value: string) => void;
};

export const useFormInputWithButton = ({
  onBlur,
  input,
  labelAnimation,
  placeholder,
  onButtonClick,
}: UseFormInputWithButtonType) => {
  const { t } = useTranslation();
  const [value, setValue] = useState<string>(input.value);

  const onChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  }, []);

  useEffect(() => {
    setValue(input.value);
  }, [input.value]);

  const buttonClickHandler = useCallback(() => {
    onButtonClick ? onButtonClick(value) : input.onChange(value);
  }, [input, onButtonClick, value]);

  const clearValueHandler = useCallback(() => {
    input.onChange(null);
    setValue('');
  }, [input]);

  const onBlurHandler = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      onBlur && onBlur();
      input.onBlur(event);
    },
    [onBlur, input],
  );
  const preparedPlaceholder = useMemo(
    () =>
      labelAnimation
        ? ' '
        : placeholder || t(`fields.${getPropertyName(input.name)}.placeholder`),
    [input.name, labelAnimation, placeholder, t],
  );

  return {
    onChange,
    onBlurHandler,
    preparedPlaceholder,
    value,
    buttonClickHandler,
    clearValueHandler,
  };
};
