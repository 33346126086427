import { useRef, useCallback, useEffect } from 'react';

export function useDebounce() {
  const timer = useRef(null);

  useEffect(
    () => () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    },
    [],
  );

  const debounce = useCallback((action: () => void, delay: number) => {
    if (timer.current) {
      clearTimeout(timer.current);
    }

    timer.current = setTimeout(action, delay);
  }, []);

  return { timer, debounce };
}
