import { AnySchema } from 'ajv';

import { FieldErrorMessage } from '../../../../../../../models/interfaces/FieldErrorMessage';
import { IUpdatePersonDetailsPayload } from '../../../../../../../store/saga-slices/personalData/models';
import { validateFormData } from '../../../../../../../utils/validations/validateFormData';
import { MemberPersonalDetailsFormFields } from './memberPersonalDetailsForm.models';

export const memberPersonalDetailsFormSchema: AnySchema | any = {
  type: 'object',
  properties: {
    [MemberPersonalDetailsFormFields.Phone]: {
      type: 'string',
      format: 'phone',
    },
  },
  required: [],
};

export const validateMemberPersonalDetailsForm = (
  data: IUpdatePersonDetailsPayload,
  additionalErrors?: FieldErrorMessage[],
) => validateFormData(data, additionalErrors, memberPersonalDetailsFormSchema);
