import styled from '@emotion/styled/macro';

type StyledButtonType = {
  isOpen: boolean;
};

export const StyledButton = styled.div<StyledButtonType>`
  height: 1.4rem;
  width: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  cursor: pointer;

  div {
    width: 1.5rem;
    height: 0.125rem;
    background: ${({ theme }) => theme.color.white};
    border-radius: 0.625rem;
    transition: all 0.3s linear;
    transform-origin: 0.063rem;
    position: relative;

    :first-of-type {
      transform: ${({ isOpen }) => (isOpen ? 'rotate(45deg)' : 'rotate(0)')};
    }

    :nth-of-type(2) {
      opacity: ${({ isOpen }) => (isOpen ? '0' : '1')};
      transform: ${({ isOpen }) =>
        isOpen ? 'translateX(1rem)' : 'translateX(0)'};
    }

    :nth-of-type(3) {
      transform: ${({ isOpen }) => (isOpen ? 'rotate(-45deg)' : 'rotate(0)')};
    }
  }
`;
