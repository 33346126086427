import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { transparentize } from 'polished';

export const Wrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

export const Overlay = styled.div`
  background-color: ${({ theme }) => transparentize(0.95, theme.color.primary)};
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 20;
`;

type ContainerType = {
  withChildren?: boolean;
};

export const Container = styled.div<ContainerType>`
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  display: flex;
  right: 0;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 20;
  height: 100%;

  ${({ withChildren }) =>
    withChildren &&
    css`
      position: absolute;
    `}
`;

export const Icon = styled.img`
  height: 100%;
`;
