import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  SectionTitle,
  SectionWrapper,
} from '../../../../../../../components/Form/Form';
import FormInputWithButton from '../../../../../../../components/UI/Form/FormInputWithButton/FormInputWithButton';
import { MessageStatus } from '../../../../../../../components/UI/StatusMessage/statusMessage.models';
import { useProcessing } from '../../../../../../../hooks/useProcessing';
import { CustomForm } from '../../../../../../../models/types/CustomForm';
import { aaPromoCodePlanSelector } from '../../../../../../../store/redux-slices/plans/airAmbulance/selectors';
import { MEMBERSHIP_PLANS_LOAD_PROCESSING } from '../../../../../../../store/redux-slices/processes/constants';
import { useMobileMediaQuery } from '../../../../../../../utils/mediaQueries';
import { PromotionCodeFields } from './promotionCode.models';
import { StyledStatusMessage } from './promotionCode.styles';

const PromotionCodeForm = ({ className, disabled }: CustomForm) => {
  const { t } = useTranslation();

  const { appliedPromoCode, isPromoCodeApplied } = useSelector(
    aaPromoCodePlanSelector,
  );
  const membersPlansLoading = useProcessing(MEMBERSHIP_PLANS_LOAD_PROCESSING);
  const isMobile = useMobileMediaQuery();

  return (
    <SectionWrapper className={className}>
      <SectionTitle>{t('headers.promotion-code')}</SectionTitle>
      <Field
        component={FormInputWithButton}
        label={t(
          `fields.${!isMobile ? 'promoCodeOptional' : 'promoCode'}.label`,
        )}
        buttonLabel={t(`buttons.${!isPromoCodeApplied ? 'apply' : 'clear'}`)}
        disabled={disabled || membersPlansLoading}
        inputDisabled={isPromoCodeApplied}
        inputId="add-membership-promotion-code-promo-code-input"
        name={PromotionCodeFields.PromoCode}
        isClearable={isPromoCodeApplied}
      />
      {isPromoCodeApplied && (
        <StyledStatusMessage status={MessageStatus.Success}>
          {t('phrase.promo-code-applied', { value: appliedPromoCode })}
        </StyledStatusMessage>
      )}
    </SectionWrapper>
  );
};

export default PromotionCodeForm;
