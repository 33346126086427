import { useAlert } from 'react-alert';
import { Trans, useTranslation } from 'react-i18next';

import { copyToClipboard } from '../../../../../../utils/clipboard';
import { CopyToClipboard, PromoCodeWrapper, Root } from './promoCode.styles';

type Props = {
  promoCode: string;
  promoCodeOwner?: string;
};

export const PromoCode = ({ promoCode, promoCodeOwner }: Props) => {
  const { t } = useTranslation();
  const alert = useAlert();
  const handleCopy = async (copyValue: string) => {
    await copyToClipboard(copyValue);
    alert.success(<div>{t('phrase.copy-success')}</div>);
  };

  return (
    <Root>
      <PromoCodeWrapper>
        {promoCode}
        <CopyToClipboard onClick={() => handleCopy(promoCode)} />
      </PromoCodeWrapper>
      {promoCodeOwner && (
        <div>
          <Trans>{t('phrase.promo-code-for', { value: promoCodeOwner })}</Trans>
        </div>
      )}
    </Root>
  );
};
