import { createSliceSaga, SagaType } from 'redux-toolkit-saga';
import { put, call } from 'typed-redux-saga/macro';
import { PayloadAction } from '@reduxjs/toolkit';

import { AxiosResponse } from 'axios';

import { Log } from '../../../../utils/logger';
import {
  IAddKEFamilyMemberPayload,
  ILoadKEMembershipsPayload,
  IRemoveKEFamilyMemberPayload,
} from './models';
import { updateProcessState } from '../../../redux-slices/processes';
import {
  FAMILY_MEMBER_ADDING_PROCESSING,
  FAMILY_MEMBER_REMOVING_PROCESSING,
  MEMBERSHIPS_KE_LOAD_PROCESSING,
} from '../../../redux-slices/processes/constants';
import {
  createKESecondaryMember,
  deleteKESecondaryMember,
  getKEMemberships,
} from './api';
import { IMemberships } from '../../../../models/interfaces/IMemberships';
import { setKEMemberships } from '../../../redux-slices/membership/kidnapAndExtortion';

const keMembershipSlice = createSliceSaga({
  caseSagas: {
    *addKEFamilyMember({
      payload: { error, success, data, membershipId, cancellationToken },
    }: PayloadAction<IAddKEFamilyMemberPayload>) {
      try {
        yield put(updateProcessState(FAMILY_MEMBER_ADDING_PROCESSING));
        yield call(
          createKESecondaryMember,
          membershipId,
          data,
          cancellationToken,
        );
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(FAMILY_MEMBER_ADDING_PROCESSING));
      }
    },
    *removeKEFamilyMember({
      payload: { error, success, membershipId, memberId, cancellationToken },
    }: PayloadAction<IRemoveKEFamilyMemberPayload>) {
      try {
        yield put(updateProcessState(FAMILY_MEMBER_REMOVING_PROCESSING));
        yield call(
          deleteKESecondaryMember,
          membershipId,
          memberId,
          cancellationToken,
        );
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(FAMILY_MEMBER_REMOVING_PROCESSING));
      }
    },
    *loadKEMemberships({
      payload: { error, success, cancellationToken },
    }: PayloadAction<ILoadKEMembershipsPayload>) {
      try {
        yield put(updateProcessState(MEMBERSHIPS_KE_LOAD_PROCESSING));

        const response: AxiosResponse<IMemberships> = yield* call(
          getKEMemberships,
          cancellationToken,
        );

        yield put(setKEMemberships({ memberships: response.data.memberships }));
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(MEMBERSHIPS_KE_LOAD_PROCESSING));
      }
    },
  },
  name: 'ke-membership-saga',
  sagaType: SagaType.TakeLatest,
});

export default keMembershipSlice.saga;
export const { addKEFamilyMember, removeKEFamilyMember, loadKEMemberships } =
  keMembershipSlice.actions;
export const { actions } = keMembershipSlice;
