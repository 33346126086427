import { useEffect, useState } from 'react';

import { useDebounce } from '../../hooks/useDebounce';
import { isPropertyEmpty } from '../../utils/propertyHelpers';

import { SectionSubTitle } from '../Typography/Typography';

import LoadingColor from './icons/LoadingColor.svg';
import LoadingWhite from './icons/LoadingWhite.svg';
import { ISpinnerProps } from './spinner.models';
import { Container, Overlay, Wrapper, Icon } from './spinner.styles';

const Spinner = ({
  children,
  className,
  delaySpinner,
  isVisible,
  label,
  mode = 'dark',
  size = '100%',
  contentIsHidden = false,
}: ISpinnerProps) => {
  const [spinnerIsVisible, setSpinnerIsVisible] = useState(false);
  const { debounce } = useDebounce();

  useEffect(() => {
    if (isPropertyEmpty(children)) {
      setSpinnerIsVisible(true);
      return;
    }

    debounce(
      () => {
        setSpinnerIsVisible(isVisible);
      },
      isPropertyEmpty(delaySpinner) ? 300 : delaySpinner,
    );
  }, [children, debounce, delaySpinner, isVisible]);

  if (!children && !spinnerIsVisible) {
    return null;
  }

  return (
    <Wrapper className={className}>
      {(isVisible || spinnerIsVisible) && children && <Overlay />}
      {spinnerIsVisible && (
        <Container withChildren={!!children} style={{ height: `${size}` }}>
          <Icon
            alt="spinner"
            src={mode === 'dark' ? LoadingColor : LoadingWhite}
          />
          {label && <SectionSubTitle>{label}</SectionSubTitle>}
        </Container>
      )}
      {!contentIsHidden && children}
    </Wrapper>
  );
};

export default Spinner;
