import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

import { mq } from '../../../utils/mediaQueries';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  margin-top: 1.5rem;
  justify-content: space-between;
  float: right;
  flex-direction: row-reverse;
  gap: 0.5rem;

  ${mq.xSmall(css`
    margin-top: 3.5rem;
  `)}

  > :first-of-type {
    width: 100%;
    ${mq.xSmall(css`
      width: auto;
    `)}
  }
`;
