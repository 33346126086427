export interface IAddress {
  latitude?: number;
  longitude?: number;
  addressLine1: string;
  addressLine2: string;
  addressLine3?: string;
  zipCode: string;
  city: string;
  stateRegion: string;
  country: string;
}

export interface IAddressLines {
  line1: string;
  line2: string;
  line3: string;
}

export const addressInit: IAddress = {
  addressLine1: '',
  addressLine2: '',
  city: '',
  country: '',
  stateRegion: '',
  zipCode: '',
};
