import { FieldRenderProps, useField } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { SectionWrapper } from '../../../../../../../../components/Form/Form';
import FormFieldArrayTitle from '../../../../../../../../components/Form/FormFieldArrayTitle/FormFieldArrayTitle';
import FamilyMemberForm from '../../../../../../../../components/Forms/FamilyMemberForm/FamilyMemberForm';
import { FamilyMemberFields } from '../../../../../../../../components/Forms/FamilyMemberForm/familyMemberForm.models';
import FormMessage from '../../../../../../../../components/UI/Form/FormMessage/FormMessage';
import { useRouteParams } from '../../../../../../../../hooks/useRouteParams';
import { MemberType } from '../../../../../../../../models/enums/MemberType';
import { CustomField } from '../../../../../../../../models/types/CustomField';
import { selectedMembershipPlanSelector } from '../../../../../../../../store/redux-slices/membership/airAmbulance/selectors';
import { getFormGroupPath } from '../../../../../../../../utils/formGroupFieldHelpers';
import FamilyMemberMessage from './FamilyMemberMessage/FamilyMemberMessage';

type FamilyMemberSectionProps = CustomField & {
  onRemove?: () => void;
  index?: number;
  memberTypes: MemberType[];
};

const FamilyMemberSection = ({
  name,
  disabled,
  onRemove,
  index,
  memberTypes,
  readOnly,
}: FamilyMemberSectionProps) => {
  const { t } = useTranslation();
  const membershipPlan = useSelector(selectedMembershipPlanSelector);

  const {
    meta: { error },
  }: FieldRenderProps<MemberType> = useField(
    getFormGroupPath(name, FamilyMemberFields.DateOfBirth),
  );

  const { membershipId } = useRouteParams();

  return (
    <SectionWrapper key={name}>
      <FormFieldArrayTitle
        title={t('headers.family-member', { number: index + 1 })}
        disabled={disabled}
        onRemove={onRemove}
      />
      <FamilyMemberForm
        disabled={disabled || readOnly}
        name={name}
        memberTypes={memberTypes}
        birthOfDateErrorIsHidden={readOnly}
        membershipPlanId={membershipPlan?.planId}
        renewedMembershipId={membershipId}
      />
      {readOnly && error && (
        <FormMessage status="error" message={<FamilyMemberMessage />} />
      )}
    </SectionWrapper>
  );
};

export default FamilyMemberSection;
