import { useCallback, useEffect } from 'react';

import { useActions } from '../../../hooks/useActions';
import {
  changeToPrevStep,
  changeToNextStep,
  resetToInvalidAfterActiveStep,
} from '../../../store/saga-slices/wizard';

type UseWizardPageType = {
  onNextStepClick?: () => void;
  onPrevStepClick?: () => void;
  isDirty?: boolean;
};

export const useWizardPage = ({
  onNextStepClick,
  onPrevStepClick,
  isDirty,
}: UseWizardPageType) => {
  const ChangeToPrevStep = useActions(changeToPrevStep);
  const ChangeToNextStep = useActions(changeToNextStep);

  const ResetValidAfterActiveStep = useActions(resetToInvalidAfterActiveStep);

  const onNextStepClickHandler = useCallback(() => {
    ChangeToNextStep();
    onNextStepClick && onNextStepClick();
  }, [ChangeToNextStep, onNextStepClick]);

  const onPrevStepClickHandler = useCallback(() => {
    ChangeToPrevStep();
    onPrevStepClick && onPrevStepClick();
  }, [ChangeToPrevStep, onPrevStepClick]);

  useEffect(() => {
    if (isDirty) {
      ResetValidAfterActiveStep();
    }
  }, [ResetValidAfterActiveStep, isDirty]);

  return { onNextStepClickHandler, onPrevStepClickHandler };
};
