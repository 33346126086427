import { useEffect } from 'react';
import { FieldRenderProps, useField } from 'react-final-form';
import { useSelector } from 'react-redux';

import { useActions } from '../../../../../../hooks/useActions';
import { useProcessing } from '../../../../../../hooks/useProcessing';
import { useRouteParams } from '../../../../../../hooks/useRouteParams';
import { useCancellationToken } from '../../../../../../hooks/useTokenCancellation';
import {
  isSelectedMembershipPlanPeriodRenewal,
  membershipSummarySelector,
} from '../../../../../../store/redux-slices/membership/airAmbulance/selectors';
import { MEMBERSHIP_SUMMARY_LOAD_PROCESSING } from '../../../../../../store/redux-slices/processes/constants';
import { loadMembershipSummary } from '../../../../../../store/saga-slices/membership/airAmbulance';
import { ILoadMembershipSummaryPayload } from '../../../../../../store/saga-slices/membership/airAmbulance/models';
import { AutoRenewFormFields } from '../Forms/AutoRenewForm/autoRenewForm.models';

export const usePaymentSummary = () => {
  const cancellationToken = useCancellationToken();

  const LoadMembershipSummary = useActions(loadMembershipSummary);
  const dataLoading = useProcessing(MEMBERSHIP_SUMMARY_LOAD_PROCESSING);

  const membershipSummary = useSelector(membershipSummarySelector);

  const { membershipId } = useRouteParams();

  const isRenewalPlan = useSelector(isSelectedMembershipPlanPeriodRenewal);
  const {
    input: { value: autoRenewal },
  }: FieldRenderProps<boolean> = useField(AutoRenewFormFields.AutoRenewal);

  useEffect(() => {
    const payload: ILoadMembershipSummaryPayload = {
      autoRenewal: isRenewalPlan ? autoRenewal : false,
      renewMembershipId: membershipId,
      cancellationToken,
    };

    LoadMembershipSummary(payload);
  }, [
    LoadMembershipSummary,
    autoRenewal,
    cancellationToken,
    isRenewalPlan,
    membershipId,
  ]);

  return { membershipSummary, dataLoading };
};
