import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

import Button from '../../../../components/UI/Button/Button';
import downloadIcon from '../../../../assets/icons/download-icon.svg';

import { mq } from '../../../../utils/mediaQueries';
import { SecondPageTitle } from '../../../../components/Typography/Typography';

export const MainContainer = styled.div`
  align-items: flex-start;
  border-bottom: 1px solid ${({ theme }) => theme.color.light1};
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: start;
  padding: 0 0 2rem 0;
  width: 100%;

  ${mq.medium(css`
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  `)}

  ${mq.large(css`
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 2.5rem;
    padding: 0 0 2.5rem 0;
  `)}
`;

export const ProfileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
`;

export const ProfileIcon = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.color.hover};
  border: 1px solid ${({ theme }) => theme.color.secondary2};
  border-radius: 100%;
  color: ${({ theme }) => theme.color.secondary1};
  display: flex;
  font: normal 700 2rem/100% ${({ theme }) => theme.font.gotham};
  justify-content: center;
  width: 4rem;
  height: 4rem;

  ${mq.large(css`
    width: 6.25rem;
    height: 6.25rem;
    font-size: 3rem;
  `)}
`;

export const ProfileTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const ProfileName = styled.div`
  font: normal 500 1.375rem/2rem ${({ theme }) => theme.font.gotham};
  ${mq.large(css`
    font-size: 1.5rem;
  `)}
`;

export const MemberNumber = styled.div`
  font: normal 500 1rem/1rem ${({ theme }) => theme.font.gotham};
  color: ${({ theme }) => theme.color.dark2};
  ${mq.large(css`
    font-size: 1.125rem;
    line-height: 1.125rem;
  `)};
`;

export const MembershipCardButton = styled(Button)`
  align-items: center;
  background: url(${downloadIcon});
  background-repeat: no-repeat;
  background-position: center left;
  display: flex;
  font-weight: 500;
  gap: 1rem;
  padding-left: 2rem;
`;

MembershipCardButton.defaultProps = {
  styleType: 'link',
};

export const ProfileNameContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  justify-content: start;
  ${mq.large(css`
    align-items: center;
    flex-direction: column;
    justify-content: center;
  `)}
`;

export const Title = styled(SecondPageTitle)`
  margin-top: 1.5rem;

  ${mq.medium(css`
    margin-top: 0rem;
  `)}

  ${mq.large(css`
    display: none;
  `)}
`;
