import { createSliceSaga, SagaType } from 'redux-toolkit-saga';
import { PayloadAction } from '@reduxjs/toolkit';
import { put } from 'redux-saga/effects';
import { putResolve, select } from 'typed-redux-saga/macro';

import { updateActiveStep, updateValidSteps } from '../../redux-slices/wizard';
import {
  activeStepCnSelector,
  activeStepSelector,
  wizardStepsSelector,
  wizardVisibleStepIndexSelector,
  wizardAvailableStepsSelector,
} from '../../redux-slices/wizard/selectors';
import {
  IWizardStep,
  IWizardValidStep,
} from '../../redux-slices/wizard/models';

const wizardSagaSlice = createSliceSaga({
  name: 'wizard-saga',
  caseSagas: {
    *changeToNextStep() {
      const activeStepCn = yield* select(activeStepCnSelector);
      const visibleSteps = yield* select(wizardAvailableStepsSelector);

      const currentVisibleStepIndex = yield* select(
        wizardVisibleStepIndexSelector,
      );
      const nextVisibleStepIndex = currentVisibleStepIndex + 1;

      if (visibleSteps.length > nextVisibleStepIndex) {
        yield put(
          updateValidSteps([{ canonicalName: activeStepCn, isValid: true }]),
        );
        yield put(
          updateActiveStep(visibleSteps[nextVisibleStepIndex].canonicalName),
        );
      }
    },
    *changeToPrevStep() {
      const visibleSteps = yield* select(wizardAvailableStepsSelector);

      const currentVisibleStepIndex = yield* select(
        wizardVisibleStepIndexSelector,
      );
      const prevVisibleStepIndex = currentVisibleStepIndex - 1;

      if (prevVisibleStepIndex >= 0) {
        yield put(
          updateActiveStep(visibleSteps[prevVisibleStepIndex].canonicalName),
        );
      }
    },
    *changeStepByIndex(action: PayloadAction<number>) {
      const wizardSteps = yield* select(wizardStepsSelector);
      if (wizardSteps[action.payload].isValid === true) {
        yield put(updateActiveStep(wizardSteps[action.payload].canonicalName));
      }
    },
    *changeStepByCn(action: PayloadAction<string>) {
      const wizardSteps = yield* select(wizardAvailableStepsSelector);
      const stepIndex = wizardSteps.findIndex(
        (step) => step.canonicalName === action.payload,
      );
      if (
        stepIndex === 0 ||
        (wizardSteps[stepIndex - 1] &&
          wizardSteps[stepIndex - 1].isValid === true)
      ) {
        yield put(updateActiveStep(action.payload));
      }
    },
    *changeToLastValidStep() {
      const wizardSteps = yield* select(wizardStepsSelector);
      const validVisibleSteps = wizardSteps.filter(
        (step) => step.isValid && step.isAvailable,
      );

      if (validVisibleSteps.length > 0) {
        yield put(updateActiveStep(validVisibleSteps.pop().canonicalName));
        return yield;
      }

      if (validVisibleSteps.length === 0) {
        const visibleSteps = wizardSteps.filter((step) => step.isAvailable);
        yield put(updateActiveStep(visibleSteps[0].canonicalName));
      }
    },
    *changeToLastAvailableVisibleStep() {
      const wizardSteps = yield* select(wizardStepsSelector);
      const activeStep = yield* select(activeStepSelector);
      const visibleStepsAfterActiveStep: IWizardStep[] = wizardSteps
        .slice(activeStep.order)
        .filter((step) => step.isAvailable);

      if (visibleStepsAfterActiveStep.length > 0) {
        yield put(
          updateActiveStep(visibleStepsAfterActiveStep[0].canonicalName),
        );
        return yield;
      }

      const visibleStepsBeforeActiveStep: IWizardStep[] = wizardSteps
        .slice(0, activeStep.order)
        .filter((step) => step.isAvailable);
      if (visibleStepsBeforeActiveStep.length > 0) {
        yield put(
          updateActiveStep(visibleStepsBeforeActiveStep[0].canonicalName),
        );
        return yield;
      }

      const visibleSteps = wizardSteps.filter((step) => step.isAvailable);
      yield put(updateActiveStep(visibleSteps[0].canonicalName));
    },
    *changeActiveSteps({ payload }: PayloadAction<string[]>) {
      const wizardSteps = yield* select(wizardStepsSelector);
      const validSteps: IWizardValidStep[] = [];
      wizardSteps.forEach((step) => {
        let isValid = false;

        if (payload.findIndex((x) => x === step.canonicalName) !== -1) {
          isValid = true;
        }

        validSteps.push({
          canonicalName: step.canonicalName,
          isValid,
        });
      });

      yield putResolve(updateValidSteps(validSteps));
    },
    *resetToInvalidAfterActiveStep() {
      const activeStep = yield* select(activeStepCnSelector);
      const visibleSteps = yield* select(wizardAvailableStepsSelector);

      const activeStepIndex = visibleSteps.findIndex(
        (step) => step.canonicalName === activeStep,
      );

      const stepsToChangeValid: IWizardValidStep[] = visibleSteps
        .slice(activeStepIndex)
        .map((step) => ({ canonicalName: step.canonicalName, isValid: false }));

      yield putResolve(updateValidSteps(stepsToChangeValid));
    },
  },
  sagaType: SagaType.TakeLatest,
});

export const {
  changeActiveSteps,
  changeStepByCn,
  changeStepByIndex,
  changeToLastAvailableVisibleStep,
  changeToLastValidStep,
  changeToNextStep,
  changeToPrevStep,
  resetToInvalidAfterActiveStep,
} = wizardSagaSlice.actions;
export default wizardSagaSlice.saga;
