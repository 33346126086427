import { useTranslation } from 'react-i18next';

import { formatDate } from '../../../../../utils/dateHelpers';
import { Membership as MembershipType } from '../../../../../models/types/Membership';

import {
  MembershipContainer,
  MembershipHeader,
  MembershipSection,
  MembershipSectionItem,
  SectionItemDescription,
  SectionItemTitle,
  ItemInfo,
  MembershipDetails,
  MembershipDetailItem,
  ExpireIcon,
  ExpirationDateWrapper,
} from '../Common/membership.styles';

import { Members } from '../Common/Members/Members';
import { useSRMembership } from './srMembership.hooks';
import RemoveSRFamilyMemberModal from './Modals/RemoveSRFamilyMemberModal/RemoveSRFamilyMemberModal';
import AddSRFamilyMemberModal from './Modals/AddSRFamilyMemberModal/AddSRFamilyMemberModal';
import MembershipTitle from '../Common/MembershipTitle/MembershipTitle';

export type SRMembershipProps = {
  membership: MembershipType;
  onFamilyMemberAdded?: () => void;
  onFamilyMemberRemoved?: () => void;
};

export const SRMembership = ({
  membership,
  onFamilyMemberAdded,
  onFamilyMemberRemoved,
}: SRMembershipProps) => {
  const { t } = useTranslation();

  const {
    addFamilyMemberHandler,
    removeFamilyMemberHandler,
    showFamilyMembers,
  } = useSRMembership(membership);

  const {
    membershipId,
    productType,
    membershipPlanTypeName,
    membershipPlanTypeDescription,
    memberType,
    effectiveDate,
    isExpired,
    isExpiring,
    expirationDate,
    familyMembers,
    organizationName,
    metadata: { canAddMemberTypes },
  } = membership;

  return (
    <>
      <AddSRFamilyMemberModal
        id={membershipId}
        onFamilyMemberAdded={onFamilyMemberAdded}
      />
      <RemoveSRFamilyMemberModal
        id={membershipId}
        onFamilyMemberDeleted={onFamilyMemberRemoved}
      />
      <MembershipContainer>
        <MembershipHeader>
          <MembershipTitle
            organizationName={organizationName}
            productType={productType}
          />
        </MembershipHeader>
        <MembershipSection>
          <MembershipSectionItem>
            <ItemInfo>{t('properties.plan')}</ItemInfo>
            <SectionItemTitle>{membershipPlanTypeName}</SectionItemTitle>
            <SectionItemDescription>
              {membershipPlanTypeDescription}
            </SectionItemDescription>
          </MembershipSectionItem>
        </MembershipSection>
        <MembershipDetails>
          <MembershipDetailItem>
            <ItemInfo>{t('properties.member')}</ItemInfo>
            {memberType}
          </MembershipDetailItem>
          <MembershipDetailItem>
            <ItemInfo>{t('properties.effectiveDate')}</ItemInfo>
            {formatDate(effectiveDate)}
          </MembershipDetailItem>
          <MembershipDetailItem>
            <ItemInfo>{t('properties.expirationDate')}</ItemInfo>
            <ExpirationDateWrapper>
              {formatDate(expirationDate)}
              {isExpiring && <ExpireIcon />}
            </ExpirationDateWrapper>
          </MembershipDetailItem>
        </MembershipDetails>
        {showFamilyMembers && (
          <Members
            onAddFamilyMember={() => addFamilyMemberHandler?.()}
            onRemoveFamilyMember={(member) =>
              removeFamilyMemberHandler?.(member)
            }
            canRemoveMember={!isExpired}
            canAddMember={!!canAddMemberTypes?.length}
            membersList={familyMembers}
          />
        )}
      </MembershipContainer>
    </>
  );
};
