import { CustomField } from '../../../../../../../models/types/CustomField';
import FamilyEnrollmentModal from '../../../../../Modals/FamilyEnrollmentModal/FamilyEnrollmentModal';
import FamilyMembersForm from '../FamilyMembersForm/FamilyMembersForm';
import { useFamilyMembersSection } from './familyMembersSection.hooks';

type FamilyMembersSectionProps = CustomField & {
  maxDependentMembersCount?: number;
  maxSpouseMembersCount?: number;
};

const FamilyMembersSection = (props: FamilyMembersSectionProps) => {
  const {
    onAddFamilyMemberHandler,
    shouldShowFamilyMembersModal,
    familyMembers,
    onSelectFamilyMembersHandler,
  } = useFamilyMembersSection();
  return (
    <>
      <FamilyEnrollmentModal
        onSelectFamilyMembers={onSelectFamilyMembersHandler}
        members={familyMembers}
      />
      <FamilyMembersForm
        {...props}
        onAddFamilyMember={
          shouldShowFamilyMembersModal && onAddFamilyMemberHandler
        }
      />
    </>
  );
};

export default FamilyMembersSection;
