import { ErrorBoundary } from 'react-error-boundary';

import { useActions } from '../../hooks/useActions';

import { CustomComponent } from '../../models/types/CustomComponent';
import { resetError } from '../../store/redux-slices/errors';

import { Log } from '../../utils/logger';
import ActionErrorHandler from './ActionErrorHandler/ActionErrorHandler';
import FallbackComponent from './FallbackComponent/FallbackComponent';

const ErrorHandler = ({ children }: CustomComponent) => {
  const logError = (error: Error) => {
    Log.errorException(error);
  };
  const ResetError = useActions(resetError);

  return (
    <ErrorBoundary
      onReset={ResetError}
      onError={logError}
      FallbackComponent={FallbackComponent}
    >
      <ActionErrorHandler />
      {children}
    </ErrorBoundary>
  );
};

export default ErrorHandler;
