import { useTranslation } from 'react-i18next';

import { CustomComponent } from '../../models/types/CustomComponent';
import FormErrorActivator from '../FormErrorProvider/FormErrorActivator/FormErrorActivator';
import { StandardText } from '../Typography/Typography';
import {
  ButtonsContainer,
  CancelButton,
  Header,
  SaveButton,
  Title,
  Wrapper,
} from './modalFormPage.styles';

interface IModalFormPageProps extends CustomComponent {
  handleSubmit: any;
  isProcessing?: boolean;
  onCancel: any;
  title?: string;
  description?: string;
}

const ModalFormPage = ({
  children,
  className,
  handleSubmit,
  isProcessing,
  onCancel,
  title,
  description,
}: IModalFormPageProps) => {
  const { t } = useTranslation();

  return (
    <FormErrorActivator>
      <Wrapper className={className}>
        <Header>
          {title && <Title>{title}</Title>}
          {description && <StandardText>{description}</StandardText>}
        </Header>
        <form>{children}</form>
        <ButtonsContainer>
          <CancelButton
            disabled={isProcessing}
            onClick={onCancel}
            styleType="lighter-blue"
          >
            {t('buttons.cancel')}
          </CancelButton>
          <SaveButton
            isLoading={isProcessing}
            onClick={handleSubmit}
            type="submit"
          >
            {t('buttons.save')}
          </SaveButton>
        </ButtonsContainer>
      </Wrapper>
    </FormErrorActivator>
  );
};

export default ModalFormPage;
