import { MemberTitle } from '../../../../../../../models/enums/MemberTitle';
import { getOptionsFromEnum } from '../../../../../../../utils/getOptionsFromEnum';

export enum MemberDetailsFields {
  PersonPrefix = 'personPrefix',
  FirstName = 'firstName',
  LastName = 'lastName',
  PhoneNumber = 'phoneNumber',
  DateOfBirth = 'dateOfBirth',
}

export const MemberTitleOptions = getOptionsFromEnum(MemberTitle);
