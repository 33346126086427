import { Trans, useTranslation } from 'react-i18next';

import { Page } from '../Page/Page';
import { useErrorPage } from './errorPage.hooks';
import {
  Circle,
  Description,
  StyledButton,
  StyledNeedHelpInfo,
  Title,
  Image,
} from './errorPage.styles';

type ErrorPageType = {
  title?: string;
  description?: string;
  buttonLabel?: string;
  buttonAction?: () => void;
  iconPath?: string;
};

const ErrorPage = ({
  buttonAction,
  buttonLabel,
  description,
  title,
  iconPath,
}: ErrorPageType) => {
  const { t } = useTranslation();
  const { refreshPage } = useErrorPage();

  const customButton = buttonAction || buttonLabel;
  return (
    <Page>
      {iconPath ? <Image src={iconPath} alt="error" /> : <Circle />}
      <Title>{title}</Title>
      <Description>
        <Trans>{description}</Trans>
      </Description>
      <StyledButton
        styleType="lighter-blue"
        onClick={customButton ? buttonAction : refreshPage}
      >
        {customButton ? buttonLabel : t('buttons.refresh-page')}
      </StyledButton>
      <StyledNeedHelpInfo />
    </Page>
  );
};

export default ErrorPage;
