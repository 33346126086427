import { useState, useCallback } from 'react';

export const useDatePickerInput = () => {
  const [isFocused, setIsFocused] = useState(false);
  const onFocusInputHandler = useCallback((value: boolean) => {
    setIsFocused(value);
  }, []);

  return { isFocused, onFocusInputHandler };
};
