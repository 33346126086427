import styled from '@emotion/styled/macro';

import Button from '../Button/Button';

import { StyledInput } from '../Input/input.styles';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;

  ${StyledInput} {
    border-radius: 0;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-right: none;
    height: 100%;
  }
`;

export const StyledButton = styled(Button)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
`;
