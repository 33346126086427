import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

import { ReactComponent as CheckMark } from '../../../../assets/icons/circle-checkmark-icon.svg';
import { Card } from '../../Card/Card';

type DefaultType = {
  checked?: boolean;
  disabled?: boolean;
};

export const StyledCard = styled(Card)<DefaultType>`
  position: relative;
  border-collapse: collapse;
  overflow: hidden;
  cursor: pointer;
  ${({ theme, disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.5;
      box-shadow: inset 0 0 0 0.063rem ${theme.color.dark2};
    `}
  ${({ theme, checked, disabled }) =>
    checked &&
    css`
      box-shadow: inset 0 0 0 0.188rem
        ${disabled ? theme.color.dark2 : theme.color.secondary3};
    `}
`;

export const StyledCheckMark = styled(CheckMark)`
  position: absolute;
  right: 1rem;
  top: 1rem;
`;
