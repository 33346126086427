import { StripeElementChangeEvent } from '@stripe/stripe-js';
import { useState } from 'react';

import {
  useFormErrorsActions,
  useFormErrorsValues,
} from '../FormErrorProvider/FormErrorProvider.hooks';

import { StripeCardDetailsFields } from './stripeCardDetailsForm.models';
import { getEmptyStripeFieldError } from './stripeCardDetailsForm.utils';

export const useStripeCardDetailsForm = () => {
  const { addError, removeErrors } = useFormErrorsActions();
  const { errors } = useFormErrorsValues();

  const [focusedFields, setFocusedFields] = useState<
    Record<StripeCardDetailsFields, boolean>
  >({
    cardExpiry: false,
    cardHolderName: false,
    cardNumber: false,
    cardCvc: false,
  });

  const [fields, setFields] = useState<
    Record<StripeCardDetailsFields, StripeElementChangeEvent>
  >({
    cardExpiry: null,
    cardHolderName: null,
    cardNumber: null,
    cardCvc: null,
  });

  const changeFocusState = (
    name: StripeCardDetailsFields,
    currState: boolean,
  ) => {
    setFocusedFields((state) => ({
      ...state,
      [name]: currState,
    }));
  };

  const addStripeErrors = (evt: StripeElementChangeEvent) => {
    if (evt.empty) {
      addError(getEmptyStripeFieldError(evt.elementType), {});
      return;
    }
    if (evt.error) {
      addError(
        {
          ErrorCode: evt.error.code,
          PropertyName: evt.elementType,
          InternalError: true,
        },
        {},
      );
      return;
    }

    removeErrors([evt.elementType]);
  };

  const onChangeValue = (evt: StripeElementChangeEvent) => {
    setFields((state) => ({
      ...state,
      [evt.elementType]: evt,
    }));

    addStripeErrors(evt);
  };

  const isLabelActive = (name: StripeCardDetailsFields) =>
    focusedFields[name] || (fields[name] && !fields[name].empty);

  const getFieldError = (name: StripeCardDetailsFields) => {
    const index = errors.findIndex((x) => x.field === name);
    return index !== -1 ? errors[index].message : null;
  };

  return {
    changeFocusState,
    focusedFields,
    onChangeValue,
    isLabelActive,
    getFieldError,
  };
};
