import Tooltip from '../Tooltip/Tooltip';
import { ReactComponent as InfoIcon } from '../../../assets/icons/info-icon.svg';
import { InfoComponentType } from './InfoComponent';
import { useInfoComponentMobile } from './infoComponent.hooks';

const InfoComponentMobile = ({
  tooltip,
  tooltipWidth,
  tooltipPlacement = 'left',
  className,
}: InfoComponentType) => {
  const { ref, showTooltip, onToggleTooltipHandler } = useInfoComponentMobile();

  return (
    <Tooltip
      ref={ref}
      width={tooltipWidth}
      title={tooltip}
      placement={tooltipPlacement}
      open={showTooltip}
    >
      <InfoIcon className={className} onClick={onToggleTooltipHandler} />
    </Tooltip>
  );
};

export default InfoComponentMobile;
