import { ReactNode } from 'react';

import { useTabletMediaQuery } from '../../../utils/mediaQueries';
import InfoComponentMobile from './InfoComponentMobile';
import InfoComponentDesktop from './InfoComponentDesktop';
import { TooltipPlacement } from '../Tooltip/Tooltip';
import { CustomComponent } from '../../../models/types/CustomComponent';

export type InfoComponentType = CustomComponent & {
  tooltip?: ReactNode;
  tooltipWidth?: string;
  tooltipPlacement?: TooltipPlacement;
};

const InfoComponent = (props: InfoComponentType) => {
  const isTablet = useTabletMediaQuery();

  return isTablet ? (
    <InfoComponentMobile {...props} />
  ) : (
    <InfoComponentDesktop {...props} />
  );
};

export default InfoComponent;
