import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';

import {
  useApiResponseError,
  useFormErrorsValues,
} from '../../../../../components/FormErrorProvider/FormErrorProvider.hooks';
import { useActions } from '../../../../../hooks/useActions';
import { useProcessing } from '../../../../../hooks/useProcessing';
import useStripeProvider from '../../../../../hooks/useStripeProvider';
import { useCancellationToken } from '../../../../../hooks/useTokenCancellation';
import { ICardSetupIntentModel } from '../../../../../models/interfaces/CardSetupIntentModel';
import { IStripeCreditCardInfo } from '../../../../../models/interfaces/StripeCreditCardInfo';
import { updateModalState } from '../../../../../store/redux-slices/modals';
import { CREDIT_CARD_ADD_PROCESSING } from '../../../../../store/redux-slices/processes/constants';
import { addCreditCard } from '../../../../../store/saga-slices/payment';
import { IAddCreditCardPayload } from '../../../../../store/saga-slices/payment/models';

type UseAddCreditCardModalForm = {
  onCreditCardAdded?: () => void;
  modalName: string;
};

export const useAddCreditCardModalForm = ({
  onCreditCardAdded,
  modalName,
}: UseAddCreditCardModalForm) => {
  const UpdateModalState = useActions(updateModalState);
  const AddCreditCard = useActions(addCreditCard);
  const cancellationToken = useCancellationToken();
  const alert = useAlert();
  const { t } = useTranslation();

  const creditCardAdding = useProcessing(CREDIT_CARD_ADD_PROCESSING);

  const { handleResponseError } = useApiResponseError();
  const { errors } = useFormErrorsValues();

  const stripe = useStripeProvider();

  const submitHandler = (creditCardInfo: IStripeCreditCardInfo) => {
    const payload: IAddCreditCardPayload = {
      cancellationToken,
      success: async (setupIntent: ICardSetupIntentModel) => {
        stripe.submitCardWithStripe(
          setupIntent,
          creditCardInfo,
          () => {
            alert.success(t('phrase.credit-card-was-added-successfully'));
            UpdateModalState(modalName);
            onCreditCardAdded && onCreditCardAdded();
          },
          () => alert.error(t('phrase.credit-card-was-not-added-successfully')),
        );
      },
      error: (err) => {
        alert.error(t('phrase.credit-card-was-not-added-successfully'));
        handleResponseError(err, creditCardInfo);
      },
    };

    AddCreditCard(payload);
  };

  const validateForm = () => errors;

  const onCancelHandler = () => {
    UpdateModalState(modalName);
  };

  return { onCancelHandler, submitHandler, validateForm, creditCardAdding };
};
