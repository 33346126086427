import Button from '../Button/Button';
import { ReactComponent as RemoveIcon } from '../../../assets/icons/remove-icon.svg';
import { CustomField } from '../../../models/types/CustomField';

type RemoveButtonProps = CustomField & {
  onRemove?: () => void;
};

const RemoveButton = ({ onRemove, className, disabled }: RemoveButtonProps) => (
  <Button
    className={className}
    disabled={disabled}
    styleType="icon"
    onClick={onRemove}
  >
    <RemoveIcon />
  </Button>
);

export default RemoveButton;
