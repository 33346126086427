import { createSelector } from 'reselect';

import { IPersonalDataState } from '.';
import { IPerson } from '../../../models/interfaces/Person';
import { IProfileModel } from '../../../models/interfaces/ProfileModel';
import { IUpdatePersonModel } from '../../../models/interfaces/UpdatePersonModel';
import { mapAddress } from '../../../utils/addressHelpers';
import { getFirstLetters } from '../../../utils/textHelpers';

import { RootState } from '../index';

export const personalDataSelector = (state: RootState) => state.personalData;

export const personSelector = createSelector(
  personalDataSelector,
  (personalData: IPersonalDataState): IPerson => personalData.person,
);

export const updatePersonSelector = createSelector(
  personSelector,
  (person: IPerson): IUpdatePersonModel =>
    person && {
      billingAddress: person.billingAddress,
      billingAddressSameAsResidenceAddress:
        person.billingAddressSameAsResidenceAddress,
      phone: person.phone,
      prefix: person.prefix,
      residenceAddress: person.residenceAddress,
    },
);

export const personNameSelector = createSelector(
  personSelector,
  (person: IPerson): string => [person?.firstName, person?.lastName].join(' '),
);

export const profileSelector = createSelector(
  personSelector,
  (person: IPerson): IProfileModel => ({
    ...person,
    personId: person.id,
    initials: getFirstLetters(`${person.firstName} ${person.lastName}`),
    residenceAddress: mapAddress(person.residenceAddress),
    billingAddress: mapAddress(person.billingAddress),
  }),
);
