import { useTranslation } from 'react-i18next';

import { FormFieldWrapper } from '../../../Form/Form';
import { ErrorLabel } from '../../../UI/Field/Field';
import { CustomStripeField } from '../../stripeCardDetailsForm.models';
import {
  StripeInputWrapper,
  StyledFieldLabel,
} from '../../StripeInputWrapper.styles';
import { StyledCardCvcElement } from './cardCvc.styles';

export const CardCvc = ({
  changeFocusState,
  isLabelActive,
  onChange,
  error,
  disabled,
  options,
  name,
}: CustomStripeField) => {
  const { t } = useTranslation();
  return (
    <FormFieldWrapper>
      <StripeInputWrapper isError={!!error}>
        <StyledCardCvcElement
          options={options}
          id={name}
          onFocus={() => changeFocusState(name, true)}
          onBlur={() => changeFocusState(name, false)}
          onChange={onChange}
        />
        <StyledFieldLabel
          htmlFor={name}
          disabled={disabled}
          autoLabelAnimation={false}
          labelActive={isLabelActive}
          isError={!!error}
        >
          {t('fields.cvc.label')}
        </StyledFieldLabel>
      </StripeInputWrapper>
      {error && <ErrorLabel>{`${error}`}</ErrorLabel>}
    </FormFieldWrapper>
  );
};
