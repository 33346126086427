import { Trans, useTranslation } from 'react-i18next';

import { formatDate } from '../../../../../utils/dateHelpers';
import { Membership as MembershipType } from '../../../../../models/types/Membership';

import {
  MembershipContainer,
  MembershipHeader,
  MembershipSection,
  MembershipSectionItem,
  SectionItemDescription,
  SectionItemTitle,
  ItemInfo,
  MembershipDetails,
  MembershipDetailItem,
  ExpireIcon,
  ExpirationDateWrapper,
} from '../Common/membership.styles';

import { PromoCode } from './PromoCode/PromoCode';
import { Members } from '../Common/Members/Members';
import MainTooltip from '../../../../../components/UI/Tooltip/Tooltip';
import AutoRenewFlag from './AutoRenewFlag/AutoRenewFlag';
import ActionsContainer from './ActionsContainer/ActionsContainer';
import AddAAFamilyMemberModal from './Modals/AddAAFamilyMemberModal/AddAAFamilyMemberModal';
import { useAAMembership } from './aaMembership.hooks';
import RemoveAAFamilyMemberModal from './Modals/RemoveAAFamilyMemberModal/RemoveAAFamilyMemberModal';
import MembershipTitle from '../Common/MembershipTitle/MembershipTitle';

export type AAMembershipProps = {
  membership: MembershipType;
  onFamilyMemberAdded?: () => void;
  onFamilyMemberRemoved?: () => void;
  onUpdateAutoRenewFlag?: (membership: MembershipType) => void;
  onRenew?: (membershipId: string) => void;
};

export const AAMembership = ({
  membership,
  onFamilyMemberAdded,
  onFamilyMemberRemoved,
  onUpdateAutoRenewFlag,
  onRenew,
}: AAMembershipProps) => {
  const { t } = useTranslation();

  const {
    addFamilyMemberHandler,
    removeFamilyMemberHandler,
    manualRenewButtonVisible,
    renewAvailable,
    showFamilyMembers,
    isRenewableByPlanType,
  } = useAAMembership(membership);

  const {
    membershipId,
    productType,
    membershipPlanTypeName,
    membershipPlanTypeDescription,
    membershipPlanGroupName,
    membershipPlanGroupDescription,
    memberType,
    promoCode,
    promoCodeOwner,
    effectiveDate,
    isExpired,
    isExpiring,
    expirationDate,
    autoRenewal,
    familyMembers,
    organizationName,
    metadata: { canRenew, canAddMemberTypes },
  } = membership;

  return (
    <>
      <AddAAFamilyMemberModal
        id={membershipId}
        onFamilyMemberAdded={onFamilyMemberAdded}
      />
      <RemoveAAFamilyMemberModal
        id={membershipId}
        onFamilyMemberDeleted={onFamilyMemberRemoved}
      />
      <MembershipContainer>
        <MembershipHeader>
          <MembershipTitle
            organizationName={organizationName}
            productType={productType}
          />
          <ActionsContainer
            manualRenewButtonVisible={manualRenewButtonVisible}
            membership={membership}
            onRenew={onRenew}
          />
        </MembershipHeader>
        <MembershipSection>
          <MembershipSectionItem>
            <ItemInfo>{t('properties.plan')}</ItemInfo>
            <SectionItemTitle>{membershipPlanTypeName}</SectionItemTitle>
            <SectionItemDescription>
              {membershipPlanTypeDescription}
            </SectionItemDescription>
          </MembershipSectionItem>
          <MembershipSectionItem>
            <ItemInfo>{t('properties.type')}</ItemInfo>
            <SectionItemTitle>{membershipPlanGroupName}</SectionItemTitle>
            <SectionItemDescription>
              {membershipPlanGroupDescription}
            </SectionItemDescription>
          </MembershipSectionItem>
        </MembershipSection>
        <MembershipDetails>
          <MembershipDetailItem>
            <ItemInfo>{t('properties.member')}</ItemInfo>
            {memberType}
          </MembershipDetailItem>
          {promoCode && (
            <MembershipDetailItem>
              <ItemInfo>{t('properties.promo-code')}</ItemInfo>
              <PromoCode
                promoCode={promoCode}
                promoCodeOwner={promoCodeOwner}
              />
            </MembershipDetailItem>
          )}
          <MembershipDetailItem>
            <ItemInfo>{t('properties.effectiveDate')}</ItemInfo>
            {formatDate(effectiveDate)}
          </MembershipDetailItem>
          <MembershipDetailItem>
            <ItemInfo>{t('properties.expirationDate')}</ItemInfo>
            <ExpirationDateWrapper>
              {formatDate(expirationDate)}
              {isExpiring && <ExpireIcon />}
            </ExpirationDateWrapper>
          </MembershipDetailItem>
          {renewAvailable && (
            <MainTooltip
              isShowing={!canRenew}
              title={<Trans>{t('phrase.this-option-is-not-available')}</Trans>}
            >
              <MembershipDetailItem>
                <ItemInfo>{t('properties.auto-renew')}</ItemInfo>
                <AutoRenewFlag
                  autoRenewal={autoRenewal}
                  canRenew={canRenew}
                  isRenewableByPlanType={isRenewableByPlanType}
                  onUpdateAutoRenewFlag={() =>
                    onUpdateAutoRenewFlag?.(membership)
                  }
                />
              </MembershipDetailItem>
            </MainTooltip>
          )}
        </MembershipDetails>
        {showFamilyMembers && (
          <Members
            onAddFamilyMember={() => addFamilyMemberHandler?.()}
            onRemoveFamilyMember={(member) =>
              removeFamilyMemberHandler?.(member)
            }
            canRemoveMember={!isExpired}
            canAddMember={!!canAddMemberTypes?.length}
            membersList={familyMembers}
          />
        )}
      </MembershipContainer>
    </>
  );
};
